import { Injectable } from '@angular/core';

import { ModalService } from 'src/app/components/modals/modal.service';
import { StorageManagerService } from './storage-manager.service';
import { UploadOverwriteWarningService } from './upload-overwrite-warning.service';
import { StorageApiService } from 'src/app/api/services/storage-api.service';
import { FileUploaderService } from './file-uploader.service';
import { UploadUriService } from './upload-uri.service';
import { FileCheckerService } from './file-checker.service';

export type UploadManagerConfig = {
  validType: string;
  addFile: Function;
  onUploadStatus: Function;
  detectChanges: Function;
  getFolderPath: Function;
  skipOverwrite?: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class UploadManagerService {

  static readonly STORAGE_UPLOAD_CHUNK_SIZE = 256 * 1024 * 100; // Multiple of Google required chunk size (256 * 1024)


  constructor(private modalService: ModalService,
    private storageApiService: StorageApiService,
    private storageManagerService: StorageManagerService,
    private fileUploaderService: FileUploaderService,
    private uploadUriService: UploadUriService,
    private uploadOverwriteWarningService: UploadOverwriteWarningService,
    private fileCheckerService: FileCheckerService
  ) {

  }

  getFileUploader(config) {
    let fileTypeWarned;

    let validExtensions = this.storageManagerService.getValidExtensionsList(config.validType);

    let FileUploader = this.fileUploaderService.getUploader();

    FileUploader._showInvalidExtensionsMessage = () => {
      var title = 'This file type is not supported';
      var message = FileUploader._getValidExtensionsMessage(validExtensions);

      this.modalService.showMessage(title, message);
    };

    FileUploader._getValidExtensionsMessage = (validExtensions) => {
      var prefix = validExtensions;
      var suffix = '';

      if (validExtensions.length > 1) {
        prefix = validExtensions.slice(0, validExtensions.length - 1);
        suffix = ' and ' + validExtensions[validExtensions.length - 1].toUpperCase();
      }

      return 'Rise Vision supports ' + prefix.join(', ').toUpperCase() + suffix + '.';
    };

    let checkFileType = (fileItem) => {
      if (!validExtensions.length) {
        return;
      }

      if (this.storageManagerService.fileHasValidExtension(fileItem.name || fileItem.file.name, validExtensions)) {
        return;
      }

      if (fileItem.file && fileItem.file.type && fileItem.file.type.indexOf('video') === 0 &&
        fileItem.encodingFileName) {
        return;
      }

      if (fileTypeWarned) {
        return true;
      }
      fileTypeWarned = true;
      FileUploader._showInvalidExtensionsMessage();
      return true;
    };

    FileUploader.currentFilePath = () => {
      return config.getFolderPath();
    };

    FileUploader.activeUploadCount = () => {
      return FileUploader.queue.length;
    };

    FileUploader._isUploading = () => {
      return FileUploader.activeUploadCount() > 0;
    };

    FileUploader.removeItem = (item) => {
      FileUploader.removeFromQueue(item);
      config.onUploadStatus(FileUploader._isUploading());
    };

    FileUploader.retryFailedUpload = (file) => {
      if (file.isError) {
        FileUploader.retryItem(file);
      }
    };

    FileUploader.retryFailedUploads = () => {
      FileUploader.queue.forEach((file) => {
        FileUploader.retryFailedUpload(file);
      });
    };

    FileUploader.cancelAllUploads = () => {
      FileUploader.removeAll();
      config.onUploadStatus(FileUploader._isUploading());
    };

    FileUploader.uploadSelectedFiles = (selectedFiles) => {
      return FileUploader.compress(selectedFiles)
        .then((fileItems) => {
          return FileUploader.addToQueue(fileItems);
        });
    };

    FileUploader.onAddingFiles = () => {
      this.uploadOverwriteWarningService.resetConfirmation();
    };

    FileUploader.onAfterAddingFile = (fileItem) => {
      console.info('onAfterAddingFile', fileItem.file.name);

      if (!fileItem.isRetrying) {
        fileItem.file.name = (config.getFolderPath() || '') + fileItem.file.name;
      }

      config.onUploadStatus(FileUploader._isUploading());

      var uploadFile = (resp) => {
        fileItem.url = resp.message;
        fileItem.taskToken = resp.taskToken;
        fileItem.encodingFileName = resp.newFileName;
        fileItem.chunkSize = UploadManagerService.STORAGE_UPLOAD_CHUNK_SIZE;

        if (checkFileType(fileItem)) {
          FileUploader.removeFromQueue(fileItem);
          config.onUploadStatus(FileUploader._isUploading());

          return;
        }

        if (config.skipOverwrite) {
          if (resp.isOverwrite) {
            FileUploader.removeFromQueue(fileItem);
            config.onUploadStatus(FileUploader._isUploading());
            config.addFile(fileItem.file);
          } else {
            FileUploader.uploadItem(fileItem, config.detectChanges);
          }
        } else {
          console.log(fileItem.file.name);
          return this.uploadOverwriteWarningService.checkOverwrite(
            resp,
            fileItem.file.name.startsWith('canva/')
          )
            .then(() => {
              FileUploader.uploadItem(fileItem, config.detectChanges);
            }).catch(() => {
              FileUploader.removeFromQueue(fileItem);
              config.onUploadStatus(FileUploader._isUploading());
            });
        }
      };

      return this.uploadUriService.getUri(fileItem.file)
        .then(uploadFile)
        .catch((resp) => {
          console.log('getURI error', resp);

          if (resp.message === 'Unencodable overwrite') {
            return this.uploadUriService.getUri(fileItem.file, true)
              .then(uploadFile);
          }

          FileUploader.notifyErrorItem(fileItem, resp.status);
        });

    };

    FileUploader.onBeforeUploadItem = (item) => {
      console.log('Attempting to upload', item.file.name);
    };

    FileUploader.onCancelItem = (item) => {
      FileUploader.removeFromQueue(item);
      config.onUploadStatus(FileUploader._isUploading());
    };

    FileUploader.onCompleteItem = (item) => {
      if (item.isCancel || !item.isSuccess) {
        config.onUploadStatus(FileUploader._isUploading());

        if (!item.isSuccess) {
          console.log('Failed to upload: ', item.file);
        }

        return;
      }

      var baseFile = {
        'name': item.encodingFileName || item.file.name,
        'updated': {
          'value': new Date().valueOf().toString()
        },
        'size': item.file.size,
        'type': item.file.type
      };

      // Retrieve to force thumbnail creation
      this.storageApiService.refreshFileMetadata(item.encodingFileName || item.file.name)
        .then((file) => {
          this.fileCheckerService.setCachedFile(file);

          config.addFile(file);
        }, (err) => {
          console.log('Error refreshing metadata', item.file.name, err);

          this.fileCheckerService.setCachedFile(baseFile);

          config.addFile(baseFile);
        })
        .finally(() => {
          FileUploader.removeFromQueue(item);
          config.onUploadStatus(FileUploader._isUploading());
          config.detectChanges();
        });
    };

    return FileUploader;
  }

}
