import { Component, Input } from '@angular/core';

@Component({
  selector: 'edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss']
})
export class EditAddressComponent {

  @Input() model: any = {};

}
