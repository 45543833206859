import { Component, OnDestroy } from '@angular/core';

import { ScrollingListService } from 'src/app/ajs-upgraded-providers';

import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { InvoiceService } from '../../services/invoice.service';
import { BillingApiService } from 'src/app/api/services/billing-api.service';
import { BillingUtilsService } from '../../services/billing-utils.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnDestroy {

  private subscription;

  public company = this.companyStateService.getCopyOfSelectedCompany();

  public subscriptions = this.scrollingListService(this.billingApiService.getSubscriptions.bind(this.billingApiService), {
    name: 'Subscriptions'
  });
  public invoices = this.scrollingListService(this.billingApiService.getInvoices.bind(this.billingApiService), {
    name: 'Invoices'
  });

  constructor(
    private broadcaster: BroadcasterService,
    private companyStateService: CompanyStateService,
    private scrollingListService: ScrollingListService,
    public currentPlanService: CurrentPlanService,
    private billingApiService: BillingApiService,
    private billingUtils: BillingUtilsService,
    public invoiceService: InvoiceService
  ) {
    this.invoiceService.generateToken();

    this.subscription = this.broadcaster.on('risevision.company.planStarted', () => {
      this.subscriptions.doSearch();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showSubscriptionLink(subscription) {
    if (subscription.customer_id !== this.company.id) {
      return false;
    } else if (this.billingUtils.isCancelled(subscription)) {
      return false;
    }

    return true;
  }

}