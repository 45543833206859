<div class="attribute-editor-component">
  <div class="attribute-editor-row">
    <div class="form-group has-feedback" [ngClass]="{ 'has-error': usernameStatus && usernameStatus !== 'VALID', 'has-success': usernameStatus === 'VALID' && username !== '' }">
      <label class="control-label" for="twitterUsername">Enter a username:</label>
      <input type="text" id="twitterUsername"  class="form-control u_ellipsis" [(ngModel)]="username" placeholder="@RiseVision" (debounceEvent)="save()">
      <streamline-icon class="overlay-icon form-control-feedback" aria-hidden="true" name="{{usernameStatus && usernameStatus !== 'VALID' ? 'exclamation' : 'checkmark'}}" width="14" height="12"></streamline-icon>
      <p class="help-block">
        @switch (usernameStatus) {
          @case ('INVALID_USERNAME') {
            <span>Please provide a valid username.</span>
          }
        }
      </p>
    </div>
  </div>
  <div class="attribute-editor-row">
    <div class="form-group has-feedback" [ngClass]="{ 'has-error': maxitemsStatus && maxitemsStatus !== 'VALID', 'has-success': maxitemsStatus === 'VALID' && maxitems }">
      <label class="control-label" for="twitterMaxitems">Number of Tweets in queue:</label>
      <input type="text" id="twitterMaxitems" class="form-control u_ellipsis" [(ngModel)]="maxitems" placeholder="Max {{MAX_ITEMS}}" (ngModelChange)="save()">
      <streamline-icon class="overlay-icon form-control-feedback" aria-hidden="true" name="{{maxitemsStatus && maxitemsStatus !== 'VALID' ? 'exclamation' : 'checkmark'}}" width="14" height="12"></streamline-icon>
      <p class="help-block">
        @switch (maxitemsStatus) {
          @case ('INVALID_RANGE') {
            <span>Please provide a number between 1 and {{MAX_ITEMS}}.</span>
          }
        }
      </p>
    </div>
  </div>
</div>
