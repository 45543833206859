<div id="credit-card-form">
  <!-- NEW CREDIT CARD -->
  <div id="new-credit-card-form" [shown]="creditCardService.paymentMethods.selectedCard.isNew">
    @if (creditCardService.paymentMethods.selectedCard.isNew) {
      <div class="row">
        <div class="col-md-12">
          <div class="form-group" [ngClass]="{ 'has-error': (cardholderName.dirty || parentForm.submitted) && cardholderName.invalid }">
            <label for="new-card-name" class="control-label">Cardholder Name: *</label>
            <input #cardholderName="ngModel" id="new-card-name" aria-required="true" type="text" class="form-control" name="cardholderName" data-stripe="name" [(ngModel)]="creditCardService.paymentMethods.newCreditCard.name" autocomplete="cc-name" required>
            <p class="text-danger" [shown]="(cardholderName.dirty || parentForm.submitted) && cardholderName.invalid">
              Choose a Cardholder Name.
            </p>
          </div>
        </div>
      </div>
    }
    <div class="row">
      <div class="col-md-12">
        <div class="form-group" [ngClass]="{ 'has-error': stripeElementError('new-card-number') }">
          <label for="new-card-number" class="control-label">Card Number: *</label>
          <div class="form-control" id="new-card-number"></div>
          <p class="text-danger" [shown]="stripeElementError('new-card-number')">
            Choose a Card Number.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group" [ngClass]="{ 'has-error': stripeElementError('new-card-expiry') }">
          <label for="new-card-expiry" class="control-label">Expiration Date: *</label>
          <div class="form-control" id="new-card-expiry"></div>
          <p class="text-danger" [shown]="stripeElementError('new-card-expiry')">
            Choose an Expiration Date Month and Year.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group" [ngClass]="{ 'has-error': stripeElementError('new-card-cvc') }">
          <label for="new-card-cvc" class="control-label">Security Code: *</label>
          <div class="form-control" id="new-card-cvc"></div>
          <p class="text-danger" [shown]="stripeElementError('new-card-cvc')">
            Choose a Security Code.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="toggleMatchBillingAddress" class="control-label mb-0">Cardholder Billing Address: *</label>
          @if (creditCardService.paymentMethods.newCreditCard.billingAddress) {
            <div class="flex-row">
              <madero-checkbox name="toggleMatchBillingAddress" id="toggleMatchBillingAddress" [(ngModel)]="creditCardService.paymentMethods.newCreditCard.useBillingAddress" ngDefaultControl></madero-checkbox>
              <span class="mr-3">Use Company Billing Address</span>
            </div>
          }
        </div>
      </div>
    </div>
    <!-- NEW CC ADDRESS -->
    <div id="new-card-address">
      @if (!creditCardService.paymentMethods.newCreditCard.useBillingAddress) {
        <address-form [addressObject]="creditCardService.paymentMethods.newCreditCard.address" [hideCompanyName]="true"></address-form>
      }
    </div>
    <!--  END NEW CC ADDRESS -->
  </div>
  <!-- END NEW CC -->
  <!-- EXISTING CREDIT CARD -->
  @if (!creditCardService.paymentMethods.selectedCard.isNew) {
    <div id="existing-credit-card-form">
      <div class="row">
        <div class="col-md-12">
          <label>Choose Payment Method:</label><br>
          @for (card of paymentSourcesService.existingCreditCards; track card) {
            <div class="madero-radio">
              <input type="radio" [(ngModel)]="creditCardService.paymentMethods.selectedCard" [value]="card" name="{{card.payment_source.id}}" id="{{card.payment_source.id}}" tabindex="1">
              <label for="{{card.payment_source.id}}" class="font-weight-normal">
                {{card.payment_source.card | cardDescription}}
              </label>
            </div>
          }
          <div class="mt-4">
            <a class="madero-link u_clickable" (click)="creditCardService.selectNewCreditCard()">Use A Different Payment Method</a>
          </div>
        </div>
        <!-- END EXISTING CC -->
      </div>
    </div>
  }
</div>
