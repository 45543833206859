import { Injectable, NgZone } from '@angular/core';

import * as _ from 'lodash';

import { AnalyticsFactory, BigQueryLogging, ExceptionHandler } from 'src/app/ajs-upgraded-providers';

import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { HubspotService } from 'src/app/components/logging/hubspot.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { ApiUtilsService } from 'src/app/api/services/api-utils.service';
import { AccountApiService } from 'src/app/api/services/account-api.service';
import { CompanyStateService } from './company-state.service';
import { UserApiService } from 'src/app/api/services/user-api.service';
import { UserStateService } from './user-state.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  loading;

  newUser;
  profile: any;
  company: any;

  constructor(private ngZone: NgZone,
    private apiUtils: ApiUtilsService,
    private broadcaster: BroadcasterService,
    private modalService: ModalService,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private accountApiService: AccountApiService,
    private userApiService: UserApiService,
    private currentPlanService: CurrentPlanService,
    private exceptionHandler: ExceptionHandler,
    private analyticsFactory: AnalyticsFactory,
    private bigQueryLogging: BigQueryLogging,
    private hubspotService: HubspotService) { }

  _reset() {
    this.newUser = true;

    this.profile = {
      settings: {}
    };
    this.company = {};
  }

  _checkNewUser() {
    return this.userApiService.getProfile(this.userStateService.getUsername())
      .catch((resp) => {
        let error = this.apiUtils.getError(resp);

        if (error && error.message === 'User has not yet accepted the Terms of Service') {
          this.newUser = false;
        } else {
          this.newUser = true;
        }
      });
  }

  _getAccount() {
    return this.accountApiService.getAccount()
      .catch(() => {
        return null;
      });
  }

  init() {
    this._reset();

    this.loading = true;

    Promise.all([this._getAccount(), this._checkNewUser()])
      .then((result) => {
        var account = result[0] || {};

        Object.assign(this.profile, _.pick(account, ['email', 'firstName', 'lastName', 'mailSyncEnabled', 'settings']));
        this.profile.email = this.profile.email || this.userStateService.getUsername();
        if (account.companyIndustry) {
          this.company.companyIndustry = account.companyIndustry;
        }
      })
      .finally(() => {
        this.loading = false;

        this.ngZone.run(() => {});
      });
  }

  _isEducation(companyIndustry) {
    return CompanyStateService.EDUCATION_INDUSTRIES.indexOf(companyIndustry) !== -1;
  }

  register() {
    var action;

    // Automatically subscribe education users on registration or if they were already subscribed
    this.profile.mailSyncEnabled = this.profile.mailSyncEnabled || this._isEducation(this.company.companyIndustry);

    this.loading = true;

    if (this.newUser) {
      action = this.accountApiService.addAccount(this.profile, this.company);
    } else {
      action = this.accountApiService.agreeToTerms(this.userStateService.getUsername(), this.profile);
    }

    action
      .then(() => {
        this.userStateService.refreshProfile()
          .finally(() => {
            if (this.newUser) {
              this.currentPlanService.initPlanTrial();
            }

            var userCompany = this.companyStateService.getCopyOfUserCompany();
            var userProfile = this.userStateService.getCopyOfProfile();
            this.analyticsFactory.track('User Registered', {
              'companyId': this.companyStateService.getUserCompanyId(),
              'companyName': this.companyStateService.getUserCompanyName(),
              'parentId': userCompany.parentId,
              'isNewCompany': this.newUser,
              'registeredDate': userProfile.creationDate,
              'invitationAcceptedDate': this.newUser ? null : new Date()
            });

            this.hubspotService.loadAs(this.userStateService.getUsername());

            this.bigQueryLogging.logEvent('User Registered');

            this.broadcaster.emit('risevision.user.authorized');

            this.loading = false;
          });
      })
      .catch((err) => {
        this.modalService.showMessage('Error', this.apiUtils.humanReadableError(err));
        this.exceptionHandler(err, 'User registration failed.', true);

        this.userStateService.refreshProfile();

        this.loading = false;
      });
  }

}