import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { ModalService } from 'src/app/components/modals/modal.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { AssignUsersModalComponent } from '../components/assign-users-modal/assign-users-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ScheduleActionsService {

  constructor(private modalService: ModalService,
    private featuresService: FeaturesService) { }

  assignUsersToSchedule (selectedUsers: string[]) {
    let isFeatureAvailable = this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_TEACHER_ROLE);

    if (!isFeatureAvailable) {
      this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_TEACHER_ROLE);

      return Promise.reject();
    }

    return this.modalService.showMediumModal(AssignUsersModalComponent, {
      backdrop: true,
      initialState: {
        selectedUsers
      }
    });
  }
}

angular.module('risevision.apps.services')
  .factory('scheduleActions', downgradeInjectable(ScheduleActionsService));
