import { Directive, Input, Output, EventEmitter, Injector, ElementRef } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

// This Angular directive will act as an interface to the "upgraded" AngularJS component
/* eslint-disable @angular-eslint/directive-class-suffix */
@Directive({selector: 'popup-date-picker'})
export class PopupDatePickerComponent extends UpgradeComponent {
  // The names of the input and output properties here must match the names of the
  // `<` and `&` bindings in the AngularJS component that is being wrapped
  @Input() ngModel!: Date;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() ngChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(elementRef: ElementRef, injector: Injector) {
    // We must pass the name of the directive as used by AngularJS to the super
    super('popupDatePicker', elementRef, injector);
  }

}
