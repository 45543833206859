import { Injectable } from '@angular/core';
import { CoreAPILoader, ExceptionHandler } from 'src/app/ajs-upgraded-providers';
import { TimelineDescriptionService } from 'src/app/components/timeline/timeline-description.service';

import { pick } from 'lodash';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom, map } from 'rxjs';
import { type ApiListResponse, type ApiResponse } from '../api';
import { type Schedule } from './schedule';
import type { ApiSearchLegacy } from 'src/app/api/services/api-utils.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleApiService extends ApiService<Schedule> {

  static readonly SCHEDULE_WRITABLE_FIELDS = [
    'name', 'content', 'distribution', 'distributeToAll',
    'timeDefined', 'startDate', 'endDate', 'startTime', 'endTime',
    'recurrenceType', 'recurrenceFrequency', 'recurrenceAbsolute',
    'recurrenceDayOfWeek', 'recurrenceDayOfMonth', 'recurrenceWeekOfMonth',
    'recurrenceMonthOfYear', 'recurrenceDaysOfWeek', 'scheduleType', 'playOnce',
    'subcompanyDistribution', 'distributeToSubcompanies', 'assignedUsers',
    'forceDistribution'
  ];
  static readonly SCHEDULE_AP_WRITABLE_FIELDS = ['name', 'content'];
  static readonly SCHEDULE_SEARCH_FIELDS = ['name', 'id'];

  constructor(
    protected httpClient: HttpClient,
    private timelineDescriptionService: TimelineDescriptionService,
    private coreAPILoader: CoreAPILoader,
    private userStateService: UserStateService,
    protected companyStateService: CompanyStateService,
    private exceptionHandler: ExceptionHandler
  ) {
    super(httpClient, companyStateService);
  }

  override getResourceUrl(): string {
    return '/v2/schedules';
  }

  override toServerModel(entity: Schedule): any {
    return pick(entity, this.userStateService.isAssignedPublisher() ?
      ScheduleApiService.SCHEDULE_AP_WRITABLE_FIELDS :
      ScheduleApiService.SCHEDULE_WRITABLE_FIELDS
    );
  }

  private _writeScheduleTypeName (schedule) {
    switch (schedule.scheduleType) {
      case 'override_replace':
        schedule.scheduleTypeName = 'Replace';
      break;
      case 'override_insert':
        schedule.scheduleTypeName = 'Include';
      break;
      default:
        schedule.scheduleTypeName = '';
    }
  }

  override fromServerModel(json: any): any {
    this._writeScheduleTypeName(json);
    return json;
  }

  private createSearchQuery (fields: string[], search: string): string {
    let query = '';

    for (let i in fields) {
      query += 'OR ' + fields[i] + ':~\"' + search + '\" ';
    }

    query = query.substring(3);

    return query.trim();
  }

  list (search: ApiSearchLegacy, cursor?: string): Promise<ApiListResponse<Schedule>> {
    let query = search.query ?
      this.createSearchQuery(ScheduleApiService.SCHEDULE_SEARCH_FIELDS, search.query) : '';
    query += search.filter ? (search.query ? ' AND ' : '') + search.filter : '';

    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('search', query)
      .set('cursor', cursor)
      .set('count', search.count ? search.count.toString() : '')
      .set('sort', search.sortBy ? search.sortBy + (search.reverse ? ' desc' : ' asc') : '');

    return firstValueFrom(super.listAsObservable(params)
      .pipe(
        map((resp) => {
          try {
            if (resp.items) {
              for (const item of resp.items) {
                item.useLocaldate = true;
                item.timeline = this.timelineDescriptionService.updateLabel(item);
              }
            }
          } catch(e) {
            this.exceptionHandler(e, 'Failed process schedules.', true);
          }
          return resp;
        })
      ));
  }

  get (scheduleId: string): Promise<ApiResponse<Schedule>> {
    return firstValueFrom(super.getAsObservable(scheduleId));
  }

  delete (scheduleId: string): Promise<ApiResponse<Schedule>> {
    return firstValueFrom(super.deleteAsObservable(scheduleId));
  }

  add (schedule: any, forceDistribution?: boolean): Promise<ApiResponse<Schedule>> {
    schedule.forceDistribution = forceDistribution;

    return firstValueFrom(super.addAsObservable(schedule));
  }

  update (scheduleId: string, schedule: any, forceDistribution?: boolean): Promise<ApiResponse<Schedule>> {
    if (!this.userStateService.isAssignedPublisher()) {
      schedule.forceDistribution = forceDistribution;
    }

    return firstValueFrom(super.updateAsObservable(scheduleId, schedule));
  }

  copy (scheduleId?: string, companyId?: string): Promise<any> {
    const obj = {
      'id': scheduleId,
      'companyId': companyId
    };

    console.debug('copy schedule called with', scheduleId, companyId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.schedule.copy(obj);
      })
      .then((resp) => {
        console.debug('copy schedule resp', resp);
        return resp;
      })
      .then(null, (e) => {
        console.error('Failed to copy schedule.', e);
        throw e;
      });
  }

  export () {
    const companyId = this.companyStateService.getSelectedCompanyId();

    console.debug('export schedules called with', companyId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.schedule.export({ companyId: companyId });
      })
      .then((resp) => {
        console.debug('export schedules resp', resp);
        return resp;
      })
      .then(null, (e) => {
        console.error('Failed to export schedules.', e);
        throw e;
      });
  }

  addPresentation (scheduleIds?: string[], playlistItem?: any): Promise<any> {
    const obj = {
      'scheduleIds': scheduleIds,
      'playlistItem': playlistItem
    };

    console.debug('addPresentation to schedule called with', scheduleIds);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.schedule.addPresentation(obj);
      })
      .then((resp) => {
        console.debug('addPresentation to schedule resp', resp);
        return resp.result;
      })
      .then(null, (e) => {
        console.error('Failed to add presentation to schedule.', e);
        throw e;
      });
  }

  removePresentation (scheduleIds?: string[], presentationId?: string): Promise<any> {
    const obj = {
      'scheduleIds': scheduleIds,
      'presentationId': presentationId
    };

    console.debug('removePresentation from schedule called with', scheduleIds);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.schedule.removePresentation(obj);
      })
      .then((resp) => {
        console.debug('removePresentation from schedule resp', resp);
        return resp.result;
      })
      .then(null, (e) => {
        console.error('Failed to remove presentation from schedule.', e);
        throw e;
      });
  }
}

angular.module('risevision.schedules.services')
  .factory('schedule', downgradeInjectable(ScheduleApiService));
