import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalComponent } from 'src/app/components/modals/modal.component';
import { ScheduleSelectorService } from '../../services/schedule-selector.service';

@Component({
  selector: 'add-to-schedule-modal',
  templateUrl: './add-to-schedule-modal.component.html',
  styleUrl: './add-to-schedule-modal.component.scss'
})
export class AddToScheduleModalComponent extends ModalComponent {

  constructor(public modalRef: BsModalRef,
    public scheduleSelectorService: ScheduleSelectorService
  ) {
    super(modalRef);
  }

  ok() {
    super.resolve();
  }

  dismiss() {
    super.reject();
  }

}
