<div class="row pt-5 purchase-footer">
  <div class="col-md-4 mb-5">
    <div class="title mb-4">
      <streamline-icon aria-hidden="true" name="lock" width="20" height="20"></streamline-icon>
      Secure checkout powered by:
    </div>
    <img class="" src="../images/checkout/chargebee-logo.png" alt="Chargebee" height="35" />
    <span class="text-muted text-sm mx-3">and</span>
    <img class="" src="../images/checkout/stripe-logo.png" alt="Stripe" height="35" />
  </div>

  <div class="col-md-4 mb-5">
    <div class="title mb-4">We accept:</div>
    <img class="" src="../images/checkout/credit-card-icons.png" alt="Visa, American Express, Discover, Mastercard" height="30" />
  </div>

  <div class="col-md-4 mb-5">
    <div class="title mb-3"> 
      <streamline-icon aria-hidden="true" name="help" width="20" height="20"></streamline-icon>
      Need help with something?
    </div>
    <p class="mb-2"><a class="madero-link" href="https://help.risevision.com/hc/en-us/articles/360041149991-Payment-instructions-and-common-questions-" target="_blank">Common Billing & Payment Questions</a></p>
    <p><a class="madero-link" href="#" (click)="helpWidgetFactory.showContactUs()">Contact Us</a></p>
  </div>
</div>
