import { Injectable, NgZone } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { JpegCompressorService } from './jpeg-compressor.service';
import { TusUploaderService } from './tus-uploader.service';
import { XhrUploaderService } from './xhr-uploader.service';

class FileItem {
  constructor(uploader, file, options?) {
    angular.extend(this, {
      url: uploader.url,
      alias: uploader.alias,
      headers: angular.copy(uploader.headers),
      formData: angular.copy(uploader.formData),
      withCredentials: uploader.withCredentials,
      method: uploader.method
    }, options, {
      uploader: uploader,
      domFileItem: file,
      file: {
        'lastModifiedDate': angular.copy(file.lastModifiedDate),
        'size': file.size,
        'type': file.type,
        'name': file.webkitRelativePath || file.name
      },
      isReady: false,
      isUploading: false,
      isUploaded: false,
      isSuccess: false,
      isCancel: false,
      isError: false,
      isUnsupportedFile: false,
      progress: 0,
      index: null
    });
  }
}

class FileUploader {

  loadBatchTimer = null;

  url = '/';
  alias = 'file';
  headers = {};
  queue = [];
  progress = 0;
  method = 'PUT'; //'POST';
  formData = [];
  queueLimit = 10;
  // Tentative tracking for total remaining files
  remainingFileCount = 0;
  withCredentials = false;
  isUploading = false;
  nextIndex = 0;

  // stubs
  currentFilePath;
  onAddingFiles;
  onAfterAddingFile;
  onBeforeUploadItem;
  onCancelItem;
  onCompleteItem;

  // external stubs
  _getValidExtensionsMessage;
  _showInvalidExtensionsMessage;
  _isUploading;
  activeUploadCount;
  uploadSelectedFiles;
  retryFailedUploads;
  retryFailedUpload;
  removeItem;
  cancelAllUploads;

  constructor(
    private ngZone: NgZone,
    private jpegCompressorService: JpegCompressorService,
    private xhrUploaderService: XhrUploaderService,
    private tusUploaderService: TusUploaderService
  ) {

  }

  compress(files) {
    var fileItems = [];
    for (var i = 0; i < files.length; i++) {
      fileItems.push(new FileItem(this, files[i]));
    }
    return fileItems.reduce((pChain, fileItem) => {
        return pChain.then(() => {
          return this.jpegCompressorService.compress(fileItem, this.currentFilePath());
        });
      }, Promise.resolve())
      .then(() => {
        return fileItems;
      });
  }

  addToQueue(fileItems) {
    var currItem = 0;
    this.remainingFileCount += fileItems.length;
    this.onAddingFiles();

    var enqueue = (fileItem) => {
      // Checks it's a file
      if (fileItem.file.size || fileItem.file.type) {
        this.queue.push(fileItem);
        this.onAfterAddingFile(fileItem);
      } else {
        console.log('File not added to queue: ', fileItem);
      }
    };

    return new Promise((resolve) => {
      var loadBatch = (() => {
        if (currItem < fileItems.length) {
          while (this.queue.length < this.queueLimit && currItem < fileItems.length) {
            enqueue(fileItems[currItem++]);
          }

          this.loadBatchTimer = setTimeout(loadBatch, 500);
        } else {
          this.loadBatchTimer = null;

          resolve(true);
        }

        this.progress = this.getTotalProgress();
        this.render();
      });

      loadBatch();
    });

  }

  removeFromQueue(value) {
    var index = this.getIndexOfItem(value);
    var item = this.queue[index];

    if (item && item.isUploading) {
      this.cancelItem(item);
    }

    if (index >= 0 && index < this.queue.length) {
      this.queue.splice(index, 1);
      this.remainingFileCount--;
    }

    this.progress = this.getTotalProgress();
  }

  removeAll() {
    if (this.loadBatchTimer) {
      clearTimeout(this.loadBatchTimer);
      this.loadBatchTimer = null;
    }

    for (var i = this.queue.length - 1; i >= 0; i--) {
      this.removeFromQueue(this.queue[i]);
    }
  }

  someEncoding() {
    return this.queue.some((item) => {
      return !!item.taskToken;
    });
  }

  uploadItem(value, detectChanges?) {
    var index = this.getIndexOfItem(value);
    var item = this.queue[index];

    if (!item) {
      return;
    }

    item.index = item.index || ++this.nextIndex;
    item.isReady = true;

    if (this.isUploading) {
      return;
    }

    this.isUploading = true;

    item.taskToken ? this.tusUploaderService.tusUpload(item, this) : this.xhrUploaderService.xhrTransport(item, this, detectChanges);

    this.render();
  }

  cancelItem(value) {
    var index = this.getIndexOfItem(value);
    var item = this.queue[index];
    if (!item || !item.isUploading) {
      return;
    }

    return item.tusAbort ? item.tusAbort() : item.xhr.abort();
  }

  retryItem(value) {
    var index = this.getIndexOfItem(value);
    var item = this.queue[index];

    if (!item) {
      return;
    }

    item.isReady = false;
    item.isUploading = false;
    item.isUploaded = false;
    item.isSuccess = false;
    item.isCancel = false;
    item.isError = false;
    item.isRetrying = true;
    item.progress = 0;

    this.onAfterAddingFile(item);
  }

  getErrorCount() {
    return this.queue.filter(f => {
      return f.isError === true;
    }).length;
  }

  getNotErrorCount() {
    return this.queue.filter(f => {
      return f.isError === false;
    }).length;
  }

  getIndexOfItem(value) {
    return angular.isNumber(value) ? value : this.queue.indexOf(value);
  }

  getNotUploadedItems() {
    return this.queue.filter(item => {
      return !item.isUploaded;
    });
  }

  getReadyItems() {
    return this.queue
      .filter(item => {
        return (item.isReady && !item.isUploading);
      })
      .sort((item1, item2) => {
        return item1.index - item2.index;
      });
  }

  getTotalProgress(value?) {
    var notUploaded = this.getNotUploadedItems().length;
    var uploaded = notUploaded ? this.queue.length - notUploaded : this.queue.length;
    var ratio = 100 / this.queue.length;
    var current = (value || 0) * ratio / 100;

    return Math.round(uploaded * ratio + current);
  }

  render() {
    setTimeout(() => {
      this.ngZone.run(() => {});
    });
  }

  notifyBeforeUploadItem(item) {
    item.isReady = true;
    item.isUploading = true;

    this.onBeforeUploadItem(item);
  }

  notifyProgressItem(item, progress) {
    var total = this.getTotalProgress(progress);
    this.progress = total;
    item.progress = progress;

    this.render();
  }

  notifyCancelItem(item) {
    item.isReady = false;
    item.isUploading = false;
    item.isCancel = true;

    this.onCancelItem(item);
  }

  notifyCompleteItem(item) {
    this.onCompleteItem(item);

    var nextItem = this.getReadyItems()[0];
    this.isUploading = false;

    if (angular.isDefined(nextItem)) {
      this.uploadItem(nextItem);
      return;
    }

    this.progress = this.getTotalProgress();
    this.render();
  }

  notifySuccessItem(item) {
    item.isReady = false;
    item.isUploading = false;
    item.isUploaded = true;
    item.isSuccess = true;
    item.progress = 100;
  }

  notifyErrorItem(item, status?) {
    item.isReady = false;
    item.isUploading = false;
    item.isUploaded = true;
    item.isError = true;
    item.isUnsupportedFile = status === 409;
  }

}

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {

  constructor(
    private ngZone: NgZone,
    private jpegCompressorService: JpegCompressorService,
    private xhrUploaderService: XhrUploaderService,
    private tusUploaderService: TusUploaderService
  ) { }

  getUploader() {
    return new FileUploader(
      this.ngZone,
      this.jpegCompressorService,
      this.xhrUploaderService,
      this.tusUploaderService
    );
  }
}

angular.module('risevision.apps.services')
  .factory('FileUploaderService', downgradeInjectable(FileUploaderService));