<ol class="breadcrumb">
  @for (folder of tree; track folder; let last = $last) {
    <li class="active">
      @if (!last && folder.path !== undefined) {
        <a class="madero-link u_clickable" (click)="clickFolder(folder.path)">{{folder.name}}</a>
      }
      @else {
        <span>{{folder.name}}</span>
      }
    </li>
  }
</ol>