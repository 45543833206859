<svg
  role="img"
  xmlns="http://www.w3.org/2000/svg"
  [attr.aria-label]="label"
  [attr.aria-hidden]="ariaHidden"
  [attr.width]="width"
  [attr.height]="height"
  [attr.viewBox]="viewBox"
  [innerHTML]="paths">
</svg>
