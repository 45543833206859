import { Component, Input, Output, EventEmitter, DoCheck, ViewChild, ChangeDetectorRef } from '@angular/core';

import { NgForm } from '@angular/forms';

import * as _ from 'lodash';

import { BackgroundParserService } from '../../services/background-parser.service';
import { EditorService } from '../../services/editor.service';
import { PresentationParserService } from '../../services/presentation-parser.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'placeholder-settings',
  templateUrl: './placeholder-settings.component.html',
  styleUrls: ['./placeholder-settings.component.scss']
})
export class PlaceholderSettingsComponent implements DoCheck {

  @ViewChild('placeholderForm')
  public placeholderForm: NgForm;

  companyId = this.companyStateService.getSelectedCompanyId();

  private _placeholder: any = {};
  @Input() set placeholder(value: any) {
    this._placeholder = value || {};

    this._init();
  }
  get placeholder(): any {
    return this._placeholder;
  }

  @Output() placeholderChange: EventEmitter<any> = new EventEmitter<any>();

  public background;
  private _oldBackground;

  public editingName = false;

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private companyStateService: CompanyStateService,
    private editorService: EditorService,
    private presentationParserService: PresentationParserService,
    private backgroundParser: BackgroundParserService) {
  }

  _init() {
    this.editingName = false;
    this.background = undefined;
    this._oldBackground = undefined;

    if (this.placeholder) {
      this.background = this.backgroundParser.parseBackground(
        this.placeholder.backgroundStyle,
        this.placeholder.backgroundScaleToFit
      );
    }
  }

  ngDoCheck(): void {
    if (!_.isEqual(this.background, this._oldBackground)) {
      this._applyBackgroundChanges();

      this._oldBackground = _.cloneDeep(this.background);
    }
  }

  _applyBackgroundChanges(): void {
    if (this.placeholder) {
      this.placeholder.backgroundStyle = this.backgroundParser.getStyle(this.background);
      this.placeholder.backgroundScaleToFit =
        this.backgroundParser.getScaleToFit(this.background);
    }
  }

  changeDetected(): void {
    this.changeDetectorRef.detectChanges();
  }

  updatePlaceholderName() {
    if (!this.editingName ||
      this.placeholderForm.controls['newId'].invalid ||
      this.placeholder.id === this.placeholder.newId) {
      return;
    }

    this.presentationParserService.updatePresentation(this.editorService.presentation);

    this.editingName = false;
  }


}
