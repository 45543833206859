import { ElementRef, Component, ChangeDetectorRef } from '@angular/core';
import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';

@Component({
  selector: 'template-component-twitter',
  templateUrl: './twitter.component.html',
  styleUrls: ['./twitter.component.scss']
})
export class TwitterComponent {
  public readonly MAX_ITEMS = 20;

  username = null;
  usernameStatus = null;
  componentId;
  maxitems;
  maxitemsStatus;

  constructor(private elementRef: ElementRef,
      private componentsFactory: ComponentsService,
      private attributeDataFactory: AttributeDataService,
      private changeDetectorRef: ChangeDetectorRef) {

    componentsFactory.registerDirective({
      type: 'rise-data-twitter',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsFactory.selected.id;
        this._load();
      }
    });
  }

  save() {
    if (this._validateUsername(this.username)) {
      this.attributeDataFactory.setAttributeData(this.componentId, 'username', this.username.replace('@', ''));
    }

    if (this._validateMaxitems(this.maxitems)) {
      this.attributeDataFactory.setAttributeData(this.componentId, 'maxitems', Number(this.maxitems));
    }
    this.changeDetectorRef.detectChanges();
  }

  _load() {
    var username = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'username');
    var maxitems = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'maxitems');

    this.username = username && username.indexOf('@') === -1 ? '@' + username : username;
    this.maxitems = maxitems;
    this._validateUsername(this.username);
    this._validateMaxitems(this.maxitems);
  }

  _validateUsername(username) {
    var usernameRegex = /^@?(\w){1,15}$/;

    if (username && usernameRegex.test(username)) {
      this.usernameStatus = 'VALID';
      return true;
    } else {
      this.usernameStatus = 'INVALID_USERNAME';
      return false;
    }
  }

  _validateMaxitems(maxitems) {
    if (maxitems && maxitems >= 1 && maxitems <= this.MAX_ITEMS) {
      this.maxitemsStatus = 'VALID';
      return true;
    } else if (maxitems) {
      this.maxitemsStatus = 'INVALID_RANGE';
      return false;
    } else {
      this.maxitemsStatus = null;
      return false;
    }
  }
}
