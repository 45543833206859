import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss']
})
export class UpgradePlanComponent extends ConfirmModalComponent {

  currentPlan = '';
  needsPlan = '';
  planExpired = false;
  parentPlan = false;

  constructor(
    public modalRef: BsModalRef
  ) {
    super(modalRef);
  }
}
