import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[scrollingList]'
})
export class ScrollingListDirective {

  INTERVAL_DELAY = 150;

  @Output() scrollEndEvent = new EventEmitter<any>();

  ignoreEvents = false;
  eventTimeout;

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    let target = event.target;
    let limit = target.scrollHeight - target.clientHeight;

    //load more rows if less than 20px left to the bottom
    if (!this.ignoreEvents && target.scrollTop && limit - target.scrollTop < 20) {
      // ignore events for a while
      this.ignoreEvents = true;
      clearTimeout(this.eventTimeout);
      this.eventTimeout = setTimeout(() => {
        this.ignoreEvents = false;
        this.eventTimeout = null;
      }, this.INTERVAL_DELAY);

      this.scrollEndEvent.emit({
        pos: target.scrollTop
      });
    }
  }
}
