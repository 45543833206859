<div class="form-group">
  <div class="flex-row">
    <mat-checkbox class="mr-1" name="allDayCheckbox" id="allDayCheckbox" [ngModel]="allDay" (ngModelChange)="updateAllDay($event)" />
    <span class="control-label u_clickable" (click)="updateAllDay(!allDay)">All Day</span>
  </div>
</div>
<div class="row form-group" [hidden]="allDay">
  <div class="col-sm-5">
    <label class="control-label">Start Time{{ isUTC ? ' UTC' : '' }}</label>
    <div class="time-picker">
      <timepicker
        id="startTime"
        name="startTime"
        [(ngModel)]="startTime"
        (ngModelChange)="updateStartTime()"
        #selectedStartTime="ngModel"
        hourStep="1"
        minuteStep="15"
        showMeridian="true">
      </timepicker>
    </div>
  </div>
  <div class="col-sm-5">
    <label class="control-label">End Time{{ isUTC ? ' UTC' : '' }}</label>
    <div class="time-picker">
      <timepicker
        id="endTime"
        name="endTime"
        [(ngModel)]="endTime"
        (ngModelChange)="updateEndTime()"
        #selectedEndTime="ngModel"
        hourStep="1"
        minuteStep="15"
        showMeridian="true">
      </timepicker>
    </div>
  </div>
</div>