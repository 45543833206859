import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptorService } from './interceptors/auth-interceptor.service';
import { LogInterceptorService } from './interceptors/log-interceptor.service';
import { ApiUtilsService } from './services/api-utils.service';
import { PresentationApiService } from './services/presentation-api.service';
import { ScheduleApiService } from './services/schedule-api.service';
import { CompanyApiService } from './services/company-api.service';
import { UserApiService } from './services/user-api.service';
import { StorageApiService } from './services/storage-api.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogInterceptorService,
      multi: true
    }
  ]
})
export class ApiModule {
  //workaround for including downgraded components into build files
  //https://github.com/angular/angular/issues/35314#issuecomment-584821399
  static entryComponents = [];
  static providers = [ ApiUtilsService, CompanyApiService, UserApiService, PresentationApiService, ScheduleApiService, StorageApiService ];
}
