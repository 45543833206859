import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StripeLoaderService {

  private _promise: Promise<any>;

  constructor() { }

  load() {
    if (this._promise) {
      return this._promise;
    }
    this._promise = new Promise<any>((resolve, reject) => {

      var checkInterval = setInterval(function () {
        if (window.Stripe) {
          clearInterval(checkInterval);

          resolve(window.Stripe(environment.STRIPE_API_KEY));
        }
      }, 50);
    });
    return this._promise;
  }
}
