import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

import { UIRouterGlobals } from '@uirouter/angular';

import { ExceptionHandler, UrlStateService, UserAuthFactory } from 'src/app/ajs-upgraded-providers';

import { ApiUtilsService } from 'src/app/api/services/api-utils.service';
import { CustomAuthService } from '../../services/custom-auth.service';
import { UserauthApiService } from 'src/app/api/services/userauth-api.service';
import { UserStateService } from '../../services/user-state.service';
import { GoogleAuthService } from '../../services/google-auth.service';

@Component({
  selector: 'create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

  credentials: any = {};
  messages: any = {};
  errors: any = {};

  joinAccount;
  companyName;
  invalidRequest;

  loading = false;
  ssoEnabled = false;
  usePassword = false;

  constructor(private sanitizer: DomSanitizer,
    private uiRouterGlobals: UIRouterGlobals,
    private $exceptionHandler: ExceptionHandler,
    private apiUtils: ApiUtilsService,
    private userStateService: UserStateService,
    private userauth: UserauthApiService,
    private userAuthFactory: UserAuthFactory,
    private googleAuthService: GoogleAuthService,
    private customAuthService: CustomAuthService,
    private urlStateService: UrlStateService
  ) { }

  ngOnInit(): void {
    this.joinAccount = this.uiRouterGlobals.params.joinAccount;
    this.companyName = decodeURIComponent(this.uiRouterGlobals.params.companyName);
    this.credentials.username = decodeURIComponent(this.uiRouterGlobals.params.email?.replace(/\p{Cf}/gu, '') ?? '');

    this.loading = this.joinAccount;

    if (this.uiRouterGlobals.params.authError && this.uiRouterGlobals.params.authError !== 'No user') {
      this._processErrorCode(this.uiRouterGlobals.params.authError, 'in');
    }

    if (this.uiRouterGlobals.params.email && this.uiRouterGlobals.params.hash) {
      this.credentials.hash = this.uiRouterGlobals.params.hash;
      this._checkSso().finally(() => {
        this.loading = false;
      });
    } else if (this.uiRouterGlobals.params.joinAccount) {
      this.invalidRequest = true;
      this.loading = false;
    }
  }

  private _processErrorCode(e, actionName) {
    var error = this.apiUtils.getError(e);
    this.errors.messageTitle = 'Something went wrong.';
    var message = error.message ? error.message :
      'Please try again or <a target="_blank" href="mailto:support@risevision.com">reach out to our Support team</a> if the problem persists.';

    if (e && e.status >= 400 && e.status < 500) {
      this.errors.genericError = true;
    } else if (e && (e.status === -1 || error.code === -1 || error.code === 0)) {
      this.errors.message = 'Please check your connection, or proxy and firewall settings, and try again.';
    } else if (e && e.code === 403 && message.indexOf('feature is not available') !== -1) {
      this.errors.message = 'Please upgrade your account to access Rise Vision with the Teacher user role.';
    } else {
      // Catch all errors including !e, e.status === 500, e.status === 503, etc
      this.errors.message = this.sanitizer.bypassSecurityTrustHtml(message);
    }
  }

  googleLogin() {
    this.loading = true;
    if (this.joinAccount && this.uiRouterGlobals.params.email && this.uiRouterGlobals.params.hash) {
      this.userStateService._state.joinaccountState = this.uiRouterGlobals.params;
    }
    this.googleAuthService.forceAuthenticate(!this.joinAccount)
      .finally(() => {
        this.loading = false;
      });
  }

  private _authenticate() {
    return this.userAuthFactory.authenticate(true)
      .then(() => {
        this.urlStateService.redirectToState(this.uiRouterGlobals.params.state);
      });
  }

  private _checkSso(): Promise<void> {
    return this.userauth.isSsoEnabled(this.credentials.username)
      .then((resp) => {
        this.ssoEnabled = resp?.result?.item;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  resendInviteEmail() {
    this.errors = {};
    this.messages = {};
    this.loading = true;

    this.userauth.requestPasswordReset(this.credentials.username)
      .then(() => {
        // The user is already activated
        this.messages.resetPassword = true;
      })
      .catch((err) => {
        if (err.status === 403) {
          this.messages.inviteResent = true;
        } else { // No special case for 404, for security reasons
          console.error(err);
          this.messages.inviteResent = false;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  createAccount(loginForm) {
    this.errors = {};
    this.messages = {};

    if (loginForm.valid) {
      this.loading = true;

      if (this.ssoEnabled && !this.usePassword) {
        window.location.href = environment.SSO_LOGIN_URL +
          '?id=' + encodeURIComponent(this.credentials.username) +
          '&url=' + window.location.origin;
      } else {
        this.customAuthService.addUser(this.credentials)
        .then(token => {
          if (token) {
            return this._authenticate();
          } else {
            this.messages.confirmationRequired = true;
          }
        })
        .catch((err) => {
          if (err && err.status === 401) {
            if (err.result?.error?.message === 'SSO login required') {
              this.$exceptionHandler(err, 'SSO login is required for ' + this.companyName, true);
              this.errors.ssoRequired = true;
            } else {
              this.$exceptionHandler(err, 'Invalid hash provided for ' + this.credentials.username, true);
              this.invalidRequest = true;
            }
          } else if (err && err.status === 409) {
            this.$exceptionHandler(err, 'Account already Registered: ' + this.credentials.username, true);
            this.errors.duplicateError = true;
          } else { // No special cases, for security reasons
            this.$exceptionHandler(err, 'Failed to Create Account.', true);
            this._processErrorCode(err, 'up');
          }
        })
        .finally(() => {
          this.loading = false;
        });
      }
    }
  }
}
