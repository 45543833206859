<select class="form-control" [(ngModel)]="month" (ngModelChange)="emitValue()">
  <option [ngValue]="0">January</option>
  <option [ngValue]="1">February</option>
  <option [ngValue]="2">March</option>
  <option [ngValue]="3">April</option>
  <option [ngValue]="4">May</option>
  <option [ngValue]="5">June</option>
  <option [ngValue]="6">July</option>
  <option [ngValue]="7">August</option>
  <option [ngValue]="8">September</option>
  <option [ngValue]="9">October</option>
  <option [ngValue]="10">November</option>
  <option [ngValue]="11">December</option>
</select>