import { Injectable } from '@angular/core';

import { OAuthService } from './oauth.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleApiOAuthService {
  PROVIDER = 'google';

  constructor(private oAuthService: OAuthService) { }

  getConnectionStatus(scope) {
    return this.oAuthService.getConnectionStatus(this.PROVIDER, scope);
  }

  authenticate(scope) {
    return this.oAuthService.authenticate(this.PROVIDER, scope);
  }

  getUserIdentifier() {
    return this.oAuthService.getUserIdentifier(this.PROVIDER);
  }

}
