<div class="attribute-editor-component folder-component-container" rvSpinner="loading-folder" [rvShowSpinner]="processing">
  <div class="attribute-editor-row">
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          @if (values.fileMissing) {
            <div class="panel">
              <div class="panel-body bg-warning">
                <i aria-hidden="true" class="fa fa-warning icon-left"></i>
                <span [shown]="values.type === 'storage-file'">
                  This file cannot be displayed properly because it does not exist in storage
                  (it may have been moved, renamed or deleted). Please select a new file.
                </span>
                <span [shown]="values.type === 'storage-folder'">
                  This folder is either empty, or it has been moved, renamed or deleted.
                  Please select a new file or folder.
                </span>
              </div>
            </div>
          }
          @else if (conversionError) {
            <div class="panel">
              <div class="panel-body bg-warning">
                <i aria-hidden="true" class="fa fa-warning icon-left"></i>
                <span>
                  The file could not be converted for use in the playlist.<br>
                  {{ conversionError }}
                </span>
              </div>
            </div>
          }
          @if (!isGooglePhotosAlbum) {
            <div class="flex-row flex-wrap space-between mb-3">
              @if (isDocument) {
                <span>
                  <span id="folder-component-folder-name" title="{{folderPath}}">
                    <strong>{{folderName}}</strong><br>
                    <em>{{values.assetFiles ? values.assetFiles + ' pages' : ''}}</em>
                  </span>
                </span>
              }
              @else {
                <span>
                  {{typeName}} <span id="folder-component-folder-name" title="{{folderPath}}" [shown]="!isGooglePhotosAlbum"> — {{folderName}}</span>
                </span>
              }
              @if (storageManagerService.isCanva(values.source)) {
                <div class="flex-row justify-end w-100 gap-2 mt-2">
                  <streamline-icon aria-hidden="true" name="canva" class="u_lh-1" width="16" height="16" />
                  <a id="folder-edit-with-canva" class="madero-link u_clickable" (click)="editWithCanva()">Edit with Canva</a>
                </div>
              }
              @else {
                <a tabindex="0" id="folder-component-source" class="madero-link u_clickable" (click)="selectMedia()" (keyup.enter)="selectMedia()">Edit</a>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </div>
  <google-photos [shown]="isGooglePhotosAlbum" [values]="values" (valuesChange)="onChange()"></google-photos>
  <div [hidden]="isGooglePhotosAlbum && !values.source || values.fileMissing">
    <div class="attribute-editor-row folder-component-list-duration border-top pt-4" [hidden]="values.type === 'storage-file' && !isDocument">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label>Show each {{ isDocument ? 'page' : 'image' }} for:</label>
            <div class="input-group">
              <input type="number" id="folder-component-duration" class="form-control" [(ngModel)]="values.duration" (change)="saveDuration()">
              <span class="input-group-addon">Seconds</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="attribute-editor-row" [ngClass]="{ 'border-top pt-4': values.type === 'storage-file' }" [hidden]="fileType === 'image' || isDocument">
      <div id="folder-component-volume">
        <label>Video volume:</label>
        <div class="row">
          <div class="col-xs-12">
            <input min="0" max="100" type="range"
              [(ngModel)]="values.volume"
              (change)="saveVolume()"
            >
          </div>
        </div>
        <p class="range-value">{{ values.volume }}</p>
      </div>
    </div>
    <div class="attribute-editor-row" [hidden]="values.type === 'storage-file' || isDocument">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label u_margin-sm-top">Transition between folder items:</label>
            <select id="folder-component-transition" class="form-control" [(ngModel)]="values.transition" (change)="saveTransition()">
              <option value="">No Transition</option>
              <option value="fadeIn">Fade In</option>
              <option value="slideFromLeft">Slide from left</option>
              <option value="slideFromRight">Slide from right</option>
              <option value="slideFromTop">Slide from top</option>
              <option value="slideFromBottom">Slide from bottom</option>
              <option value="zoomIn">Zoom in</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
