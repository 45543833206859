import { ElementRef, Component, ChangeDetectorRef, ViewChild, Renderer2, HostListener } from '@angular/core';
import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';
import { TemplateEditorUtilsService } from '../../services/template-editor-utils.service';
import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import * as _ from 'lodash';
import { PresentationUtilsService } from 'src/app/editor/services/presentation-utils.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'template-component-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent {
  public readonly positionOptions = [
    {
      name: 'Top',
      vertical: 'top'
    },
    {
      name: 'Middle',
      vertical: 'middle'
    },
    {
      name: 'Bottom',
      vertical: 'bottom'
    }
  ];

  public readonly formattingOptions = [
    'bold', 'italic', 'underline'
  ];

  public readonly alignmentOptions = [
    'left', 'center', 'right'
  ];

  public readonly webSafeFonts = [
    {name: 'Andale Mono', font: 'Andale Mono, monospace'},
    {name: 'Arial', font: 'Arial, sans-serif'},
    {name: 'Arial Black', font: 'Arial Black, sans-serif'},
    {name: 'Book Antiqua', font: 'Book Antiqua, serif'},
    {name: 'Comic Sans MS', font: 'Comic Sans MS, sans-serif'},
    {name: 'Courier New', font: 'Courier New, monospace'},
    {name: 'Georgia', font: 'Georgia, serif'},
    {name: 'Helvetica', font: 'Helvetica, sans-serif'},
    {name: 'Impact', font: 'Impact, sans-serif'},
    {name: 'Lucida Console', font: 'Lucida Console, monospace'},
    {name: 'Symbol', font: 'symbol'},
    {name: 'Tahoma', font: 'Tahoma, sans-serif'},
    {name: 'Terminal', font: 'Terminal, monospace'},
    {name: 'Times New Roman', font: 'Times New Roman, serif'},
    {name: 'Trebuchet MS', font:'Trebuchet MS, sans-serif'},
    {name: 'Verdana', font: 'Verdana, sans-serif'}
  ];

  public readonly fontPickerMaxHeight: number = 250;

  componentId;
  value;
  showPosition;
  position;
  fontsize;
  fontsizePlaceholder;
  minfontsize;
  maxfontsize;
  isMultiline;
  isFontPickerOpen: boolean = false;
  fonts = [{ name: 'Default', font: ''}].concat(this.webSafeFonts);
  font;
  fontName;
  formatting;
  color;
  lineheight;
  letterspacing;
  alignment;
  isTextVisible;
  isPlaylistItem;
  handleMessageBind;
  lastTrackedPropertyName;

  @ViewChild('fontPicker') fontPicker: ElementRef;

  constructor(private elementRef: ElementRef,
      private renderer: Renderer2,
      private componentsFactory: ComponentsService,
      private attributeDataFactory: AttributeDataService,
      private templateEditorUtils: TemplateEditorUtilsService,
      private changeDetectorRef: ChangeDetectorRef,
      private presentationUtils: PresentationUtilsService,
      private analyticsFactory: AnalyticsFactory,
      private companyStateService: CompanyStateService) {
    componentsFactory.registerDirective({
      type: 'rise-text',
      element: this.elementRef.nativeElement,
      show: (options) => {
        this.componentId = this.componentsFactory.selected.id;

        if (this.fontPicker) {
          this.renderer.setStyle(this.fontPicker.nativeElement, 'maxHeight', this.fontPickerMaxHeight + 'px');
        }

        this.isPlaylistItem = options?.playlist;

        // make the text input visible all the time until layering issue is fixed in all templates
        // see https://github.com/Rise-Vision/html-template-library/issues/3143
        const layeringIssue = true;
        this.isTextVisible = layeringIssue || this.isPlaylistItem || this.presentationUtils.isMobileBrowser();

        this._load();
      },
      getName: (componentId) => {
        return this.attributeDataFactory.getAvailableAttributeData(componentId, 'value');
      }
    });

    this.handleMessageBind = this.handleMessageFromRiseText.bind(this);
    window.addEventListener('message', this.handleMessageBind);
  }

  _loadAlignment() {
    var textalign = this. attributeDataFactory.getAvailableAttributeData(this.componentId, 'textalign');
    var vertical = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'verticalalign');
    var horizontal = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'horizontalalign');

    this.position = _.find(this.positionOptions, (o) => {
      return o.vertical === vertical;
    });
    this.showPosition = !!this.position;


    // ensure backwards compatibility by falling back to horizontalalign
    this.alignment = textalign ? textalign : this.showPosition ? horizontal : '';
  }

  _load() {
    this.isMultiline = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'multiline');
    var value = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'value');
    var fontsize = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'fontsize');
    var minfontsize = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'minfontsize');
    var maxfontsize = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'maxfontsize');
    var font = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'font');
    var formatting = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'formatting');
    var color = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'color');
    var lineheight = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'lineheight');
    var letterspacing = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'letterspacing');
    var fontSelected = _.find(this.fonts, (obj) => {
      return obj.font === font;
    });

    var fontsizeInt = this.templateEditorUtils.intValueFor(fontsize, null);
    var letterspacingInt = this.templateEditorUtils.floatValueFor(letterspacing, null);
    this.minfontsize = this.templateEditorUtils.intValueFor(minfontsize, 1);
    this.maxfontsize = this.templateEditorUtils.intValueFor(maxfontsize, 200);
    this.fontsizePlaceholder = this.attributeDataFactory.getBlueprintData(this.componentId, 'fontsize') || "Default";

    this.value = value;
    this.fontsize = fontsizeInt;
    this.font = fontSelected ? fontSelected.font : this.fonts[0].font;
    this.fontName = fontSelected ? fontSelected.name : this.fonts[0].name;
    this.formatting = formatting ? formatting : '';
    this.color = color ? color : '';
    this.lineheight = lineheight ? lineheight.toString() : '';
    this.letterspacing = letterspacingInt;

    this._loadAlignment();
  }

  handleMessageFromRiseText(event) {
    var data = event.data;

    if ('string' === typeof event.data) {
      try {
        data = JSON.parse(event.data);
      } catch (e) {}
    }

    if (data.type === 'rise-text-change' && (this.componentId === data.id || this.isPlaylistItem)) {
      this.value = data.value;
      this.save('value');
    }
  }

  refreshUI() {
    this.changeDetectorRef.detectChanges();
  }

  save(propertyName) {
    this.attributeDataFactory.setAttributeData(this.componentId, 'value', this.value);
    this.attributeDataFactory.setAttributeData(this.componentId, 'font', this.font);
    this.attributeDataFactory.setAttributeData(this.componentId, 'formatting', this.formatting);
    this.attributeDataFactory.setAttributeData(this.componentId, 'color', this.color);
    this.attributeDataFactory.setAttributeData(this.componentId, 'fontsize', this.fontsize);
    this.attributeDataFactory.setAttributeData(this.componentId, 'lineheight', this.lineheight ? parseFloat(this.lineheight) : "");
    this.attributeDataFactory.setAttributeData(this.componentId, 'letterspacing', this.letterspacing);
    this.attributeDataFactory.setAttributeData(this.componentId, 'textalign', this.alignment);
    this.attributeDataFactory.setAttributeData(this.componentId, 'horizontalalign', this.alignment);

    if (this.showPosition) {
      this.attributeDataFactory.setAttributeData(this.componentId, 'verticalalign', this.position.vertical);
    }
    this.refreshUI();

    if (propertyName && propertyName !== this.lastTrackedPropertyName) {
      this.lastTrackedPropertyName = propertyName;
      this.analyticsFactory.track('Rise Text Property Updated', {
        companyId: this.companyStateService.getSelectedCompanyId(),
        propertyName,
      });
    }

  }

  closeFontPicker() {
    this.isFontPickerOpen = false;
  }

  onFontPickerClick() {
    this.isFontPickerOpen = !this.isFontPickerOpen;
  }

  onFontPickerOptionClick(fontFamily: any) {
    this.font = fontFamily.font;
    this.fontName = fontFamily.name;

    this.save('font');
  }

  onFontPickerKeyDown(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 27: // Escape
        this.closeFontPicker();
        break;
      default:
        return; // exit this function for other keys
    }
    event.preventDefault(); // prevent the default action (scroll / move caret)
  }

  onFormattingClick(option: string): void {
    if (this.formatting) {
      const formats = this.formatting.split(',');
      const index = formats.indexOf(option);

      if (index !== -1) {
        formats.splice(index, 1);
      } else {
        formats.push(option);
      }

      this.formatting = formats.toString();
    } else {
      this.formatting = option;
    }

    this.save('formatting');
  }

  isFormatSelected(option: string): boolean {
    if (!this.formatting) {
      return false;
    }

    const formats = this.formatting.split(',');

    return formats.includes(option);
  }

  isValidFontSize() {
    if (!this.fontsize) {
      return true;
    }

    return this.fontsize >= this.minfontsize && this.fontsize <= this.maxfontsize;
  }

  onColorChange() {
    this.attributeDataFactory.setAttributeData(this.componentId, 'color', this.color);
  }

  onAlignmentClick(option: string): void {
    this.alignment = option;

    this.save('alignment');
  }

  isAlignmentSelected(option: string): boolean {
    return this.alignment && this.alignment === option;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement: HTMLElement) {
    if (this.fontPicker && this.isFontPickerOpen) {
      const clickedInside = this.fontPicker.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.closeFontPicker();
        this.refreshUI();
      }
    }

  }

}
