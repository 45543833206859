import { Component, Input, OnInit, DoCheck, KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
import { StorageUtilsService } from '../../services/storage-utils.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'thumbnail-image',
  templateUrl: './thumbnail-image.component.html',
  styleUrls: ['./thumbnail-image.component.scss']
})
export class ThumbnailImageComponent implements OnInit, DoCheck {

  @Input() folderPath: string = '';
  @Input() file: any;
  fileDiffer: KeyValueDiffer<string, any>;
  classes = [];
  imgClasses = '';
  isDisabled = false;
  isSvg = true;
  imgSrc = 'https://s3.amazonaws.com/Rise-Images/UI/storage-file-icon%402x.png';

  constructor (
    private storageService: StorageService,
    private storageUtilsService: StorageUtilsService,
    private keyValueDiffers: KeyValueDiffers
  ) { }

  ngOnInit(): void {
    this.fileDiffer = this.keyValueDiffers.find(this.file).create();
  }

  ngDoCheck(): void {
    const fileChanges = this.fileDiffer.diff(this.file);
    if (fileChanges) {
      this.fileChanged(fileChanges);
    }
  }

  fileChanged(changes: KeyValueChanges<string, any>) {
    this.classes = [];
    if (this.file.selected || this.file.isChecked) {
      this.classes.push('list-item--selected');
    }

    if (this.storageUtilsService.fileIsFolder(this.file)) {
      this.imgSrc = 'folder';
      this.classes.push('list-item_folder');
    } else {
      this.classes.push('single-item');
      if (this.file.isThrottled) {
        this.classes.push('list-item--throttled');
      } else {
        if (!this.storageService.canSelect(this.file) ||
          this.storageService.isDisabled(this.file)) {
          this.classes.push('list-item--disabled');
          this.isDisabled = true;
        } else {
          this.classes.push('list-type_image');
          this.isDisabled = false;
        }

        if (this.file.metadata && this.file.metadata.thumbnail) {
          this.isSvg = false;
          this.imgSrc = this.file.metadata.thumbnail + '?_=' +
            (this.file.timeCreated && this.file.timeCreated.value);
          if (this.imgSrc.indexOf('http://') === 0) {
            this.imgSrc = this.imgSrc.replace('http://', 'https://');
          }
        } else if (this.storageService.fileIsImage(this.file)) {
          this.imgSrc = 'image';
        } else if (this.storageService.fileIsVideo(this.file)) {
          this.imgSrc = 'video';
        } else {
          this.imgSrc = 'file';
        }
      }
    }

    this.imgClasses = this.isDisabled && !this.file.isThrottled ?
      'disabled' : '';
  }

}
