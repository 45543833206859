import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullAddress'
})
export class FullAddressPipe implements PipeTransform {

  transform(company: any): string {
    var res = (company.street ? company.street + ', ' : '') +
      (company.city ? company.city + ', ' : '') +
      (company.province ? company.province + ', ' : '') +
      (company.country ? company.country + ', ' : '') +
      (company.postalCode ? company.postalCode + ', ' : '');
    if (res) {
      res = res.substr(0, res.length - 2);
    }
    return res;
  }

}
