import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

import { PurchaseService } from '../../services/purchase.service';
import { PurchaseStepsService } from '../../services/purchase-steps.service';
import { TaxExemptionService } from '../../services/tax-exemption.service';

@Component({
  selector: 'purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent {

  @Input() redirectTo: string;

  constructor(private location: Location,
    public purchaseService: PurchaseService,
    public purchaseStepsService: PurchaseStepsService,
    public taxExemptionService: TaxExemptionService) {

    this.purchaseService.init();
    this.purchaseStepsService.init();
    this.taxExemptionService.init(this.purchaseService.getEstimate);
  }

  redirect() {
    this.location.go(this.redirectTo);
  }

  close() {
    if (!this.purchaseService.purchase.reloadingCompany) {
      this.redirect();
    } else {
      this.purchaseService.loading = true;

      this.purchaseService.onCompanyReload(() => {
        this.purchaseService.loading = false;

        this.redirect();
      });
    }
  }

}