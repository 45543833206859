import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import { PlaylistComponentService } from '../services/playlist-component.service';
import { TemplateEditorService } from '../../services/template-editor.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';

@Injectable({
  providedIn: 'root'
})
export class MediaSelectorService {

  public static readonly FOLDER_COMPONENT = 'rise-folder';
  public static readonly FOLDER_TYPE = {
    STORAGE_FOLDER: 'storage-folder',
    STORAGE_FILE: 'storage-file'
  };

  onMediaUpdate;
  onUserComponentAdd;
  editingCanva = false;

  constructor(private playlistComponentService: PlaylistComponentService,
    private storageUtilsService: StorageUtilsService,
    private analyticsFactory: AnalyticsFactory,
    private templateEditorService: TemplateEditorService,
    private storageManagerService: StorageManagerService,
    private companyStateService: CompanyStateService
  ) { }

  _getType(file) {
    return this.storageUtilsService.isFolder(file.name) ?
      MediaSelectorService.FOLDER_TYPE.STORAGE_FOLDER : MediaSelectorService.FOLDER_TYPE.STORAGE_FILE;
  }

  _createBasicItem(type: string): any {
    return {
      tagName: MediaSelectorService.FOLDER_COMPONENT,
      'play-until-done': true,
      attributes: {
        type,
        environment: environment.production ? 'prod' : 'test'
      }
    };
  }

  private _trackFolderUsage (folderType) {
    this.analyticsFactory.track('Folder Component Updated', {
      folderType: folderType,
      companyId: this.companyStateService.getSelectedCompanyId(),
      presentationId: this.templateEditorService.presentation.id,
      presentationName: this.templateEditorService.presentation.name
    });
  }

  storageFilesSelected(selectedItems?: any[], fileType?: string) {
    if (!selectedItems || selectedItems.length === 0) {
      return;
    }

    if (this.onMediaUpdate) {
      const filePath = this.storageUtilsService.getFilePath(selectedItems[0]);
      const type = this._getType(selectedItems[0]);

      this.onMediaUpdate(filePath, type);

      this._trackFolderUsage(type);
    } else {
      this._addSelected(selectedItems, fileType);
    }
  }

  _addItems(items, fileType?: string) {
    const editItem = (items[0] && items.length === 1);

    if (this.onUserComponentAdd) {
      return this.onUserComponentAdd(items, editItem, fileType);
    }

    if (this.playlistComponentService.onAddHandler && !this.editingCanva) {
      this.playlistComponentService.onAddHandler(items, editItem);
    }
  }

  _addSelected(selectedItems: any[], fileType?: string) {
    let items = [];
    selectedItems.forEach((item) => {
      const type = this._getType(item);
      const newItem = this._createBasicItem(type);

      if (this.storageManagerService.isImage(item.name)) {
        newItem['play-until-done'] = false;
      }

      newItem.attributes.source = this.storageUtilsService.getFilePath(item);

      items.push(newItem);

      this._trackFolderUsage(type);
    });

    this._addItems(items, fileType);
  }
}
