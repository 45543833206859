import { Component, OnDestroy, NgZone } from '@angular/core';

import { ComponentsService } from '../../services/components.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { UserComponentsService } from '../../template-components/services/user-components.service';

@Component({
  selector: 'template-attribute-editor',
  templateUrl: './template-attribute-editor.component.html',
  styleUrls: ['./template-attribute-editor.component.scss']
})
export class TemplateAttributeEditorComponent implements OnDestroy {
  private handleMessageBind;

  constructor(public componentsFactory: ComponentsService,
    private ngZone: NgZone,
    public attributeDataService: AttributeDataService,
    public userComponentsService: UserComponentsService,
    ) {
    this.componentsFactory.reset();

    this.handleMessageBind = this._handleMessageFromTemplate.bind(this);

    window.addEventListener('message', this.handleMessageBind);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.handleMessageBind);
  }

  _handleMessageFromTemplate(event) {
    var data = event.data;

    if ('string' === typeof event.data) {
      try {
        data = JSON.parse(event.data);
      } catch (e) {}
    }

    switch(data.type) {
      case 'deleteComponent':
        this.userComponentsService.removeUserComponent(data.value.id);
        this.componentsFactory.editHighlightedComponent(null);
        this.ngZone.run(() => {});
        break;
      case 'editComponent':
        this.componentsFactory.editHighlightedComponent(data.value);
        this.ngZone.run(() => {});
        break;
      case 'saveComponentPosition':
        const customization = this.attributeDataService.getAttributeData(data.value.id, 'customization') || {};
        const updatedCustomization = {...customization, ...data.value.customization};
        this.attributeDataService.setAttributeData(data.value.id, 'customization', updatedCustomization);
        this.ngZone.run(() => {});
        break;
      default:
    }

  }
}
