import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as msal from '@azure/msal-browser';
import { OnedriveFilePickerComponent } from '../../components/onedrive-file-picker/onedrive-file-picker.component';
import { LoadingService } from 'src/app/components/loading/loading.service';

const FILE_PICKER_APP_CLIENT_ID = 'a9f0b46b-033c-44d5-b406-16b2e7816f0e';

@Injectable({
  providedIn: 'root',
})
export class OneDriveFilePickerService {
  private _port: MessagePort;
  private _win: Window;
  private _modal;
  private _msalApp;
  private _successCallback: (value: unknown) => void;
  private _errorCallback: (reason?: any) => void;
  private _channelId = Date.now();

  constructor(
    private modalService: BsModalService,
    private loadingService: LoadingService,
  ) {
    this._channelMessageListener = this._channelMessageListener.bind(this);
    this._initializeMessageListener = this._initializeMessageListener.bind(this);
  }

  _combine(...paths) {
    return paths
      .map((path) => path.replace(/^[\\|/]/, '').replace(/[\\|/]$/, ''))
      .join('/')
      .replace(/\\/g, '/');
  }

  _createMsalApp(isPersonalAccount) {

    const msalParams = {
      auth: {
        authority: isPersonalAccount
          ? 'https://login.microsoftonline.com/consumers'
          : 'https://login.microsoftonline.com/common',
        clientId: FILE_PICKER_APP_CLIENT_ID,
        redirectUri: window.location.origin,
      },
    };

    return msal.createStandardPublicClientApplication(msalParams);
  }

  async _getToken(command) {
    if (!this._msalApp) {
      const isPersonalAccount = !['SharePoint', 'SharePoint_SelfIssued'].includes(command.type);
      this._msalApp = await this._createMsalApp(isPersonalAccount);
    }

    let accessToken = '';
    let authParams = null;

    switch (command.type) {
      case 'SharePoint':
      case 'SharePoint_SelfIssued':
        authParams = {
          scopes: [
            `${this._combine(command.resource, 'AllSites.Read')}`,
            `${this._combine(command.resource, 'MyFiles.Read')}`,
          ],
        };
        break;
      default:
        authParams = { scopes: ['OneDrive.ReadOnly'] };
        break;
    }

    try {
      const resp = await this._msalApp.acquireTokenSilent(authParams);
      accessToken = resp.accessToken;
    } catch (e) {
      // fall back to popup
      const resp = await this._msalApp.loginPopup(authParams);
      this._msalApp.setActiveAccount(resp.account);

      if (resp.idToken) {
        const resp2 = await this._msalApp.acquireTokenSilent(authParams);
        accessToken = resp2.accessToken;
      }
    }

    return accessToken;
  }

  async _openWindow(isPersonalAccount, baseUrl, accessToken) {
    const options = {
      sdk: '8.0',
      entry: {
        oneDrive: {},
      },
      authentication: {},
      messaging: {
        origin: window.location.origin,
        channelId: this._channelId,
      },
      typesAndSources: {
        mode: 'files',
        filters: ['.pptx'],
      },
    };

    this._modal = this.modalService.show(OnedriveFilePickerComponent,
      {
        class: 'madero-style modal-lg'
      }
    );

    this._modal.onHide.subscribe(() => {
      if (this._modal) {
        this._successCallback(null);
        this._close();
      }
    });

    const _iframe = document.getElementById('filePickerIFrame') as HTMLIFrameElement;
    this._win = _iframe.contentWindow;

    const queryString = new URLSearchParams({
      filePicker: JSON.stringify(options),
      locale: 'en-us',
    });

    const urlPath = isPersonalAccount ? '' : '/_layouts/15/FilePicker.aspx';
    const url = `${baseUrl}${urlPath}?${queryString}`;

    const form = this._win.document.createElement('form');
    form.setAttribute('action', url);
    form.setAttribute('method', 'POST');

    const tokenInput = this._win.document.createElement('input');
    tokenInput.setAttribute('type', 'hidden');
    tokenInput.setAttribute('name', 'access_token');
    tokenInput.setAttribute('value', accessToken);
    form.appendChild(tokenInput);

    this._win.document.body.append(form);

    // submit the form, this will load the picker page
    form.submit();

    // picker will send a message when ready
    window.addEventListener(
      'message',
      this._initializeMessageListener
    );
  }

  _initializeMessageListener(event: MessageEvent): void {
    if (event.source && event.source === this._win) {
      const message = event.data;

      // On initial load and if it ever refreshes in its window, the Picker will send an 'initialize' message.
      // Communication with the picker should subsequently take place using a `MessageChannel`.
      if (message.type === 'initialize' && message.channelId === this._channelId) {
        this._port = event.ports[0];
        this._port.addEventListener(
          'message',
          this._channelMessageListener
        );

        // start ("open") the port
        this._port.start();

        // tell the picker to activate
        this._port.postMessage({
          type: 'activate',
        });
      }
    }
  }

  async _channelMessageListener(message: MessageEvent): Promise<void> {
    const payload = message.data;

    switch (payload.type) {
      case 'notification':
        if (payload.data.notification === 'page-loaded') {
          this.loadingService.stop("onedrive-file-picker-modal");
        }
        break;

      case 'command':
        // all commands must be acknowledged
        this._port.postMessage({
          type: 'acknowledge',
          id: message.data.id,
        });

        const command = payload.data;

        switch (command.command) {
          case 'authenticate':
            try {
              const token = await this._getToken(command);

              if (!token) {
                throw new Error('Unable to obtain a token.');
              }

              this._port.postMessage({
                type: 'result',
                id: message.data.id,
                data: {
                  result: 'token',
                  token: token,
                },
              });
            } catch (error) {
              this._port.postMessage({
                type: 'result',
                id: message.data.id,
                data: {
                  result: 'error',
                  error: {
                    code: 'unableToObtainToken',
                    message: error.message,
                  },
                },
              });
            }

            break;

          case 'close':
            this._successCallback(null);
            this._close();

            break;

          case 'pick':
            try {
              // console.log(`Picked: ${JSON.stringify(command)}`);

              const fileInfo = message.data.data.items[0];

              // let the picker know that the pick command was handled (required)
              this._port.postMessage({
                type: 'result',
                id: message.data.id,
                data: {
                  result: 'success',
                },
              });
              this._successCallback(fileInfo);
            } catch (error) {
              this._port.postMessage({
                type: 'result',
                id: message.data.id,
                data: {
                  result: 'error',
                  error: {
                    code: 'unusableItem',
                    message: error.message,
                  },
                },
              });
              this._errorCallback(error.message);
            }

            this._close();

            break;

          default:
            // Always send a reply, if if that reply is that the command is not supported.
            this._port.postMessage({
              type: 'result',
              id: message.data.id,
              data: {
                result: 'error',
                error: {
                  code: 'unsupportedCommand',
                  message: command.command,
                },
              },
            });

            break;
        }

        break;
    }
  }

  _isPersonalAccount(userAccountType) {
    return userAccountType === 'personal';
  }

  _getBaseUrl(email, isPersonalAccount) {
    if (isPersonalAccount) {
      return 'https://onedrive.live.com/picker';
    }

    // Azure AD tenants often have a domain ending in .onmicrosoft.com or a custom domain.
    // If the email domain matches one of these, you can derive the tenant name.
    const emailDomain = email.split('@')[1];
    const tenant = emailDomain.split('.')[0];

    return 'https://' + tenant + '.sharepoint.com';
  }

  _close() {
    if (this._modal) {
      window.removeEventListener('message', this._initializeMessageListener);
      this._port?.removeEventListener('message', this._channelMessageListener);

      this._modal.hide();
      this._modal = null;
      this._win = null;
      this._port = null;
    }
  }

  async open(email, userAccountType) {
    this._channelId = Date.now();

    const isPersonalAccount = this._isPersonalAccount(userAccountType);
    const baseUrl = this._getBaseUrl(email, isPersonalAccount);

    // call _getToken() right away to make sure authentication is successful
    const accessToken = await this._getToken({
      resource: baseUrl,
      command: 'authenticate',
      type: isPersonalAccount ? 'OneDrive' : 'SharePoint',
    });

    return new Promise((resolve, reject) => {
      this._successCallback = resolve;
      this._errorCallback = reject;
      this._openWindow(isPersonalAccount, baseUrl, accessToken);
    });
  }
}
