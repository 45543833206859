import { Injectable } from '@angular/core';

import { pick } from 'lodash';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { CoreAPILoader } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  static readonly USER_WRITABLE_FIELDS = [
    'mailSyncEnabled', 'email', 'firstName', 'lastName', 'telephone',
    'roles', 'status', 'companyRole', 'dataCollectionDate', 'settings'
  ];

  static readonly BASIC_USER_WRITABLE_FIELDS = [
    'mailSyncEnabled', 'email', 'firstName', 'lastName', 'telephone',
    'status', 'companyRole', 'dataCollectionDate', 'settings'
  ];

  _username: string;
  _cachedPromise: Promise<any>;

  constructor(
    private coreAPILoader: CoreAPILoader
  ) { }

  list (search, cursor?) {
    var query = search.filter ? search.filter : '';
    if (search.query) {
      query += query ? ' AND (' + search.query + ')' : search.query;
    }

    var obj = {
      'companyId': search.companyId,
      'search': query,
      'cursor': cursor,
      'count': search.count,
      'sort': search.sortBy + (search.reverse ? ' desc' : ' asc')
    };

    console.debug('List users', obj);
    return this.coreAPILoader().then(coreApi => {
        return coreApi.user.list(obj);
      })
      .then(resp => {
        console.debug('List users resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to list Users', e);
        throw e;
      });
  }

  getProfile(username, clearCache?) {
    //avoid calling API if username didn't change
    if (username !== this._username || clearCache || !this._cachedPromise) {
      this._username = username;

      this._cachedPromise = new Promise((resolve, reject) => {
        console.debug('getUserProfile called', username);

        this.get(username)
          .then(function (resp) {
            console.debug('getUserProfile resp', resp);
            //get user profile
            resolve(resp);
          })
          .catch((e) => {
            reject(e);
          });
      });
    }

    return this._cachedPromise;
  }

  get (username) {
    var criteria: any = {};
    if (username) {
      criteria.username = username;
    }
    console.debug('Get User called', criteria);

    return this.coreAPILoader().then(coreApi => {
        return coreApi.user.get(criteria);
      })
      .then(resp => {
        console.debug('Get User resp', resp);
        // return user directly
        return resp.result.item;
      })
      .catch((e) => {
        console.error('Failed to get User', e);
        throw e;
      });
  }

  update (username, profile, isUserAdmin) {
    profile = pick(profile, isUserAdmin ? UserApiService.USER_WRITABLE_FIELDS : UserApiService.BASIC_USER_WRITABLE_FIELDS);
    console.debug('updateUser called', username, profile);
    return this.coreAPILoader().then(coreApi => {
        return coreApi.user.patch({
          username: username,
          data: profile
        });
      })
      .then(resp => {
        console.debug('Update User resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to update User', e);
        throw e;
      });
  }

  add (companyId, username, profile) {
    return this.coreAPILoader().then(coreApi => {
        profile = pick(profile, UserApiService.USER_WRITABLE_FIELDS);
        return coreApi.user.add({
          username: username,
          companyId: companyId,
          data: profile
        });
      })
      .then(resp => {
        console.debug('Add User resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to add User', e);
        throw e;
      });
  }

  delete (username) {
    return this.coreAPILoader().then(coreApi => {
        return coreApi.user.delete({
          username: username
        });
      })
      .then(resp => {
        console.debug('Delete User resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to delete User', e);
        throw e;
      });
  }

  export (companyId) {
    return this.coreAPILoader().then(coreApi => {
        return coreApi.user.export({
          companyId: companyId
        });
      })
      .then(resp => {
        console.debug('Export Users resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to export Users', e);
        throw e;
      });
  }
}

angular.module('risevision.apps.services')
  .factory('userService', downgradeInjectable(UserApiService));