import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { CompanyTreeItem } from '../../services/company';
import { CompanyAllocationService } from '../../services/company-allocation.service';
import { ControlContainer, NgForm } from '@angular/forms';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'tr[company-license-item]',
  templateUrl: './company-license-item.component.html',
  styleUrls: ['./company-license-item.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class CompanyLicenseItemComponent {

  @Input() company: CompanyTreeItem;
  @Input() last: boolean;
  @Input() root: boolean;
  @Output() selectCompany: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public companyAllocationService: CompanyAllocationService,
    public companyStateService: CompanyStateService
  ) { }

  updateCount(value: number): void {
    this.companyAllocationService.allocateCount(this.company, value);
  }
}
