import { Component, ViewEncapsulation, ChangeDetectionStrategy, Inject, Optional, forwardRef, ChangeDetectorRef } from '@angular/core';
import { MatCalendar, MatDatepickerIntl, MatCalendarHeader } from '@angular/material/datepicker';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
@Component({
  selector: 'calendar-month-year-header',
  templateUrl: './calendar-month-year-header.component.html',
  styleUrls: ['./calendar-month-year-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarMonthYearHeaderComponent<D> extends MatCalendarHeader<D> {
  constructor(
    private __intl: MatDatepickerIntl,
    @Inject(forwardRef(() => MatCalendar)) public _calendar: MatCalendar<D>,
    @Optional() private __dateAdapter: DateAdapter<D>,
    @Optional() @Inject(MAT_DATE_FORMATS) private __dateFormats: MatDateFormats,
    _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(__intl, _calendar, __dateAdapter, __dateFormats, _changeDetectorRef);
  }
}
