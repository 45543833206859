<div [ngClass]="classes">
  <div class="image-container">
    @if (isSvg) {
      <streamline-icon aria-hidden="true" [name]="imgSrc"  width="38" height="30"></streamline-icon>
    }
    @else {
      <img loading="lazy" alt="Image thumbnail" [src]="imgSrc"/>
    }
  </div>
  <div class="list-item-label">
    {{file.name | storageFileName:folderPath}}
  </div>
</div>