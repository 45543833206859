<form id="confirmForm">
  <div class="modal-header">
    <button type="button" class="close" (click)="close()" data-close="modal" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>
  <div class="modal-body">
    <h4>{{ planExpired ? 'Subscription Required' : 'Plan Upgrade Required' }}</h4>
    <div class="plan-features">
      @if (!planExpired) {
        <p>
          This feature is not available on your current plan{{ currentPlan ? ' (' + currentPlan + ')' : '' }}.
          @if (!parentPlan) {
            <span>
              Upgrade <span [shown]="!!needsPlan">to <strong>{{ needsPlan }}</strong></span> in order to access this feature.
            </span>
          }
        </p>
      }
      @else {
        <p>
          This feature requires an active plan.
          @if (!parentPlan) {
            <span>
            Subscribe <span [shown]="!!needsPlan">to <strong>{{ needsPlan }}</strong></span> in order to access this feature.
          </span>
        }
      </p>
      }
      @if (parentPlan) {
        <p>
          Contact your parent company to update your subscription.
        </p>
      }
    </div>
  </div>
  <div class="modal-footer">
    @if (!parentPlan) {
      <div class="row">
        <div class="col-xs-6">
          <button type="button" class="btn btn-default w-100" (click)="close()">
            <span>Cancel</span>
          </button>
        </div>
        <div class="col-xs-6">
          <button type="button" id="confirm-primary" class="btn btn-primary w-100" (click)="confirm()">
            <span>{{ planExpired ? 'Subscribe' : 'Upgrade' }}</span>
          </button>
        </div>
      </div>
    }
    @else {
      <div class="row">
        <div class="col-xs-12">
          <button type="button" class="btn btn-primary w-100" (click)="close()">
            <span>Okay, I Got It</span>
          </button>
        </div>
      </div>
    }
  </div>
</form>
