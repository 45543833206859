<div class="pl-0 file-component-list-file-row">
  @if (showGripHandle) {
    <div class="rv-sortable-handle">
      <streamline-icon
        aria-label="Click and hold to drag and sort"
        class="streamline-component-icon"
        name="sortableHandle"
        width="14"
        height="14">
      </streamline-icon>
    </div>
  }
  <div class="file-thumbnail">
    <div class="file-helper"></div>
    @if (entry.exists && !isStreamlineThumbnail()) {
      <img
        [src]="getThumbnailUrl()"
        [ngClass]="{ 'img-contain': isDefaultThumbnail() }"
        alt="Current logo image">
    }
    <div class="broken-link" [hidden]="entry.exists && !isStreamlineThumbnail()">
      <streamline-icon width="30" height="30"
        aria-hidden="true"
        name="{{ entry.exists ? getStreamlineIcon() : 'forbidden' }}"
      ></streamline-icon>
    </div>
  </div>
  <div class="file-entry" [ngClass]="{'is-error' : !entry.exists}">
    <div class="file-text">
      <div class="file-name" title="{{ getStoragePath() }}">{{getFileName()}}</div>
      <div class="file-error" [hidden]="entry.exists">
        Your {{fileType}} has been deleted, renamed, or moved.
      </div>
    </div>
    <div class="flex-row">
      @if (storageManagerService.isCanva(entry.file)) {
        <a href="#" title="Edit with Canva" (click)="editWithCanva()">
          <streamline-icon aria-label="Edit with Canva" class="ml-3 streamline-component-icon" name="canva" width="16" height="16"></streamline-icon>
        </a>
      }
      <a class="file-remove" href="#" title="Delete" (click)="removeFileFromList()">
        <streamline-icon ariaLabel="Remove this file" class="ml-3 streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
      </a>
    </div>
  </div>
</div>
