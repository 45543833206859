
import { StatesModule, Transition } from '@uirouter/angular';
import { CanAccessApps } from '../ajs-upgraded-providers';
import { WorkspaceComponent } from './components/workspace/workspace.component';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { ArtboardComponent } from './components/artboard/artboard.component';
import { EditorService } from './services/editor.service';
import { PresentationListComponent } from './components/presentation-list/presentation-list.component';
import { ViewportComponent } from './viewport/viewport.component';

export const routes: StatesModule = {
  states: [
    {
      name: 'apps.editor',
      abstract: true,
      component: ViewportComponent
    },
    {
      name: 'apps.editor.home',
      url: '/editor',
      redirectTo: 'apps.editor.list'
    },
    {
      name: 'apps.editor.list',
      url: '/editor/list',
      component: PresentationListComponent
    },
    {
      name: 'apps.editor.add',
      url: '/editor/add/:productId?utm_campaign&utm_source',
      params: {
        productId: ''
      },
      resolve: [{
        token: 'canAccessAps',
        deps: [Transition, EditorService],
        resolveFn: (transition, editorService) => {
          const productId = transition.params().productId;
          if (productId) {
            if (productId.startsWith('cap-alert')) {
              transition.router.stateService.go('apps.displays.alerts');
            } else {
              editorService.addFromProductId(productId, {
                utm_campaign: transition.params().utm_campaign,
                utm_source: transition.params().utm_source
              });
            }
          } else {
            editorService.addPresentationModal();

            transition.abort();
            return transition.router.stateService.go('apps.editor.list');
          }
        }
      }]
    },
    {
    name: 'apps.editor.workspace',
    url: '/editor/workspace/:presentationId?copyOf',
    abstract: true,
    component: WorkspaceComponent,
    params: {
      isLoaded: false,
      presentationId: ''
    },
    data: {
      requiresAuth: false
    },
    resolve: [{
      token: 'presentationInfo',
      deps: [Transition, EditorService, CanAccessApps],
      resolveFn: (transition, editorService, canAccessApps) => {
        var signup = false;

        if (transition.params().copyOf) {
          signup = true;
        }

        return canAccessApps(signup)
          .then(() => {
            if (transition.params().presentationId && transition.params().presentationId !== 'new') {
              return editorService.getPresentation(transition.params().presentationId);
            } else if (transition.params().copyOf) {
              if (transition.params().isLoaded) {
                return editorService.presentation;
              } else {
                return editorService.copyTemplate(transition.params().copyOf);
              }
            } else {
              return editorService.newPresentation();
            }
          })
          .then((presentationInfo) => {
            return presentationInfo;
          });
      }
    }]
  },
  {
    name: 'apps.editor.workspace.artboard',
    url: '',
    reloadOnSearch: false,
    component: ArtboardComponent
  },
  {
    name: 'apps.editor.workspace.htmleditor',
    url: '/htmleditor',
    reloadOnSearch: false,
    component: HtmlEditorComponent
  }]
};
