import { Injectable } from '@angular/core';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
  providedIn: 'root'
})
export class HtmlParserService {
  
  private readonly UNIT_PIXEL = 'px';
  private readonly UNIT_PERCENT = '%';
  private readonly numbers = '0123456789.-';

  getUnitString(value, unit) {
    var decimalPoints = 0;

    if (this.UNIT_PERCENT === unit) {
      decimalPoints = 5;
    }

    return value.toFixed(decimalPoints) + unit;
  }

  private _toTokensString(tokens, divider) {
    var result = '';
    for (var i = 0; i < tokens.length; i++) {
      if (tokens[i]) {
        result += tokens[i] + divider;
      }
    }
    return result;
  }

  equalsIgnoreCase(str1, str2) {
    return str1.toUpperCase() === str2.toUpperCase();
  }

  updateStyle(param, value, styleToken) {
    var tokens = styleToken.split(';');
    var found = false;
    for (var x = 0; x < tokens.length; x++) {
      if (tokens[x].indexOf(':') !== -1) {
        if (this.equalsIgnoreCase(
          tokens[x].substring(0, tokens[x].indexOf(':')).trim(),
          param)) {
          tokens[x] = param + ':' + value;
          found = true;
          break;
        }
      }
    }

    if (!found) {
      return this._toTokensString(tokens, ';') + param + ':' + value + ';';
    }

    return this._toTokensString(tokens, ';');
  }

  removeStyle(param, styleToken) {
    var tokens = styleToken.split(';');
    for (var x = 0; x < tokens.length; x++) {
      if (tokens[x].indexOf(':') !== -1) {
        if (this.equalsIgnoreCase(
          tokens[x].substring(0, tokens[x].indexOf(':')).trim(),
          param )) {
          tokens[x] = '';
          break;
        }
      }
    }

    return this._toTokensString(tokens, ';');
  }

  getNextQuote(htmlString) {
    var quote;
    if (htmlString.indexOf('"') !== -1 &&
      (htmlString.indexOf('\'') === -1 ||
        htmlString.indexOf('"') < htmlString.indexOf('\''))) {
      quote = '"';
    } else {
      quote = '\'';
    }

    return quote;
  }

  getPropertyValue(htmlString, property) {
    var start, end;

    start = htmlString.indexOf(property);
    if (start !== -1) {
      var quote = this.getNextQuote(htmlString.substring(start));

      start = htmlString.indexOf(quote, start) + 1;
      end = htmlString.indexOf(quote, start);

      if (end !== -1) {
        return htmlString.substring(start, end);
      }
    }

    return '';
  }

  stripGarbage(s) {
    var bad = '"\' ';
    var result = '';
    for (var i = 0; i < s.length; i++) {
      if (bad.indexOf(s.charAt(i)) === -1) {
        result += s.charAt(i);
      }
    }

    return result;
  }

  getUnits = function (token) {
    var result = '';
    token = this.stripGarbage(token);

    for (var i = 0; i < token.length; i++) {
      if (this.numbers.indexOf(token.charAt(i)) === -1) {
        result += token.charAt(i);
      }
    }

    return result;
  };

  private _getNumber(token) {
    var resultString = '';

    token = this.stripGarbage(token);

    for (var i = 0; i < token.length; i++) {
      if (this.numbers.indexOf(token.charAt(i)) !== -1) {
        resultString += token.charAt(i);
      } else {
        break;
      }
    }

    return resultString;
  }

  getFloatValue(token) {
    if (typeof token === 'number') {
      return token;
    }
    var result = -1;
    var resultString = this._getNumber(token);

    result = parseFloat(resultString);

    return !result || isNaN(result) ? 0 : result;
  }

  getIntValue(token) {
    if (typeof token === 'number') {
      return token;
    }
    var result = -1;
    var resultString = this._getNumber(token);

    result = parseInt(resultString);

    return !result || isNaN(result) ? 0 : result;
  }

  parseIntProperty(object, property, defaultValue?) {
    if (object.hasOwnProperty(property)) {
      object[property] = object[property] ?
        this.getIntValue(object[property]) :
        (defaultValue ? defaultValue : 0);
    }
  }

  getBooleanValue(value) {
    if (typeof value === 'boolean') {
      return value;
    } else {
      return value === 'true';
    }
  }

  parseBooleanProperty(object, property) {
    if (object.hasOwnProperty(property)) {
      object[property] = this.getBooleanValue(object[property]);
    }
  }

  stripOuterGarbage(s) {
    s = s.trim();
    if (s.startsWith('\'')) {
      s = s.substring(1, s.length);
    }
    if (s.endsWith('\'')) {
      s = s.substring(0, s.length - 1);
    }
    if (s.startsWith('"')) {
      s = s.substring(1, s.length);
    }
    if (s.endsWith('"')) {
      s = s.substring(0, s.length - 1);
    }
    return s.trim();
  }

  updateInnerString(htmlString, start, end, token) {
    return htmlString.substring(0, start) + token +
      htmlString.substring(end, htmlString.length);
  }
}

angular.module('risevision.editor.services')
  .factory('htmlParser', downgradeInjectable(HtmlParserService));
