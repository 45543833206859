<mat-form-field color="accent" class="number-input" appearance="outline" subscriptSizing="dynamic">
  <input #numberInput="ngModel" matInput [name]="name" type="number" class="form-control" [(ngModel)]="value" (input)="onChange()" [min]="min" [max]="max" [disabled]="disabled">
  <div matSuffix class="number-input-buttons">
    <button type="button" (click)="valueUp()" [disabled]="disabled">
      <i class="fa fa-caret-up"></i>
    </button>
    <button type="button" (click)="valueDown()" [disabled]="disabled">
      <i class="fa fa-caret-down"></i>
    </button>
  </div>
</mat-form-field>