import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { GoogleService } from './google.service';

@Injectable({
  providedIn: 'root'
})
export class GooglePhotosService extends GoogleService {
  public static readonly ENDPOINT_ALBUMS = 'photos/albums';

  getAlbums(account) {
    const request = new HttpRequest( 'GET',
      environment.GOOGLE_SERVICE_URL + GooglePhotosService.ENDPOINT_ALBUMS, {
        withCredentials: true,
        responseType: 'json',
        params: new HttpParams({fromObject:{
          account: account
        }})
      }
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body && response.body.albums) {
          return response.body.albums;
        } else {
          throw new Error('Invalid photo albums data');
        }
      }).catch((err) => {
        console.error('Failed to get google photos albums.', err);

        throw err;
      });
  }

}
