import { Component } from '@angular/core';

import * as _ from 'lodash';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { CompanyApiService } from 'src/app/api/services/company-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

import { ModalComponent } from 'src/app/components/modals/modal.component';

@Component({
  selector: 'move-company',
  templateUrl: './move-company.component.html',
  styleUrls: ['./move-company.component.scss']
})
export class MoveCompanyComponent extends ModalComponent {

  public loading = false;
  public company : any = {};
  public errors = [];
  public messages = [];
  public moveSuccess = false;

  public dismissButtonText = 'Cancel';

  selectedCompany = this.companyStateService.getCopyOfSelectedCompany();

  constructor(public modalRef: BsModalRef,
    private companyStateService: CompanyStateService,
    private companyApiService: CompanyApiService
  ) {
    super(modalRef);
  }

  closeModal() {
    super.resolve();
  }

  moveCompany() {
    this.messages = [];
    this.loading = true;
    return this.companyApiService.moveCompany(this.company.authKey, this.companyStateService.getSelectedCompanyId())
      .then(() => {
        this.messages.push(
          'Success. The company has been moved under your company.');
        this.dismissButtonText = 'Close';
        this.moveSuccess = true;
      })
      .catch((err) => {
        this.errors.push('Error: ' + JSON.stringify(err));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getCompany() {
    this.errors = [];
    this.messages = [];
    this.loading = true;
    return this.companyApiService.lookupCompany(this.company.authKey)
      .then((resp) => {
        _.extend(this.company, resp);
      })
      .catch((resp) => {
        this.errors.push('Failed to retrieve company. ' + resp.message);
      })
      .finally(() => {
        this.loading = false;
      });
  }

}
