import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalComponent } from 'src/app/components/modals/modal.component';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'widget-item',
  templateUrl: './widget-item.component.html',
  styleUrls: ['./widget-item.component.scss']
})
export class WidgetItemComponent extends ModalComponent {

  WEBSITE_REGEX = ValidationService.WEBSITE_REGEX;

  constructor(public modalRef: BsModalRef) {
    super(modalRef);
  }

  form = {
    url: undefined,
    settingsUrl: undefined
  };

  apply() {
    super.resolve(this.form);
  }

  dismiss() {
    super.resolve();
  }

}
