<div bsModal #widgetSettingsModal="bs-modal" class="modal" style="z-index:10000;" tabindex="-1"
     role="dialog" aria-labelledby="widget-settings-modal">
  <div class="modal-dialog modal-full modal-lg">
    <!-- <div class="modal-content"> -->
      <div id="widget-modal" rvSpinner="widget-modal-loader" [rvShowSpinner]="loading">
        <iframe id="widget-modal-frame" name="widget-modal-frame" class="full-screen" scrolling="no" marginwidth="0" [src]="widgetUrl"></iframe>
      </div>
    <!-- </div> -->
  </div>
</div>
