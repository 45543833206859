import { Injectable } from '@angular/core';
import { ProcessErrorCode } from 'src/app/ajs-upgraded-providers';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { DisplayApiService } from 'src/app/api/services/display-api.service';
import { DisplayService } from './display.service';

@Injectable({
  providedIn: 'root'
})
export class PlayerActionsService {
  controlsError: string = '';

  constructor(
    private displayApiService: DisplayApiService,
    private modalService: ModalService,
    private processErrorCode: ProcessErrorCode,
    private trackerService: TrackerService,
    private displayService: DisplayService
  ) { }

  _restart (displayId, displayName) {
    if (!displayId) {
      return;
    }

    return this.displayApiService.restart(displayId)
      .then(() => {
        this.trackerService.displayEvent('Display Restarted', displayId, displayName);
      });
  }

  private _reboot (displayId, displayName) {
    if (!displayId) {
      return;
    }

    return this.displayApiService.reboot(displayId)
      .then(() => {
        this.trackerService.displayEvent('Display Rebooted', displayId, displayName);
      });
  }

  restartByObject (display) {
    return this._restart(display.id, display.name);
  }

  rebootByObject (display) {
    return this._reboot(display.id, display.name);
  }

  private _confirmRestart (displayId, displayName) {
    this.modalService.confirm('Restart Rise Player?',
      'Rise Player will restart and the content showing on your display will be interrupted. Do you wish to proceed?',
      'Yes',
      'No'
    ).then(() => {
      // do what you need if user presses ok
      this.controlsError = '';

      this._restart(displayId, displayName).catch((e) => {
        this.controlsError = this.processErrorCode(e);
      });
    }).catch(() => {
      // do what you need to do if user cancels
    });
  }

  private _confirmReboot (displayId, displayName) {
    this.modalService.confirm('Reboot media player?',
      'The media player will reboot and the content showing on your display will be interrupted. Do you wish to proceed?',
      'Yes',
      'No'
    ).then(() => {
      // do what you need if user presses ok
      this.controlsError = '';

      this._reboot(displayId, displayName).catch((e) => {
        this.controlsError = this.processErrorCode(e);
      });
    }).catch(() => {
      // do what you need to do if user cancels
    });
  }

  confirm (displayId, displayName, mode) {
    if (this.displayService.showLicenseRequired()) {
      return;
    }

    if (mode === 'reboot') {
      this._confirmReboot(displayId, displayName);
    } else if (mode === 'restart') {
      this._confirmRestart(displayId, displayName);
    }
  }
}
