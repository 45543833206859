import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'week-dropdown',
  templateUrl: './week-dropdown.component.html',
  styleUrls: ['./week-dropdown.component.scss']
})
export class WeekDropdownComponent {

  @Input() week: number;
  @Output() weekChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  emitValue () {
    this.weekChange.emit(this.week);
  }
}