import { Component } from '@angular/core';
import { ModalComponent } from '../../modals/modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'company-id-modal',
  templateUrl: './company-id-modal.component.html',
  styleUrls: ['./company-id-modal.component.scss']
})
export class CompanyIdModalComponent extends ModalComponent {

  title: string;
  description: string;
  cancelButton: string;
  applyButton: string;
  companyId: string;

  constructor(public modalRef: BsModalRef) {
    super(modalRef);
  }

  apply () {
    super.resolve(this.companyId);
  }

  dismiss () {
    super.reject();
  }
}