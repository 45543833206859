import { Injectable } from '@angular/core';

import { ScheduleService } from './schedule.service';
import { PlaylistService } from './playlist.service';

@Injectable({
  providedIn: 'root'
})
export class FirstScheduleService {

  constructor(
    private scheduleService: ScheduleService,
    private playlistService: PlaylistService
  ) { }

  create(presentation) {
    return this.scheduleService.checkFirstSchedule()
      .then(() => {
        this.scheduleService.newSchedule(true);

        this.scheduleService.schedule.name = 'All Displays - 24/7';
        this.scheduleService.schedule.distributeToAll = true;

        return this.playlistService.addPresentationItem(presentation);
      })
      .then(() => {
        return this.scheduleService.addSchedule();
      })
      .then(() => {
        if (this.scheduleService.apiError) {
          return Promise.reject(this.scheduleService.apiError);
        }
      });
  }
}
