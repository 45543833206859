import { Injectable } from '@angular/core';

import { PurchaseService } from './purchase.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseStepsService {

  currentStep;
  finalStep;

  constructor(private purchaseService: PurchaseService) {
    this.init();
  }

  init() {
    this.currentStep = 0;
    this.finalStep = false;
  }

  _refreshEstimate() {
    if (this.currentStep === 1 || this.currentStep === 2) {
      this.purchaseService.getEstimate();
    }
  }

  setNextStep() {
    // Note: Ensure to check if the form is valid before calling
    if ((this.finalStep && this.currentStep < 1) || this.currentStep === 1) {
      this.currentStep = 2;

      this.finalStep = true;
    } else {
      this.currentStep++;
    }

    this._refreshEstimate();
  }

  setPreviousStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  setCurrentStep(index) {
    this.purchaseService.purchase.checkoutError = null;

    this.currentStep = index;

    this._refreshEstimate();
  }

}