import { Directive } from '@angular/core';

import { Validator, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';

import { PlaceholderService } from '../services/placeholder.service';
import { PlaceholdersService } from '../services/placeholders.service';

@Directive({
  selector: '[placeholderNameValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: PlaceholderNameDirective, multi: true}]
})
export class PlaceholderNameDirective implements Validator {

  constructor(private placeholderService: PlaceholderService,
    private placeholdersService: PlaceholdersService) { }

  checkPlaceholderNames(control) {
    let value = control.value;
    var placeholders = this.placeholdersService.getPlaceholders();
    for (var i = 0; i < placeholders.length; i++) {
      if (placeholders[i] !== this.placeholderService.placeholder &&
        !placeholders[i].deleted &&
        (placeholders[i].id === value ||
          placeholders[i].newId === value)) {
        return false;
      }
    }

    return true;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.checkPlaceholderNames(control) ? null : {
      duplicate: {
        valid: false
      }
    };
  }

}
