import { Spinner } from 'spin.js';
import { Directive, ElementRef, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { LoadingService } from './loading.service';

@Directive({
  selector: '[rvSpinner]'
})
export class RvSpinnerDirective implements OnInit, OnChanges, OnDestroy {

  @Input() rvSpinner;
  @Input() rvShowSpinner;

  private backdropDiv :HTMLDivElement;
  private spinner;
  private subscription;

  constructor(private el: ElementRef, private loadingService: LoadingService) {
    this.backdropDiv = document.createElement('div');
    this.backdropDiv.className = 'spinner-backdrop fade in';
    this.backdropDiv.style.display = 'none';
    this.el.nativeElement.prepend(this.backdropDiv);
  }

  ngOnInit() {
    this.subscription = this.loadingService.emitter.subscribe((eventObject) => {
      if (eventObject.targetSpinner === this.rvSpinner) {
        if (eventObject.event === 'rv-spinner:start') {
          this.startSpinner();
        } else if (eventObject.event === 'rv-spinner:stop') {
          this.stopSpinner();
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges() {
    if (this.rvShowSpinner) {
      this.startSpinner();
    } else {
      this.stopSpinner();
    }
  }

  startSpinner() {
    this.spinner = new Spinner();
    this.backdropDiv.style.display = 'block';
    this.spinner.spin(this.backdropDiv);
  }

  stopSpinner() {
    if (this.spinner) {
      this.spinner.stop(this.backdropDiv);
    }
    this.backdropDiv.style.display = 'none';
  }
}
