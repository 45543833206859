import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from '../../modals/modal.component';

@Component({
  selector: 'upgrade-feature-modal',
  templateUrl: './upgrade-feature-modal.component.html',
  styleUrl: './upgrade-feature-modal.component.scss'
})
export class UpgradeFeatureModalComponent extends ModalComponent {

  cta = '';
  title = '';
  description = '';
  imageSource = '';
  background = '';
  helpLink = '';

  constructor(
    public modalRef: BsModalRef
  ) {
    super(modalRef);
  }

  dismiss() {
    super.reject('cancel');
  }

  upgrade() {
    super.resolve();
  }
}
