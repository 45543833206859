import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { UserStateService } from 'src/app/auth/services/user-state.service';

@Directive({
  selector: '[requireRole]'
})
export class RequireRoleDirective {

  rendered = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private userStateService: UserStateService
  ) { }

  @Input() set requireRole(roles: string) {
    if (this.userStateService.hasPermission(roles) && !this.rendered) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);

      this.rendered = true;
    } else if (!this.userStateService.hasPermission(roles) && this.rendered) {
      this.viewContainerRef.clear();

      this.rendered = false;
    }
  }

}
