import { Injectable } from '@angular/core';
import { ResourceLoaderService } from 'src/app/shared/services/resource-loader.service';
import { DisplayApiService } from 'src/app/api/services/display-api.service';
import { DisplayService } from './display.service';
import { ScreenshotRequesterService } from './screenshot-requester.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotService {

  screenshot: any = {};
  screenshotLoading?: boolean;

  constructor(
    private displayService: DisplayService,
    private displayApiService: DisplayApiService,
    private screenshotRequesterService: ScreenshotRequesterService,
    private resourceLoaderService: ResourceLoaderService
  ) { }

  requestScreenshot () {
    if (this.displayService.showLicenseRequired()) {
      return;
    }

    this.screenshotLoading = true;

    return this.screenshotRequesterService.request((clientId) => {
      console.log('clientId', clientId);
      return this.displayApiService.requestScreenshot(this.displayService.display.id, clientId);
    })
    .then(() => {
      return this.loadScreenshot();
    })
    .catch((err) => {
      this.screenshotLoading = false;

      this.screenshot = {
        error: 'requesting'
      };
      console.log('Error requesting screenshot', err);
    });

  }

  loadScreenshot () {
    this.screenshotLoading = true;

    const url =
      'https://storage.googleapis.com/risevision-display-screenshots/' +
      this.displayService.display.id + '.jpg';

    return this.resourceLoaderService.imageBlobLoader(url)
      .then((resp) => {
        this.screenshot = resp;
      })
      .catch((err) => {
        this.screenshot = {
          error: 'loading'
        };
        console.log('Error loading screenshot', err);
      })
      .finally(() => {
        this.screenshotLoading = false;
      });
  }

}
