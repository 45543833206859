import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalComponent } from '../modal.component';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent extends ModalComponent {

  title: string;
  message: string;

  constructor(public modalRef: BsModalRef) {
    super(modalRef);
  }

  close() {
    super.resolve();
  }

}
