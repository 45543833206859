import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { ApiService } from './api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageApiService extends ApiService<any> {

  protected APIUrl = environment.SPRING_STORAGE_ENDPOINT_URL + this.getResourceUrl();

  constructor(
    protected httpClient: HttpClient,
    protected companyStateService: CompanyStateService
  ) {
    super(httpClient, companyStateService);
  }

  getResourceUrl(): string {
    return '/v3';
  }

  _mapFileNames (files) {
    if (files.items && files.items.length && files.items[0].name) {
      files = files.items.map(file => { return file.name; });
    }
    return files;
  }

  getFiles (search): Promise<any> {
    let params = new HttpParams()
      .set('companyId', search.companyId || this.companyStateService.getSelectedCompanyId());

    if (search.folderPath) {
      params = params.append('folder', search.folderPath);
    }
    if (search.file) {
      params = params.append('file', search.file);
    }
    if (search.assetId) {
      params = params.append('assetId', search.assetId);
    }
    if (search.assetType) {
      params = params.append('assetType', search.assetType);
    }

    return firstValueFrom(super.requestAsObservable('', 'GET', 'files', params));
  }

  deleteFiles (selectedFileNames): Promise<any> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId());

    const body = {
      'files': this._mapFileNames(selectedFileNames)
    };

    return firstValueFrom(super.requestAsObservable('', 'DELETE', 'files', params, body));
  }

  moveToTrash (selectedFileNames): Promise<any> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId());

    const body = {
      'files': this._mapFileNames(selectedFileNames)
    };

    return firstValueFrom(super.requestAsObservable('', 'POST', 'trash/move', params, body));
  }

  restoreFromTrash (selectedFileNames): Promise<any> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId());

    const body = {
      'files': this._mapFileNames(selectedFileNames)
    };

    return firstValueFrom(super.requestAsObservable('', 'POST', 'trash/restore', params, body));
  }

  createFolder (folder): Promise<any> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('folder', folder);

    return firstValueFrom(super.requestAsObservable('', 'POST', 'folder', params));
  }

  getFolderContents (folder): Promise<any> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('folder', folder);

    return firstValueFrom(super.requestAsObservable('', 'GET', 'folder', params));
  }

  getResumableUploadURI (fileName, fileType): Promise<any> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('fileName', encodeURIComponent(fileName))
      .set('fileType', fileType)
      .set('origin', window.location.origin);

    return firstValueFrom(super.requestAsObservable('', 'POST', 'files/resumableUploadURI', params));
  }

  getSignedDownloadURI (file): Promise<any> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('fileName', encodeURIComponent(file.name));

    return firstValueFrom(super.requestAsObservable('', 'GET', 'files/signedDownloadURI', params));
  }

  rename (sourceName, destinationName): Promise<any> {
    if (sourceName === destinationName) {
      return Promise.reject({
        status: 400,
        result: {
          error: {
            message: 'must-be-different'
          }
        }
      });
    } else {
      const params = new HttpParams()
        .set('companyId', this.companyStateService.getSelectedCompanyId())
        .set('sourceName', sourceName)
        .set('destinationName', destinationName);

      return firstValueFrom(super.requestAsObservable('', 'PATCH', 'files/rename', params));
    }
  }

  duplicate (sourceName): Promise<any> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('sourceName', sourceName);

    return firstValueFrom(super.requestAsObservable('', 'POST', 'files/duplicate', params));
  }

  getEncodingUploadURI (fileName): Promise<any> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('fileName', encodeURIComponent(fileName));

    return firstValueFrom(super.requestAsObservable('', 'POST', 'encoding/uploadURI', params));
  }

  startEncodingTask (data): Promise<any> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('taskToken', data.taskToken)
      .set('fileUUID', data.fileUUID)
      .set('fileName', data.fileName);

    return firstValueFrom(super.requestAsObservable('', 'POST', 'encoding/startTask', params));
  }

  acceptEncodedFile (fileName): Promise<any> {
    const params = new HttpParams()
      .set('companyId', this.companyStateService.getSelectedCompanyId())
      .set('fileName', encodeURIComponent(fileName));

    return firstValueFrom(super.requestAsObservable('', 'POST', 'encoding/acceptFile', params));
  }

  requestServiceAssets (companyId, file, assetType): Promise<any> {
    const params = new HttpParams()
      .set('companyId', companyId || this.companyStateService.getSelectedCompanyId())
      .set('file', file)
      .set('assetType', assetType);

    return firstValueFrom(super.requestAsObservable('', 'POST', 'files/requestServiceAssets', params));
  }

  refreshFileMetadata (fileName, remainingAttempts = 2): Promise<any> {
    console.log('Attempt #' + remainingAttempts + ' to get metadata for: ' + fileName);

    return this.getFiles({
        file: fileName
      })
      .then(resp => {
        var file = resp && resp.files && resp.files[0];

        if (file && (!file.metadata || file.metadata['needs-thumbnail-update'] !== 'true')) {
          return file;
        } else if (file && remainingAttempts > 0) {
          return this.refreshFileMetadata(fileName, remainingAttempts - 1);
        } else {
          console.log('Error refreshing metadata', file.name);

          return file;
        }
      });
  }

}

angular.module('risevision.apps.services')
  .factory('storage', downgradeInjectable(StorageApiService));