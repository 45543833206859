<div class="calendar-day-month-header">
  <div class="mat-calendar-header">
    <div class="mat-calendar-controls flex-row space-between">
      <button mat-icon-button type="button" class="mat-calendar-previous-button"
              [disabled]="!previousEnabled()" (click)="previousClicked()"
              [attr.aria-label]="prevButtonLabel">
      </button>

      <span class="mat-calendar-header-label">{{periodButtonText}}</span>

      <button mat-icon-button type="button" class="mat-calendar-next-button"
              [disabled]="!nextEnabled()" (click)="nextClicked()"
              [attr.aria-label]="nextButtonLabel">
      </button>
    </div>
  </div>
  <label [id]="_periodButtonLabelId" class="mat-calendar-hidden-label">{{periodButtonDescription}}</label>
