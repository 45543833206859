import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
  providedIn: 'root'
})
export class WindowLocalStorageService {
  get(): typeof localStorage {
    return window.localStorage;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageHelperService {

  get isSupported(): any {
    return !!this.windowLocalStorageService.get();
  }

  constructor(
    private windowLocalStorageService: WindowLocalStorageService
  ) { }

  get(key) {
    const value = this.windowLocalStorageService.get().getItem(key);

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  set(key, value) {
    try {
      value = JSON.stringify(value);
    } catch (e) {
      // Do nothing
    }

    this.windowLocalStorageService.get().setItem(key, value);
  }

  remove(key) {
    this.windowLocalStorageService.get().removeItem(key);
  }
}

angular.module('risevision.apps.services')
  .factory('localStorageService', downgradeInjectable(LocalStorageHelperService));