import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { TimelineUtilsService } from './timeline-utils.service';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
  providedIn: 'root'
})
export class TimelineDescriptionService {

  static readonly RECURRENCE = {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly'
  };

  static readonly RECURRENCE_PERIOD = {
    Daily: 'day',
    Weekly: 'week',
    Monthly: 'month',
    Yearly: 'year'
  };

  static readonly LABEL = {
    ALL_THE_TIME: 'Show all the time',
    EVERY_DAY: 'Every day',
    ALL_DAY: 'all day',
    EVERY: 'every',
    FROM: 'from',
    TO: 'to',
    OF: 'of',
    ON: 'on',
    THE: 'the'
  };

  static readonly STATUS = {
    STARTING: 'Starting',
    ACTIVE: 'Active',
    EXPIRED: '<span class="text-danger">Expired</span>'
  };

  static readonly OPTIONS_WEEK = ['first', 'second', 'third', 'fourth', 'fifth',
    'last'
  ];

  static readonly OPTIONS_DAY_OF_THE_WEEK = ['Sunday', 'Monday', 'Tuesday',
    'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ];

  static readonly OPTIONS_WEEKDAY_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  static readonly WEEKDAYS_MAP = {
    Sun: 0,
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6
  };

  static readonly ORDINAL_MAP = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];

  static readonly OPTIONS_MONTH = ['January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  constructor(
    private timelineUtilsService: TimelineUtilsService
  ) { }

  private _parseDate (date, useLocaldate) {
    if (!date) {
      return null;
    }
    const dateObject = new Date(typeof date === 'string' ? this.timelineUtilsService.sanitizeDateString(date) : date);
    if (useLocaldate) {
      dateObject.setMinutes(dateObject.getMinutes() + dateObject.getTimezoneOffset());
    }
    return dateObject;
  }

  private _formatDate (date, omitYear = false) {
    let format = 'd-MMM';
    if (!omitYear) {
      format += '-y';
    }
    return formatDate(date, format, 'en-US');
  }

  private _formatTime (date) {
    return formatDate(date, 'h:mm a', 'en-US');
  }

  private _setTime (date, time) {
    if (time) {
      date.setHours(time.getHours());
      date.setMinutes(time.getMinutes());
      date.setSeconds(time.getSeconds());
      date.setMilliseconds(time.getMilliseconds());
    } else {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
    }
  }

  private _timelineRange (startDate, endDate, startTime, endTime) {

    let range = '';
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (!startTime || !endTime) {
      this._setTime(now, null);
    } else {
      this._setTime(start, startTime);
      this._setTime(end, endTime);
    }
    const started = !startDate || now.getTime() >= start.getTime();
    const ended = endDate && now.getTime() > end.getTime();

    if (started) {
      if (ended) {
        range += TimelineDescriptionService.STATUS.EXPIRED + ' ' + this._formatDate(endDate);
        return range;
      } else {
        range += TimelineDescriptionService.STATUS.ACTIVE;
      }
    } else {
      range += TimelineDescriptionService.STATUS.STARTING;
    }

    if (startDate) {
      range += ' ' + this._formatDate(startDate, endDate && startDate.getFullYear() === endDate.getFullYear());
    }

    if (endDate) {
      range += ' ' + TimelineDescriptionService.LABEL.TO + ' ' + this._formatDate(endDate);
    }

    return range;
  }

  private _timelineDaysOfWeek (days: string[], longForm?: boolean) {

    let label = '';

    const daysMap = days.map(d => TimelineDescriptionService.WEEKDAYS_MAP[d]);
    // It should already be sorted, but just in case:
    daysMap.sort((a, b) => {
      return a - b;
    });

    // Handle cases like 'Thu-Mon' (instead of writing 'Sun-Mon & Thu-Sat')
    if (daysMap.length < 7 && daysMap[0] === 0 && daysMap[daysMap.length - 1] === 6) {
      let start = 0;
      for (let i = 0; i < daysMap.length; i++) {
        if (i !== daysMap[i]) {
          start = i;
          break;
        }
      }
      // Move all days before first skipped day to end of array
      daysMap.push.apply(daysMap, daysMap.slice(0, start).map(d => d + 7));
      daysMap.splice(0, start);
    }

    const dayNames = longForm ? TimelineDescriptionService.OPTIONS_DAY_OF_THE_WEEK : TimelineDescriptionService.OPTIONS_WEEKDAY_SHORT;
    let previous = undefined;
    let range = 0;
    const firstDay = daysMap[0];
    const lastDay = daysMap[daysMap.length - 1];
    for (let day of daysMap) {
      if (previous !== day - 1) {
        // We are at the start of a new day range
        if (range > 0) {
          // Record the last day of previous range
          label += range > 1 ? '-' : ', ';
          label += dayNames[previous % 7];
        }
        label += day > firstDay ? ', ' : '';
        label += dayNames[day % 7];
        range = 0;
      } else {
        range += 1;
        if (day === lastDay) {
          label += range > 1 ? '-' : ', ';
          label += dayNames[day % 7];
        }
      }
      previous = day;
    }

    // Replace last comma with ampersand
    return label.replace(/,(?=[^,]*$)/, ' &');
  }

  private _ordinalNumber (number) {
    const cent = number % 100;
    const str = number.toString();
    if (cent >= 10 && cent <= 20) {
      return str + 'th';
    }
    return str + TimelineDescriptionService.ORDINAL_MAP[parseInt(str.slice(-1))];
  }

  private _timelineRecurrence (timeline) {

    let recurrence = '';

    switch (timeline.recurrenceType) {

      case TimelineDescriptionService.RECURRENCE.DAILY:
      case TimelineDescriptionService.RECURRENCE.WEEKLY:
        recurrence += TimelineDescriptionService.LABEL.EVERY + ' ';

        if (timeline.recurrenceFrequency > 1) {
          recurrence += timeline.recurrenceFrequency + ' ' +
            TimelineDescriptionService.RECURRENCE_PERIOD[timeline.recurrenceType] + 's';
        } else {
          recurrence += TimelineDescriptionService.RECURRENCE_PERIOD[timeline.recurrenceType];
        }

        if (timeline.recurrenceType === TimelineDescriptionService.RECURRENCE.WEEKLY &&
          timeline.recurrenceDaysOfWeek && timeline.recurrenceDaysOfWeek.length > 0) {
          recurrence += ' ' + TimelineDescriptionService.LABEL.ON +
            ' ' + this._timelineDaysOfWeek(timeline.recurrenceDaysOfWeek);
        }
        break;

      case TimelineDescriptionService.RECURRENCE.MONTHLY:
        recurrence += ' ' + TimelineDescriptionService.LABEL.THE;
        if (timeline.recurrenceAbsolute) {
          recurrence += ' ' + this._ordinalNumber(timeline.recurrenceDayOfMonth);
        } else {
          recurrence += ' ' + TimelineDescriptionService.OPTIONS_WEEK[timeline.recurrenceWeekOfMonth] +
            ' ' + TimelineDescriptionService.OPTIONS_DAY_OF_THE_WEEK[timeline.recurrenceDayOfWeek];
        }
        recurrence += ' ' + TimelineDescriptionService.LABEL.OF + ' ' + TimelineDescriptionService.LABEL.EVERY + ' ';

        if (timeline.recurrenceFrequency > 1) {
          recurrence += this._ordinalNumber(timeline.recurrenceFrequency) + ' ';
        }
        recurrence += TimelineDescriptionService.RECURRENCE_PERIOD[timeline.recurrenceType];
        break;

      case TimelineDescriptionService.RECURRENCE.YEARLY:
        if (timeline.recurrenceAbsolute) {
          recurrence += ' ' + TimelineDescriptionService.OPTIONS_MONTH[timeline.recurrenceMonthOfYear] +
            ' ' + this._ordinalNumber(timeline.recurrenceDayOfMonth);
        } else {
          recurrence += ' ' + TimelineDescriptionService.LABEL.THE +
            ' ' + TimelineDescriptionService.OPTIONS_WEEK[timeline.recurrenceWeekOfMonth] +
            ' ' + TimelineDescriptionService.OPTIONS_DAY_OF_THE_WEEK[timeline.recurrenceDayOfWeek] +
            ' ' + TimelineDescriptionService.LABEL.OF +
            ' ' + TimelineDescriptionService.OPTIONS_MONTH[timeline.recurrenceMonthOfYear];
        }
        recurrence += ' ' + TimelineDescriptionService.LABEL.EVERY + ' ';

        recurrence += TimelineDescriptionService.RECURRENCE_PERIOD[timeline.recurrenceType];
        break;
    }

    return recurrence;
  }

  private _dailyTimeRange (timeline: any, startTime?: Date, endTime?: Date, isUTC?: boolean): string {
    let label = '';
    if (timeline.allDay || !startTime) {
      label += timeline.recurrenceType ? ', ' : '';
      label += TimelineDescriptionService.LABEL.ALL_DAY;
    } else {
      label += ' ' + TimelineDescriptionService.LABEL.FROM +
        ' ' + this._formatTime(startTime) +
        (isUTC ? ' UTC ' : ' ') +
        TimelineDescriptionService.LABEL.TO +
        ' ' + this._formatTime(endTime) +
        (isUTC ? ' UTC' : '');
    }
    return label;
  }

  updateLabel (timeline, lineBreak = false) {

    if (timeline.timeDefined === false) {
      return TimelineDescriptionService.LABEL.ALL_THE_TIME;
    }

    const startDate = this._parseDate(timeline.startDate, timeline.useLocaldate);
    const endDate = this._parseDate(timeline.endDate, timeline.useLocaldate);
    const startTime = this._parseDate(timeline.startTime, timeline.useLocaldate);
    const endTime = this._parseDate(timeline.endTime, timeline.useLocaldate);

    let label = this._timelineRange(startDate, endDate, startTime, endTime);
    label += lineBreak ? '<br>' : ', ';

    if (timeline.recurrenceType) {
      label += this._timelineRecurrence(timeline);
    }

    label += this._dailyTimeRange(timeline, startTime, endTime);

    return label;
  }

  updateBasicLabel (timeline) {
    let label = '';
    const startTime = this._parseDate(timeline.startTime, timeline.useLocaldate);
    const endTime = this._parseDate(timeline.endTime, timeline.useLocaldate);

    if (timeline.everyDay || timeline.recurrenceDaysOfWeek.length === 0 || timeline.recurrenceDaysOfWeek.length === 7) {
      label = TimelineDescriptionService.LABEL.EVERY_DAY;
    } else if (timeline.recurrenceDaysOfWeek.length > 0) {
      label = 'Every ' + this._timelineDaysOfWeek(timeline.recurrenceDaysOfWeek, true);
    }

    label += ', ' + this._dailyTimeRange(timeline.allDay, startTime, endTime, true);

    return label;
  }

}

angular.module('risevision.common.components')
  .factory('timelineDescription', downgradeInjectable(TimelineDescriptionService));