import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/components/modals/modal.component';
import { PresentationUtilsService } from '../../services/presentation-utils.service';

@Component({
  selector: 'embedding-presentations-modal',
  templateUrl: './embedding-presentations-modal.component.html',
  styleUrls: ['./embedding-presentations-modal.component.scss']
})
export class EmbeddingPresentationsModalComponent extends ModalComponent {

  presentation : any = {};
  embeddingPresentations = [];
  embeddingSchedules = [];
  isHtmlPresentation = this.presentationUtils.isHtmlPresentation;

  constructor(
    public modalRef: BsModalRef,
    public presentationUtils: PresentationUtilsService
  ) {
    super(modalRef);
  }

  close() {
    super.reject();
  }
}
