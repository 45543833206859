import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const CheckForPopupClosedInterval = 500;

@Injectable({
  providedIn: 'root'
})

export class AuthPopup {
   _popup: Window;
  private _promise: Promise<unknown>;
  private _resolve: (value: unknown) => void;
  private _reject: (value: unknown) => void;
  private _checkForPopupClosedTimer: number;

  constructor() {
  }

  popup(provider: string, id: string, scope: string = null) {

    this._promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });

    const url = environment.OAUTH_TOKEN_PROVIDER_URL + 'v2/authenticate?provider=' + provider + '&id=' + id +
    '&scope=' + encodeURIComponent(scope);

    this._popup = window.open(url, '_blank', `location=no,toolbar=no,${this._getPopupPosition()};`);

    if (this._popup) {
      this._checkForPopupClosedTimer = window.setInterval(this._checkForPopupClosed.bind(this), CheckForPopupClosedInterval);
      window.addEventListener("message", this.onMessage.bind(this), false);
    }

    return this._promise;
  }

  onMessage(message) {
    var data = message ? message.data : undefined;

    if (data && data.type === 'rise-auth-popup-callback') {
      this._callback(data);
    }
  }

  _callback(data) {
    this._cleanup();
    this._resolve(data);
  }

  _error(message) {
    this._cleanup();
    this._reject(new Error(message));
  }

  _cleanup() {

    window.clearInterval(this._checkForPopupClosedTimer);
    window.removeEventListener("message", this.onMessage);
    this._checkForPopupClosedTimer = null;

    if (this._popup) {
        this._popup.close();
    }
    this._popup = null;
  }

  _checkForPopupClosed() {
    if (!this._popup || this._popup.closed) {
      this._error("Popup window closed");
    }
  }

  _getPopupPosition() {
    const popupWidth = 500;
    const popupHeight = 500;
    const left = (window.screen.width / 2) - (popupWidth / 2);
    const top = (window.screen.height / 2) - (popupHeight / 2);

    return `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`;
  }
}
