<div id="checkout-success" class="row">
  <div class="col-md-8 col-md-offset-2">
    <h4 class="u_margin-md-bottom">
      @if (purchaseAction === 'add' || purchaseAction === 'unlimited') {
        <span>A great big thank you!</span>
      }
      @else if (purchaseAction === 'remove') {
        <span>Display license removal initiated.</span>
      }
      @else if (purchaseAction === 'annual') {
        <span>Billing frequency has been adjusted.</span>
      }
    </h4>
    @if (subscriptionService.getItemSubscription().has_scheduled_changes) {
      <pending-change-alert></pending-change-alert>
    }
    <div class="border-container p-4">
      <p class="u_margin-md-bottom">
      We’ve sent a confirmation email to <b>{{updateSubscriptionService.userEmail}}</b>.
    </p>
    <p>
      <b>Details:</b><br />
      @if (purchaseAction === 'add') {
        <span>
          You’ve added
          @if (updateSubscriptionService.displayLicensePurchase.licensesToAdd) {
            <b>{{updateSubscriptionService.displayLicensePurchase.licensesToAdd}} display license{{ updateSubscriptionService.displayLicensePurchase.licensesToAdd > 1 ? 's' : '' }}</b>
          }
          @if (updateSubscriptionService.displayLicensePurchase.licensesToAdd && updateSubscriptionService.unlimitedLicensePurchase.licensesToAdd) {
            <span> and </span>
          }
          @if (updateSubscriptionService.unlimitedLicensePurchase.licensesToAdd) {
            <b>{{updateSubscriptionService.unlimitedLicensePurchase.licensesToAdd}} unlimited license{{ updateSubscriptionService.unlimitedLicensePurchase.licensesToAdd > 1 ? 's' : '' }}</b>
          }
          to your subscription.<br/>
        </span>
      }
      @else if (purchaseAction === 'remove') {
        <span>
          <b>{{updateSubscriptionService.displayLicensePurchase.licensesToRemove}} display license{{ updateSubscriptionService.displayLicensePurchase.licensesToRemove > 1 ? 's' : '' }}</b> will be removed from your subscription on the next billing cycle.<br/>
        </span>
      }
      @else if (purchaseAction === 'annual') {
        <span>
          You’ve changed your billing frequency to <b>Annual</b>.
        </span>
      }
      @else if (purchaseAction === 'unlimited') {
        <span>
          You’ve added <b>unlimited display licenses</b> to your subscription.<br/>
        </span>
      }
      @else if (purchaseAction === 'change') {
        <span>
          You’ve updated your plan to <b>{{ planName }}</b>.
        </span>
      }
      @if (updateSubscriptionService.getTotalDue() && !updateSubscriptionService.getCreditTotal()) {
        <span>
          You will be charged <b>{{updateSubscriptionService.getTotalDue() | currency }}</b>.
        </span>
      }
      @if (updateSubscriptionService.getCreditTotal() && !updateSubscriptionService.getTotalDue()) {
        <span>
          You will be credited <b>{{updateSubscriptionService.getCreditTotal() | currency }}</b>.
        </span>
      }
      @if (updateSubscriptionService.getTotalDue() && updateSubscriptionService.getCreditTotal()) {
        <span>
          You will be credited <b>{{updateSubscriptionService.getCreditTotal() | currency }}</b> and charged <b>{{updateSubscriptionService.getTotalDue() | currency }}</b>.
        </span>
      }
    </p>
    @if (subscriptionService.getItemSubscription().has_scheduled_changes && updateSubscriptionService.estimates[0]?.next_invoice_estimate) {
      <p id="next-invoice-row" class="left-right-aligner mb-0 border-top pt-5 pb-2">
        <span class="font-weight-bold">Next invoice on {{updateSubscriptionService.estimates[0].subscription_estimate?.next_billing_at * 1000 | date:'d-MMM-yyyy'}}:</span>
        <span>
          <span class="u_margin-right text-subtle">{{updateSubscriptionService.estimates[0].next_invoice_estimate?.currency_code}}</span>
          <span class="purchase-total">{{updateSubscriptionService.estimates[0].next_invoice_estimate?.total/100 | currency }}</span>
        </span>
      </p>
    }
    </div>
    <div class="button-row u_margin-md-top">
      <button id="doneButton" class="btn btn-primary btn-block" (click)="closeEvent.emit()" aria-label="Done" tabindex="1">Continue</button>
    </div>
  </div>
</div>
