import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import { LocationHelperService } from 'src/app/shared/services/location-helper.service';

@Injectable({
  providedIn: 'root'
})
export class RvshareAppDetectionService {

  constructor(private locationHelperService: LocationHelperService) { }

  isScreenShareApp(): boolean {
    const paramVal = this.locationHelperService.getSearchParam("rvshare");

    return paramVal != null && paramVal !== "false";
  }

  getInvalidCode(): string {
    return this.locationHelperService.getHashParam("invalidCode");
  }

  getConnectionError(): string {
    return this.locationHelperService.getHashParam("connectionError")
  }

  resetConnectionError(): void {
    if (!this.isScreenShareApp()) {return;}

    this.locationHelperService.removeHashParam("connectionError");
  }

  issueDeepLink(target: string): void {
    setTimeout(() => {
      window.location.href = `deeplink://rvshare/${target}`;
    }, 0);
  }
}

angular.module('risevision.apps.services')
  .factory('rvShareAppDetection', downgradeInjectable(RvshareAppDetectionService));
