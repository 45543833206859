import { Component, Input } from '@angular/core';

@Component({
  selector: 'template-editor-empty-file-list',
  templateUrl: './empty-file-list.component.html',
  styleUrls: ['./empty-file-list.component.scss']
})
export class EmptyFileListComponent {

  @Input() fileType: string;
  @Input() isEditingLogo: boolean;

  constructor() { }

}
