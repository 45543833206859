import { Injectable } from '@angular/core';
import { ModalService } from 'src/app/components/modals/modal.service';
import { CompanyApiService } from 'src/app/api/services/company-api.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  loading = false;

  constructor(
    private userStateService: UserStateService,
    private companyApiService: CompanyApiService,
    private modalService: ModalService) { }

  exportCompany(company: any) {
    return new Promise<void>((resolve, reject) => {
      this.modalService.confirm('Export companies?',
        'An export file will be prepared and emailed to you at <b>' + this.userStateService.getUserEmail() +
        '</b> once ready.<br/> Please ensure your email is configured to accept emails from <b>no-reply@risevision.com</b>.',
        'Export', 'Cancel').then(() => {

        this.loading = true;
        this.companyApiService.exportCompany(company.id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
      }).catch(() => {
        reject();
      }).finally(() => {
        this.loading = false;
      });
    });
  }
}
