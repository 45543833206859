import { ElementRef, Component, ChangeDetectorRef } from '@angular/core';
import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';

@Component({
  selector: 'template-component-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})
export class ColorsComponent {

  componentId;
  override;
  baseColor;
  accentColor;

  constructor(private elementRef: ElementRef,
    private componentsFactory: ComponentsService,
    private attributeDataFactory: AttributeDataService,
    private changeDetectorRef: ChangeDetectorRef) {

    componentsFactory.registerDirective({
      type: 'rise-override-brand-colors',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsFactory.selected.id;
        this.load();
      }
    });
  }

  handleEnterKey() {
    this.override = !this.override;
    this.save();
  }

  save() {
    var brandingOverride = null;
    if (this.override) {
      brandingOverride = {
        'baseColor': this.baseColor,
        'accentColor': this.accentColor
      };
    }
    this.attributeDataFactory.setAttributeDataGlobal('brandingOverride', brandingOverride);
    this.changeDetectorRef.detectChanges();
  }

  load() {
    var brandingOverride = this.attributeDataFactory.getAttributeDataGlobal('brandingOverride');
    this.override = !!brandingOverride;
    this.baseColor = this.override ? brandingOverride.baseColor : null;
    this.accentColor = this.override ? brandingOverride.accentColor : null;
  }

}
