import { Component, Input, EventEmitter, Output } from '@angular/core';
import { TimelineDescriptionService } from '../timeline-description.service';
import { TimelineUtilsService } from '../timeline-utils.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { TimelineModalComponent } from '../timeline-modal/timeline-modal.component';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'timeline-textbox',
  templateUrl: './timeline-textbox.component.html',
  styleUrls: ['./timeline-textbox.component.scss']})
export class TimelineTextboxComponent {

  @Input() useLocaldate: boolean = false;
  @Input() readonly: boolean = false;

  private _timelineObject: any = {};
  get timelineObject(): any {
    return this._timelineObject;
  }
  @Input() set timelineObject(value: any) {
    this._timelineObject = value;
    this.timeline = {
      useLocaldate: !!this.useLocaldate,
      always: !this._timelineObject.timeDefined,
      startDate: this._timelineObject.startDate || this.timelineUtilsService.getDateTime(0, 0, this.useLocaldate),
      endDate: this._timelineObject.endDate || null,
      allDay: true && (!this._timelineObject.startTime && !this._timelineObject.endTime || false),
      startTime: this._timelineObject.startTime || null,
      endTime: this._timelineObject.endTime || null,
      recurrenceType: this._timelineObject.recurrenceType || TimelineDescriptionService.RECURRENCE.DAILY,
      recurrenceFrequency: this._timelineObject.recurrenceFrequency || 1,
      recurrenceAbsolute: typeof this._timelineObject.recurrenceAbsolute !== 'undefined'
        ? this._timelineObject.recurrenceAbsolute
        : true,
      recurrenceDayOfWeek: this._timelineObject.recurrenceDayOfWeek || 0,
      recurrenceDayOfMonth: this._timelineObject.recurrenceDayOfMonth || 1,
      recurrenceWeekOfMonth: this._timelineObject.recurrenceWeekOfMonth || 0,
      recurrenceMonthOfYear: this._timelineObject.recurrenceMonthOfYear || 0,
      recurrenceDaysOfWeek: this._timelineObject.recurrenceDaysOfWeek || []
    };

    this.timeline.label = this.timelineDescriptionService.updateLabel(this.timeline);
  }

  @Output() timelineObjectChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() timelineChange: EventEmitter<void> = new EventEmitter<void>();

  timeline: any = {};

  constructor(
    private timelineDescriptionService: TimelineDescriptionService,
    private timelineUtilsService: TimelineUtilsService,
    private modalService: ModalService
  ) { }

  toggleAlways(value: boolean) {
    this.timeline.always = value;
    this.timelineObject.timeDefined = !value;
    this.timelineObjectChange.emit(this.timelineObject);
    this.timelineChange.emit();
  }

  openModal() {
    if (this.readonly) {
      return;
    }

    this.modalService.showMediumModal(TimelineModalComponent, {
      initialState: {
        timeline: angular.copy(this.timeline)
      }
    }).then((timeline) => {
      this.timeline = timeline;

      this.timelineObject.startDate = timeline.startDate;
      this.timelineObject.endDate = timeline.endDate;
      this.timelineObject.startTime = timeline.startTime;
      this.timelineObject.endTime = timeline.endTime;
      this.timelineObject.recurrenceType = timeline.recurrenceType;
      this.timelineObject.recurrenceFrequency = timeline.recurrenceFrequency;
      this.timelineObject.recurrenceAbsolute = timeline.recurrenceAbsolute;
      this.timelineObject.recurrenceDayOfWeek = timeline.recurrenceDayOfWeek;
      this.timelineObject.recurrenceDayOfMonth = timeline.recurrenceDayOfMonth;
      this.timelineObject.recurrenceWeekOfMonth = timeline.recurrenceWeekOfMonth;
      this.timelineObject.recurrenceMonthOfYear = timeline.recurrenceMonthOfYear;
      this.timelineObject.recurrenceDaysOfWeek = timeline.recurrenceDaysOfWeek;

      this.timeline.label = this.timelineDescriptionService.updateLabel(this.timeline);

      this.timelineObjectChange.emit(this.timelineObject);
      this.timelineChange.emit();
    }).catch(function () {});
  }

}

angular.module('risevision.common.components')
  .directive(
    'timelineTextbox',
    downgradeComponent({
      component: TimelineTextboxComponent
    }) as angular.IDirectiveFactory
  );