import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'weekday-dropdown',
  templateUrl: './weekday-dropdown.component.html',
  styleUrls: ['./weekday-dropdown.component.scss']
})
export class WeekdayDropdownComponent {

  @Input() weekday: number;
  @Output() weekdayChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  emitValue () {
    this.weekdayChange.emit(this.weekday);
  }
}