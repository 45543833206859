import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';

import * as _ from 'lodash';

import { CompanyAssetsService, ProcessErrorCode, ScrollingListService } from 'src/app/ajs-upgraded-providers';

import { ModalService } from 'src/app/components/modals/modal.service';

import { PlaylistService } from './playlist.service';
import { ScheduleApiService } from 'src/app/api/services/schedule-api.service';

import { AddToScheduleModalComponent } from '../components/add-to-schedule-modal/add-to-schedule-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ScheduleSelectorService {

  search: any = {
    sortBy: 'name'
  };
  selectedSchedules = [];
  unselectedSchedules = null;
  selectedCount = 0;

  presentation;

  hasSelectedSchedules = false;
  loadingSchedules = false;

  errorMessage;
  apiError;

  schedulesComponent = {
    type: 'rise-schedules',
    service: this,
    showNoSchedulesError: false
  };

  constructor(
    private stateService: StateService,
    private processErrorCode: ProcessErrorCode,
    private modalService: ModalService,
    private ScrollingListService: ScrollingListService,
    private scheduleApiService: ScheduleApiService,
    private playlistService: PlaylistService,
    private companyAssetsService: CompanyAssetsService
  ) { }

  _reset () {
    // reset search query
    this.search = {
      sortBy: 'name'
    };
    this.selectedCount = 0;
  }

  loadSelectedSchedules () {
    if (!this.presentation) {
      return Promise.resolve();
    }

    var search = {
      sortBy: 'name',
      filter: 'presentationIds:~\"' + this.presentation?.id + '\"'
    };

    this.hasSelectedSchedules = true;

    this.selectedSchedules = [];
    this.loadingSchedules = true;

    return this.scheduleApiService.list(search)
      .then((result) => {
        this.selectedSchedules = result.items ? result.items : [];

        this.hasSelectedSchedules = !!this.selectedSchedules.length;
      })
      .catch((e) => {
        this.errorMessage = 'Failed to load schedules.';
        this.apiError = this.processErrorCode('Schedules', 'load', e);

        console.error(this.errorMessage, e);
      })
      .finally(() => {
        this.loadingSchedules = false;
      });
  }

  getSchedulesComponent (currentPresentation) {
    this.presentation = currentPresentation;
    this.schedulesComponent.showNoSchedulesError = false;

    this.loadSelectedSchedules();

    return this.schedulesComponent;
  }

  _loadUnselectedSchedules () {
    this.search.filter = 'NOT presentationIds:~\"' + this.presentation.id + '\"';

    this.unselectedSchedules = this.ScrollingListService(this.scheduleApiService.list.bind(this.scheduleApiService), this.search);
  }

  load () {
    this._reset();

    this._loadUnselectedSchedules();
  }

  _getSelectedScheduleIds () {
    var filteredSchedules = _.filter(this.unselectedSchedules.items.list, (item) => {
      return item.isSelected;
    });

    return _.map(filteredSchedules, (item) => {
      return item.id;
    });
  }

  _getUnselectedScheduleIds () {
    var filteredSchedules = _.filter(this.selectedSchedules, (item) => {
      return item.isSelected === false;
    });

    return _.map(filteredSchedules, (item) => {
      return item.id;
    });
  }

  _updateSelectedCount () {
    this.selectedCount = this._getSelectedScheduleIds().length + this._getUnselectedScheduleIds().length;
  }

  selectItem (item, inSelectedSchedules?) {
    if (item.isSelected === true) {
      item.isSelected = false;
    } else if (item.isSelected === false) {
      item.isSelected = true;
    } else {
      item.isSelected = !inSelectedSchedules;
    }

    this._updateSelectedCount();
  }

  isSelected (item, inSelectedSchedules?) {
    if (item.isSelected === true) {
      return true;
    } else if (item.isSelected === false) {
      return false;
    } else {
      return inSelectedSchedules;
    }
  }

  _updateSelectedSchedules () {
    var scheduleIds = this._getSelectedScheduleIds();

    if (!scheduleIds.length) {
      return Promise.resolve();
    }

    var playlistItem = this.playlistService.newPresentationItem(this.presentation);

    return this.playlistService.initPlayUntilDone(playlistItem, this.presentation, true)
      .then(() => {
        return this.scheduleApiService.addPresentation(scheduleIds, JSON.stringify(playlistItem));
      });
  }

  _updateUnselectedSchedules () {
    var scheduleIds = this._getUnselectedScheduleIds();

    if (!scheduleIds.length) {
      return Promise.resolve();
    }

    return this.scheduleApiService.removePresentation(scheduleIds, this.presentation.id);
  }

  select () {
    if (this.selectedCount === 0) {
      return;
    }

    this.loadingSchedules = true;

    return Promise.all([this._updateSelectedSchedules(), this._updateUnselectedSchedules()])
      .then(() => {
        return this.loadSelectedSchedules();
      });
  }

  addSchedule () {
    this.stateService.go('apps.schedules.add', {
      presentationItem: this.presentation
    });
  }

  checkAssignedToSchedules () {
    return this.companyAssetsService.hasSchedules()
      .then((hasSchedules) => {
        if (hasSchedules && !this.hasSelectedSchedules) {
          return this._showAddToScheduleModal();
        }
      });
  }

  _showAddToScheduleModal () {
    this.schedulesComponent.showNoSchedulesError = true;
    return this.modalService.showModal(AddToScheduleModalComponent, {
      class: 'modal-sm'
    });
  }

}
