import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BillingUtilsService {

  constructor() { }

  isActive(subscription) {
    return subscription.status === 'active' || subscription.status === 'non_renewing';
  }

  isCancelled(subscription) {
    return subscription.status === 'cancelled';
  }

  isSuspended(subscription) {
    return subscription.status === 'suspended';
  }

  hasPendingChange(subscription) {
    return subscription.has_scheduled_changes || subscription.cancelled_at;
  }

}
