<ng-template #shareUrlTooltip>
  <div id="share-url-popover">
    <span class="text-sm font-weight-bold">Share</span>
    <button type="button" class="close" (click)="toggleTooltip()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="10" height="10"></streamline-icon>
    </button>

    <div class="aligner mt-3">
      <input class="form-control copy-text-box w-100" type="text" readonly="readonly" value="{{getUrl()}}" (focus)="onTextFocus($event)" [focusMe]="true"/>
      <button type="button" id="copyUrlButton" class="btn btn-primary ml-2" (click)="copyToClipboard(getUrl())" aria-label="Copy">Copy</button>
    </div>

  </div>
</ng-template>

<button id="share-url-button" type="button"
  #tooltipTrigger="bs-tooltip"
  triggers=""
  [tooltip]="shareUrlTooltip"
  tooltipAnimation="false"
  [adaptivePosition]="false"
  placement="bottom"
  containerClass="madero-style tooltip-share-options"
  tooltip-template="'partials/billing/share-url-popover.html'"
  (onShown)="showPopover()"
  (onHidden)="hidePopover()"
  (click)="toggleTooltip()"
  class="btn btn-default btn-toolbar"
>
  Share
</button>