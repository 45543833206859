import { Component, Input } from '@angular/core';
import { PlansService } from 'src/app/components/plans/plans.service';
import { SubscriptionService } from 'src/app/billing/services/subscription.service';
import { HelpWidgetFactory } from 'src/app/ajs-upgraded-providers';

@Component({
  selector: 'pending-change-alert',
  templateUrl: './pending-change-alert.component.html',
  styleUrls: ['./pending-change-alert.component.scss']
})
export class PendingChangeAlertComponent {

  @Input() isUpdate: boolean;
  @Input() upgrade: boolean;

  getPlanName(planId): any {
    if (!planId || !this.plansService.getPlanById(planId)) {
      return '';
    }

    return this.plansService.getPlanById(planId).name;
  }

  constructor(
    private plansService: PlansService,
    public subscriptionService: SubscriptionService,
    public helpWidgetFactory: HelpWidgetFactory
  ) { }

}
