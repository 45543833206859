import { Component, Input, Output, EventEmitter, NgZone, OnInit, ChangeDetectorRef } from '@angular/core';
import { ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { DisplayService } from 'src/app/displays/services/display.service';
import { FeaturesService } from '../../plans/features.service';
import { DisplayApiService } from 'src/app/api/services/display-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'distribution-list',
  templateUrl: './distribution-list.component.html',
  styleUrls: ['./distribution-list.component.scss']
})
export class DistributionListComponent implements OnInit {

  static readonly DB_MAX_COUNT = 40;

  @Input() distribution: string[];
  @Input() subcompanyDistribution: string[];
  @Input() distributeToSubcompanies: boolean;
  @Input() enableSubcompanies: boolean = false;
  @Output() distributionChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() subcompanyDistributionChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() distributeToSubcompaniesChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeEvent: EventEmitter<null> = new EventEmitter<null>();

  search: any = {
    sortBy: 'name',
    count: DistributionListComponent.DB_MAX_COUNT,
    reverse: false,
    includeSubcompanies: false,
    onListUpdate: () => {
      this.changeDetectorRef.detectChanges();
    }
  };

  displays;
  scheduleOverrides: string[] = [];

  filterConfig = {
    placeholder: 'Search Displays',
    id: 'displaySearchInput'
  };

  constructor(private ScrollingListService: ScrollingListService,
    private displayApiService: DisplayApiService,
    public displayService: DisplayService,
    public companyStateService: CompanyStateService,
    private featuresService: FeaturesService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.companyStateService.isRootCompanySelected()) {
      this.distributeToSubcompanies = false;
    }

    if (this.distributeToSubcompanies) {
      this.search.includeSubcompanies = true;
    }
    this.displays = this.ScrollingListService(this.displayApiService.list.bind(this.displayApiService), this.search);
  }

  get subcompanyDistributionAvailable(): boolean | null {
    return this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_SUBCOMPANY_DISTRIBUTION);
  }

  get subcompanyDistributionDisabled() {
    return !this.subcompanyDistributionAvailable && !this.distributeToSubcompanies || this.companyStateService.isRootCompanySelected();
  }

  showUpgradePlanModal(): void {
    this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_SUBCOMPANY_DISTRIBUTION).then((result) => {
      if (result) {
        this.closeEvent.emit();
      }
    });
  }

  toggleAllCompanies (value) {
    if (!this.subcompanyDistributionDisabled) {
      this.distributeToSubcompanies = value;
      this.distributeToSubcompaniesChange.emit(value);

      this.search.includeSubcompanies = value;
      this.displays.doSearch();
    }
  }

  refresh () {
    setTimeout(() => {
      this.ngZone.run(() => {});
    });
  }

  showPopover (overrideNames: string[]) {
    this.scheduleOverrides = overrideNames;
    this.refresh();
  }

  private _updateDistribution (displayId, distribution, emitter) {
    const index = distribution.indexOf(displayId);
    if (index > -1) {
      distribution.splice(index, 1);
    } else {
      distribution.push(displayId);
    }
    emitter.emit(distribution);
  }

  toggleDisplay (displayId, companyId) {
    if (companyId === this.companyStateService.getSelectedCompanyId()) {
      this._updateDistribution(displayId, this.distribution, this.distributionChange);
    } else {
      this._updateDistribution(displayId, this.subcompanyDistribution, this.subcompanyDistributionChange);
    }
  }

  isSelected (displayId) {
    return this.distribution.indexOf(displayId) >= 0 || this.subcompanyDistribution.indexOf(displayId) >= 0;
  }
}