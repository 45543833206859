<div class="madero-style alert alert-info flex-row" role="alert">
  <streamline-icon aria-hidden="true" name="info" width="16" height="16"></streamline-icon>
  <p><strong>
    @if (subscriptionService.getItemSubscription().has_scheduled_changes) {
      <span>
        Pending change:
        @if (subscriptionService.getItemSubscription().changed_plan_quantity > 0) {
          <span>
            remove {{ subscriptionService.getItemSubscription().changed_plan_quantity }}
            from {{ subscriptionService.getItemSubscription().plan_quantity }} display licenses
          </span>
        }
        @else if (subscriptionService.getItemSubscription().changed_plan_quantity < 0) {
          <span>
            add {{ -subscriptionService.getItemSubscription().changed_plan_quantity }}
            to {{ subscriptionService.getItemSubscription().plan_quantity }} display licenses
          </span>
        }

        @if (getPlanName(this.subscriptionService.getItemSubscription().changed_plan_id)) {
          <span>
            @if (subscriptionService.getItemSubscription().changed_plan_quantity) {
              <span>and change plan</span>
            }
            from {{ getPlanName(this.subscriptionService.getItemSubscription().plan_id) }}
            to {{ getPlanName(this.subscriptionService.getItemSubscription().changed_plan_id) }}
          </span>
        }
        will take effect on
        {{ subscriptionService.getItemSubscription().next_billing_at * 1000 | date:'d-MMM-yyyy' }}.
      </span>
    }
    @else {
      <span>
        @if (subscriptionService.getItemSubscription().cancelled_at) {
          <span>
            This subscription is scheduled to cancel on
            {{ subscriptionService.getItemSubscription().cancelled_at * 1000 | date:'d-MMM-yyyy' }}.
          </span>
        }
        @else {
          <span>
            Changes to your subscription will update on your next billing cycle on
            {{ subscriptionService.getItemSubscription().next_billing_at * 1000 | date:'d-MMM-yyyy' }}.
          </span>
        }
      </span>
    }
    @if (isUpdate && subscriptionService.getItemSubscription().has_scheduled_changes) {
      <div class="pt-3">
        The following change will cancel
        @if (!upgrade) {
          <span>and replace</span>
        }
        your previous pending changes.
        <a class="madero-link" href="#" (click)="helpWidgetFactory.showContactUs()">Contact Us</a>
        if you need help updating this subscription.
      </div>
    }
  </strong></p>
</div>
