import { Injectable } from '@angular/core';

import { StorageUtilsService } from './storage-utils.service';
import { StorageApiService } from 'src/app/api/services/storage-api.service';

@Injectable({
  providedIn: 'root'
})
export class StorageManagerService {
  public static readonly FILE_TYPE = {
    IMAGE: 'image',
    VIDEO: 'video',
    FOLDER: 'folder',
    IMAGE_VIDEO: 'image,video',
    IMAGE_VIDEO_FOLDER: 'image,video,folder',
    DOCUMENT: 'document'
  };

  public static readonly DOCUMENT_TYPE = {
    POWERPOINT: 'powerpoint'
  }

  public static readonly SUPPORTED_IMAGE_TYPES = '.bmp, .gif, .jpeg, .jpg, .png, .svg, .webp';
  public static readonly SUPPORTED_VIDEO_TYPES = '.mp4, .webm';
  public static readonly SUPPORTED_IMAGE_VIDEO_TYPES = '.bmp, .gif, .jpeg, .jpg, .png, .svg, .webp, .mp4, .webm';
  public static readonly SUPPORTED_DOCUMENT_TYPES = '.docx, .pdf, .xlsx';
  public static readonly SUPPORTED_POWERPOINT_TYPES = '.pptx';

  public static readonly CANVA_FOLDER = 'canva/';

  public onSelectHandler: Function;
  public isSingleFileSelector: Function;

  public fileType;
  public loadingFiles;
  isListView = true;
  folderItems = [];
  constructor(
    private storageApiService: StorageApiService,
    private storageUtils: StorageUtilsService) { }

    fileHasValidExtension(file: string, extensions?: string[]): boolean {
      return !extensions || extensions.length === 0 || extensions.some((extension) => {
        return file.toLowerCase().endsWith(extension.trim().toLowerCase());
      });
    }

    getValidExtensions(fileType: string): string {
      if (fileType === StorageManagerService.FILE_TYPE.IMAGE) {
        return StorageManagerService.SUPPORTED_IMAGE_TYPES;
      } else if (fileType === StorageManagerService.FILE_TYPE.VIDEO) {
        return StorageManagerService.SUPPORTED_VIDEO_TYPES;
      } else if (fileType && fileType.indexOf(StorageManagerService.FILE_TYPE.IMAGE_VIDEO) !== -1) {
        return StorageManagerService.SUPPORTED_IMAGE_VIDEO_TYPES;
      } else if (fileType === StorageManagerService.FILE_TYPE.DOCUMENT) {
        return StorageManagerService.SUPPORTED_DOCUMENT_TYPES;
      } else if (fileType === StorageManagerService.DOCUMENT_TYPE.POWERPOINT) {
        return StorageManagerService.SUPPORTED_POWERPOINT_TYPES;
      } else {
        return '';
      }
    }

    getValidExtensionsList(fileType: string): string[] {
      const validExtensions = this.getValidExtensions(fileType);

      return validExtensions ? validExtensions
        .split(',')
        .map(extension => {
          return extension.trim();
        }) : [];
    }

    isImage(file: string): boolean {
      return this.getFileType(file) === StorageManagerService.FILE_TYPE.IMAGE;
    }

    getFileType(file: string): string {
      if (file && this.fileHasValidExtension(file, this.getValidExtensionsList(StorageManagerService.FILE_TYPE.IMAGE))) {
        return StorageManagerService.FILE_TYPE.IMAGE;
      } else if (file && this.fileHasValidExtension(file, this.getValidExtensionsList(StorageManagerService.FILE_TYPE.VIDEO))) {
        return StorageManagerService.FILE_TYPE.VIDEO;
      } else if (file && this.fileHasValidExtension(file, this.getValidExtensionsList(StorageManagerService.FILE_TYPE.DOCUMENT))) {
        return StorageManagerService.FILE_TYPE.DOCUMENT;
      } else {
        return '';
      }
    }

    getDocumentType(file: string): string {
      if (file && this.fileHasValidExtension(file, this.getValidExtensionsList(StorageManagerService.DOCUMENT_TYPE.POWERPOINT))) {
        return StorageManagerService.DOCUMENT_TYPE.POWERPOINT;
      } else {
        return '';
      }
    }

    isCanva(source: string): boolean {
      if (!source) {
        return false;
      }
      const parts = source.split('/');
      return parts.length > 2 && parts[parts.length - 2] === 'canva';
    }

    loadFiles(folderPath: string, type: string = ''): Promise<any> {
      this.loadingFiles = true;

      const fileType = type ? type : this.fileType;

      return this.storageApiService.getFiles({
          folderPath: folderPath
        })
        .then((items) => {
          if (items.files) {
            let validExtensions = this.getValidExtensionsList(fileType);

            this.folderItems = items.files.filter((item) => {
              let isValid = false;

              if (fileType !== StorageManagerService.FILE_TYPE.FOLDER) {
                isValid = this.fileHasValidExtension(item.name, validExtensions);
              }

              return item.name !== folderPath && (this.storageUtils.fileIsFolder(item) || isValid);
            });
          } else {
            this.folderItems = [];
          }

          return this.folderItems;
        })
        .catch( (err) => {
          console.log('Failed to load files', err);
        })
        .finally( () => {
          this.loadingFiles = false;
        });
    }
}
