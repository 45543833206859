<div class="workspace-sidebar-body pr-3">
  <form #placeholderForm="ngForm" id="placeholderForm" role="form" name="forms.placeholderForm" novalidate>
    <div class="form-group" [hidden]="editingName">
      <label class="control-label">Name</label>
      <a id="editPlaceholderName" class="link u_margin-left" href="#" (click)="editingName = true; placeholder.newId = placeholder.id">Edit</a>
      <input type="text" name="id" id="placeholderId" class="form-control" [ngModel]="placeholder.id" [disabled]="true">
    </div><!--form-group-->

    <div class="form-group" [shown]="editingName" [ngClass]="{ 'has-error': newId.errors }">
      <label class="control-label">Name</label>
      <a id="savePlaceholderName" class="link u_margin-left" href="#" (click)="updatePlaceholderName()">Apply</a>
      <a id="cancelPlaceholderName" class="link u_margin-left" href="#" (click)="editingName = false">Cancel</a>

      <input #newId="ngModel" type="text" id="placeholderNewId" name="newId" class="form-control" placeholder="Placeholder Name" [(ngModel)]="placeholder.newId" required pattern="^[a-zA-Z0-9\-\_]*$" placeholderNameValidator (ngModelChange)="changeDetected()">
      <p id="requiredValidation" class="text-danger" [shown]="newId.errors?.['required']">
        Required
      </p>
      <p id="invalidValidation" class="text-danger" [shown]="newId.errors?.['pattern']">
        Placeholder name contains invalid character
      </p>
      <p id="duplicateValidation" class="text-danger" [shown]="newId.errors?.['duplicate']">
        Duplicate Placeholder names are not allowed
      </p>
    </div><!--form-group-->

    <div class="form-group">
      <div class="flex-row">
        <madero-checkbox name="visibility" [(ngModel)]="placeholder.visibility" ngDefaultControl></madero-checkbox>
        <span class="control-label mr-3">Visible</span>
      </div>
    </div>

    <div class="form-group">
      <div class="row sm-gutter">
        <div class="col-sm-6">
          <label class="control-label u_margin-right">Width:</label>
          <div class="input-group">
            <input class="form-control" name="width" type="number" [(ngModel)]="placeholder.width">
            <span class="input-group-addon">{{placeholder.widthUnits}}</span>
          </div>
        </div>
        <div class="col-sm-6">
          <label class="control-label u_margin-right">Top (Y):</label>
          <div class="input-group">
            <input class="form-control" name="top" type="number" [(ngModel)]="placeholder.top">
            <span class="input-group-addon">{{placeholder.topUnits}}</span>
          </div>
        </div>
      </div>

      <div class="row sm-gutter u_margin-sm-top">
        <div class="col-sm-6">
          <label class="control-label u_margin-right">Height:</label>
          <div class="input-group">
            <input class="form-control" name="height" type="number" [(ngModel)]="placeholder.height">
            <span class="input-group-addon">{{placeholder.heightUnits}}</span>
          </div>
        </div>
        <div class="col-sm-6">
          <label class="control-label u_margin-right">Left (X):</label>
          <div class="input-group">
            <input class="form-control" name="left" type="number" [(ngModel)]="placeholder.left">
            <span class="input-group-addon">{{placeholder.leftUnits}}</span>
          </div>
        </div>
      </div>

    </div><!--form-group-->


    <div class="form-group">
      <label class="control-label u_margin-right">Z Order:</label>
      <div class="row sm-gutter">
        <div class="col-sm-6">
          <input type="number" name="zIndex" class="form-control" [(ngModel)]="placeholder.zIndex">
        </div><!--col-->

        <div class="col-sm-3">
        </div><!--col-->
      </div><!--row-->

    </div><!--form-group-->

    <div class="form-group">
      <label>Background:</label>
      <background-image id="placeholderBackgroundSettings" [background]="background" [companyId]="companyId"></background-image>
    </div>

    <div class="form-group">
      <label id="transitionLabel" class="control-label">Transition:</label>
      <select name="transition" id="transitionSelect" class="form-control" [(ngModel)]="placeholder.transition">
        <option value="none">None</option>
        <option value="fade">Fade</option>
        <option value="slide-right">Slide Right</option>
        <option value="slide-left">Slide Left</option>
        <option value="slide-up">Slide Up</option>
        <option value="slide-down">Slide Down</option>
      </select>
    </div><!--form-group-->

    <div class="attribute-editor-row mt-4">
      <label class="control-label">Timeline:</label>

      <timeline-textbox [(timelineObject)]="placeholder"></timeline-textbox>
    </div>

    <div class="attribute-editor-row mt-4">
      <label class="control-label">Distribution:</label>

      <distribution-selector [(distribution)]="placeholder.distribution" [(distributeToAll)]="placeholder.distributeToAll" [hideCta]="true"></distribution-selector>
    </div>

  </form>
</div>
