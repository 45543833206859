import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, FormGroup, ValidationErrors } from '@angular/forms';

import * as _ from 'lodash';

import { GeodataService } from 'src/app/shared/services/geodata.service';

@Directive({
  selector: '[provinceValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: ProvinceValidatorDirective, multi: true}]
})
export class ProvinceValidatorDirective implements Validator {

  validate(control: FormGroup): ValidationErrors | null {
    let provinceControl = control.controls['province'];
    let countryControl = control.controls['country'];

    let province = provinceControl ? provinceControl.value : '';
    let country = countryControl ? countryControl.value : '';

    let valid: any = true;

    if (country) {
      if (country === 'CA') {
        valid = province && _.find(GeodataService.REGIONS_CA, (region) => {
          return region[1] === province;
        });
      } else if (country === 'US') {
        valid = province && _.find(GeodataService.REGIONS_US, (region) => {
          return region[1] === province;
        });
      }
    }

    return valid ? null : {
      validProvince: {
        valid: false
      }
    };
  }

}
