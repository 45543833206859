@if (countOnly) {
  <div>
    <span>
      {{ playerLicenseService.getUsedLicenseCountString() }}
    </span>
  </div>
} @else {
  <div>
    @if (playerLicenseService.hasProfessionalLicenses()) {
      <span>
        {{ playerLicenseService.getUsedLicenseString() }}
      </span>
    }
    @if (!currentPlanService.isUnlimitedPlan() && displaySummaryService.summary && displaySummaryService.summary.scheduledUnlicensed) {
      <span>
        @if (playerLicenseService.hasProfessionalLicenses()) {
          <span> / </span>
        }
        <span class="danger">
          {{ displaySummaryService.summary.scheduledUnlicensed }}
          Scheduled Un-Licensed Display{{ displaySummaryService.summary.scheduledUnlicensed > 1 ? 's' : '' }}{{ requiredLicenses > 0 ? '.' : '' }}
        </span>
        @if (requiredLicenses > 0) {
          <a class="madero-link" href="#" (click)="purchaseLicenses()">
            Purchase License{{ displaySummaryService.summary.scheduledUnlicensed > 1 ? 's' : '' }}?
          </a>
        }
      </span>
    }
    @if (showOnlineCount && displaySummaryService.summary) {
      <div>
        {{ displaySummaryService.summary.online }} Online Displays / {{ displaySummaryService.summary.offline }} Offline Displays
      </div>
    }
  </div>
}