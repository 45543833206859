import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/components/modals/modal.component';
import { StorageUtilsService } from '../../services/storage-utils.service';
import { StorageApiService } from 'src/app/api/services/storage-api.service';

@Component({
  selector: 'new-folder-modal',
  templateUrl: './new-folder-modal.component.html',
  styleUrls: ['./new-folder-modal.component.scss']
})
export class NewFolderModalComponent extends ModalComponent {

  folderName = '';

  folderPath = '';
  existingFiles = [];

  duplicateFolderSpecified = false;
  waitingForResponse = false;
  serverError = false;
  accessDenied = false;
  accessDeniedMessage = '';
  respCode: number;

  constructor(
    public modalRef: BsModalRef,
    private storageApiService: StorageApiService
  ) {
    super(modalRef);
  }

  private _fileNameExists (fileName) {
    for (var i = 0; i < this.existingFiles.length; i++) {
      if (this.existingFiles[i].name === fileName) {
        return true;
      }
    }
    return false;
  }

  ok () {
    if (!this.folderName) {
      this.folderName = '';
    }
    this.folderName = this.folderName.replace(/\//g, '');
    if (this._fileNameExists(this.folderName + '/')) {
      this.duplicateFolderSpecified = true;
      return;
    }
    if (this.folderName !== '') {
      this.waitingForResponse = true;

      this.storageApiService.createFolder(this.folderPath + this.folderName)
        .then((resp) => {

          if (resp.code === 200) {
            super.resolve(this.folderName);

          } else if (resp.code === 403 && resp.message.indexOf('restricted-role') === -1) {
            this.accessDenied = true;
            this.accessDeniedMessage = StorageUtilsService.STORAGE_CLIENT_ERROR[resp.message];
          } else if (resp.code === 403) {
            this.accessDenied = true;
          } else {
            this.respCode = resp.code;
            this.serverError = true;
          }
        }).finally(() => {
          this.waitingForResponse = false;
        });
    }
  }

  cancel () {
    super.reject('cancel');
  }
}
