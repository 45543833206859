<div class="calendar-component-container">
  <div class="attribute-editor-component" rvSpinner="calendar-editor-loader" [rvShowSpinner]="spinner">
    <div class="calendar-component-settings te-scrollable-container">
      @if(revokeFailed) {
        <div class="attribute-editor-row">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group">
                <div class="text-sm">
                  We could not revoke your access with {{revokeFailedProviderName}} at this time. We strongly recommend you manually revoke access in your {{revokeFailedProviderName}} account.
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      @if(authenticateFailed) {
        <div class="attribute-editor-row">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group has-error">
                <p class="help-block">
                  <span>Sorry, it seems there's an issue with authentication.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      }

      <div class="attribute-editor-row">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              @if(state === states.NotConfigured) {
                <label>Connect Outlook Calendar:</label><br>
                <button id="te-outlook-connect" class="btn btn-provider-auth btn-microsoft-auth dark btn-hg btn-block mt-2" (click)="connectAccount(outlookProvider)">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/2/25/Microsoft_icon.svg">
                  <div class="mx-auto">Sign in with Microsoft</div>
                </button>
                <div class="calendar-horizontal-line">
                  <span class="calendar-or">or</span>
                </div>
                <label>Connect Google Calendar:</label><br>
                <button id="te-google-connect" class="btn btn-provider-auth btn-google-auth btn-hg btn-block mt-2" (click)="connectAccount(googleProvider)">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg">
                  <div class="mx-auto">Sign in with Google</div>
                </button>
              }
              @else {
                <div class="flex-row flex-wrap space-between mb-3">
                  <label>Connected Account:</label><br>
                  @if(state === states.ConfiguredByMe) {
                    <a class="madero-link u_clickable" (click)="confirmDisconnect()">Disconnect</a>
                  }
                </div>

                <div class="authenticated mt-2">
                  @if(provider === googleProvider) {
                    <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg">
                  }

                  @if(provider === outlookProvider) {
                    <img src="https://upload.wikimedia.org/wikipedia/commons/2/25/Microsoft_icon.svg">
                  }

                  <div class="mx-auto u_ellipsis">{{componentAccountUsername}}</div>
                </div>
              }
            </div>

            @if(state !== states.NotConfigured) {
              <hr class="flex-grow border-top">
            }
          </div>
        </div>
      </div>
      @if(provider === googleProvider && state !== states.NotConfigured) {
        <div class="attribute-editor-row">
          <div class="form-group has-feedback" [ngClass]="{ 'has-error': calendarsFailed }">
            @if(!isPublicUrl || state === states.ConfiguredByMe ) {
              <label class="control-label" for="te-calendar-id">Google Calendar:</label>
            }

            @if(state === states.ConfiguredByMe) {
              <select class="form-control" id="te-calendar-id" name="te-calendar-id" [(ngModel)]="selectedCalendar" (ngModelChange)="selectedCalendarChanged()">
                <option value="">Public URL</option>
                @for (c of calendars; track c) {
                  <option [value]="c.id">{{c.name}}</option>
                }
              </select>
              @if (calendarsFailed) {
                <p class="help-block">
                  <span>Sorry, it seems like we can't provide the list of your Google calendars. If the issue persists, contact support.</span>
                </p>
              }
            }
            @else {
              {{selectedName ? selectedName : ""}}
            }
          </div>
          @if (selectedCalendar && this.sameAccount) {
            <div>
              <a [href]="googleCalendarLink()" target="_blank">View Calendar</a>
            </div>
          }
        </div>
      }
      @if(isPublicUrl()) {
        <div class="attribute-editor-row">
          <div class="form-group has-feedback" [ngClass]="{ 'has-error': sourceResult && sourceResult !== 'VALID', 'has-success': sourceResult === 'VALID' && source !== '' }">
            <label class="control-label" for="te-calendar-source">Google Calendar Public URL:</label>
            <input type="text" id="te-calendar-source" class="form-control u_ellipsis" name="te-calendar-source" [(ngModel)]="source" placeholder="Calendar URL or ID" (debounceEvent)="sourceChanged()">
            <streamline-icon class="overlay-icon form-control-feedback" aria-hidden="true" name="{{sourceResult && sourceResult !== 'VALID' ? 'exclamation' : 'checkmark'}}" width="14" height="12"></streamline-icon>
            <p class="help-block">
              @switch (sourceResult) {
                @case ('INVALID') {
                  <span>
                    The calendar is not valid or is not publicly accessible. Refer to <a target="_blank" href="https://help.risevision.com/hc/en-us/articles/8357738823188-How-do-I-show-a-Google-Calendar-on-my-display-">this article</a> for instructions on how to correct this error.
                  </span>
                }
                @case ('FAILED') {
                  <span>Could not retrieve information for calendar {{source}}. Please try again. If you need help email <a target="_blank" href="mailto:support@risevision.com">support&#64;risevision.com</a></span>
                }
              }
            </p>
            @if (source && sourceResult === 'VALID') {
              <div>
                <a [href]="googleCalendarLink()" target="_blank">View Calendar</a>
              </div>
            }
          </div>
        </div>
      }

      @if(provider === outlookProvider && state !== states.NotConfigured) {
        <div class="attribute-editor-row">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group" [ngClass]="{ 'has-error': outlookGroupsFailed }">
                <label>Calendar Group:</label><br>
                @if(state === states.ConfiguredByMe) {
                  <select name="te-outlook-group-id" class="form-control" [(ngModel)]="selectedOutlookGroup" (ngModelChange)="selectedOutlookGroupChanged()">
                    <option value="" [shown]="false">Select Group</option>
                    @for (g of outlookGroups; track g) {
                      <option [value]="g.id">{{g.name}}</option>
                    }
                  </select>
                  @if (outlookGroupsFailed) {
                    <p class="help-block">
                      <span>Sorry, it seems like we can't provide the list of your Outlook calendar groups. If the issue persists, contact support.</span>
                    </p>
                  }
                }
                @else {
                  {{selectedOutlookGroupName ? selectedOutlookGroupName : "none"}}
                }
              </div>
            </div>
          </div>
        </div>
        <div class="attribute-editor-row">
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group" [ngClass]="{ 'has-error': calendarsFailed }">
                <label>Outlook Calendar:</label><br>
                @if(state === states.ConfiguredByMe) {
                  <select name="te-outlook-calendar-id" class="form-control" [(ngModel)]="selectedCalendar" (ngModelChange)="selectedCalendarChanged()">
                    <option value="" [shown]="false">Select Calendar</option>
                    @for (c of calendars; track c) {
                      <option [value]="c.id">{{c.name}}</option>
                    }
                  </select>
                  @if (calendarsFailed) {
                    <p class="help-block">
                      <span>Sorry, it seems like we can't provide the list of your Outlook calendars. If the issue persists, contact support.</span>
                    </p>
                  }
                }
                @else {
                  {{selectedName ? selectedName : "none"}}
                }
              </div>
            </div>
          </div>
        </div>
      }

      <div class="attribute-editor-row">
        <label class="control-label">Date Range:</label>
        <div class="has-feedback" [ngClass]="{ 'has-error': !isValidRange(), 'has-success': isValidRange() && range }">
          <div class="flex-row">
            <input type="number" class="form-control mr-2" id="te-calendar-range" name="te-calendar-range" placeholder="Range"
              [(ngModel)]="range"
              [min]="rangeMin"
              [max]="rangeMax"
              (ngModelChange)="rangeChanged()">
            <select class="form-control" id="te-calendar-period" name="te-calendar-period" [(ngModel)]="period" (ngModelChange)="periodChanged()">
              <option value="days">Days</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
            </select>
          </div>
          @if (!isValidRange()) {
            <p class="help-block">
              <span>Please provide a number between {{rangeMin}} and {{rangeMax}}.</span>
            </p>
          }
        </div>
      </div>
      <div class="attribute-editor-row">
        <div class="flex-row left-right-aligner pt-3">
          <label for="te-calendar-completed" id="calendar-completed-label" class="mb-0">Hide Today's Completed Events</label>
          <mat-slide-toggle id="te-calendar-completed" class="pl-3" [checked]="completed" (change)="completedChanged()" ariaLabelledby="calendar-completed-label"></mat-slide-toggle>
        </div>
      </div>
    </div>

    @if(state !== states.NotConfigured) {
      <div class="calendar-component-action-bar">
        <div class="pt-4">
          <button id="calendar-switch-source-button" class="btn btn-default btn-block" (click)="switchSource()">
            <strong>Switch Source</strong>
          </button>
        </div>
      </div>
    }
  </div>
</div>

