import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { LocationHelperService } from 'src/app/shared/services/location-helper.service';

@Injectable({
  providedIn: 'root'
})
export class RvTokenStoreService {

  TOKEN_STORE_KEY = 'rv-auth-object';

  constructor(
    private cookieService: CookieService,
    private locationHelperService: LocationHelperService
  ) { }

  read() {
    var token = this.cookieService.get(this.TOKEN_STORE_KEY);

    try {
      return JSON.parse(token);
    } catch (e) {
      return token;
    }
  }

  _clearLegacyToken(baseDomain) {
    this.cookieService.delete(this.TOKEN_STORE_KEY, '/', baseDomain);
  }

  write(value) {
    var baseDomain = this.locationHelperService.getBaseDomain();
    if (baseDomain === 'localhost') {
      this.cookieService.set(this.TOKEN_STORE_KEY, JSON.stringify(value), {
        path: '/'
      });
    } else {
      this._clearLegacyToken(baseDomain);

      this.cookieService.set(this.TOKEN_STORE_KEY, JSON.stringify(value), {
        path: '/',
        secure: true
      });
    }
  }

  clear() {
    var baseDomain = this.locationHelperService.getBaseDomain();
    if (baseDomain === 'localhost') {
      this.cookieService.delete(this.TOKEN_STORE_KEY, '/');
    } else {
      this.cookieService.delete(this.TOKEN_STORE_KEY, '/');

      this._clearLegacyToken(baseDomain);
    }
  }

}

angular.module('risevision.apps.services')
  .factory('rvTokenStore', downgradeInjectable(RvTokenStoreService));