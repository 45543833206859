import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { CreditCardService } from '../../services/credit-card.service';
import { PurchaseService } from '../../services/purchase.service';
import { PurchaseStepsService } from '../../services/purchase-steps.service';

@Component({
  selector: 'payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent {

  paymentMethods = this.creditCardService.paymentMethods;
  purchase = this.purchaseService.purchase;

  @ViewChild('paymentMethodsForm')
  public paymentMethodsForm: NgForm;

  constructor(private creditCardService: CreditCardService,
    private purchaseService: PurchaseService,
    public purchaseStepsService: PurchaseStepsService) { }

    completePayment() {
      return this.purchaseService.completePayment()
        .then(() => {
          if (!this.purchaseService.purchase.checkoutError) {
            this.purchaseStepsService.setNextStep();
          }
        });
    }

    completeCardPayment() {
      if (!this.paymentMethodsForm.valid) {
        return;
      }

      this.purchaseService.validatePaymentMethod()
        .then(() => {
          return this.purchaseService.preparePaymentIntent();
        })
        .then(this.completePayment.bind(this));
    }

}