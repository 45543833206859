<div id="addPresentationModal">
  <div class="modal-header">
    <button type="button" class="close" (click)="dismiss()" aria-label="Close this modal">
      <streamline-icon ariaLabel="Close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 class="modal-title">Select Presentations</h4>
  </div>
  <div class="modal-body align-left">
    <search-filter [filterConfig]="filterConfig" [search]="search" [doSearch]="presentations.doSearch" iconSet="madero"></search-filter>
    <div class="scrollable-list border-container u_margin-sm-top"
      scrollingList (scrollEndEvent)="presentations.load()"
      rvSpinner="presentation-list-loader" [rvShowSpinner]="presentations.loadingItems">
      <table id="presentationListTable" class="table table--hover">
        <thead class="table-header">
          <tr class="table-header__row">
            <th id="tableHeaderName" (click)="presentations.sortBy('name')" class="table-header__cell u_clickable">
              Name
              <!-- ngIf: search.sortBy == 'name' -->
              @if (search.sortBy === 'name') {
                <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
              }
              <!-- end ngIf: search.sortBy == 'name' -->
            </th>
            <th id="tableHeaderStatus" class="table-header__cell text-right">
              Status
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          @for (presentation of presentations.items.list; track presentation) {
            <tr class="table-body__row u_clickable" (click)="presentations.select(presentation)">
              <td class="table-body__cell">
                <div class="flex-row">
                  <madero-checkbox [ngValue]="presentation.selected"></madero-checkbox>
                  <a id="presentationName" class="madero-link u_ellipsis-lg"><strong>{{presentation.name}}</strong></a>
                </div>
              </td>
              <td class="table-body__cell text-right"><span [ngClass]="{'text-danger': presentation.revisionStatus === 1}">{{presentation.revisionStatusName}}</span></td>
            </tr>
          }
          <!-- If no presentation available -->
          <tr class="table-body__row" [shown]="!presentations.loadingItems && presentations.items.list.length === 0 && !search.query">
            <td colspan="3" class="text-center">Add a presentation to start communicating better with your audience!</td>
          </tr>
          <!-- If no search results -->
          <tr class="table-body__row" [shown]="presentations.items.list.length === 0 && search.query">
            <td colspan="3" class="text-center">Your search didn't return any results.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button id="selectPresentations" type="submit" class="btn btn-primary btn-toolbar" (click)="add()" [disabled]="!presentations?.getSelected().length">Add Selected</button>
  </div>
</div>
