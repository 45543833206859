import { Directive, ElementRef } from '@angular/core';

import { ArtboardService } from '../services/artboard.service';

@Directive({
  selector: '[workspace-element]'
})
export class WorkspaceElementDirective {

  constructor(elementRef: ElementRef,
    artboardService: ArtboardService) {
    // Used to determine new placeholder placement based on 
    // workspace size & scroll location
    artboardService.init();
    artboardService.getWorkspaceElement = () => {
      return elementRef.nativeElement;
    };
  }

}
