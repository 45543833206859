import { Component, Input } from '@angular/core';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent {

  public ICONS_LIST = {
    icons1: {
      riseWidgetImageMulti: 'M16.41,16.77l-4.89,5.6L8.13,18.86l0-.17-5.6,6.52H22ZM5.45,13a1.71,1.71,0,1,1-1.71,1.71A1.71,1.71,0,0,1,5.45,13ZM12.6,28.39H26.1L26.15,8.49H12.56v2H24.14l0,15.89H12.56ZM3.51,5.6V7.82h1.8V7.11H27.22V23.5H26.42V25H29V5.6H3.51Zm3-2.72V5.1H8.29V4.39H30.24V20.78H29.45v1.51H32V2.89H6.54Zm7,23.5H2L2,10.49H13.58v-2H0L0.05,28.39h13.5Z',
      riseWidgetFolder: 'M28.61,8.77H13.92L11,6.16a0.85,0.85,0,0,0-.57-0.22H3.67a0.88,0.88,0,0,0-1,.71V24.22a1.11,1.11,0,0,0,1,1H28.61a0.88,0.88,0,0,0,.71-1V9.48A0.63,0.63,0,0,0,28.61,8.77Z'
    }
  };

  @Input() p: string;

}

angular.module('risevision.common.components')
  .directive(
    'svgIcon', 
    downgradeComponent({
      component: SvgIconComponent
    }) as angular.IDirectiveFactory
  );
