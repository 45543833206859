import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ResourceLoaderService {

  imageBlobLoader(imageUrl: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', imageUrl, true);
      xhr.responseType = 'arraybuffer';
      xhr.timeout = 10000;

      xhr.onload = (e) => {
        const arrayBufferView = new Uint8Array(xhr.response);
        const blob = new Blob([arrayBufferView], {
          type: 'image/jpeg'
        });
        const url = URL.createObjectURL(blob);
        const status = xhr.status;
        const resp = {
          status: status,
          imageUrl: status === 200 ? url : null,
          lastModified: status === 200 ? new Date(xhr.getResponseHeader(
            'last-modified')) : null
        };

        resolve(resp);
      };

      xhr.onerror = xhr.ontimeout = (err) => {
        reject({
          status: xhr.status,
          err: err
        });
      };

      xhr.send();

    });
  }
}
