import { Injectable } from '@angular/core';
import * as angular from 'angular';
import * as _ from 'lodash';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WidgetUtilsService {

  static readonly WIDGETS_INFO = {
    TEXT: {
      render: true,
      ids: {
        PROD: '32d460d1-a727-4765-a8e9-587f7915ab05',
        TEST: '64cc543c-c2c6-49ab-a4e9-40ceba48a253',
        PROD_OLD: 'ba0da120-7c67-437f-9caf-73585bd30c74'
      },
      svgIcon: 'riseWidgetText'
    },
    IMAGE: {
      render: true,
      ids: {
        PROD: '5233a598-35ce-41a4-805c-fd2147f144a3',
        TEST: '2707fc05-5051-4d7b-bcde-01fafd6eaa5e'
      },
      svgIcon: 'riseWidgetImage'
    },
    VIDEO: {
      render: false,
      ids: {
        PROD: 'a7261343-1b0b-4150-a051-25d6e1b45136',
        TEST: '4bf6fb3d-1ead-4bfb-b66f-ae1fcfa3c0c6',
        PROD_OLD: '2d407395-d1ae-452c-bc27-78124a47132b'
      },
      svgIcon: 'riseWidgetVideo',
      iconClass: 'ph-video-item'
    },
    TIME_AND_DATE: {
      render: true,
      ids: {
        PROD: '8b984369-f83c-4eca-add6-e431d338eaff',
        TEST: '23e390be-8abb-4569-9084-e89722038895'
      }
    },
    RSS_WIDGET: {
      render: true,
      ids: {
        PROD: '82e64a53-c863-4c69-b8a2-add30580ff53',
        TEST: 'b656647d-757e-448d-ab3d-b819b4244dcf'
      }
    },
    WEB_PAGE: {
      render: true,
      ids: {
        PROD: 'df887785-3614-4f05-86c7-fce07b8745dc',
        TEST: 'aab933d7-ec65-499d-8c6f-e0081b8ea2ee'
      }
    },
    HTML_WIDGET: {
      render: true,
      ids: {
        PROD: 'c187c76a-c85b-4bd9-91fa-6f2748c8189c',
        TEST: '87284652-711a-4621-8220-22942114a5a1'
      }
    },
    SPREADSHEET_WIDGET: {
      render: true,
      ids: {
        PROD: '3132a345-9246-49df-854f-16455b833abf',
        TEST: 'b172714a-d753-475e-bb38-281f2aff594c'
      }
    },
    CALENDAR_WIDGET: {
      render: true,
      ids: {
        PROD: 'e2223213-cdaa-44be-b9d3-7a01211f63f7',
        TEST: '570012a1-54cc-4926-acb6-f9873588eddf'
      }
    },
    TWITTER: {
      render: false,
      inAppSettings: {
        type: 'twitterWidget'
      },
      ids: {
        PROD: '67e511ae-62b5-4a44-9551-077f63596079',
        TEST: '83850b51-9040-445d-aa3b-d25946a725c5'
      }
    },
    PRESENTATION: {
      svgIcon: 'iconPresentation',
      iconClass: 'ph-embedded-item',
      ids: {
        PROD: 'presentation'
      }
    }
  };

  static readonly PROFESSIONAL_WIDGETS = [
    {
      name: 'Embedded Presentation',
      imageUrl: 'https://s3.amazonaws.com/Store-Products/Rise-Vision/embedded-presentations-640x480.jpg',
      imageAlt: 'add embedded presentation',
      gadgetType: 'Presentation',
      id: 'presentation',
      productCode: 'd3a418f1a3acaed42cf452fefb1eaed198a1c620'
    },
    {
      env: 'TEST',
      name: 'Google Spreadsheet',
      imageUrl: 'https://s3.amazonaws.com/Store-Products/Rise-Vision/widget_spreadsheet_image.png',
      imageAlt: 'add google spreadsheet',
      gadgetType: 'Widget',
      id: 'b172714a-d753-475e-bb38-281f2aff594c',
      url: 'https://widgets.risevision.com/widget-google-spreadsheet/2.0.0/dist/widget.html'
    },
    {
      env: 'PROD',
      name: 'Google Spreadsheet',
      imageUrl: 'https://s3.amazonaws.com/Store-Products/Rise-Vision/widget_spreadsheet_image.png',
      imageAlt: 'add google spreadsheet',
      gadgetType: 'Widget',
      // productCode: "5dfc252d24ec3131fa5d5f2f1a70384541c5f8c9",
      id: '3132a345-9246-49df-854f-16455b833abf',
      url: 'https://widgets.risevision.com/widget-google-spreadsheet/2.0.0/dist/widget.html'
    },
    {
      env: 'TEST',
      name: 'Google Calendar',
      imageUrl: 'https://s3.amazonaws.com/Store-Products/Rise-Vision/widget_calender_image.png',
      imageAlt: 'add google calendar',
      gadgetType: 'Widget',
      id: '570012a1-54cc-4926-acb6-f9873588eddf',
      url: 'https://widgets.risevision.com/widget-google-calendar/0.1.0/dist/widget.html'
    },
    {
      env: 'PROD',
      name: 'Google Calendar',
      imageUrl: 'https://s3.amazonaws.com/Store-Products/Rise-Vision/widget_calender_image.png',
      imageAlt: 'add google calendar',
      gadgetType: 'Widget',
      // productCode: "91387ea62986b4b096bfc8360ef974bb27f0dccc",
      id: 'e2223213-cdaa-44be-b9d3-7a01211f63f7',
      url: 'https://widgets.risevision.com/widget-google-calendar/0.1.0/dist/widget.html'
    },
    {
      env: 'TEST',
      name: 'Web Page',
      imageUrl: 'https://s3.amazonaws.com/Store-Products/Rise-Vision/widget_webpage_image.png',
      imageAlt: 'add web page',
      gadgetType: 'Widget',
      id: '5e9499c8-c877-4791-95b9-9ae4835030e4',
      url: 'https://widgets.risevision.com/widget-web-page/1.0.0/dist/widget.html'
    },
    {
      env: 'PROD',
      name: 'Web Page',
      imageUrl: 'https://s3.amazonaws.com/Store-Products/Rise-Vision/widget_webpage_image.png',
      imageAlt: 'add web page',
      gadgetType: 'Widget',
      // productCode: "ba0f3870703a59f306b7bd7277fbca3efaea5f50",
      id: 'df887785-3614-4f05-86c7-fce07b8745dc',
      url: 'https://widgets.risevision.com/widget-web-page/1.0.0/dist/widget.html'
    },
    {
      env: 'TEST',
      name: "Time and Date Widget",
      imageUrl: "https://s3.amazonaws.com/Store-Products/Rise-Vision/time-and-date-widget/widget_time_and_date.png",
      imageAlt: 'add time and date',
      gadgetType: 'Widget',
      productCode: "93874075099798077b420df578960fd4198808ae"
    },
    {
      env: 'TEST',
      name: "Text Widget",
      imageUrl: "https://s3.amazonaws.com/Store-Products/Rise-Vision/widget_text_image.png",
      imageAlt: 'add text',
      gadgetType: 'Widget',
      productCode: "a0ddac10ab2a87a08c88e7c1dfd999200129511a"
    },
    {
      env: 'TEST',
      name: "HTML Widget",
      imageUrl: "https://s3.amazonaws.com/Store-Products/Rise-Vision/widget_html_image.png",
      imageAlt: 'add html',
      gadgetType: 'Widget',
      productCode: "99595a01ea7ade1bf0fd556e150673d7523f12f8"
    },
    {
      env: 'TEST',
      name: "RSS Widget",
      imageUrl: "https://s3.amazonaws.com/Store-Products/Rise-Vision/rss-widget/widget_rss_image.png",
      imageAlt: 'add rss',
      gadgetType: 'Widget',
      productCode: "6a8045bd4d8eac84d7ecfefc98b1a1a3bdc59185"
    },
    {
      env: 'TEST',
      name: "Video Widget",
      imageUrl: "https://s3.amazonaws.com/Store-Products/Rise-Vision/video-widget/widget_video-product.png",
      imageAlt: 'add video',
      gadgetType: 'Widget',
      productCode: "6e96ddf4d644c3e6f5d283d377da8ec44613c997"
    },
    {
      env: 'TEST',
      name: "Image Widget",
      imageUrl: "https://s3.amazonaws.com/Store-Products/Rise-Vision/image-widget/widget_image-product.jpg",
      imageAlt: 'add image',
      gadgetType: 'Widget',
      productCode: "bd9b195f4b35c9e5ced65a7e5c47c1a26e8c3bd5"
    },
    {
      env: 'PROD',
      name: "Time and Date Widget",
      imageUrl: "https://s3.amazonaws.com/Store-Products/Rise-Vision/time-and-date-widget/widget_time_and_date.png",
      imageAlt: 'add time and date',
      gadgetType: 'Widget',
      productCode: "93874075099798077b420df578960fd4198808ae"
    },
    {
      env: 'PROD',
      name: "Text Widget",
      imageUrl: "https://s3.amazonaws.com/Store-Products/Rise-Vision/widget_text_image.png",
      imageAlt: 'add text',
      gadgetType: 'Widget',
      productCode: "a0ddac10ab2a87a08c88e7c1dfd999200129511a"
    },
    {
      env: 'PROD',
      name: "HTML Widget",
      imageUrl: "https://s3.amazonaws.com/Store-Products/Rise-Vision/widget_html_image.png",
      imageAlt: 'add html',
      gadgetType: 'Widget',
      productCode: "99595a01ea7ade1bf0fd556e150673d7523f12f8"
    },
    {
      env: 'PROD',
      name: "RSS Widget",
      imageUrl: "https://s3.amazonaws.com/Store-Products/Rise-Vision/rss-widget/widget_rss_image.png",
      imageAlt: 'add rss',
      gadgetType: 'Widget',
      productCode: "6a8045bd4d8eac84d7ecfefc98b1a1a3bdc59185"
    },
    {
      env: 'PROD',
      name: "Video Widget",
      imageUrl: "https://s3.amazonaws.com/Store-Products/Rise-Vision/video-widget/widget_video-product.png",
      imageAlt: 'add video',
      gadgetType: 'Widget',
      productCode: "6e96ddf4d644c3e6f5d283d377da8ec44613c997"
    },
    {
      env: 'PROD',
      name: "Image Widget",
      imageUrl: "https://s3.amazonaws.com/Store-Products/Rise-Vision/image-widget/widget_image-product.jpg",
      imageAlt: 'add image',
      gadgetType: 'Widget',
      productCode: "bd9b195f4b35c9e5ced65a7e5c47c1a26e8c3bd5"
    }
  ];

  RENDER_WIDGETS = [];
  IN_APP_SETTINGS = {};
  SVG_ICONS = {};
  ICON_CLASSES = {};

  constructor() {
    this._init();
  }

  private _init() {
    let i, j;
    const widgetsInfo = WidgetUtilsService.WIDGETS_INFO;

    for (i in widgetsInfo) {
      const widget = widgetsInfo[i];
      if (widget.render) {
        for (j in widget.ids) {
          this.RENDER_WIDGETS.push(widget.ids[j]);
        }
      }

      if (widget.inAppSettings) {
        for (j in widget.ids) {
          this.IN_APP_SETTINGS[widget.ids[j]] = widget.inAppSettings;
        }
      }

      if (widget.svgIcon) {
        for (j in widget.ids) {
          this.SVG_ICONS[widget.ids[j]] = widget.svgIcon;
        }
      }

      if (widget.iconClass) {
        for (j in widget.ids) {
          this.ICON_CLASSES[widget.ids[j]] = widget.iconClass;
        }
      }
    }
  }

  private _getItemId (item) {
    if (item && item.type === 'presentation') {
      return item.type;
    } else if (item) {
      return item.objectReference;
    } else {
      return '';
    }
  }

  isRenderingAllowed (widgetId) {
    for (var i = 0; i < this.RENDER_WIDGETS.length; i++) {
      if (this.RENDER_WIDGETS[i] === widgetId) {
        return true;
      }
    }
    return false;
  }

  getInAppSettings (widgetId) {
    if (widgetId && this.IN_APP_SETTINGS[widgetId]) {
      return this.IN_APP_SETTINGS[widgetId];
    }
    return null;
  }

  isWebpageWidget (widgetId) {
    for (var j in WidgetUtilsService.WIDGETS_INFO.WEB_PAGE.ids) {
      if (WidgetUtilsService.WIDGETS_INFO.WEB_PAGE.ids[j] === widgetId) {
        return true;
      }
    }
    return false;
  }

  getIconClass (item?) {
    var widgetId = this._getItemId(item);
    if (this.ICON_CLASSES[widgetId]) {
      return 'ph-item-icon ' + this.ICON_CLASSES[widgetId];
    } else {
      return 'ph-item-icon';
    }
  }

  getSvgIcon (item?) {
    var widgetId = this._getItemId(item);
    if (this.SVG_ICONS[widgetId]) {
      return this.SVG_ICONS[widgetId];
    } else {
      return 'riseWidgetMore';
    }
  }

  private _getEnv () {
    return environment.production ? 'PROD' : 'TEST';
  }

  getWidgetId (type?) {
    if (!type) {
      return null;
    }
    if (WidgetUtilsService.WIDGETS_INFO[type.toUpperCase()]) {
      return WidgetUtilsService.WIDGETS_INFO[type.toUpperCase()].ids[this._getEnv()];
    }
    return null;
  }

  getFileName (file?) {
    if (!file) {
      return '';
    }

    var index = file.lastIndexOf('/');
    if (index === file.length - 1) {
      return this.getFileName(file.substr(0, file.length - 1));
    } else if (index === -1) {
      return file;
    } else {
      return file.substr(index + 1, file.length);
    }
  }

  getProfessionalWidgets () {
    const env = this._getEnv();
    return _.filter(WidgetUtilsService.PROFESSIONAL_WIDGETS, function (item) {
      return !item.env || item.env === env;
    });
  }

  rewriteS3Urls (url) {
    var search = [
      new RegExp('https?://s3.amazonaws.com/widget-', 'g'),
      new RegExp('https?://data-feed.digichief.com/risevision/weather/WeatherWidget.html', 'gi'),
      new RegExp('https?://account.testinseconds.com/WeatherWidget/widget.html', 'gi'),
      new RegExp('https://account.testinseconds.com/TextMarquee/widget.html', 'gi'),
      new RegExp('https://account.testinseconds.com/TrafficMapWidget/widget.html', 'gi'),
      new RegExp('http://www.scottsdigitalsignage.com/widget/youtube-widget/demo/index.html', 'gi'),
      new RegExp('https://data-feed.digichief.com/risevision/NewsRadar/NewsRadarWidget.html', 'gi'),
      new RegExp('http://data-feed.digichief.com/risevision/News/NewsWidget.html', 'gi'),
      new RegExp('https://rep.smartplayds.com/plugin/facebook-widget/widget.html', 'gi'),
      new RegExp('https://account.testinseconds.com/CountUpWidget/widget.html', 'gi'),
      new RegExp('https://account.testinseconds.com/CountdownWidget/widget.html', 'gi'),
      new RegExp('http://data-feed.digichief.com/risevision/Sports/SportsWidget.html', 'gi'),
      new RegExp('http://scottsdigitalsignage.com/widget/vimeo-widget/demo/index.html', 'gi')
    ];

    var replace = [
      'https://widgets.risevision.com/widget-',
      'https://widgets.risevision.com/widget-digichief-weather/WeatherWidget.html',
      'https://widgets.risevision.com/widget-computer-aid-weather/widget.html',
      'https://widgets.risevision.com/widget-computer-aid-marquee/widget.html',
      'https://widgets.risevision.com/widget-computer-aid-traffic/widget.html',
      'https://widgets.risevision.com/widget-youtube/demo/index.html',
      'https://widgets.risevision.com/widget-newsradar/NewsRadarWidget.html',
      'https://widgets.risevision.com/widget-news/NewsWidget.html',
      'https://widgets.risevision.com/widget-facebook/widget.html',
      'https://widgets.risevision.com/widget-computer-aid-count-up/widget.html',
      'https://widgets.risevision.com/widget-computer-aid-count-down/widget.html',
      'https://widgets.risevision.com/widget-sports/SportsWidget.html',
      'https://widgets.risevision.com/widget-vimeo/demo/index.html'
    ];

    if (url) {
      angular.forEach(search, function (regex, i) {
        url = url.replace(regex, replace[i]);
      });
    }

    return url;
  }
}

angular.module('risevision.editor.services')
  .factory('widgetUtils', downgradeInjectable(WidgetUtilsService));
