import { Injectable, NgZone } from '@angular/core';

import * as _ from 'lodash';

import { ProcessErrorCode } from 'src/app/ajs-upgraded-providers';

import { ModalService } from 'src/app/components/modals/modal.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { CardDescriptionPipe } from 'src/app/purchase/pipes/card-description.pipe';
import { BillingApiService } from 'src/app/api/services/billing-api.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  items: any[] = [{}];

  loading;
  apiError;

  constructor(private ngZone: NgZone,
    private modalService: ModalService,
    private billingApiService: BillingApiService,
    private currentPlanService: CurrentPlanService,
    private processErrorCode: ProcessErrorCode,
    private cardDescriptionPipe: CardDescriptionPipe) { }

  _clearMessages() {
    this.loading = false;

    this.apiError = '';
  }

  getItemSubscription(index: number = 0) {
    return this.items[index].subscription || {};
  }

  getItemSubscriptionByPlanId(planId: string) {
    return this.items.find((item) => { item.subscription.plan_id === planId; });
  }

  getItemCustomer(index: number = 0) {
    return this.items[index].customer || {};
  }

  isInvoiced(index: number = 0): boolean {
    if (this.getItemSubscription(index).auto_collection) {
      return this.getItemSubscription(index).auto_collection === 'off';
    } else if (this.getItemCustomer(index).auto_collection) {
      return this.getItemCustomer(index).auto_collection === 'off';
    } else {
      return false;
    }
  }

  getPaymentSourceId(index: number = 0) {
    if (this.getItemSubscription(index).payment_source_id) {
      return this.getItemSubscription(index).payment_source_id;
    } else if (this.getItemCustomer(index).primary_payment_source_id) {
      return this.getItemCustomer(index).primary_payment_source_id;
    } else {
      return null;
    }
  }

  _updatePaymentSourceId(index: number = 0) {
    if (this.isInvoiced(index)) {
      this.items[index || 0].paymentSourceId = 'invoice';
    } else {
      this.items[index || 0].paymentSourceId = this.getPaymentSourceId();
    }
  }

  getSubscriptions(subscriptionIds: string[], loadChanges?: boolean) {
    this._clearMessages();

    this.items = [{}];
    this.loading = true;

    return Promise.all(subscriptionIds.map((subscriptionId, index) => {
      if (!subscriptionId) {
        subscriptionId = this.currentPlanService.currentPlan.subscriptionId;
      }

      return this.billingApiService.getSubscription(subscriptionId)
        .then((resp) => {
          this.items[index] = resp.item;

          this._updatePaymentSourceId(index);

          if (loadChanges && resp.item?.subscription?.has_scheduled_changes) {
            return this.billingApiService.getSubscription(subscriptionId, true)
              .then((withChanges) => {
                if (withChanges.item.subscription.plan_id !== this.items[index].subscription.plan_id) {
                  this.items[index].subscription.changed_plan_id = withChanges.item.subscription.plan_id;
                }
                this.items[index].subscription.changed_plan_quantity = this.items[index].subscription.plan_quantity - withChanges.item.subscription.plan_quantity;
              });
          }
        })
        .catch((e) => {
          this._showErrorMessage(e);
        });
    })).finally(() => {
      this.loading = false;

      this.ngZone.run(() => {});
    });
  }

  getSubscription(subscriptionId: string = '', loadChanges?) {
    return this.getSubscriptions([subscriptionId], loadChanges);
  }

  changePoNumber(index: number = 0) {
    this.loading = true;

    return this.billingApiService.changePoNumber(this.getItemSubscription(index).id, this.getItemSubscription(index).poNumber)
      .then((resp) => {
         _.extend(this.items[index || 0], resp.item);
      })
      .catch((e) => {
        this._showErrorMessage(e);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  _changePaymentSource(subscriptionId, paymentSourceId) {
    this.loading = true;

    return this.billingApiService.changePaymentSource(subscriptionId, paymentSourceId)
      .then((resp) => {
        _.extend(this.items[0], resp.item);

        this._updatePaymentSourceId();
      })
      .catch((e) => {
        this._showErrorMessage(e);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changePaymentMethod(event, card) {
    // prevent the radio ng-model from being updated
    event.preventDefault();

    if (!this.isInvoiced() && this.getPaymentSourceId() === card.payment_source.id) {
      return;
    }

    var description = this.cardDescriptionPipe.transform(card.payment_source.card);

    this.modalService.confirm('Change Payment Method',
      'Are you sure you want to change the payment method? The <strong>' +
      description +
      '</strong> will be used for this subscription.',
      'Yes, Change', 'Cancel'
    ).then(() => {
      this._changePaymentSource(this.getItemSubscription().id, card.payment_source.id);
    });
  }

  _showErrorMessage(e) {
    this.apiError = this.processErrorCode(e);

    console.error(this.apiError, e);
  }

}
