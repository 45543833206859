import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserStateService } from "src/app/auth/services/user-state.service";
import { ApiUtilsService } from "../services/api-utils.service";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private userStateService: UserStateService,
    private apiUtilsService: ApiUtilsService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.apiUtilsService.isAuthorizedEndpoint(req.url)) {
      return next.handle(req.clone({
        headers: req.headers.append(
          'Authorization', 'Bearer ' + this.userStateService.getAccessToken().access_token
        )
      }));
    }
    return next.handle(req);
  }
}
