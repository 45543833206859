import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { GadgetsApi } from 'src/app/ajs-upgraded-providers';
import { PromiseUtilsService } from 'src/app/shared/services/promise-utils.service';
import { WidgetSettingsService } from '../../services/widget-settings.service';

@Component({
  selector: 'widget-settings',
  templateUrl: './widget-settings.component.html',
  styleUrls: ['./widget-settings.component.scss']
})
export class WidgetSettingsComponent {
  @ViewChild('widgetSettingsModal', { static: false }) widgetSettingsModal?: ModalDirective;

  widgetUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
  additionalParams;
  loading;
  deferred;

  constructor(private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private promiseUtils: PromiseUtilsService,
    private gadgetsApi: GadgetsApi,
    widgetSettingsService: WidgetSettingsService) {
    widgetSettingsService.widgetSettingsModal = this.showModal.bind(this);
  }

  showModal(widget): any {
    this.deferred = this.promiseUtils.generateDeferredPromise();
    this.widgetUrl = this.sanitizer.bypassSecurityTrustResourceUrl(widget.url);
    this.additionalParams = widget.additionalParams;

    this.init();
    this.showChildModal();

    this.changeDetectorRef.detectChanges();

    return this.deferred.promise;
  }

  showChildModal(): void {
    this.widgetSettingsModal?.show();
  }

  hideChildModal(): void {
    this.widgetSettingsModal?.hide();

    this.widgetUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
  }

  _registerRpc() {
    if (this.gadgetsApi) {
      setTimeout(() => {
        this.gadgetsApi.rpc.register('rscmd_saveSettings',
          this._saveSettings.bind(this));
        this.gadgetsApi.rpc.register('rscmd_closeSettings',
          this._closeSettings.bind(this));
        this.gadgetsApi.rpc.register('rscmd_getAdditionalParams',
          this._getAdditionalParams.bind(this));

        this.gadgetsApi.rpc.setupReceiver('widget-modal-frame');
      });
    }
  }

  _stopLoader() {
    this.loading = false;
  }

  init() {
    this.loading = true;

    setTimeout(this._stopLoader.bind(this), 3000);

    this._registerRpc();
  }

  _getAdditionalParams() {
    this._stopLoader();

    return this.additionalParams;
  }

  _saveSettings(data) {
    this.hideChildModal();

    this.deferred.resolve(data);
  }

  _closeSettings() {
    this.hideChildModal();

    this.deferred.reject('cancel');
  }

}
