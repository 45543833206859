import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, DestroyRef, forwardRef, inject, Injector, NgZone, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl, Validators } from '@angular/forms';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatChipInputEvent } from '@angular/material/chips';
import { filter, map, Observable, of, tap } from 'rxjs';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'emails-field',
  templateUrl: './emails-field.component.html',
  styleUrls: ['./emails-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailsFieldComponent),
      multi: true,
    }
  ]
})
export class EmailsFieldComponent implements
  OnInit,
  AfterViewInit,
  ControlValueAccessor {
  readonly destroyRef = inject(DestroyRef);
  readonly email = new FormControl('');
  public control: NgControl;
  
  constructor(private zone: NgZone,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    this.control = this.injector.get(NgControl);
    this.control && (this.control.valueAccessor = this);
  }
  
  ngAfterViewInit(): void {
    this.control.statusChanges.subscribe(status =>{
      if (status === 'INVALID') {
        this.email.setValidators(Validators.compose([
          Validators.email
        ]));
        this.email.markAsDirty();
        this.email.markAsTouched();
      } else this.email.clearValidators();
      this.email.updateValueAndValidity({emitEvent: false});
    });
  }

  onTokenEnd(event$: MatChipInputEvent) {
    this._onAdding(event$)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => event$.chipInput.clear()),
        filter(valid => 
            valid
            && this._validate(event$.value)
            && this.control.valid),
        map(() => event$.value)
      )
      .subscribe(email => {
        this.emailsList.push(email);
        this.onChange();
      });
  }

  onRemove(index: number) {
    this.emailsList.splice(index, 1);
    this.onTagInputRemove();
  }

  onInputChange(value: string) {
    this.onTagInputTextChange(value);
  }

  readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;
  placeholderText = 'example1@email.com, example2@email.com';
  emailsList = [];

  // from ControlValueAccessor
  public onChangeFn = (_: any) => {};
 
  public onTouchedFn = () => {};
 
  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
 
  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }
 
  public writeValue(obj: any): void {
    this.control.control.setErrors(null);

    this.emailsList = obj ?? [];
  }

  public onChange() {
    this.zone.run(() => {
      this.onChangeFn(this.emailsList);
    });
  }

  // tag-input functionality
  _validEmail(value?) {
    return ValidationService.EMAIL_REGEX.test(value);
  }

  _validate(email) {
    this.control.control.setErrors(null);
    if (
      this.hasRequiredValidator()
      && !email
      && (!this.emailsList || !this.emailsList.length)
    )
      this.control.control.setErrors({'requireEmails': true});
    if (email && !this._validEmail(email)) {
      this.control.control.setErrors({'emails': true});
      return false;
    } else if (this.emailsList && this.emailsList.indexOf(email) >= 0) {
      this.control.control.setErrors({'duplicateEmails': true});
    }
    return true;
  }

  onTagInputTextChange($event) {
    this._validate($event);
  }

  onTagInputRemove() {
    this.onChange();

    if (this.emailsList.length === 0) {
      this.control.control.setErrors({'requireEmails': true});
    }
  }

  _onAdding<T extends string | {value: string}>(email: T): Observable<boolean> {
    return of(email)
      .pipe(map(() => {
        if (typeof email === 'object') {
          return this._validEmail(email.value);
        }

        return this._validEmail(email);
      }));
  }

  hasRequiredValidator(): boolean {
    if (this.control && this.control.control) {
      const validators = this.control.control.validator ? [this.control.control.validator] : [];
      return validators.some(validator =>
        validator({} as AbstractControl)?.required
      );
    }
    return false;
  }

  public onTagInputAdding = this._onAdding.bind(this);
}
