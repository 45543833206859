import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'unlock-feature',
  templateUrl: './unlock-feature.component.html',
  styleUrls: ['./unlock-feature.component.scss']
})
export class UnlockFeatureComponent extends ConfirmModalComponent {

  constructor(public modalRef: BsModalRef) {
    super(modalRef);
  }

}
