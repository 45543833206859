import { Component, Input, Output, EventEmitter } from '@angular/core';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'list-grid-toggle',
  templateUrl: './list-grid-toggle.component.html',
  styleUrls: ['./list-grid-toggle.component.scss']
})
export class ListGridToggleComponent {

  @Input() isListView: boolean;
  @Output() isListViewChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleListView () {
    this.isListViewChange.emit(!this.isListView);
  }
}

angular.module('risevision.storage.directives')
  .directive(
    'listGridToggle',
    downgradeComponent({
      component: ListGridToggleComponent
    }) as angular.IDirectiveFactory
  );
