import { Component, Input } from '@angular/core';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

  @Input() isMultipleFilesFoldersSelector: boolean;

}

angular.module('risevision.storage.directives')
  .directive(
    'emptyState',
    downgradeComponent({
      component: EmptyStateComponent
    }) as angular.IDirectiveFactory
  );