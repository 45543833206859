import { Component, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';

import { environment } from 'src/environments/environment';

import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';
import { TemplateEditorUtilsService } from '../../services/template-editor-utils.service';
import { MediaSelectorService } from '../services/media-selector.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { FileExistenceCheckService } from '../services/file-existence-check.service';

import { GooglePhotosComponent } from '../google-photos/google-photos.component';
import { DocumentService } from 'src/app/storage/services/document.service';
import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';
import { BasePlaylistService } from '../services/base-playlist.service';
import { StorageCacheBusterService } from '../../services/storage-cache-buster.service';

@Component({
  selector: 'template-component-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss']
})
export class FolderComponent {

  componentId: string;
  folderName = '';
  folderPath = '';
  fileType = '';
  values = {
    duration: 0,
    transition: null,
    source: '',
    type: '',
    volume: 0,
    fileMissing: false,
    assetFiles: null
  };
  processing = false;
  conversionError = '';
  isPlaylistItem: boolean = false;

  get typeName(): string {
    switch (this.values.type) {
      case MediaSelectorService.FOLDER_TYPE.STORAGE_FILE:
        return 'File from Storage';
      case MediaSelectorService.FOLDER_TYPE.STORAGE_FOLDER:
        return 'Folder from Storage';
      case BasePlaylistService.FOLDER_TYPE.GOOGLE_PHOTOS_ALBUM:
        return 'Google Photos';
      default:
        return 'Folder';
    }
  }

  get isDocument(): boolean {
    return this.fileType === StorageManagerService.FILE_TYPE.DOCUMENT;
  }

  get isImage(): boolean {
    return this.fileType === StorageManagerService.FILE_TYPE.IMAGE;
  }

  get isVideo(): boolean {
    return this.fileType === StorageManagerService.FILE_TYPE.VIDEO;
  }

  get isGooglePhotosAlbum(): boolean {
    return this.values.type === BasePlaylistService.FOLDER_TYPE.GOOGLE_PHOTOS_ALBUM;
  }

  @ViewChild(GooglePhotosComponent)
  googlePhotosComponent: GooglePhotosComponent;

  constructor(
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private componentsService: ComponentsService,
    private attributeDataService: AttributeDataService,
    private templateEditorUtilsService: TemplateEditorUtilsService,
    public storageManagerService: StorageManagerService,
    public mediaSelectorService: MediaSelectorService,
    private fileExistenceCheckService: FileExistenceCheckService,
    private documentService: DocumentService,
    private storageUtilsService: StorageUtilsService,
    private storageCacheBusterService: StorageCacheBusterService
  ) {

    this.componentsService.registerDirective({
      type: 'rise-folder',
      element: this.elementRef.nativeElement,
      show: (options?) => {
        this.componentId = this.componentsService.selected.id;

        this.isPlaylistItem = options?.playlist ? options.playlist : false;

        this._init();
      },
      getName: (componentId: string): string => {
        const type = this.attributeDataService.getAvailableAttributeData(componentId, 'type');

        if (type === BasePlaylistService.FOLDER_TYPE.GOOGLE_PHOTOS_ALBUM) {
          return this.attributeDataService.getAvailableAttributeData(componentId, 'name') || ComponentsService.GooglePhotosItem.title;
        }

        return this.storageUtilsService.parseFolderName(
          this.attributeDataService.getAvailableAttributeData(componentId, 'source')
        );
      },
      getIcon: (componentId: string): string => {
        const type = this.attributeDataService.getAvailableAttributeData(componentId, 'type');

        if (type === MediaSelectorService.FOLDER_TYPE.STORAGE_FILE) {
          const source = this.attributeDataService.getAvailableAttributeData(componentId, 'source');

          return this.storageManagerService.isCanva(source) ?
            'canva'
          : this.storageManagerService.getFileType(source);
        }
        if (type === BasePlaylistService.FOLDER_TYPE.GOOGLE_PHOTOS_ALBUM) {
          return ComponentsService.GooglePhotosItem.icon;
        }
      },
      getLabel: (componentId: string): string => {
        if (this.fileType === StorageManagerService.FILE_TYPE.DOCUMENT) {
          return 'Document Player';
        }

        if (this.isImage) {
          return 'Image';
        }

        if (this.isVideo) {
          return 'Video';
        }

        const type = this.attributeDataService.getAvailableAttributeData(componentId, 'type');

        if (type === BasePlaylistService.FOLDER_TYPE.GOOGLE_PHOTOS_ALBUM) {
          return ComponentsService.GooglePhotosItem.title;
        }
      },
      onPresentationOpen: () => {
        this.storageCacheBusterService.loadStorageFiles();
      }
    });
  }

  private _init () {
    const original = this.values.source;
    this.values.duration = this.attributeDataService.getAvailableAttributeData(this.componentId, 'duration') || 10;
    this.values.transition = this.attributeDataService.getAvailableAttributeData(this.componentId, 'transition-type') || '';
    this.values.source = this.attributeDataService.getAvailableAttributeData(this.componentId, 'source') || '';
    this.values.type = this.attributeDataService.getAvailableAttributeData(this.componentId, 'type');
    const volume = this.attributeDataService.getAvailableAttributeData(this.componentId, 'volume') || 0;
    this.values.volume = this.templateEditorUtilsService.intValueFor(volume, 0);
    this.values.fileMissing = false;
    this.checkFileExists(this.values.source);

    this.folderName = this.storageUtilsService.parseFolderName(this.values.source);
    this.folderPath = this.storageUtilsService.parseStoragePath(this.values.source);
    this.fileType = this.storageManagerService.getFileType(this.values.source);

    this.googlePhotosComponent.init(this.componentId);

    if (this.values.source !== original) {
      this.values.assetFiles = null;
    }
    this.convertFile();
  }

  private convertFile() {
    this.conversionError = '';

    if (this.isDocument && !this.values.assetFiles) {
      let path = this.storageUtilsService.parseFilePath(this.values.source);

      if (!path) {
        return;
      }

      this.processing = true;
      this.documentService.convertFile(path[2], path[1]).then((count) => {
        this.values.assetFiles = count;

      }).catch((error) => {
        console.error(error);
        this.conversionError = error.message;

      }).finally(() => {
        this.processing = false;
      });
    }
  }

  private checkFileExists(path: string) {
    if (this.values.type === 'storage-file') {
      this.fileExistenceCheckService.checkFileExists(path).then((exists) => {
        this.values.fileMissing = !exists;
      });
    } else if (this.values.type === 'storage-folder') {
      // ensure the file type 'image,video,folder' is used in loadFiles function
      this.storageManagerService.loadFiles(path.substring(path.indexOf('/') + 1), StorageManagerService.FILE_TYPE.IMAGE_VIDEO_FOLDER).then((files) => {
        this.values.fileMissing = files.length === 0;
      });
    }
  }

  onChange() {
    this.changeDetectorRef.detectChanges();
  }

  saveDuration() {
    this.attributeDataService.setAttributeData(this.componentId, 'duration', this.values.duration);
  }

  saveTransition() {
    this.attributeDataService.setAttributeData(this.componentId, 'transition-type', this.values.transition);
  }

  saveVolume() {
    this.attributeDataService.setAttributeData(this.componentId, 'volume', this.values.volume);
  }

  updateSource(value: string, type: string) {
    this.values.source = value;
    this.values.type = type;
    this.values.fileMissing = false;
    this.checkFileExists(this.values.source);

    if (type !== BasePlaylistService.FOLDER_TYPE.GOOGLE_PHOTOS_ALBUM) {
      this.folderName = this.storageUtilsService.parseFolderName(value);
      this.folderPath = this.storageUtilsService.parseStoragePath(value);
    }

    this.fileType = this.storageManagerService.getFileType(this.values.source);

    this.values.assetFiles = null;
    this.convertFile();

    this.attributeDataService.setAttributeData(this.componentId, 'source', value);
    this.attributeDataService.setAttributeData(this.componentId, 'type', type);
    this.attributeDataService.setAttributeData(this.componentId, 'environment', environment.production ? 'prod' : 'test');

    this.componentsService.updateComponentValue(value);
  }

  selectMedia() {
    let selectorFileType;

    if (this.values.type === MediaSelectorService.FOLDER_TYPE.STORAGE_FOLDER && !this.isPlaylistItem) {
      // As a user component the file type must be "folder"
      selectorFileType = StorageManagerService.FILE_TYPE.FOLDER;
    } else {
      selectorFileType = this.isDocument || this.isVideo || this.isImage ? this.fileType : undefined;
    }

    this.mediaSelectorService.onUserComponentAdd = null;
    this.mediaSelectorService.onMediaUpdate = this.updateSource.bind(this);
    this.mediaSelectorService.editingCanva = false;
    this.componentsService.editComponent({
      type: 'rise-media-selector'
    }, {
      fileType: selectorFileType
    });
  }

  editWithCanva() {
    if (this.values.source) {
      this.mediaSelectorService.onMediaUpdate = null;
      this.mediaSelectorService.onUserComponentAdd = null;
      this.mediaSelectorService.editingCanva = true;

      this.componentsService.editComponent({
        type: 'rise-canva',
      }, {
        source: this.values.source
      });
    }
  }
}
