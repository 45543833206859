import { Component } from '@angular/core';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import { HelpWidgetFactory } from 'src/app/ajs-upgraded-providers';

@Component({
  selector: 'help-widget-button',
  templateUrl: './help-widget-button.component.html',
  styleUrls: ['./help-widget-button.component.scss']
})
export class HelpWidgetButtonComponent {

  constructor(public helpWidgetFactory: HelpWidgetFactory) { }

}

angular.module('risevision.common.components')
  .directive(
    'helpWidgetButton', 
    downgradeComponent({
      component: HelpWidgetButtonComponent
    }) as angular.IDirectiveFactory
  );
