import { Component, Input } from '@angular/core';

@Component({
  selector: 'edit-po-number',
  templateUrl: './edit-po-number.component.html',
  styleUrls: ['./edit-po-number.component.scss']
})
export class EditPoNumberComponent {

  private _item : any = {};
  @Input() set item(value: any) {
    this._item = value || {};

    this._reset();
  }

  get item(): any {
    return this._item;
  }

  @Input() updateFunction!: any;

  editPoNumber: boolean = false;

  constructor() { }

  _reset() {
    this._item.poNumber = this._item.po_number || '';
  }

  updatePoNumber() {
    this.updateFunction()
      .then(() => {
        this.editPoNumber = false;
      })
      .catch(() => {});
  }

  hideEditForm() {
    this._reset();
    this.editPoNumber = false;
  }

}