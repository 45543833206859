import { Injectable } from '@angular/core';

import { ModalComponent } from './modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalStackService {

  static modals: ModalComponent[] = [];

  constructor() { }

  static push(modal: ModalComponent) {
    this.modals.push(modal);
  }

  static remove(modal: ModalComponent) {
    const index = this.modals.indexOf(modal);

    if (index > -1) {
      this.modals.splice(index, 1);
    }
  }

  static closeAll() {
    while(this.modals.length) {
      let modal = this.modals.pop();

      modal.hide();
    }
  }

}
