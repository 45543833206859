import { Component } from '@angular/core';

import { ModalService } from 'src/app/components/modals/modal.service';
import { PlaceholderService } from '../../services/placeholder.service';
import { PlaceholdersService } from '../../services/placeholders.service';

@Component({
  selector: 'placeholders-list',
  templateUrl: './placeholders-list.component.html',
  styleUrls: ['./placeholders-list.component.scss']
})
export class PlaceholdersListComponent {

  constructor(private modalService: ModalService,
    private placeholderService: PlaceholderService,
    public placeholdersService: PlaceholdersService) { }

  manage(placeholder) {
    this.placeholderService.setPlaceholder(placeholder);
  }

  remove(placeholder) {
    this.modalService.confirmDanger('Remove Placeholder',
      'Are you sure you want to remove this Placeholder from the Presentation?',
      'Remove'
    )
    .then(() => {
      this.placeholdersService.removePlaceholder(placeholder);
    });
  }
}
