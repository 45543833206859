<div class="component-container">
  <div class="attribute-editor-component">
    <button id="addPlaceholderButton" class="btn btn-default btn-block mb-2" (click)="placeholdersService.addNewPlaceholder()">
      Add
    </button>
    <div class="editor-playlist workspace-sidebar-body">
      @for (placeholder of placeholdersService.getPlaceholders(); track placeholder) {
        <div class="editor-playlist-item attribute-row attribute-row-hover">
          <div class="attribute-desc mr-auto">
            <streamline-icon aria-hidden="true" class="streamline-component-icon" name="playlist" width="24" height="18"></streamline-icon>
            <a id="manageLink" class="madero-link" (click)="manage(placeholder)">{{placeholder.id}}</a>
          </div>
          <a href="#" id="duplicateButton" (click)="placeholdersService.duplicatePlaceholder(placeholder)">
            <streamline-icon ariaLabel="Duplicate placeholder" class="ml-3 mr-0 streamline-component-icon" name="copy" width="16" height="18"></streamline-icon>
          </a>
          <a href="#" id="removeButton" (click)="remove(placeholder)">
            <streamline-icon ariaLabel="Remove placeholder" class="ml-3 streamline-component-icon" name="trash" width="16" height="18"></streamline-icon>
          </a>
        </div>
      }
      <div [hidden]="placeholdersService.getPlaceholders().length">
        <div class="panel-body">
          <p class="text-muted text-center u_remove-bottom">You haven't added any Placeholders to your Presentation yet.</p>
        </div>
      </div>
    </div>
  </div>
</div>
