<div rvSpinner="purchase-loader" [rvShowSpinner]="purchaseService.loading || taxExemptionService.loading">
  <div class="app-header u_padding-20-vertical mb-5 border-bottom">
    <!-- App Title -->
    <h1 class="app-header-title">Subscribe</h1>
  </div>

  <plan-tier-picker [shown]="purchaseStepsService.currentStep === 0"></plan-tier-picker>
  <billing-address [shown]="purchaseStepsService.currentStep === 1"></billing-address>
  <payment-methods [shown]="purchaseStepsService.currentStep === 2"></payment-methods>
  <checkout-success [shown]="purchaseStepsService.currentStep === 3" (closeEvent)="close()"></checkout-success>

  <div class="mt-5 border-top">
    <purchase-footer></purchase-footer>
  </div>

</div>
