import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pick-time-range',
  templateUrl: './pick-time-range.component.html',
  styleUrls: ['./pick-time-range.component.scss']
})
export class PickTimeRangeComponent {

  @Input() allDay: boolean;
  @Input() startTime: Date;
  @Input() endTime: Date;
  @Input() isUTC: boolean;
  @Output() allDayChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() startTimeChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() endTimeChange: EventEmitter<Date> = new EventEmitter<Date>();

  constructor() { }

  updateAllDay(value: boolean) {
    this.allDay = value;
    this.allDayChange.emit(value);
  }

  updateStartTime() {
    this.startTimeChange.emit(this.startTime);
  }

  updateEndTime() {
    this.endTimeChange.emit(this.endTime);
  }

}
