import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { ScrollingListService } from 'src/app/ajs-upgraded-providers';
import { CompanyApiService } from 'src/app/api/services/company-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

import { ModalComponent } from 'src/app/components/modals/modal.component';

@Component({
  selector: 'select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent extends ModalComponent {

  filterConfig = {
    placeholder: 'Search Companies'
  };

  allCompanies = true;
  search = {
    companyId: this.companyStateService.getSelectedCompanyId(),
    sortBy: 'name',
    reverse: false,
    name: 'Companies',
    includeSubcompanies: this.allCompanies
  };

  selectedCompanyId = this.companyStateService.getSelectedCompanyId();

  companies = this.ScrollingListService(this.companyApiService.getCompanies.bind(this.companyApiService), this.search);

  constructor(public modalRef: BsModalRef,
    private companyStateService: CompanyStateService,
    private ScrollingListService: ScrollingListService,
    private companyApiService: CompanyApiService
  ) {
    super(modalRef);
  }

  closeModal() {
    super.resolve();
  }

  setCompany(company) {
    this.companyStateService.switchCompany(company.id);

    this.closeModal();
  }

  toggleAllCompanies(value) {
    this.allCompanies = value;

    this.search.includeSubcompanies = value;
    this.companies.doSearch();
  }

}
