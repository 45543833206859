import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'month-dropdown',
  templateUrl: './month-dropdown.component.html',
  styleUrls: ['./month-dropdown.component.scss']
})
export class MonthDropdownComponent {

  @Input() month: number;
  @Output() monthChange: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  emitValue () {
    this.monthChange.emit(this.month);
  }
}