import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ModalService } from 'src/app/components/modals/modal.service';
import { PresentationSelectorComponent } from '../components/presentation-selector/presentation-selector.component';
import { PresentationMultiSelectorComponent } from '../components/presentation-multi-selector/presentation-multi-selector.component';
import { PresentationApiService } from 'src/app/api/services/presentation-api.service';

@Injectable({
  providedIn: 'root'
})
export class PresentationsService {

  constructor(
    private presentationApiService: PresentationApiService,
    private modalService: ModalService
  ) { }

  private _presentations = {};
  loadingPresentation = false;
  apiError = '';

  private _find (presentationId) {
    return this._presentations[presentationId];
  }

  setPresentation (presentation) {
    if (presentation.id && !this._find(presentation.id)) {
      this._presentations[presentation.id] = Promise.resolve(presentation);
    }
  }

  resetPresentation (presentation) {
    if (presentation.id) {
      delete this._presentations[presentation.id];
    }
  }

  getPresentationCached (presentationId?) {
    var presentation = this._find(presentationId);

    if (presentation) {
      return presentation;
    } else {
      return this._getPresentation(presentationId);
    }
  }

  private _getPresentation (presentationId?) {
    //show loading spinner
    this.loadingPresentation = true;

    var newPromise = new Promise((resolve, reject) => {
      this.presentationApiService.get(presentationId)
        .then((result) => {
          this.setPresentation(result.item);

          resolve(result.item);
        })
        .then(null, (e) => {
          this.apiError = e.message ? e.message : e.toString();

          reject();
        })
        .finally(() => {
          this.loadingPresentation = false;
        });
    });

    this._presentations[presentationId] = newPromise;

    return newPromise;
  }

  selectPresentation () {
    PresentationSelectorComponent.PRESENTATION_SEARCH.filter = ' NOT presentationType:\"HTML Template\"';

    setTimeout(function () {
      PresentationSelectorComponent.PRESENTATION_SEARCH.filter = '';
    });

    return this.openPresentationSelector();
  }

  openPresentationSelector() {
    return this.modalService.showMediumModal(PresentationSelectorComponent);
  }

  openPresentationMultiSelector() {
    return this.modalService.showMediumModal(PresentationMultiSelectorComponent);
  }
}

angular.module('risevision.editor.services')
  .factory('presentationFactory', downgradeInjectable(PresentationsService));