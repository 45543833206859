import { Injectable } from '@angular/core';
import { GapiLoader } from 'src/app/ajs-upgraded-providers';

@Injectable({
  providedIn: 'root',
})
export class GoogleDrivePickerService {
  private _successCallback: (value: unknown) => void;
  private _pickerInitialized: boolean;

  constructor(private gapiLoader: GapiLoader) {
    this.gapiLoader().then((gapi) => {
      gapi.load('client:picker', () => {
        this._pickerInitialized = true;
      });
    });
  }

  createPicker(accessToken: string, pickerCallback: (data: any) => void) {
    const picker = new window.google.picker.PickerBuilder()
      .setOAuthToken(accessToken)
      .addView('spreadsheets')
      .setCallback(pickerCallback)
      .build();

    picker.setVisible(true);
  }

  async _openWindow(accessToken: string) {
    if (!this._pickerInitialized) {
      return;
    }

    this.createPicker(accessToken, this._pickerCallback.bind(this));
  }

  _pickerCallback(data) {

    switch (data.action) {
      case 'picked':
        const doc = data.docs[0];
        const result = {
          id: doc.id,
          name: doc.name,
        };
        this._successCallback(result);
        break;

      case 'cancel':
        this._successCallback(null);
        break;

      default:
        break;
    }
  }

  async open(accessToken: string) {
    return new Promise((resolve) => {
      this._successCallback = resolve;
      this._openWindow(accessToken);
    });
  }
}
