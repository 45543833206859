<div class="attribute-editor-component" rvSpinner="rss-editor-loader" [rvShowSpinner]="spinner">
  <div class="attribute-editor-row">
    <div class="form-group has-feedback" [ngClass]="{ 'has-error': validationResult && validationResult !== 'VALID', 'has-success': validationResult === 'VALID' && feedUrl !== '' }">
      <label class="control-label" for="te-rss-feed">Enter the link to your RSS feed:</label>
      <input type="text" id="te-rss-feed" class="form-control u_ellipsis" [(ngModel)]="feedUrl" placeholder="Enter the link to your RSS feed" [disabled]="spinner" (debounceEvent)="saveFeed()" >
      <streamline-icon class="overlay-icon form-control-feedback" aria-hidden="true" name="{{validationResult && validationResult !== 'VALID' ? 'exclamation' : 'checkmark'}}" width="14" height="12"></streamline-icon>
      <p class="help-block">
        @switch (validationResult) {
          @case ('INVALID_URL') {
            <span>Provide a URL to a valid RSS feed. If you need help email <a target="_blank" href="mailto:support@risevision.com">support&#64;risevision.com</a></span>
          }
          @case ('UNAUTHORIZED') {
            <span>RSS feeds that require authentication are not supported. If you need help email <a target="_blank" href="mailto:support@risevision.com">support&#64;risevision.com</a></span>
          }
          @case ('NON_FEED') {
            <span>Provide a URL to a valid RSS feed. If you need help email <a target="_blank" href="mailto:support@risevision.com">support&#64;risevision.com</a></span>
          }
          @case ('NOT_FOUND') {
            <span>Provide a URL to a valid RSS feed. If you need help email <a target="_blank" href="mailto:support@risevision.com">support&#64;risevision.com</a></span>
          }
          @case ('INVALID_FEED') {
            <span>The errors shown here must be corrected to show this RSS feed. If you need help email <a target="_blank" href="mailto:support@risevision.com">support&#64;risevision.com</a></span>
          }
        }
      </p>
    </div>
  </div>
  <div class="attribute-editor-row">
    <div class="form-group">
      <label class="control-label" for="te-rss-max-items">Max items in queue:</label>
      <select id="te-rss-max-items" [(ngModel)]="maxItems" class="form-control" (change)="saveMaxItems()">
        <option value="1">1</option>
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="25">25</option>
      </select>
    </div>
  </div>
</div>
