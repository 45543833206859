import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { PresentationUtilsService } from '../../services/presentation-utils.service';
import { ModalComponent } from 'src/app/components/modals/modal.component';

@Component({
  selector: 'product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent extends ModalComponent implements OnInit {

  product;
  showPreviewLink = false;

  constructor(modalRef: BsModalRef,
    private presentationUtils: PresentationUtilsService) {
    super(modalRef);
  }

  ngOnInit() {
    this.showPreviewLink = !this.presentationUtils.isHtmlTemplate(this.product);
  }

  select() {
    super.resolve(this.product);
  }

  dismiss() {
    super.reject();
  }

}
