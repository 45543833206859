<div class="flex-col form-group mb-0">
  <label class="timeline-label">Timeline:</label>
  <mat-radio-group class="flex-col" [ngModel]="timeline.always" (ngModelChange)="toggleAlways($event)">
    <div class="flex-row space-between">
      <label for="timelineAlwaysRadio">Always on</label>
      <mat-radio-button id="timelineAlwaysRadio" [value]="true" />
    </div>
    <div class="flex-row space-between">
      <label for="timelineAlwaysRadio">Custom</label>
      <mat-radio-button id="timeloneCustomRadio" [value]="false" />
    </div>
  </mat-radio-group>
</div>
<span id="timelineLabelMadero" class="madero-link u_clickable" timeline="timeline" (click)="openModal()" [shown]="!timeline.always">{{timeline.label}}</span>
