import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ComponentsService } from '../../services/components.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { TemplateEditorService } from '../../services/template-editor.service';

export type StockLibraryFile = {
  url: string;
  filename: string;
};

@Injectable({
  providedIn: 'root'
})
export class StockLibraryService {

  static readonly GCS_STORAGE_BUCKET = 'https://widgets.risevision.com/apps/pixabay/';
  static readonly PIXABAY_API_URL = 'https://pixabay.com/api/';

  loading = false;

  constructor(
    private componentsService: ComponentsService,
    private trackerService: TrackerService,
    private templateEditorService: TemplateEditorService
  ) { }

  showStockLibrary(fileType: string, addFile: (file: File) => void, isMediaSelector = false) {
    if (fileType === 'image' || fileType === 'video') {
      new Observable<File>((subscriber) => {
        this.componentsService.editComponent({
          type: 'rise-stock-library'
        }, {
          fileType,
          subscriber,
          isMediaSelector
        });
      }).subscribe({
        next: (selected) => {
          addFile(selected);
          this.trackerService.presentationEvent(
            `Stock ${fileType === 'video' ? 'Video' : 'Image'} Added`,
            this.templateEditorService.presentation.id,
            this.templateEditorService.presentation.name
          );
        }
      });
    }
  }
}
