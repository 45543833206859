import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Transition } from '@uirouter/angular';

import { ModalService } from 'src/app/components/modals/modal.service';
import { ScheduleService } from '../../services/schedule.service';

@Component({
  selector: 'schedule-details',
  templateUrl: './schedule-details.component.html',
  styleUrls: ['./schedule-details.component.scss']
})
export class ScheduleDetailsComponent {

  @ViewChild('scheduleForm')
  public scheduleForm: NgForm;

  constructor(public scheduleService: ScheduleService,
    private modalService: ModalService,
    private transition: Transition) {

  }

  addSchedule() {
    if (!this.scheduleForm.dirty) {
      this.transition.router.stateService.go('apps.schedules.add');
    } else {
      this.modalService.confirm('Unsaved Changes',
          'You have unsaved changes. Do you want to Save or Discard them?',
          'Save', 'Discard')
      .then(() => {
        // do what you need if user presses ok
        this.save()
          .then(() => {
            this.transition.router.stateService.go('apps.schedules.add');
          });
      })
      .catch((value) => {
        // do what you need to do if user cancels
        if (value) {
          this.transition.router.stateService.go('apps.schedules.add');
        }
      });
    }
  }

  save() {
    if (!this.scheduleForm.valid) {
      console.info('form not valid: ', this.scheduleForm.errors);

      return Promise.reject();
    } else {
      return this.scheduleService.updateSchedule();
    }
  }

}
