import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimelineUtilsService {

  constructor() { }

  sanitizeDateString (date: string) {
    return date.replace(/\u202f/, ' ');
  }

  getDateTime(hour: number, minute: number, useLocaldate: boolean): Date|string {
    var d = new Date();

    if (useLocaldate) {
      return new Date(d.getFullYear(), d.getMonth(), d.getDate(), hour, minute, 0);
    } else {
      d.setHours(hour);
      d.setMinutes(minute);
      d.setSeconds(0);

      return this.formatDate(d);
    }
  }

  utcToLocalTime(date?: Date|string): Date {
    if (date) {
      if (typeof date === 'string') {
        date = new Date(this.sanitizeDateString(date));
      }
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      return date;
    }
  }

  localTimeToUtc (date?: Date): Date {
    if (date) {
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    }
    return date;
  }

  parseDate (dateString?: string): Date|null {
    return dateString ? new Date(this.sanitizeDateString(dateString)) : null;
  }

  formatDate (date?: Date): string|null {
    return date ? this.sanitizeDateString(date.toLocaleDateString('en-US') + ' ' + date.toLocaleTimeString('en-US')) : null;
  }

  resetTime (date?: Date): void {
    if (date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
    }
  }

  parseWeeklyRecurrence(recurrenceDaysOfWeek: string[]): any {
    const days = {} as any;

    recurrenceDaysOfWeek.forEach((day) => {
      if (day === 'Mon') {
        days.monday = true;
      } else if (day === 'Tue') {
        days.tuesday = true;
      } else if (day === 'Wed') {
        days.wednesday = true;
      } else if (day === 'Thu') {
        days.thursday = true;
      } else if (day === 'Fri') {
        days.friday = true;
      } else if (day === 'Sat') {
        days.saturday = true;
      } else if (day === 'Sun') {
        days.sunday = true;
      }
    });

    return days;
  }

  saveWeeklyRecurrence(weeklyRecurrence: any): string[] {
    const recurrenceDaysOfWeek = [];

    if (weeklyRecurrence.monday) {
      recurrenceDaysOfWeek.push('Mon');
    }
    if (weeklyRecurrence.tuesday) {
      recurrenceDaysOfWeek.push('Tue');
    }
    if (weeklyRecurrence.wednesday) {
      recurrenceDaysOfWeek.push('Wed');
    }
    if (weeklyRecurrence.thursday) {
      recurrenceDaysOfWeek.push('Thu');
    }
    if (weeklyRecurrence.friday) {
      recurrenceDaysOfWeek.push('Fri');
    }
    if (weeklyRecurrence.saturday) {
      recurrenceDaysOfWeek.push('Sat');
    }
    if (weeklyRecurrence.sunday) {
      recurrenceDaysOfWeek.push('Sun');
    }

    return recurrenceDaysOfWeek;
  }

}
