import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModelGroup } from '@angular/forms';

import * as _ from 'lodash';

import { COUNTRIES, UrlStateService } from 'src/app/ajs-upgraded-providers';

import { RegistrationService } from '../../services/registration.service';
import { CompanyIcpService } from 'src/app/common-header/services/company-icp.service';
import { UserStateService } from '../../services/user-state.service';
import { GeodataService } from 'src/app/shared/services/geodata.service';

@Component({
  selector: 'registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {


  COMPANY_INDUSTRY_FIELDS = this.sort(CompanyIcpService.COMPANY_INDUSTRY_FIELDS);

  regionsCA = GeodataService.REGIONS_CA;
  regionsUS = GeodataService.REGIONS_US;

  get companyRoles() {
    if (this.registrationService.company.companyIndustry === 'PRIMARY_SECONDARY_EDUCATION') {
      return CompanyIcpService.EDUCATION_COMPANY_ROLE_FIELDS;
    } else {
      return CompanyIcpService.COMPANY_ROLE_FIELDS;
    }
  }

  isSignUp = this.userStateService.isSignUp();
  unconfirmedGoogleAccount = !this.userStateService.isRiseAuthUser() && this.registrationService.newUser && !this.userStateService._state.joinaccountState;
  unexpectedGoogleAccount = !this.userStateService.isRiseAuthUser() && this.userStateService._state.joinaccountState && this.userStateService._state.joinaccountState.email !== this.userStateService._state.user.username;
  username = this.userStateService.getUsername();
  joinaccountState = this.userStateService._state.joinaccountState || {};

  get isProvinceShown() {
    return this.registrationService.company?.country === 'CA' || this.registrationService.company?.country === 'US';
  }

  personalizeComplete;

  get showPersonalization() {
    return this.registrationService.newUser && !this.personalizeComplete;
  }

  @ViewChild('registrationForm')
  public registrationForm: NgForm;

  @ViewChild('personalizationModelGroup')
  public personalizationModelGroup: NgModelGroup;

  @ViewChild('finalModelGroup')
  public finalModelGroup: NgModelGroup;

  constructor(private changeDetectorRef: ChangeDetectorRef,
    public countries: COUNTRIES,
    private userStateService: UserStateService,
    protected registrationService: RegistrationService,
    private urlStateService: UrlStateService) {}

  ngOnInit() {
    this.populateIndustryFromUrl();
  }

  confirmGoogleAccount() {
    this.unconfirmedGoogleAccount = false;
  }

  newUserK12Selected() {
    return this.registrationService.newUser && this.registrationService.company.companyIndustry === 'PRIMARY_SECONDARY_EDUCATION';
  }

  onPersonalizeComplete() {
    this.personalizationModelGroup.control.controls.firstName.markAsDirty();
    this.personalizationModelGroup.control.controls.lastName.markAsDirty();
    this.personalizationModelGroup.control.controls.companyName.markAsDirty();
    this.personalizationModelGroup.control.controls.companyIndustry.markAsDirty();
    this.personalizationModelGroup.control.controls.companyRole.markAsDirty();
    this.personalizationModelGroup.control.controls.jobTitle?.markAsDirty();

    if (!this.personalizationModelGroup.invalid) {
      this.personalizeComplete = true;
    }
  }

  save() {
    this.registrationForm.controls.accepted.markAsDirty();
    this.personalizationModelGroup.control.controls.firstName.markAsDirty();
    this.personalizationModelGroup.control.controls.lastName.markAsDirty();

    if (this.registrationService.newUser) {
      this.personalizationModelGroup.control.controls.companyName.markAsDirty();
      this.personalizationModelGroup.control.controls.companyIndustry.markAsDirty();
      this.personalizationModelGroup.control.controls.companyRole.markAsDirty();
      this.personalizationModelGroup.control.controls.jobTitle?.markAsDirty();

      this.finalModelGroup.control.controls.country.markAsDirty();
      this.finalModelGroup.control.controls.region.markAsDirty();
      this.finalModelGroup.control.controls.refSource.markAsDirty();
      this.finalModelGroup.control.controls.displayCount.markAsDirty();
    }

    if (!this.registrationForm.invalid) {
      this.registrationService.register();
    }
  }

  populateIndustryFromUrl() {

    var industryName = this.urlStateService.getUrlParam('industry');

    if (this.registrationService.newUser && industryName) {

      this.COMPANY_INDUSTRY_FIELDS.forEach((industry) => {
        if (industryName === industry[0]) {
          this.registrationService.company.companyIndustry = industry[1];
        }
      });
    }
  }

  sort(items) {
    return _.sortBy(items, item => {
      return item[0];
    });
  }

  onChange() {
    this.changeDetectorRef.detectChanges();
  }

}