
import { StatesModule, Transition } from '@uirouter/angular';

import { ModalService } from '../components/modals/modal.service';
import { CurrentPlanService } from '../components/plans/current-plan.service';

import { PurchaseComponent } from './components/purchase/purchase.component';
import { UpdateSubscriptionComponent } from './components/update-subscription/update-subscription.component';
import { ChangePlanComponent } from './components/change-plan/change-plan.component';
import { UpdatePlanComponent } from './components/update-plan/update-plan.component';

import { ViewportComponent } from './viewport/viewport.component';

const resolveAccess = (transition, currentPlanService) => {
  if (!transition.params().subscriptionId && !currentPlanService.isSubscribed()) {
    transition.abort();
    return transition.router.stateService.go('apps.purchase.home', transition.params());
  }
};

export const routes: StatesModule = {
  states: [
    {
      name: 'apps.purchase',
      abstract: true,
      component: ViewportComponent,
      params: {
        displayCount: 1
      }
    },

    {
      name: 'apps.purchase.plans',
      url: '/plans',
      redirectTo: 'apps.purchase.home'
    },

    {
      name: 'apps.purchase.change-plan',
      url: '/plan/change/:subscriptionId/:planType/:displayCount?enterprise',
      component: ChangePlanComponent,
      resolve: [
        {
          token: 'resolveAccess',
          deps: [Transition, CurrentPlanService],
          resolveFn: resolveAccess
        }
      ]
    },

    {
      name: 'apps.purchase.update-plan',
      url: '/plan/update/:subscriptionId/:productCode',
      component: UpdatePlanComponent,
      params: {
        monthly: { value: false },
        redirectTo: { value: '' }
      },
      resolve: [
        {
          token: 'resolveAccess',
          deps: [Transition, CurrentPlanService],
          resolveFn: resolveAccess
        }
      ]
    },

    {
      name: 'apps.purchase.home',
      url: '/purchase?enterprise&unlimited',
      component: PurchaseComponent,
      resolve: [
        {
          token: 'purchaseHome',
          deps: [Transition, ModalService, CurrentPlanService],
          resolveFn: (transition, modalService, currentPlanService) => {
            if (currentPlanService.isSubscribed()) {
              if (!currentPlanService.canModifyPlan) {
                var contactInfo = currentPlanService.currentPlan.parentPlanContactEmail ? ' at ' +
                  currentPlanService.currentPlan.parentPlanContactEmail : '';

                return modalService.showMessage(
                  'You can\'t edit your current plan.',
                  'Your plan is managed by your parent company. Please contact your account administrator' +
                  contactInfo + ' for additional licenses.'
                ).finally(() => {
                  if (!transition.to().name) {
                    transition.abort();
                    return transition.router.stateService.go('apps.home');
                  } else {
                    return Promise.reject();
                  }
                });
              } else {
                transition.abort();
                return transition.router.stateService.go('apps.purchase.licenses.add', {
                  displayCount: transition.params().displayCount
                });
              }
            }
          }
        },
        {
          token: 'redirectTo',
          resolveFn: () => {
            return location.pathname !== '/purchase' ? location.pathname : '/';
          }
        }
      ]
    },

    {
      name: 'apps.purchase.licenses',
      url: '/licenses',
      abstract: true,
      component: UpdateSubscriptionComponent,
      resolve: [
        {
          token: 'resolveAccess',
          deps: [Transition, CurrentPlanService],
          resolveFn: resolveAccess
        },
        {
          token: 'redirectTo',
          resolveFn: () => {
            return location.pathname.indexOf('/licenses') !== 0 ? location.pathname : '';
          }
        }
      ]
    },
    {
      name: 'apps.purchase.licenses.add',
      url: '/add/:subscriptionId?enterprise&unlimited',
      params: {
        purchaseAction: 'add',
        subscriptionId: ''
      }
    },
    {
      name: 'apps.purchase.licenses.change',
      url: '/change/:subscriptionId',
      params: {
        purchaseAction: 'change',
        subscriptionId: '',
        productCode: '',
        monthly: false
      }
    },
    {
      name: 'apps.purchase.licenses.remove',
      url: '/remove/:subscriptionId',
      params: {
        purchaseAction: 'remove',
        subscriptionId: ''
      }
    },
    {
      name: 'apps.purchase.licenses.unlimited',
      url: '/unlimited/:subscriptionId',
      params: {
        purchaseAction: 'unlimited',
        subscriptionId: ''
      }
    },

    {
      name: 'apps.purchase.frequency',
      url: '/frequency/:subscriptionId',
      component: UpdateSubscriptionComponent,
      params: {
        purchaseAction: 'annual',
        subscriptionId: ''
      },
      resolve: [
        {
          token: 'resolveAccess',
          deps: [Transition, CurrentPlanService],
          resolveFn: resolveAccess
        },
        {
          token: 'redirectTo',
          resolveFn: () => {
            return location.pathname.indexOf('/frequency') !== 0 ? location.pathname : '';
          }
        }
      ]
    }
  ]
};
