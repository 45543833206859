<div class="form-group timelineWeekdays">
  <div class="flex-row">
    <mat-checkbox class="mr-1" name="weeklyRecurrenceMonday" [ngModel]="days.monday" (ngModelChange)="update('monday', $event)" />
    <span class="u_clickable" (click)="update('monday', !days.monday)">Monday</span>
  </div>
  <div class="flex-row">
    <mat-checkbox class="mr-1" name="weeklyRecurrenceTuesday" [ngModel]="days.tuesday" (ngModelChange)="update('tuesday', $event)" />
    <span class="u_clickable" (click)="update('tuesday', !days.tuesday)">Tuesday</span>
  </div>
  <div class="flex-row">
    <mat-checkbox class="mr-1" name="weeklyRecurrenceWednesday" [ngModel]="days.wednesday" (ngModelChange)="update('wednesday', $event)" />
    <span class="u_clickable" (click)="update('wednesday', !days.wednesday)">Wednesday</span>
  </div>
  <div class="flex-row">
    <mat-checkbox class="mr-1" name="weeklyRecurrenceThursday" [ngModel]="days.thursday" (ngModelChange)="update('thursday', $event)" />
    <span class="u_clickable" (click)="update('thursday', !days.thursday)">Thursday</span>
  </div>
  <div class="flex-row">
    <mat-checkbox class="mr-1" name="weeklyRecurrenceFriday" [ngModel]="days.friday" (ngModelChange)="update('friday', $event)" />
    <span class="u_clickable" (click)="update('friday', !days.friday)">Friday</span>
  </div>
  <div class="flex-row">
    <mat-checkbox class="mr-1" name="weeklyRecurrenceSaturday" [ngModel]="days.saturday" (ngModelChange)="update('saturday', $event)" />
    <span class="u_clickable" (click)="update('saturday', !days.saturday)">Saturday</span>
  </div>
  <div class="flex-row">
    <mat-checkbox class="mr-1" name="weeklyRecurrenceSunday" [ngModel]="days.sunday" (ngModelChange)="update('sunday', $event)" />
    <span class="u_clickable" (click)="update('sunday', !days.sunday)">Sunday</span>
  </div>
</div>