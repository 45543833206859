<div class="attribute-editor-component" rvSpinner="slides-editor-loader" [rvShowSpinner]="spinner">
  <div class="attribute-editor-row">
    <form>
      <div class="form-group has-feedback" [ngClass]="{ 'has-error': validationResult && validationResult !== 'VALID', 'has-success': validationResult === 'VALID' && src !== '' }">
        <label class="control-label" for="te-slides-src">Google Slides URL:</label>
        <input type="text" id="te-slides-src" name="te-slides-src" class="form-control u_ellipsis" [(ngModel)]="src" placeholder="Enter URL" (debounceEvent)="saveSrc()" >
        <streamline-icon class="overlay-icon form-control-feedback" aria-hidden="true" name="{{validationResult && validationResult !== 'VALID' ? 'exclamation' : 'checkmark'}}" width="14" height="12"></streamline-icon>
        <p class="help-block">
          @switch (validationResult) {
            @case ('DELETED') {
              <span>Your Google Slideshow is no longer accessible, please check if it’s been deleted.</span>
            }
            @case ('NOT_PUBLIC') {
              <span>To use this Google Slideshow it must be publicly accessible.</span>
            }
            @case ('INVALID') {
              <span>Please provide a valid Google Slides URL.</span>
            }
          }
        </p>
        <p [shown]="validationResult === 'NOT_PUBLIC'">
          <streamline-icon class="icon-help" aria-hidden="true" name="help" width="15" height="15"></streamline-icon>
          <a href="https://support.google.com/docs/answer/183965?co=GENIE.Platform%3DDesktop&hl=en" target="_blank">How to make your Google Slideshow public.</a>
        </p>
      </div>
    </form>
  </div>
  <div class="attribute-editor-row">
    <label class="control-label" for="te-slides-duration">Show each slide for:</label>
    <div class="input-group input-duration">
      <input type="number" id="te-slides-duration" class="form-control" name="te-slides-duration" min="1" [(ngModel)]="duration" placeholder="10" (ngModelChange)="saveDuration()">
      <span class="input-group-addon">seconds</span>
    </div>
  </div>
</div>
