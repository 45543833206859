import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { AttributeDataService } from './attribute-data.service';
import { FileCheckerService } from 'src/app/storage/services/file-checker.service';
import { TemplateEditorService } from './template-editor.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';
import { FileMetadataUtilsService } from '../template-components/services/file-metadata-utils.service';
import { LogoImageService } from '../template-components/services/logo-image.service';

@Injectable({
  providedIn: 'root'
})
export class StorageCacheBusterService {

  constructor(
    private templateEditorService: TemplateEditorService,
    private attributeDataService: AttributeDataService,
    private storageUtilsService: StorageUtilsService,
    private storageManagerService: StorageManagerService,
    private fileCheckerService: FileCheckerService,
    private fileMetadataUtilsService: FileMetadataUtilsService
  ) { }

  _filterComponents(components, type) {
    return _.filter(components, component => {
      return component.tagName === type || component.type === type;
    });
  }

  _getComponentsByIds(components, ids) {
    return _.filter(components, component => {
      return ids.indexOf(component.id) >= 0;
    });
  }

  _getImageComponents(attributeData): any[] {
    const imageComponentIds = this.attributeDataService.getComponentIds({
      type: 'rise-image'
    });

    if (attributeData && attributeData.components) {
      return this._getComponentsByIds(attributeData.components, imageComponentIds);
    }

    return [];
  }

  _getFolderComponents(attributeData): any[] {
    const folderComponentIds = this.attributeDataService.getComponentIds({
      type: 'rise-folder'
    });

    if (attributeData && attributeData.components) {
      const folderUserComponents = this._filterComponents(attributeData.components, 'rise-folder');
      const folderComponents = this._getComponentsByIds(attributeData.components, folderComponentIds);
      folderComponents.push(...folderUserComponents);

      return _.filter(folderComponents, component => {
        return component.type === 'storage-file' &&
          this.storageManagerService.isImage(component.source);
      });
    }

    return [];
  }

  _filterPlaylistItems(components, type) {
    const items = [];

    _.each(components, component => {
      const newItems = _.filter(component.items, item => {
        return item && item.element && item.element.tagName === type;
      });

      items.push(...newItems);
    });

    return items;
  }

  _getFolderPlaylistComponents(attributeData): any[] {
    const playlistComponentIds = this.attributeDataService.getComponentIds({
      type: 'rise-playlist'
    });

    if (attributeData && attributeData.components) {
      const playlistUserComponents = this._filterComponents(attributeData.components, 'rise-playlist');
      const playlistComponents = this._getComponentsByIds(attributeData.components, playlistComponentIds);
      playlistComponents.push(...playlistUserComponents);

      const folderPlaylistComponents = this._filterPlaylistItems(playlistComponents, 'rise-folder');

      return _.filter(folderPlaylistComponents, item => {
        return item && item.element && item.element.attributes &&
          item.element.attributes.type === 'storage-file' &&
          this.storageManagerService.isImage(item.element.attributes.source);
      });
    }

    return [];
  }

  _getFileUrls(): any[] {
    var attributeData = this.templateEditorService.presentation.templateAttributeData;

    const imageComponents = this._getImageComponents(attributeData);
    const folderComponents = this._getFolderComponents(attributeData);
    const folderPlaylistComponents = this._getFolderPlaylistComponents(attributeData);

    const componentUrls = _.flatMap(imageComponents, (component: any) => {
      return this.fileMetadataUtilsService.filesAttributeToArray(component.files);
    });
    const folderComponentUrls = _.map(folderComponents, (component: any) => {
      return component.source;
    });
    const playlistComponentUrls = _.map(folderPlaylistComponents, (item: any) => {
      return item && item.element && item.element.attributes && item.element.attributes.source;
    });
    componentUrls.push(...folderComponentUrls);
    componentUrls.push(...playlistComponentUrls);

    return componentUrls;
  }

  loadStorageFiles() {
    const fileUrls = this._getFileUrls();

    this.fileCheckerService.requestFiles(fileUrls);
  }

  updateStorageFileTimestamps(attributeData) {
    const imageComponents = this._getImageComponents(attributeData);
    const folderComponents = this._getFolderComponents(attributeData);
    const folderPlaylistComponents = this._getFolderPlaylistComponents(attributeData);

    _.each(imageComponents, (component: any) => {
      const files =  this.fileMetadataUtilsService.filesAttributeToArray(component.files);
      const metadata = component.metadata;
      const cachedFiles = [];

      _.each(files, (file) => {
        const cachedFile = this.fileCheckerService.getCachedFile(file);

        if (cachedFile) {
          cachedFiles.push(cachedFile);
        }
      });

      component.metadata = this.fileMetadataUtilsService.metadataWithFile(metadata, LogoImageService.DEFAULT_IMAGE_THUMBNAIL, cachedFiles);
    });
    _.each(folderComponents, (component: any) => {
      const file = this.fileCheckerService.getCachedFile(component.source);

      if (file) {
        component.source = this.storageUtilsService.getFilePathWithTimeCreated(file);
      }
    });
    _.each(folderPlaylistComponents, (item: any) => {
      const fileName = item && item.element && item.element.attributes && item.element.attributes.source;

      const file = this.fileCheckerService.getCachedFile(fileName);

      if (file) {
        item.element.attributes.source = this.storageUtilsService.getFilePathWithTimeCreated(file);
      }
    });
  }

}
