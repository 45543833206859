import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { Display } from "../../services/display";

@Component({
  selector: "edit-screen-sharing",
  templateUrl: './edit-screen-sharing.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  styleUrls: ["./edit-screen-sharing.component.scss"]
})
export class EditScreenSharingComponent implements OnInit {
  @Input() model: ScreenSharingModel = {
    settings: {}
  };
  @Input() licenseRequired: TemplateRef<any>;
  @Input() isLicenseRequired: boolean;

  bulkEditForm: NgForm;

  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.bulkEditForm = <NgForm>this.controlContainer;
  }

  screenShareModeChange(mode: string, $event: {checked: boolean}) {
    if (mode === 'moderated') {
      this.model.screenSharingModerated = $event.checked;
      this.model.screenSharingStandard = this.model.screenSharingStandard && !$event.checked;
    } else if (mode === 'standard') {
      this.model.screenSharingStandard = $event.checked;
      this.model.screenSharingModerated = this.model.screenSharingModerated && !$event.checked;
    }

    if (this.model.screenSharingStandard || this.model.screenSharingModerated) {
      this.model.settings.screenSharingMode = this.model.screenSharingStandard
        ? 'standard' : 'moderated';
    } else {
      this.model.settings.screenSharingMode = null;
    }

    this.model.screenSharingEnabled = !!this.model.settings.screenSharingMode;
  }
}

type ScreenSharingModel = Pick<
  Display,
  | 'screenSharingEnabled'
  | 'screenSharingModerated'
  | 'screenSharingStandard'
  | 'settings'
  | 'isDisplayControlEnabled'
>
