import * as angular from 'angular';

export class BaseList {

  list: unknown[];
  maxCount: number;
  cursor: any;
  endOfList: boolean;
  productCodes: string[];

  constructor (maxCount?) {
    this.list = [];
    this.maxCount = maxCount ? maxCount : 40;
    this.cursor = null;
    this.endOfList = false;
  }

  add (items, cursor) {
    this.cursor = cursor;
    this.endOfList = items.length < this.maxCount;
    this.concat(items);
  }

  clear () {
    this.list = [];
    this.cursor = null;
    this.endOfList = false;
  }

  concat (items) {
    this.list = this.list.concat(items);
  }
}

angular.module('risevision.common.components.scrolling-list')
  .value('BaseList', BaseList);