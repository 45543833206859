import { Input, Component, ElementRef, OnChanges, SimpleChanges, ViewChild, OnInit } from '@angular/core';

import { PresentationUtilsService } from 'src/app/editor/services/presentation-utils.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { UploadManagerConfig, UploadManagerService } from 'src/app/storage/services/upload-manager.service';

import * as _ from 'lodash';
import { StorageUtilsService } from 'src/app/storage/services/storage-utils.service';

@Component({
  selector: 'basic-uploader',
  templateUrl: './basic-uploader.component.html',
  styleUrls: ['./basic-uploader.component.scss']
})
export class BasicUploaderComponent implements OnChanges, OnInit {

  @Input() uploaderId!: string;
  @Input() uploadManager!: any;

  private _validType = '';
  @Input() set validType(value: string) {
    this._validType = value || '';
  }
  get validType(): string {
    return this._validType;
  }

  @Input() fileList!: any;

  @ViewChild('inputField') inputField: ElementRef;

  uploader;
  folderNames = [];

  constructor(
    private storageUtilsService: StorageUtilsService,
    private presentationUtils: PresentationUtilsService,
    private storageManagerService: StorageManagerService,
    private uploadManagerService: UploadManagerService) {
  }

  ngOnInit(): void {
    this.initFileUploader();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fileList?.currentValue?.length > 0) {
      this.uploadSelectedFiles(changes.fileList.currentValue);
    }
  }

  fileInputChangeEvent(files) {
    var selectedFiles = Array.from(files);
    this.uploadSelectedFiles(selectedFiles)
      .finally(() => {
        this.inputField.nativeElement.value = null;
      });
  }

  initFileUploader() {
    let uploadManagerConfig: UploadManagerConfig = {
      validType: this.validType,
      addFile: this._addFile.bind(this),
      onUploadStatus: this.uploadManager.onUploadStatus,
      detectChanges: this.uploadManager.detectChanges || (() => {}),
      skipOverwrite: this.uploadManager.skipOverwrite,
      getFolderPath: () => {
        return this.uploadManager.folderPath;
      }
    };

    this.uploader = this.uploadManagerService.getFileUploader(uploadManagerConfig);

  }

  _addFile(file) {
    if (this.validType !== StorageManagerService.FILE_TYPE.FOLDER) {
      console.log('Add file to the list', file);
      this.uploadManager.addFile(file);
    } else {
      let folderName = this.storageUtilsService.getUploadedFolder(file.name, this.uploadManager.folderPath);
      let folder = {
        name: folderName,
        kind: 'folder'
      };

      if (this.folderNames.indexOf(folderName) === -1) {
        this.folderNames.push(folderName);

        console.log('Add folder to the list', folder);
        this.uploadManager.addFile(folder);
      }
    }
  }

  fileNameOf(path) {
    return this.storageUtilsService.fileNameOf(path);
  }

  uploadSelectedFiles(selectedFiles) {
    this.folderNames = [];

    return this.uploader.uploadSelectedFiles(selectedFiles);
  }

  getAcceptedTypes() {
    if (this.presentationUtils.isMobileBrowser()) {
      // set accept parameters for mobile
      // https://github.com/Rise-Vision/rise-vision-apps/pull/1241
      if (this.validType === StorageManagerService.FILE_TYPE.IMAGE || this.validType === StorageManagerService.FILE_TYPE.VIDEO) {
        return this.validType + '/*';
      } else if (this.validType === StorageManagerService.FILE_TYPE.IMAGE_VIDEO) {
        return 'image/*, video/*';
      } else {
        return '';
      }
    } else if (this.validType.indexOf(StorageManagerService.FILE_TYPE.VIDEO) !== -1) {
      // if videos are allowed, check for valid type after passing files to encoding
      // https://github.com/Rise-Vision/rise-vision-apps/pull/1685
      return '*';
    } else {
      // return a csv string of the specific extensions or blank for folder
      return this.storageManagerService.getValidExtensions(this.validType);
    }
  }

}
