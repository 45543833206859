import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TemplateEditorService } from '../../services/template-editor.service';
import { concat, lastValueFrom, retry } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebPageService {

  static readonly COMPANY_NOTIFICATIONS_BUCKET = 'risevision-company-notifications';

  constructor(
    private templateEditorService: TemplateEditorService,
    private httpClient: HttpClient,
  ) { }

  isWebPageComponent(tagName: string) {
    return tagName === 'rise-web-page';
  }

  getUsernameReadOnly(credentialId) {
    if (this.templateEditorService.presentation.credentials && this.templateEditorService.presentation.credentials[credentialId]) {
      return this.templateEditorService.presentation.credentials[credentialId];
    }

    return null;
  }

  saveCredential(username: string, password: string) : string {
    // Generate a new credentialId every time
    const credentialId = crypto.randomUUID();
    this.setCredentialUpdate(credentialId, {
      username: username,
      password: password
    });

    return credentialId;
  }

  copyCredential(copyFromId) {
    const credentialId = crypto.randomUUID();
    this.setCredentialUpdate(credentialId, {
      copyFromId: copyFromId
    });

    return credentialId;
  }

  removeCredential(credentialId) {
    this.setCredentialUpdate(credentialId, {
      remove: true
    });
  }

  setCredentialUpdate(credentialId, updateObject) {
    if (!this.templateEditorService.presentation.credentialsUpdate) {
      this.templateEditorService.presentation.credentialsUpdate = {};
    }

    this.templateEditorService.presentation.credentialsUpdate[credentialId] = updateObject;
  }

  updatePreview(credentialId, src) {
    const companyId = this.templateEditorService.presentation.companyId;
    const presentationId = this.templateEditorService.presentation.id;

    const path = this.getPreviewImagePath(companyId, presentationId, credentialId);

    const origin = "https://storage.googleapis.com";
    const url =  `${origin}/${path}`;

    const notify = this.notifyImager(credentialId, src);
    const poll =  this.checkTargetImage(url);

    return lastValueFrom(concat(notify, poll));
  }

  getPreviewImagePath(companyId, presentationId, credentialId) {
    return [
      WebPageService.COMPANY_NOTIFICATIONS_BUCKET,
      `/${companyId}`,
      `/template-data`,
      `/${presentationId}`,
      `/revised`,
      `/authenticated-web-page-images`,
      `/${credentialId}.png`
    ].join("");
  }

  checkTargetImage(url = "") {
    const req = () => this.httpClient.head(url, {responseType: "text"});

    return req().pipe(retry({count: 4, delay: 5000}));
  }

  notifyImager(credentialId, src) {
    const origin = document.location.origin.includes('apps-stage-') ?
      "https://services2-stage.risevision.com" :
      "https://services2.risevision.com";

    // preview screenshots should not be deleted, unlike production ones which expire.
    const refresh = 0;

    const url = [
      origin,
      "/authenticated-web-page-imager/",
      `companies/${this.templateEditorService.presentation.companyId}/`,
      `presentations/${this.templateEditorService.presentation.id}/`,
      `credentials/${credentialId}?`,
      `refreshSeconds=${refresh}`,
      "&useDraft"
    ].join("");

    const headers = {
      "content-type": "application/json"
    };

    return this.httpClient.put(url, {src}, {
        observe: 'response',
        responseType: 'json',
        headers
    })
  }

  forceSave() {
    this.templateEditorService.hasUnsavedChanges = true;

    return this.templateEditorService.save()
      .catch((err) => {
        console.log('Failed to save presentation', err.message);
      });
  }
}
