import { Injectable } from '@angular/core';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { Company } from 'src/app/common-header/services/company';

@Injectable({
  providedIn: 'root'
})
export class PlanUtilsService {

  constructor(private companyStateService: CompanyStateService) { }

  isPlanAllocated(company: Company = ({} as Company)): boolean {
		return company.licenseAllocationActive || company.allocatedFromCompanyId &&
				!company.planCompanyId && !company.planBillToId;
  }

  isPlanSubscribed(company: Company = ({} as Company)): boolean {
    // Plan inherited from Parent
    if (company.planCompanyId) {
      return true;
    }

    // Active or Trial
    if (company.planBillToId && (company.planSubscriptionStatus === 'Active' || company.planSubscriptionStatus === 'Trial')) {
      return true;
    }

    return false;
  }

  isSelfManagedPlan(company: Company = ({} as Company)): boolean {
    return company.id !== this.companyStateService.getSelectedCompanyId() &&
        company.planBillToId !== this.companyStateService.getSelectedCompanyId() &&
        !this.isPlanAllocated(company) && this.isPlanSubscribed(company);
  }

}
