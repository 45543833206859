import { Injectable } from '@angular/core';
import { AnalyticsFactory, ProcessErrorCode } from 'src/app/ajs-upgraded-providers';
import { PlansService } from 'src/app/components/plans/plans.service';
import { ReloadSelectedCompanyService } from 'src/app/components/userstate/reload-selected-company.service';
import { PricingService } from './pricing.service';
import { SubscriptionService } from 'src/app/billing/services/subscription.service';
import { BillingApiService } from 'src/app/api/services/billing-api.service';
import { StateService } from '@uirouter/angular';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateSubscriptionService {

  public userEmail;
  public loading;
  public errorMessage;
  public apiError;
  public purchases: any[] = [];
  public estimates: any[] = [];
  private purchaseIntent: any;
  public _purchaseAction;
  public endOfTerm: boolean;
  public couponCode: string;
  public allowMultiple: boolean;

  constructor(private stateService: StateService,
    userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private reloadSelectedCompany: ReloadSelectedCompanyService,
    private billingApiService: BillingApiService,
    private analyticsFactory: AnalyticsFactory,
    private pricingService: PricingService,
    private subscriptionService: SubscriptionService,
    private processErrorCode: ProcessErrorCode,
    private plansService: PlansService) {

      this.userEmail = userStateService.getUserEmail();
    }

    _clearMessages() {
      this.loading = false;

      this.errorMessage = '';
      this.apiError = '';
    }

    init(purchaseAction: string, planCode?: string, monthly?: boolean, downgrade?: boolean) {
      this._clearMessages();
      this.loading = true;

      this._purchaseAction = purchaseAction;

      this.endOfTerm = purchaseAction === 'remove' || purchaseAction === 'change' && downgrade;
      this.couponCode = '';
      const subscriptionIds = this.stateService.params.subscriptionId.split(',');
      const displayCount = this.stateService.params.displayCount;
      const enterprise = this.stateService.params.enterprise;
      const unlimited = this.stateService.params.unlimited;

      this.purchases = [];
      this.allowMultiple = (enterprise || unlimited) && unlimited !== undefined;

      this.subscriptionService.getSubscriptions(subscriptionIds, true).then(() => {
        this.purchases = this.subscriptionService.items.map((item) => {
          const purchase = {
            subscriptionId: item.subscription.id,
            planId: item.subscription.plan_id,
            completed: false,
            licensesToAdd: 0,
            licensesToRemove: 0
          };

          if (purchase.planId && purchaseAction === 'annual') {
            purchase.planId = purchase.planId.replace('1m', '1y');

          } else if (purchaseAction === 'unlimited') {
            const productCode = this.plansService.getUnlimitedPlan().productCode;
            const currency = item.subscription.currency_code.toLowerCase();
            purchase.planId = productCode + '-' + currency + '01y';

          } else if (purchaseAction === 'change' && planCode) {
            purchase.planId = planCode + '-' + item.subscription.currency_code.toLowerCase() + (monthly ? '01m' : '01y');

          } else if (purchaseAction === 'add') {
            if (this.allowMultiple) {
              const plan = this.plansService.getPlanById(purchase.planId);
              if (this.plansService.isEnterprisePlan(plan)) {
                purchase.licensesToAdd = enterprise || 0;
              } else if (this.plansService.isUnlimitedPlan(plan)) {
                purchase.licensesToAdd = unlimited;
              }
            } else {
              purchase.licensesToAdd = displayCount;
            }

          } else if (purchaseAction === 'remove') {
            purchase.licensesToRemove = displayCount;
          }

          return purchase;
        });

      })
      .finally(() => {
        if (this.subscriptionService.apiError) {
          this.errorMessage = 'Something went wrong.';
          this.apiError = this.subscriptionService.apiError;
        }

        if (enterprise !== undefined && this.displayLicensePurchase.planId === undefined) {
          this.purchases.push({
            planId: this.getPlanId(this.plansService.getEnterprisePlan(this.companyStateService.isEducationCustomer()).productCode),
            completed: false,
            licensesToAdd: enterprise,
            licensesToRemove: 0
          });
        }
        if (unlimited !== undefined && this.unlimitedLicensePurchase.planId === undefined) {
          this.purchases.push({
            planId: this.getPlanId(this.plansService.getUnlimitedPlan().productCode),
            completed: false,
            licensesToAdd: unlimited,
            licensesToRemove: 0
          });
        }

        if (!this.subscriptionService.apiError) {
          this.getEstimate(true);
        } else {
          this.loading = false;
        }
      });
    }

    private getPlanId(productCode: string): string {
      const currency = this.companyStateService.getCopyOfSelectedCompany().country === 'CA' ? 'cad' : 'usd';
      return productCode + '-' + currency + '01y';
    }

    getEstimateType(estimate: any): string {
      const subscriptionId = estimate.subscription_estimate.id;
      const purchase = this.getPurchase(subscriptionId);
      if (!purchase) {
        return '';
      }
      return this.plansService.isDisplayLicensePlan(this.plansService.getPlanById(purchase.planId)) ? 'display' : 'unlimited';
    }

    get displayLicensePurchase(): any {
      return this.purchases.find((purchase) => {
        return this.plansService.isDisplayLicensePlan(this.plansService.getPlanById(purchase.planId));
      }) || {};
    }

    get unlimitedLicensePurchase(): any {
      return this.purchases.find((purchase) => {
        return this.plansService.isUnlimitedPlan(this.plansService.getPlanById(purchase.planId));
      }) || {};
    }

    getPurchase(subscriptionId?: string) {
      return this.purchases.find((purchase) => purchase.subscriptionId === subscriptionId) || this.purchases[0];
    }

    getSubscription(subscriptionId?: string) {
      const item = subscriptionId ? this.subscriptionService.items.find((item) => item.subscription.id === subscriptionId) : this.subscriptionService.items[0];
      return item.subscription;
    }

    getCurrentDisplayCount(purchase?: any): number {
      if (purchase?.subscriptionId) {
        const subscription = this.getSubscription(purchase.subscriptionId);
        return subscription?.plan_quantity || 0;
      }
      return purchase ? 0 : this.subscriptionService.getItemSubscription().plan_quantity || 0;
    }

    private _getChangeInLicenses(purchase: any): number {
      if (this._purchaseAction === 'unlimited') {
        return 1;
      }

      const licensesToAdd = purchase.licensesToAdd || 0;
      const licensesToRemove = purchase.licensesToRemove || 0;

      return licensesToAdd - licensesToRemove;
    }

    getUnlimitedCount(): number {
      const purchase = this.unlimitedLicensePurchase;
      return purchase?.planId ? this.getTotalDisplayCount(purchase) : 0;
    }

    getTotalDisplayCount(purchase?: any): number {
      if (this._purchaseAction === 'unlimited') {
        return 1;
      }
      if (purchase === undefined) {
        purchase = this.displayLicensePurchase;
      }
      return this.getCurrentDisplayCount(purchase) + this._getChangeInLicenses(purchase);
    }

    private _getTrackingProperties(subscriptionId: string) {
      const purchase = this.getPurchase(subscriptionId);
      const subscription = this.getSubscription(subscriptionId);
      return {
        planId: purchase.planId,
        currentPlanId: subscription.plan_id,
        purchaseAction: this._purchaseAction === 'annual' || this._purchaseAction === 'unlimited' ? 'change' : this._purchaseAction,
        subscriptionId: subscription.id,
        subscriptionPlan: this.plansService.getPlanById(purchase.planId).name,
        paymentTerm: purchase.planId.endsWith('m') ? 'monthly' : 'yearly',
        changeInLicenses: this._getChangeInLicenses(purchase),
        totalLicenses: this.getTotalDisplayCount(purchase),
        companyId: subscription.customer_id
      };
    }

    private _defaultPricePerDisplay(isMonthly: boolean, displayCount: number, amount: number, term: number): number {
      let price = amount / displayCount / (isMonthly ? 100 : 1200);
      if (term > 1) {
        price /= term;
      }
      return price;
    }

    private _updatePerDisplayPrice() {
      this.purchases.forEach((purchase) => {
        const estimate = this.estimates.find((estimate) => estimate.subscription_estimate?.id === purchase.subscriptionId);
        if (estimate && estimate.next_invoice_estimate) {

          const currentDisplayCount = this.getCurrentDisplayCount(purchase);
          const displayCount = this.getTotalDisplayCount(purchase);

          const lineItem = estimate.next_invoice_estimate.line_items[0];
          const currentSubscription = this.subscriptionService.getItemSubscriptionByPlanId(purchase.planId);
          const newProduct = lineItem.entity_id.substring(0, lineItem.entity_id.indexOf('-'));
          const currentProduct = currentSubscription ? currentSubscription.plan_id.substring(0, currentSubscription.plan_id.indexOf('-')) : newProduct;

          let descriptor: string = lineItem.entity_id.substring(lineItem.entity_id.lastIndexOf('-'));
          let isMonthly = false;
          let term = NaN;
          if (descriptor.length > 6) {
            // Strip off the hyphen and the currency code
            descriptor = descriptor.substring(4);
            isMonthly = descriptor.includes('m');
            term = Number(descriptor.substring(0, 2));
          }

          const educationDiscount = !!estimate.next_invoice_estimate?.line_item_discounts?.find((item) => item.coupon_id === 'EDUCATION');

          purchase.currentPricePerDisplay = this.pricingService.getPricePerDisplay(
            isMonthly,
            currentDisplayCount,
            educationDiscount,
            currentProduct
          ) || this._defaultPricePerDisplay(
            isMonthly,
            displayCount,
            lineItem.amount,
            term
          );
          purchase.newPricePerDisplay = this.pricingService.getPricePerDisplay(
            isMonthly,
            displayCount,
            educationDiscount,
            newProduct
          ) || this._defaultPricePerDisplay(
            isMonthly,
            displayCount,
            lineItem.amount,
            term
          );
        }
      });
    }

    getEstimate(keepErrors: boolean = false) {
      if (!keepErrors) {
        this._clearMessages();
      }

      this.loading = true;

      const items = this.purchases.map((purchase) => {
        return {
          subscriptionId: purchase.subscriptionId,
          id: purchase.planId,
          qty: this.getTotalDisplayCount(purchase)
        };
      }).filter(item => item.qty > 0);

      return this.billingApiService.estimateSubscriptionUpdate(items, this.couponCode, this.endOfTerm)
        .then((result) => {
          this.estimates = result.items;

          if (this._purchaseAction !== 'unlimited') {
            this._updatePerDisplayPrice();
          }

          items.forEach((item) => {
            this.analyticsFactory.track('Subscription Update Estimated', this._getTrackingProperties(item.subscriptionId));
          });
        })
        .catch((e) => {
          this.errorMessage = 'Something went wrong.';
          this.apiError = this.processErrorCode(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }

    getEstimateCredit(estimate: any): number {
      if (!estimate || !estimate.credit_note_estimates) {
        return 0;
      }

      const total = estimate.credit_note_estimates.reduce((total, note) => {
        return total + note.total;
      }, 0);

      return total / 100;
    }

    getCreditTotal(): number {
      let total = 0;

      this.estimates.forEach((estimate) => {
        total += this.getEstimateCredit(estimate);
      });

      return total;
    }

    getCreditCurrency(estimate: any): string {
      return estimate?.credit_note_estimates ? estimate.credit_note_estimates.currency_code : '';
    }

    getInvoiceTotal(estimate: any): number {
      return (estimate?.invoice_estimate?.amount_due || 0) / 100;
    }

    getTotalDue(): number {
      let total = 0;

      this.estimates.forEach((estimate) => {
        total += this.getInvoiceTotal(estimate);
      });

      return total;
    }

    getInvoiceCurrency(estimate: any): string {
      return estimate?.invoice_estimate ? estimate.invoice_estimate.currency_code : '';
    }

    getNextInvoiceTotal(estimate: any): number {
      return (estimate?.next_invoice_estimate?.total || 0) / 100;
    }

    getNextInvoiceCurrency(estimate: any): string {
      return estimate?.next_invoice_estimate ? estimate.next_invoice_estimate.currency_code : '';
    }

    getNextBillingTime(estimate: any): number {
      return estimate.subscription_estimate.next_billing_at * 1000;
    }

    preparePayment() {
      this._clearMessages();

      this.loading = true;

      if (this.subscriptionService.isInvoiced() || this.subscriptionService.getPaymentSourceId() === 'invoice') {
        return Promise.resolve();
      }

      const companyId = this.subscriptionService.getItemSubscription().customer_id;

      const items = this.purchases.map((purchase) => {
        return {
          subscriptionId: purchase.subscriptionId,
          companyId,
          id: purchase.planId,
          qty: this.getTotalDisplayCount(purchase),
          changed: this._getChangeInLicenses(purchase) !== 0
        };
      }).filter(item => item.qty > 0);

      if (items.filter((item) => item.changed).length <= 1) {
        return Promise.resolve();
      }

      return this.billingApiService.prepareUpdateSubscription(items.map(({ changed, ...item }) => item), this.couponCode, this.endOfTerm)
        .then((response) => {
          this.purchaseIntent = response;
        })
        .catch( (e) => {
          this.errorMessage = 'Something went wrong.';
          this.apiError = this.processErrorCode(e);

          this.loading = false;
        });
    }

    completePayment() {
      this._clearMessages();

      this.loading = true;

      const companyId = this.subscriptionService.getItemSubscription().customer_id;
      const paymentIntentId = this.purchaseIntent ? this.purchaseIntent.intentId : null;

      const items = this.purchases.map((purchase) => {
        return {
          subscriptionId: purchase.subscriptionId,
          companyId,
          id: purchase.planId,
          qty: this.getTotalDisplayCount(purchase),
          changed: this._getChangeInLicenses(purchase) !== 0
        };
      }).filter(item => item.qty > 0);

      const card = this.subscriptionService.isInvoiced() || this.subscriptionService.getPaymentSourceId() === 'invoice' ? null : {
        intentId: paymentIntentId
      };

      return this.billingApiService.updateSubscription(items, this.couponCode, this.endOfTerm, card)
        .then(() => {
          items.forEach((item) => {
            this.analyticsFactory.track('Subscription Updated', this._getTrackingProperties(item.subscriptionId));
          });

          if (!this.endOfTerm) {
            return this.reloadSelectedCompany.reloadPlan(items[0].id, items[0].qty)
              .catch((err) => {
                console.debug('Failed to reload company', err);
              });
          }
        })
        .then(() => {
          this.purchases.forEach((purchase) => {
            purchase.completed = true;
          });
        })
        .catch((e) => {
          this.errorMessage = 'Something went wrong.';
          this.apiError = this.processErrorCode(e);
        })
        .finally(() => {
          this.loading = false;
        });
    }
}
