import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

import { PlansService } from 'src/app/components/plans/plans.service';
import { PromiseUtilsService } from 'src/app/shared/services/promise-utils.service';

@Injectable({
  providedIn: 'root'
})
export class ReloadSelectedCompanyService {

  constructor(private companyStateService: CompanyStateService,
    private plansService: PlansService,
    private promiseUtilsService: PromiseUtilsService) { }

  private _reloadAsync(delay: number): Promise<any> {
    return this.promiseUtilsService.delay(delay)
      .then(() => {
        return this.companyStateService.reloadSelectedCompany();
      });
  }

  reloadWithExpectation(expectedResult, attempt: number = 0, delay: number = 10000): Promise<any> {
    return this._reloadAsync(delay)
      .then(() => {
        if (attempt >= 3) {
          return false;
        }

        const selectedCompany = this.companyStateService.getCopyOfSelectedCompany(true);
        const expectedCompany = {};

        _.defaults(expectedCompany, expectedResult, selectedCompany);

        if (_.isEqual(expectedCompany, selectedCompany)) {
          return true;
        } else {
          return this.reloadWithExpectation(expectedResult, attempt + 1);
        }
      });
  }

  reloadPlan(plan: string, displayCount: number, delay: number = 10000): Promise<any> {
    const unlimitedPlanProductCode = this.plansService.getUnlimitedPlan().productCode;
    let expectedResult: any = {
      playerProTotalLicenseCount: displayCount
    };

    if (plan && plan.indexOf(unlimitedPlanProductCode) !== -1) {
      expectedResult = {
        planProductCode: unlimitedPlanProductCode
      };
    }

    return this.reloadWithExpectation(expectedResult, 0, delay);
  }

}
