import { Pipe, PipeTransform } from '@angular/core';
import { StorageUtilsService } from '../services/storage-utils.service';

@Pipe({
  name: 'storageFileName'
})
export class StorageFileNamePipe implements PipeTransform {

  transform(filename: string, currentFolder?: string): string {
    let returnValue = filename;

    if (currentFolder && currentFolder.length > 0) {
      if (filename === currentFolder) {
        const path = filename.lastIndexOf('/', filename.length - 2) + 1;
        returnValue = path ? filename.substring(0, path) : '';
      } else if (filename.indexOf(currentFolder) !== -1) {
        returnValue = filename.substring(currentFolder.length);
      }
    }

    return (returnValue || '/').replace(StorageUtilsService.TRASH_FOLDER, 'Trash/');
  }

}
