import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CalendarMonthYearHeaderComponent } from 'src/app/components/calendar-month-year-header/calendar-month-year-header.component';
import { TemplatesService } from '../../services/templates.service';

@Component({
  selector: 'template-calendar',
  templateUrl: './template-calendar.component.html',
  styleUrls: ['./template-calendar.component.scss']
})
export class TemplateCalendarComponent implements OnInit {

  @Input() displayed: boolean = true;
  @Input() selectedDate: Date | null;
  @Output() selectedDateChange: EventEmitter<Date | null> = new EventEmitter<Date | null>();

  deselectDate: boolean = false;
  minDate: Date;
  maxDate: Date;

  calendarMonthYearHeader = CalendarMonthYearHeaderComponent;

  constructor(private templatesService: TemplatesService) {}

  ngOnInit(): void {
    const future = new Date();
    const past = new Date(future);
    future.setFullYear(future.getFullYear() + 1);
    past.setFullYear(past.getFullYear() - 1);
    this.maxDate = future;
    this.minDate = past;
  }

  filterDate = (date: Date): string => {
    return this.templatesService.templateDates.includes(this.templatesService.formatSearchDate(date)) ? 'has-events' : '';
  };

  deselectDateDown (event: PointerEvent) {
    if ((event.target as Element).classList.contains('mat-calendar-body-selected')) {
      this.deselectDate = true;
    }
  }

  deselectDateUp (event: PointerEvent) {
    setTimeout(() => {
      if (this.deselectDate && (event.target as Element).classList.contains('mat-calendar-body-selected')) {
        this.selectedDate = null;
        this.selectedDateChange.emit(null);
      }
      this.deselectDate = false;
    });
  }
}
