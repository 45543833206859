<form id="confirmForm">
    <div class="modal-header">
      <button type="button" class="close" (click)="close()" aria-label="Click to close this modal">
        <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
      </button>
    </div>
    <div class="modal-body">
      <h4>{{title}}</h4>
      <p class="align-left" [innerHTML]="message"></p>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-xs-6">
          <button type="button" class="btn btn-default w-100" (click)="close()">
            <span>{{cancelButton}}</span>
          </button>
        </div>
        <div class="col-xs-6">
          <button type="button" id="confirm-primary" class="btn {{confirmButtonClass}} w-100" (click)="confirm()">
            <span>{{confirmButton}}</span>
          </button>
        </div>
      </div>
    </div>
  </form>
  