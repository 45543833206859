<div id="share-schedule-button" class="u_clickable" (click)="openMenu()">
  <a
    class="btn hidden-xs"
    [ngClass]="buttonClass"
    [ngStyle]="{ 'pointer-events': 'none' }"
    [matMenuTriggerFor]="shareMenu"
  >
    Share
  </a>
</div>
<mat-menu #shareMenu="matMenu" xPosition="before" class="share-panel" panelClass="share-panel">
  <shared-schedule-popover #sharePopover (dismissEvent)="dismiss()"></shared-schedule-popover>
</mat-menu>

<button
  id="actionSheetButton"
  type="button"
  class="btn visible-xs-inline"
  [ngClass]="buttonClass"
  (click)="openActionSheet()"
>
  Share
</button>
