import { Component, Input } from '@angular/core';

import { CreditCardService } from '../../services/credit-card.service';

@Component({
  selector: 'payment-methods-form',
  templateUrl: './payment-methods-form.component.html',
  styleUrls: ['./payment-methods-form.component.scss']
})
export class PaymentMethodsFormComponent {

  @Input() showInvoiceOption;
  @Input() contactEmail;

  paymentMethods = this.creditCardService.paymentMethods;

  constructor(private creditCardService: CreditCardService) { }

}
