<div id="presentationPropertiesModal">
  <div class="modal-header">
    <button id="dismissButton" type="button" class="close" (click)="dismiss()" aria-label="Close this modal">
      <streamline-icon ariaLabel="Close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 class="modal-title">Presentation Properties</h4>
  </div>
  <form #presentationPropertiesForm="ngForm" id="presentationPropertiesForm" role="form" name="forms.presentationPropertiesForm" novalidate>
    <div class="modal-body align-left">
      <div class="form-group" [shown]="presentationProperties.id">
        <label>Presentation Id:</label>
        <p id="presentationId">{{presentationProperties.id}}</p>
      </div><!--form-group-->
      <div class="form-group">
        <div class="flex-row">
          <madero-checkbox name="sharePresentation" [(ngModel)]="presentationProperties.isTemplate" ngDefaultControl [disabled]="!sharedTemplatesAvailable && !presentationProperties.isTemplate"></madero-checkbox>
          <span class="font-weight-bold mr-3" [ngClass]="{ 'control-label': sharedTemplatesAvailable, 'u_link-disabled': !sharedTemplatesAvailable }">Share this Presentation with your Sub-Companies</span>
        </div>
        <div class="ml-5" [shown]="!sharedTemplatesAvailable">
          <span [shown]="sharedTemplatesAvailable === null">
            <a href="#" class="madero-link" uiSref="apps.purchase.home" (click)="dismiss()">Subscription Required</a>
          </span>
          <span [shown]="sharedTemplatesAvailable !== null">
            <a href="#" class="madero-link" (click)="showUpgradePlanModal()">Plan Upgrade Required</a>
          </span>
        </div>
      </div>
      <hr>
      <div class="form-group">
        <label id="resolutionLabel">Resolution:</label>
        <resolution-selector [(width)]="presentationProperties.width" [(height)]="presentationProperties.height"></resolution-selector>
      </div><!--form-group-->
      <div class="form-group">
        <label id="backgroundLabel">Background:</label>
        <background-image id="backgroundImageSettings" [background]="presentationProperties.background" [companyId]="companyId"></background-image>
      </div>
      <div class="form-group">
        <label id="playUntilDonePlaceholderLabel" class="control-label">Play Until Done Placeholder:</label>
        <select name="playUntilDonePlaceholder" id="playUntilDonePlaceholderSelect" class="form-control" [(ngModel)]="presentationProperties.donePlaceholder">
          @for (placeholder of placeholders; track placeholder) {
            <option value="{{placeholder.id}}">{{placeholder.id}}</option>
          }
        </select>
      </div><!--form-group-->
      <div class="form-group">
        <div class="flex-row">
          <madero-checkbox name="hideMousePointer" id="hideMousePointerCheckbox" [(ngModel)]="presentationProperties.hidePointer" ngDefaultControl></madero-checkbox>
          <span id="hideMousePointerCheckboxLabel" class="control-label mr-3">Hide Mouse Pointer</span>
        </div>
      </div>
    </div><!--modal-body-->
    <div class="modal-footer">
      <button id="presentationPropertiesCancelButton" type="button" class="btn btn-default btn-toolbar" (click)="dismiss()">Cancel</button>
      <button id="applyButton" type="submit" class="btn btn-primary btn-toolbar" (click)="apply()" [disabled]="presentationPropertiesForm?.invalid">Apply</button>
    </div>
  </form>
</div>
