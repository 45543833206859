<form id="confirmForm" autocomplete="off" (ngSubmit)="delete()">
  <div class="modal-header">
    <button type="button" class="close" (click)="dismiss()" data-dismiss="modal" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 class="modal-title">Delete Selected {{itemName}}{{companyItems + subCompanyItems > 1 ? 's' : ''}}?</h4>
  </div>
  <div class="modal-body mb-2">
    <p class="align-left">
      @if (companyItems) {
        <span>
          <strong>{{companyItems}}</strong> {{itemName | lowercase}}{{companyItems > 1 ? 's' : ''}} from your company will be permanently deleted.
          <br/>
        </span>
      }
      @if (subCompanyItems) {
        <span>
          <strong>{{subCompanyItems}}</strong> {{itemName | lowercase}}{{subCompanyItems > 1 ? 's' : ''}} from your sub-companies will be permamently deleted.
          <br/>
        </span>
      }
      <br/>
      @if (itemName === 'Presentation') {
        <span>
          <strong>This also deletes the selected presentation{{companyItems + subCompanyItems > 1 ? 's' : ''}} from schedules.</strong>
        </span>
      }
    </p>
    <div class="align-left u_margin-md-top">
      <p class="mb-2"><strong>Type "{{expectedText}}" to confirm deletion:</strong></p>
      <input name="confirmInput" class="w-100 form-control" type="text" [(ngModel)]="inputText" [focusMe]="true" />
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-xs-6">
        <button type="button" class="btn btn-default w-100" (click)="dismiss()">
          Cancel
        </button>
      </div>
      <div class="col-xs-6">
        <button type="submit" id="confirm-primary" class="btn btn-danger w-100" [disabled]="inputText !== expectedText">
          Delete
        </button>
      </div>
    </div>
  </div>
</form>