<div id="newFolderModal">
  <div class="modal-header">
    <button type="button" class="close" (click)="cancel()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 class="modal-title">Create Folder</h4>
  </div>
  <div class="modal-body align-left">
    <div class="form-group">
      <label for="newFolderInput">Folder Name:</label>
      <input id="newFolderInput" type="text" class="form-control" [(ngModel)]="folderName" placeholder="Enter Folder Name" (focus)="duplicateFolderSpecified=false" [focusMe]="true">
      <br>
      <div [shown]="duplicateFolderSpecified">
        <div class="alert alert-danger" role="alert">A Folder already uses that name, please choose a different one.</div>
      </div>
      <div [shown]="accessDenied && accessDeniedMessage">
        <div class="alert alert-danger" role="alert">{{accessDeniedMessage}}</div>
      </div>
      <div [shown]="accessDenied && !accessDeniedMessage">
        <div class="alert alert-danger" role="alert">You don’t have permission to do this.</div>
      </div>
      <div [shown]="serverError">
        <div class="alert alert-danger" role="alert">An error has occured. {{respCode}}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
      <button id="newFolderModalCancelButton" type="button" class="btn btn-default btn-toolbar" (click)="cancel()">
        Cancel
      </button>
      <button id="newFolderModalSaveButton" type="submit" class="btn btn-primary btn-toolbar" (click)="ok()" [disabled]="!folderName || accessDenied || serverError || waitingForResponse">
        Save
      </button>
  </div>
</div>
