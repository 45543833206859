import { Injectable } from '@angular/core';

import { EditorService } from './editor.service';
import { BackgroundParserService } from './background-parser.service';
import { PresentationParserService } from './presentation-parser.service';

@Injectable({
  providedIn: 'root'
})
export class PresentationPropertiesService {

  constructor(private editorService: EditorService,
    private presentationParserService: PresentationParserService,
    private backgroundParser: BackgroundParserService) {
  }

  _newPresentationProperties() {
    return {
      id: '',
      name: 'New Presentation',
      width: 1920,
      height: 1080,
      widthUnits: 'px',
      heightUnits: 'px',
      background: undefined,
      hidePointer: true,
      donePlaceholder: '',
      isTemplate: false,
      isStoreProduct: false
    };
  }

  getPresentationProperties() {
    var presentationProperties = this._newPresentationProperties();

    if (this.editorService.presentation && this.editorService.presentation.name) {
      presentationProperties.id = this.editorService.presentation.id;
      presentationProperties.name = this.editorService.presentation.name;
      presentationProperties.width = this.editorService.presentation.width;
      presentationProperties.height = this.editorService.presentation.height;
      presentationProperties.widthUnits = this.editorService.presentation.widthUnits;
      presentationProperties.heightUnits = this.editorService.presentation.heightUnits;
      presentationProperties.background = this.backgroundParser.parseBackground(
        this.editorService.presentation.backgroundStyle,
        this.editorService.presentation.backgroundScaleToFit
      );
      presentationProperties.hidePointer = this.editorService.presentation.hidePointer;
      presentationProperties.donePlaceholder = this.editorService.presentation
        .donePlaceholder;
      presentationProperties.isTemplate = this.editorService.presentation.isTemplate;
      presentationProperties.isStoreProduct = this.editorService.presentation
        .isStoreProduct;
    }

    return presentationProperties;
  }

  setPresentationProperties(presentationProperties) {
    if (presentationProperties) {
      this.editorService.presentation.id = presentationProperties.id;
      this.editorService.presentation.name = presentationProperties.name;
      this.editorService.presentation.width = presentationProperties.width;
      this.editorService.presentation.height = presentationProperties.height;
      this.editorService.presentation.widthUnits = presentationProperties.widthUnits;
      this.editorService.presentation.heightUnits = presentationProperties.heightUnits;
      this.editorService.presentation.backgroundStyle = this.backgroundParser.getStyle(
        presentationProperties.background);
      this.editorService.presentation.backgroundScaleToFit =
        this.backgroundParser.getScaleToFit(presentationProperties.background);
      this.editorService.presentation.hidePointer = presentationProperties.hidePointer;
      this.editorService.presentation.donePlaceholder =
        presentationProperties.donePlaceholder;
      this.editorService.presentation.isTemplate = presentationProperties.isTemplate;
      this.editorService.presentation.isStoreProduct =
        presentationProperties.isStoreProduct;

      this.presentationParserService.updatePresentation(this.editorService.presentation);
    }
  }

}
