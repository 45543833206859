import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class NumberInputComponent {

  @Input() min?: number;
  @Input() max?: number;
  @Input() name?: string;
  @ViewChild('numberInput') numberInput: NgModel;

  _value: number = 0;
  get value(): number {
    return this._value;
  }
  @Input() set value(n: number) {
    this._value = Math.round(n || 0);
  }

  @Output() updateValue: EventEmitter<number> = new EventEmitter<number>();

  @Input() disabled: boolean;

  valueUp(): void {
    const newValue = Number(this.value) + 1;
    if (this.max === undefined || newValue <= this.max) {
      this.numberInput.control.setValue(newValue);
      this.numberInput.control.markAsDirty();
      this.updateValue.emit(newValue);
    }
  }

  valueDown(): void {
    const newValue = Number(this.value) - 1;
    if (this.min === undefined || newValue >= this.min) {
      this.numberInput.control.setValue(newValue);
      this.numberInput.control.markAsDirty();
      this.updateValue.emit(newValue);
    }
  }

  onChange() {
    this.updateValue.emit(this.value);
  }
}
