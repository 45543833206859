import { Directive, Input, Output, EventEmitter, Injector, ElementRef } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

/* eslint-disable @angular-eslint/directive-class-suffix */
@Directive({selector: 'color-picker'})
export class ColorPickerComponent extends UpgradeComponent {

  @Input() ngModel!: any;
  @Input() pickerId!: string;
  @Input() placeholderText!: string;
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() ngChange!: any;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('colorPicker', elementRef, injector);
  }

}
