import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

import { FeaturesService } from 'src/app/components/plans/features.service';

@Component({
  selector: 'schedule-type-selector',
  templateUrl: './schedule-type-selector.component.html',
  styleUrls: ['./schedule-type-selector.component.scss']
})
export class ScheduleTypeSelectorComponent {

  @Input() scheduleType: string;
  @Input() playOnce: boolean;
  @Output() scheduleTypeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() playOnceChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() readonly?: boolean;

  get description(): string {
    if (this.scheduleType === 'override_insert') {
      return 'Override Include';
    } else if (this.scheduleType === 'override_replace') {
      return 'Override Replace';
    } else {
      return 'Default';
    }
  }

  constructor(private changeDetectorRef: ChangeDetectorRef,
    private featuresService: FeaturesService) { }

  detectChanges (): void {
    this.changeDetectorRef.detectChanges();

    this.scheduleTypeChange.emit(this.scheduleType);
    this.playOnceChange.emit(this.playOnce);
  }

  get isOverrideAvailable(): boolean | null {
    return this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_OVERRIDE);
  }

  showUpgradePlanModal(): void {
    this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_OVERRIDE);
  }

}