import { Injectable } from '@angular/core';
import { TimelineUtilsService } from './timeline-utils.service';
import { TimelineDescriptionService } from './timeline-description.service';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  private _timeline: any;
  get timeline() {
    return this._timeline;
  }
  set timeline(timeline: any) {
    if (timeline.useLocaldate) {
      timeline.startDate = this.timelineUtilsService.utcToLocalTime(timeline.startDate);
      timeline.startTime = this.timelineUtilsService.utcToLocalTime(timeline.startTime);
      timeline.endDate = this.timelineUtilsService.utcToLocalTime(timeline.endDate);
      timeline.endTime = this.timelineUtilsService.utcToLocalTime(timeline.endTime);
    } else {
      timeline.startDate = this.timelineUtilsService.parseDate(timeline.startDate);
      timeline.endDate = this.timelineUtilsService.parseDate(timeline.endDate);
    }

    this._timeline = timeline;
    this._recurrence = {
      daily: {
        recurrenceFrequency: 1
      },
      weekly: {
        recurrenceFrequency: 1
      },
      monthly: {
        recurrenceAbsolute: true,
        absolute: {
          recurrenceFrequency: 1,
          recurrenceDayOfMonth: 1
        },
        relative: {
          recurrenceFrequency: 1,
          recurrenceWeekOfMonth: 0,
          recurrenceDayOfWeek: 0
        }
      },
      yearly: {
        recurrenceAbsolute: true,
        absolute: {
          recurrenceMonthOfYear: 0,
          recurrenceDayOfMonth: 1
        },
        relative: {
          recurrenceDayOfWeek: 0,
          recurrenceWeekOfMonth: 0,
          recurrenceMonthOfYear: 0
        }
      }
    };

    // _init
    if (timeline.allDay) {
      this._timeline.startTime = this.timelineUtilsService.getDateTime(8, 0, timeline.useLocaldate);
      this._timeline.endTime = this.timelineUtilsService.getDateTime(17, 30, timeline.useLocaldate);
    }

    // _initRecurrence
    if (timeline.recurrenceType === TimelineDescriptionService.RECURRENCE.DAILY) {
      this._recurrence.daily.recurrenceFrequency = timeline.recurrenceFrequency;
    } else if (timeline.recurrenceType === TimelineDescriptionService.RECURRENCE.WEEKLY) {
      this._recurrence.weekly = this.timelineUtilsService.parseWeeklyRecurrence(timeline.recurrenceDaysOfWeek);
      this._recurrence.weekly.recurrenceFrequency = timeline.recurrenceFrequency;
    } else if (timeline.recurrenceType === TimelineDescriptionService.RECURRENCE.MONTHLY) {
      this._recurrence.monthly.recurrenceAbsolute = timeline.recurrenceAbsolute;
      if (timeline.recurrenceAbsolute) {
        this._recurrence.monthly.absolute.recurrenceFrequency =
          timeline.recurrenceFrequency;
        this._recurrence.monthly.absolute.recurrenceDayOfMonth =
          timeline.recurrenceDayOfMonth;
      } else {
        this._recurrence.monthly.relative.recurrenceFrequency =
          timeline.recurrenceFrequency;
        this._recurrence.monthly.relative.recurrenceWeekOfMonth =
          timeline.recurrenceWeekOfMonth;
        this._recurrence.monthly.relative.recurrenceDayOfWeek =
          timeline.recurrenceDayOfWeek;
      }
    } else if (timeline.recurrenceType === TimelineDescriptionService.RECURRENCE.YEARLY) {
      this._recurrence.yearly.recurrenceAbsolute = timeline.recurrenceAbsolute;
      if (timeline.recurrenceAbsolute) {
        this._recurrence.yearly.absolute.recurrenceMonthOfYear =
          timeline.recurrenceMonthOfYear;
        this._recurrence.yearly.absolute.recurrenceDayOfMonth =
          timeline.recurrenceDayOfMonth;
      } else {
        this._recurrence.yearly.relative.recurrenceDayOfWeek =
          timeline.recurrenceDayOfWeek;
        this._recurrence.yearly.relative.recurrenceWeekOfMonth =
          timeline.recurrenceWeekOfMonth;
        this._recurrence.yearly.relative.recurrenceMonthOfYear =
          timeline.recurrenceMonthOfYear;
      }
    }
  }

  private _recurrence: any;
  get recurrence() {
    return this._recurrence;
  }

  constructor(private timelineUtilsService: TimelineUtilsService) { }

  save () {
    if (!this._timeline) {
      return;
    }

    this.timelineUtilsService.resetTime(this._timeline.startDate);
    this.timelineUtilsService.resetTime(this._timeline.endDate);

    if (this._timeline.useLocaldate) {
      this._timeline.startDate = this.timelineUtilsService.localTimeToUtc(this._timeline.startDate) || null;
      this._timeline.endDate = this.timelineUtilsService.localTimeToUtc(this._timeline.endDate) || null;
      this._timeline.startTime = this._timeline.allDay ? null : this.timelineUtilsService.localTimeToUtc(this._timeline.startTime);
      this._timeline.endTime = this._timeline.allDay ? null : this.timelineUtilsService.localTimeToUtc(this._timeline.endTime);
    } else {
      this._timeline.startDate = this.timelineUtilsService.formatDate(this._timeline.startDate);
      this._timeline.endDate = this.timelineUtilsService.formatDate(this._timeline.endDate);
      this._timeline.startTime = this._timeline.allDay ? null : this.timelineUtilsService.formatDate(this._timeline.startTime);
      this._timeline.endTime = this._timeline.allDay ? null : this.timelineUtilsService.formatDate(this._timeline.endTime);
    }

    // _saveRecurrence
    if (this._timeline.recurrenceType === TimelineDescriptionService.RECURRENCE.DAILY) {
      this._timeline.recurrenceFrequency = this._recurrence.daily.recurrenceFrequency;

    } else if (this._timeline.recurrenceType === TimelineDescriptionService.RECURRENCE.WEEKLY) {
      this._timeline.recurrenceFrequency = this._recurrence.weekly.recurrenceFrequency;
      this._timeline.recurrenceDaysOfWeek = this.timelineUtilsService.saveWeeklyRecurrence(this._recurrence.weekly);

    } else if (this._timeline.recurrenceType === TimelineDescriptionService.RECURRENCE.MONTHLY) {
      this._timeline.recurrenceAbsolute = this._recurrence.monthly.recurrenceAbsolute;
      if (this._timeline.recurrenceAbsolute) {
        this._timeline.recurrenceFrequency =
          this._recurrence.monthly.absolute.recurrenceFrequency;
        this._timeline.recurrenceDayOfMonth =
          this._recurrence.monthly.absolute.recurrenceDayOfMonth;
      } else {
        this._timeline.recurrenceFrequency =
          this._recurrence.monthly.relative.recurrenceFrequency;
        this._timeline.recurrenceWeekOfMonth =
          this._recurrence.monthly.relative.recurrenceWeekOfMonth;
        this._timeline.recurrenceDayOfWeek =
          this._recurrence.monthly.relative.recurrenceDayOfWeek;
      }
    } else if (this._timeline.recurrenceType === TimelineDescriptionService.RECURRENCE.YEARLY) {
      this._timeline.recurrenceAbsolute = this._recurrence.yearly.recurrenceAbsolute;
      if (this._timeline.recurrenceAbsolute) {
        this._timeline.recurrenceMonthOfYear =
          this._recurrence.yearly.absolute.recurrenceMonthOfYear;
        this._timeline.recurrenceDayOfMonth =
          this._recurrence.yearly.absolute.recurrenceDayOfMonth;
      } else {
        this._timeline.recurrenceDayOfWeek =
          this._recurrence.yearly.relative.recurrenceDayOfWeek;
        this._timeline.recurrenceWeekOfMonth =
          this._recurrence.yearly.relative.recurrenceWeekOfMonth;
        this._timeline.recurrenceMonthOfYear =
          this._recurrence.yearly.relative.recurrenceMonthOfYear;
      }
    }
  }
}
