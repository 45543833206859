import { Injectable } from '@angular/core';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { PlaceholderService } from './placeholder.service';
import { cloneDeep } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PlaceholderPlaylistService {

  constructor(private placeholderService: PlaceholderService) {}

  getItems () {
    if (!this.placeholderService.placeholder) {
      return [];
    }
    return this.placeholderService.placeholder.items ?
      this.placeholderService.placeholder.items :
      this.placeholderService.placeholder.items = [];
  }

  private _getItemIndex (item) {
    return this.getItems() ?
      this.getItems().indexOf(item) : -1;
  }

  updateItem (item) {
    if (this._getItemIndex(item) === -1) {
      this.getItems().push(item);
    }
  }

  removeItem (item) {
    var index = this._getItemIndex(item);
    if (index !== -1) {
      this.getItems().splice(index, 1);
    }
  }

  private _setItemCopyName (item, index?) {
    var items = this.getItems();
    index = index || 1;
    var itemName = item.name + ' (' + index + ')';

    for (var i = 0; i < items.length; i++) {
      if (items[i].name === itemName) {
        // Same name exists
        return this._setItemCopyName(item, ++index);
      }
    }

    item.name = itemName;

    return;
  }

  duplicateItem (item) {
    var index = this._getItemIndex(item);
    if (index !== -1) {
      var newItem = cloneDeep(item);

      this._setItemCopyName(newItem);

      this.getItems().splice(index + 1, 0, newItem);
    }
  }

  moveItem (currIndex, newIndex) {
    var items = this.getItems();

    items.splice(newIndex, 0, items.splice(currIndex, 1)[0]);
  }
}

angular.module('risevision.editor.services')
  .factory('placeholderPlaylistFactory', downgradeInjectable(PlaceholderPlaylistService));
