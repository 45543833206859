import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { GoogleService } from './google.service';

export type ValidationResult = 'VALID' | 'INVALID' | 'FAILED';

@Injectable({
  providedIn: 'root'
})
export class GoogleCalendarService extends GoogleService {
  public static readonly ENDPOINT_CALENDARS = 'calendar/calendars';
  public static readonly ENDPOINT_EVENTS = 'calendar/events';

  validate(source: string): Promise<ValidationResult> {
    const serviceUrl = environment.GOOGLE_SERVICE_URL;
    const endpointUrl = GoogleCalendarService.ENDPOINT_EVENTS;
    const cacheBuster = Date.now();
    const params = new HttpParams({
      fromObject: {
        calendarId: this.extractCalendarId(source),
        cb: cacheBuster
      }
    });

    if (!source) {
      return Promise.resolve('VALID');
    }

    return this.httpClient.get(serviceUrl + endpointUrl, {
        params
      })
      .toPromise()
      .then((_response) => {
        return 'VALID' as const;
      }).catch((err) => {
        if (err.status == 404) {
          return 'INVALID';
        } else {
          console.error('Failed to retrieve Calendar information.', err);
          return 'FAILED';
        }
      });
  }

  getCalendars(account: string): Promise<ValidationResult> {
    const serviceUrl = environment.GOOGLE_SERVICE_URL;
    const endpointUrl = GoogleCalendarService.ENDPOINT_CALENDARS;
    const cacheBuster = Date.now();
    const params = new HttpParams({
      fromObject: {
        account: account,
      }
    });

    return this.httpClient.get(serviceUrl + endpointUrl, {
        params
      })
      .toPromise()
      .then((response: any) => {
        if (response && response.calendars) {
          return response.calendars;
        } else {
          throw new Error('Invalid calendars data');
        }
      }).catch((err) => {
        console.error('Failed to retrieve the list of calendars', err);
        throw err;
      });
  }

  extractCalendarId(source: string): string {
    // the source could be either the calendar ID or calendar URL
    // the URL could be in the following formats:
    // - Embed: https://calendar.google.com/calendar/embed?src=xyz.calendar.google.com&ctz=America%2FToronto
    // - iCal : https://calendar.google.com/calendar/ical/xyz%40group.calendar.google.com/public/basic.ics

    // if the source is URL, then extract calendar ID from it
    if (/^https?:\/\//.test(source)) {
      const regExp = /^.*(?:embed\?src=|ical\/)(.*?)[&/].*/;
      const match = source.match(regExp);

      if (match) {
        return match[1];
      }
    }

    // for a calendar id, ensure to return it url encoded
    return encodeURIComponent(source);
  }
}
