<div class="form-group mb-0">
  <div class="flex-row">
    <div class="row-entry">
      <label class="control-label distribution-label pull-left mb-0 u_margin-right">Distribution:</label>

      <mat-checkbox [shown]="!readonly" class="mr-1" id="allDisplaysCheckbox" (change)="outputDistributeToAll()" [(ngModel)]="distributeToAll" aria-labelledby="distributeToAllLabel"></mat-checkbox>
      @if (!readonly || distributeToAll) {
        <span id="distributeToAllLabel" class="mr-3 u_clickable" (click)="distributeToAll = !distributeToAll; outputDistributeToAll()" (keyup.enter)="distributeToAll = !distributeToAll; outputDistributeToAll()">Show on all displays.</span>
      } @else if (readonly && !distributeToAll) {
        <span id="distributeToAllLabel">{{ distributionSelectionMessage }}</span>
      }
    </div>
  </div>
  <span id="distributionFieldMadero" class="madero-link u_clickable" timeline="timeline" (click)="manage()" [shown]="!readonly && !distributeToAll">

    <span id="distributionFieldTextMadero" tabindex="0" (keyup.enter)="manage()">{{ distributionSelectionMessage }}</span>
  </span>
</div>
