import { Injectable } from '@angular/core';
import { pick } from 'lodash';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { CoreAPILoader } from 'src/app/ajs-upgraded-providers';
import type { CompanyLicenseInfo } from '../../common-header/services/company-license-info';

@Injectable({
  providedIn: 'root'
})
export class CompanyApiService {

  static readonly COMPANY_WRITABLE_FIELDS = [
    'name', 'street', 'unit', 'city', 'province', 'country',
    'postalCode', 'timeZoneOffset', 'telephone', 'fax', 'companyStatus',
    'mailSyncEnabled', 'shipToUseCompanyAddress',
    'shipToName', 'shipToStreet', 'shipToUnit', 'shipToCity',
    'shipToProvince', 'shipToPostalCode', 'shipToCountry', 'website',
    'companySize', 'companyIndustry', 'billingContactEmails', 'shareCompanyPlan', 'settings',
    'unlockAllFeatures'
  ];

  static readonly ALERTS_WRITABLE_FIELDS = [
    'alertSettings'
  ];

  constructor(private coreAPILoader: CoreAPILoader)
  { }

  updateAlerts (companyId, company) {
    var fields = pick.apply(this, [company].concat(
      CompanyApiService.ALERTS_WRITABLE_FIELDS));
    var obj = {
      'id': companyId,
      'data': fields
    };
    console.debug('updateAlerts called', companyId, fields);

    return this.coreAPILoader().then((coreApi) => {
        return coreApi.company.patch(obj);
      })
      .then((resp) => {
        console.debug('update Alerts resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to update Alerts.', e);
        return Promise.reject(e);
      });
  }

  getCompanies (search, cursor?) {
    var obj = {
      'companyId': search.companyId,
      'search': search.query || '*',
      'filter': search.filter || '',
      'includeSubcompanies': search.includeSubcompanies || false,
      'cursor': cursor,
      'count': search.count,
      'sort': search.sortBy + (search.reverse ? ' desc' : ' asc')
    };

    console.debug('getCompanies called with', obj);
    return this.coreAPILoader().then((coreApi) => {
      return coreApi.company.list(obj);
    })
    .then((resp) => {
      console.debug('getCompanies resp', resp);
      return resp.result;
    });
  }

  getCompany (id?) {
    console.debug('getCompany called', id);

    return this.coreAPILoader().then((coreApi) => {
      var obj: any = {};
      if (id) {
        obj.id = id;
      }
      return coreApi.company.get(obj);
    })
    .then((resp) => {
      console.debug('getCompany resp', resp);
      // return the company from the response
      return resp.result.item;
    })
    .catch((e) => {
      console.error('Failed to get Company.', e);
      return Promise.reject(e);
    });
  }

  createCompany (parentCompanyId, company) {
    return this.coreAPILoader().then((coreApi) => {
      var fields = pick.apply(this, [company].concat(
        CompanyApiService.COMPANY_WRITABLE_FIELDS));
      return coreApi.company.add({
        parentId: parentCompanyId,
        data: fields
      });
    })
    .then((resp) => {
      console.debug('createCompany resp', resp);
      // return the company from the response
      return resp.result.item;
    })
    .catch((e) => {
      console.error('Failed to create Company.', e);
      return Promise.reject(e);
    });
  }

  updateCompany (companyId, fields) {
    fields = pick.apply(this, [fields].concat(CompanyApiService.COMPANY_WRITABLE_FIELDS));
    console.debug('updateCompany called', companyId, fields);
    // fields.validate = validationRequired || false;
    return this.coreAPILoader().then((coreApi) => {
      return coreApi.company.patch({
        id: companyId,
        data: fields
      });
    })
    .then((resp) => {
      console.debug('updateCompany resp', resp);
      // return the company from the response
      return resp.result.item;
    })
    .catch((e) => {
      console.error('Failed to update Company.', e);
      return Promise.reject(e);
    });
  }

  deleteCompany (id) {
    console.debug('deleteCompany called', id);

    return this.coreAPILoader().then((coreApi) => {
      var obj: any = {};
      if (id) {
        obj.id = id;
      }
      return coreApi.company.delete(obj);
    })
    .then((resp) => {
      console.debug('deleteCompany resp', resp);
      // return the company from the response
      return resp.result.item;
    })
    .catch((e) => {
      console.error('Failed to delete Company.', e);
      return Promise.reject(e);
    });
  }

  lookupCompany (authKey) {
    console.debug('lookupCompany called', authKey);

    return this.coreAPILoader().then((coreApi) => {
      return coreApi.company.lookup({
        authKey: authKey
      });
    })
    .then((resp) => {
      console.debug('lookupCompany resp', resp);
      // return the company from the response
      return resp.result.item;
    })
    .catch((e) => {
      console.error('Failed to lookup Company.', e);
      return Promise.reject(e);
    });
  }

  moveCompany (authKey, newParentId) { //get a company either by id or authKey
    return this.coreAPILoader().then((coreApi) => {
      return coreApi.company.move({
        authKey: authKey,
        newParentId: newParentId
      });
    })
    .then((resp) => {
      console.debug('moveCompany resp', resp);
      return resp.result;
    })
    .catch((e) => {
      console.error('Failed to move Company.', e);
      return Promise.reject(e);
    });
  }

  exportCompany (companyId) {
    console.debug('export called with', companyId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.company.export({
          'companyId': companyId
        });
      })
      .then((resp) => {
        console.debug('export resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to export companies.', e);
        return Promise.reject(e);
      });
  }

  regenerateField (companyId, fieldName) {
    console.debug('regenerateField called', companyId, fieldName);
    return this.coreAPILoader().then((coreApi) => {
      return coreApi.company.regenerateField({
        'id': companyId,
        'fieldName': fieldName
      });
    })
    .then((resp) => {
      console.debug('regenerateField resp', resp);
      return resp.result;
    })
    .catch((e) => {
      console.error('Failed to regenerate company field.', e);
      return Promise.reject(e);
    });
  }

  enableCompanyProduct (companyId, licenseType, displayStatusMap) {
    console.debug('enableCompanyProduct called', companyId, licenseType, displayStatusMap);

    return this.coreAPILoader().then((coreApi) => {
      return coreApi.company.enableProduct({
        id: companyId,
        licenseType: licenseType,
        data: displayStatusMap
      });
    })
    .then((resp) => {
      console.debug('enableCompanyProduct resp', resp);
      return resp.result;
    })
    .catch((e) => {
      console.error('Failed to enableCompanyProduct.', e);
      throw e;
    });
  }

  getLicenseAllocationInfo (companyId: string): Promise<{ item: { licenseInfo: CompanyLicenseInfo } }> {
    const obj = {
      'id': companyId
    };

    console.debug('getLicenseAllocationInfo called with', obj);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.company.getLicenseAllocationInfo(obj);
      })
      .then((resp) => {
        console.debug('getLicenseAllocationInfo resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to getLicenseAllocationInfo.', e);
        throw e;
      });
  }

  allocateLicenses (companyId: string, companyLicenses: { [key: string]: any }, licenseAllocationEnabled?: boolean): Promise<any> {
    const allocations = {};
    for (const [key, value] of Object.entries(companyLicenses)) {
      allocations[key] = {
        allocatedProductCode: value.allocatedProductCode,
        allocatedLicenseCount: value.allocatedLicenseCount
      };
    }

    const obj = {
      id: companyId,
      licenseAllocationEnabled,
      data: {
        allocationData: JSON.stringify({
          data: allocations
        })
      }
    };

    console.debug('allocateLicenses called with', obj);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.company.allocateLicenses(obj);
      })
      .then((resp) => {
        console.debug('allocateLicenses resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to allocateLicenses.', e);
        throw e;
      });
  }

}

angular.module('risevision.apps.services')
  .factory('companyService', downgradeInjectable(CompanyApiService));