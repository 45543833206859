import { Injectable } from '@angular/core';
import * as angular from 'angular';
import * as _ from 'lodash';
import { BlueprintService } from './blueprint.service';
import { TemplateEditorService } from './template-editor.service';
import { cloneDeep } from 'lodash';

const CUSTOMIZATION = 'customization';

@Injectable({
  providedIn: 'root'
})
export class AttributeDataService {

  constructor(private blueprintService: BlueprintService, private templateEditorService: TemplateEditorService) {}

  getBlueprintData(componentId, attributeKey?) {
    return this.blueprintService.getBlueprintData(componentId, attributeKey);
  }

  getAttributeData(componentId, attributeKey?) {
    if (!componentId) {
      return null;
    }

    var component = this._componentFor(componentId, false);

    if (component.element && component.element.attributes) {
      return attributeKey ? component.element.attributes[attributeKey] : component.element.attributes;
    } else {
      // if the attributeKey is not provided, it returns the full component structure
      return attributeKey ? component[attributeKey] : component;
    }
  }

  getAvailableAttributeData(componentId, attributeName) {
    var result = this.getAttributeData(componentId, attributeName);

    if (angular.isUndefined(result)) {
      result = this.getBlueprintData(componentId, attributeName);
    }

    return result;
  }

  setAttributeData(componentId, attributeKey, value) {
    if (!componentId) {
      return null;
    }

    var component = this._componentFor(componentId, true);

    if (component.element) {
      if (!component.element.attributes) {
        component.element.attributes = {};
      }

      component.element.attributes[attributeKey] = value;
    } else {
      component[attributeKey] = value;
    }
  }

  getAttributeDataGlobal(attributeKey) {
    return this.templateEditorService.presentation.templateAttributeData[attributeKey];
  }

  setAttributeDataGlobal(attributeKey, value) {
    this.templateEditorService.presentation.templateAttributeData[attributeKey] = value;
  }

  getCustomization(componentId, attributeKey) {
    const customization = this.getAttributeData(componentId, CUSTOMIZATION) || {};
    return customization[attributeKey];
  }

  setCustomization(componentId, attributeKey, value) {
    const customization = this.getAttributeData(componentId, CUSTOMIZATION) || {};
    customization[attributeKey] = value;
    this.setAttributeData(componentId, CUSTOMIZATION, customization);
  }

  getUserComponents() {
    var attributeData = this.templateEditorService.presentation.templateAttributeData;
    var userComponents = [];

    if (attributeData.components && attributeData.components.length) {
      userComponents = attributeData.components.filter(comp => comp.userComponent);
    }

    return userComponents;
  }

  addUserComponent(addedComponent) {
    if (!addedComponent.id || !addedComponent.userComponent) {
      return null;
    }

    var attributeData = this.templateEditorService.presentation.templateAttributeData;
    var component;

    if (attributeData.components) {
      component = _.find(attributeData.components, {
        id: addedComponent.id
      });

      // check the component doesn't already exist
      if (!component) {
        // ensure component is added to beginning/top of list
        attributeData.components.unshift(addedComponent);
      }
    } else {
      attributeData.components = [addedComponent];
    }
  }

  removeUserComponent(id) {
    if (!id) {
      return null;
    }

    var attributeData = this.templateEditorService.presentation.templateAttributeData;

    if (attributeData.components) {
      attributeData.components = attributeData.components.filter(comp => comp.id !== id);
    }
  }

  reorderUserComponents(ordered) {
    if (!ordered || !Array.isArray(ordered)) {
      return null;
    }

    const userComponents = this.getUserComponents();
    const reordered = cloneDeep(userComponents);
    const attributeData = this.templateEditorService.presentation.templateAttributeData;

    // clear all user components currently saved
    userComponents.forEach((userComponent) => this.removeUserComponent(userComponent.id));

    reordered.sort((a, b) => {
      return ordered.indexOf(a.id) - ordered.indexOf(b.id);
    });

    // re-add all user components in new order
    reordered.forEach((component) => attributeData.components.push(component));
  }

  getComponent(componentId: string) {
    let component = this.blueprintService.componentFor(componentId);

    if (!component) {
      component = this._componentFor(componentId, null);
    }

    return component;
  }

  // updateAttributeData: do not update the object on getAttributeData
  // or it will unnecessarily trigger hasUnsavedChanges = true
  _componentFor(componentId, updateAttributeData) {
    var attributeData = this.templateEditorService.presentation.templateAttributeData;
    var component;

    if (componentId.indexOf(' ') !== -1) {
      var tokens = componentId.split(' ');
      var playlistId = tokens[0];
      var playlist = this._componentFor(playlistId, updateAttributeData);

      if (playlist.items) {
        return playlist.items[tokens[1]];
      }
    } else if (attributeData.components) {
      component = _.find(attributeData.components, {
        id: componentId
      });
    } else if (updateAttributeData) {
      attributeData.components = [];
    }

    if (!component) {
      component = {
        id: componentId
      };

      var blueprintComponent = this.blueprintService.componentFor(componentId);

      // Retrieve playlist items from the blueprint
      if (blueprintComponent && blueprintComponent.type === 'rise-playlist') {
        component.items = this.getBlueprintData(componentId, 'items');
      }

      if (updateAttributeData) {
        attributeData.components.push(component);
      }
    }

    return component;
  }

  getComponentIds(filter?): string[] {
    if (!this.blueprintService.blueprintData) {
      return [];
    }
    const components = this.blueprintService.blueprintData.components;

    const filteredComponents = _.filter(components, filter);

    return _.map(filteredComponents, (component: any) => {
      return component.id;
    });
  }
}
