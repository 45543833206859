import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrimusService {

  create(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const primus = new window.Primus(environment.MESSAGING_PRIMUS_URL, {
          reconnect: {
            retries: 0
          }
        });
        resolve(primus);
      } catch (error) {
        reject(error);
      }
    });
  }
}
