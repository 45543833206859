import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'storageFileType'
})
export class StorageFileTypePipe implements PipeTransform {

  transform(filename: string): string {
    if (filename.endsWith('/')) {
      return 'Folder';
    }

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(filename)[1];

    if (ext && ext.length <= 4) {
      return ext.toUpperCase();
    }

    return '';
  }

}
