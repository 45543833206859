import { Component, Input, EventEmitter, Output, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as angular from 'angular';
import * as _ from 'lodash';
import { ModalService } from '../../modals/modal.service';
import { DistributionModalComponent } from '../distribution-modal/distribution-modal.component';

@Component({
  selector: 'distribution-selector',
  templateUrl: './distribution-selector.component.html',
  styleUrls: ['./distribution-selector.component.scss']
})
export class DistributionSelectorComponent implements OnChanges {

  @Input() distribution: any;
  @Output() distributionChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() subcompanyDistribution: any;
  @Output() subcompanyDistributionChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() distributeToSubcompanies: boolean;
  @Output() distributeToSubcompaniesChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() enableSubcompanies: boolean = false;
  @Input() distributeToAll?: boolean = true;
  @Output() distributeToAllChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() hideCta?: boolean;
  @Input() readonly?: boolean;

  get distributionSelectionMessage(): string {
    let message = 'No Displays Selected';

    let count = this.distribution ? this.distribution.length : 0;
    if (this.distributeToSubcompanies) {
      count += this.subcompanyDistribution ? this.subcompanyDistribution.length : 0;
    }

    if (count > 0) {
      if (count === 1) {
        message = '1 Display Selected';
      } else {
        message = count + ' Displays Selected';
      }
    }
    return message;
  }

  constructor(private modalService: ModalService,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.distributeToAll && changes.distributeToAll.currentValue === undefined) {
      this.distributeToAll = true;
    }
  }

  manage() {
    if (this.readonly) {
      return;
    }
    this.modalService.showModal(DistributionModalComponent, {
      initialState: {
        distribution: this.distribution ? _.cloneDeep(this.distribution) : [],
        subcompanyDistribution: this.subcompanyDistribution ? _.cloneDeep(this.subcompanyDistribution) : [],
        distributeToSubcompanies: this.distributeToSubcompanies,
        enableSubcompanies: this.enableSubcompanies,
        returnFocusId: 'distributionFieldTextMadero'
      },
      class: 'madero-style modal-lg'
    }).then((distribution: any) => {
      this.distribution = distribution.distribution;
      this.subcompanyDistribution = distribution.subcompanyDistribution;
      this.distributeToSubcompanies = distribution.distributeToSubcompanies;
      this.distributionChange.emit(distribution.distribution);
      this.subcompanyDistributionChange.emit(distribution.subcompanyDistribution);
      this.distributeToSubcompaniesChange.emit(distribution.distributeToSubcompanies);
      this.changeDetectorRef.detectChanges();
    }).catch(() => { });
  }

  outputDistributeToAll() {
    if (this.distributeToAll) {
      this.distributeToSubcompanies = false;
      this.distributeToSubcompaniesChange.emit(false);
    }

    this.distributeToAllChange.emit(this.distributeToAll);
    this.changeDetectorRef.detectChanges();
  }
}

angular.module('risevision.common.components')
  .directive(
    'distributionSelector',
    downgradeComponent({
      component: DistributionSelectorComponent
    }) as angular.IDirectiveFactory
  );
