<div>
  <div class="app-header u_padding-20-vertical mb-5 border-bottom">
    <!-- App Title -->
    <h1 class="app-header-title">Add Payment Method</h1>
  </div>

  <div rvSpinner="payment-source-loader" [rvShowSpinner]="subscriptionService.loading || addPaymentSourceService.loading"></div>

  <div id="errorBox" [shown]="subscriptionService.apiError" class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
    <strong>{{subscriptionService.apiError}}</strong>
  </div>

  <div id="errorBox" [shown]="addPaymentSourceService.apiError" class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
    <strong>{{addPaymentSourceService.apiError}}</strong>
    <br/>If you need help please email us at support&#64;risevision.com.
  </div>

  <div class="row">
    <div class="col-md-8 col-md-offset-2">
      <h4 class="u_margin-md-bottom">Payment Method</h4>

      <div class="border-container p-4">
        <form #paymentMethodsForm="ngForm" id="forms.paymentMethodsForm" role="form" name="forms.paymentMethodsForm" novalidate>
          <payment-methods-form [showInvoiceOption]="!subscriptionService.loading && subscriptionService.getItemSubscription().billing_period_unit !== 'month' && !subscriptionService.isInvoiced()" [contactEmail]="contactEmail"></payment-methods-form>
        </form>
      </div>

      <div class="row u_margin-md-top mb-5">
        <div class="col-md-4 mb-2">
          <a id="backButton" type="button" aria-label="Go back" class="btn btn-default btn-hg btn-block" uiSref="apps.billing.subscription" [uiParams]="({subscriptionId: subscriptionService.getItemSubscription().id})">Cancel</a>
        </div>
        <div class="col-md-8">
          <button id="addButton" type="submit" class="btn btn-primary btn-hg btn-block" form="forms.paymentMethodsForm" (click)="addPaymentMethod(subscriptionService.getItemSubscription().id)" tabindex="1" aria-label="Add Payment Method">
            Add Payment Method
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-5 border-top">
    <purchase-footer></purchase-footer>
  </div>

</div><!--container-->
