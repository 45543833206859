import { Injectable } from '@angular/core';
import { HtmlParserService } from './html-parser.service';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
  providedIn: 'root'
})
export class DistributionParserService {

  constructor(private htmlParser: HtmlParserService) { }

  private _parseDistribution(placeholders, distributionList) {
    var j, placeholder;

    for (var i = 0; i < distributionList.length; i++) {
      var id = distributionList[i].itemId;

      if (id) {
        var tokens = id.split('#');
        var displaysList = distributionList[i].displayIds;

        if (tokens.length > 0) {
          var placeholderId = tokens[0];

          for (j = 0; j < placeholders.length; j++) {
            placeholder = placeholders[j];

            if (placeholder.id === placeholderId) {
              if (tokens.length === 1) {
                placeholder.distribution = displaysList;
                placeholder.distributeToAll = false;
              } else {
                var itemNumber = this.htmlParser.getIntValue(tokens[1]);
                if (itemNumber !== -1 &&
                  itemNumber < placeholder.items.length) {
                  placeholder.items[itemNumber].distribution =
                    displaysList;
                  placeholder.items[itemNumber].distributeToAll = false;
                }
              }
              break;
            }
          }
        }
      }
    }
  }
  
  parseDistribution(presentation) {
    if (presentation.placeholders && presentation.distribution) {
      this._parseDistribution(presentation.placeholders,
        presentation.distribution);
    }
  }

  updateDistribution(presentation) {
    var j, placeholder;
    presentation.distribution = [];
    if (presentation.placeholders) {
      for (var i = 0; i < presentation.placeholders.length; i++) {
        placeholder = presentation.placeholders[i];
        if (placeholder.distributeToAll === false) {
          presentation.distribution.push({
            itemId: placeholder.id,
            displayIds: placeholder.distribution || []
          });
        }

        if (placeholder.items) {
          for (j = 0; j < placeholder.items.length; j++) {
            var id = placeholder.id + '#' + j;
            if (placeholder.items[j].distributeToAll === false) {
              presentation.distribution.push({
                itemId: id,
                displayIds: placeholder.items[j].distribution || []
              });
            }
          }
        }
      }
    }
  }
}

angular.module('risevision.editor.services')
  .factory('distributionParser', downgradeInjectable(DistributionParserService));