import { Injectable } from '@angular/core';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CurrentPlanService } from 'src/app/components/plans/current-plan.service';
import { PlansService } from 'src/app/components/plans/plans.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyAccessService {

  constructor(
    private currentPlanService: CurrentPlanService,
    private plansService: PlansService,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService
  ) { }

  get isEnterpriseOrUnlimited(): boolean {
    const plan = this.currentPlanService.currentPlan;
    return this.plansService.isUnlimitedPlan(plan) || this.plansService.isEnterprisePlan(plan);
  }

  get accessCompanyLicenses(): boolean {
    const company = this.companyStateService.getCopyOfSelectedCompany();

    return (this.userStateService.hasRole('sa') || this.userStateService.hasRole('ua')) &&
      this.currentPlanService.isPlanActive() &&
      (this.isEnterpriseOrUnlimited || this.currentPlanService.isOnTrial()) &&
      !this.currentPlanService.isParentPlan() &&
      (!company.allocatedFromCompanyId || company.allocatedFromCompanyId === company.id);
  }
}
