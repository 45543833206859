<form #bulkEditForm="ngForm" id="bulkEditForm" name="bulkEditForm" class="bulk-edit-modal" (submit)="save()">
  <div class="modal-header">
    <button type="button" class="close" (click)="dismiss()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
  </div>

  <div class="modal-body mb-2">
    <h4 class="mb-4">{{title}}</h4>
    <div class="align-left">
      <div #target></div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="row">
      <div class="col-xs-6">
        <button type="button" class="btn btn-default w-100" (click)="cancel()">
          Cancel
        </button>
      </div>
      <div class="col-xs-6">
        <button type="submit" id="saveBulkEditForm" class="btn btn-primary w-100" [disabled]="bulkEditForm.invalid">
          Save
        </button>
      </div>
    </div>
  </div>
</form>
