<div class="form-group" [ngClass]="{ 'has-error' : (control?.control.invalid && control?.control.dirty) }">
  <label class="control-label" for="password">
    {{ label || 'Password' }}
  </label>
  <input [formControl]="control.control"
    id="password"
    type="password"
    class="form-control"
    autocomplete="off"
    placeholder="{{ placeholder }}">
  @if (control?.control.invalid && control?.control.dirty) {
    <div role="alert">
      <p class="text-danger" [shown]="control?.control.errors?.required">Oops, don't leave this blank.</p>
      <p class="text-danger" [shown]="control?.control.errors?.minlength">Password needs to be {{ minlength }} or more characters in length.</p>
      <p class="text-danger" [shown]="control?.control.errors?.currentPasswordNotValid">Current Password is not valid.</p>
      <p class="text-danger" [shown]="control?.control.errors?.weakPassword">{{ control?.control.errors?.weakPassword }}</p>
    </div>
  }
  @if (showPasswordMeter && scorePercentage && control?.control.dirty) {
    <div id="passwordMeter" class="has-error">
      <p id="passwordFeedback" class="text-warning" [shown]="!control?.control.errors">{{feedback}}</p>
      <p>Strength: <span id="strengthText" class="text-{{strengthClass}}">{{strength}}</span></p>
      <progressbar [max]="100" [value]="scorePercentage" [type]="strengthClass"></progressbar>
    </div>
  }
</div>
