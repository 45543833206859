import { Injectable } from '@angular/core';

import * as angular from 'angular';

import { DocumentService } from './document.service';

@Injectable({
  providedIn: 'root'
})
export class XhrService {
  get(): any {
    return new XMLHttpRequest();
  }
}

@Injectable({
  providedIn: 'root'
})
export class XhrUploaderService {

  constructor(
    private documentService: DocumentService,
    private xhrService: XhrService
  ) { }

  isSuccessCode(status) {
    return (status >= 200 && status < 300) || status === 304;
  }

  xhrTransport(item, FileUploader, detectChanges) {
    var xhr = item.xhr = this.xhrService.get();
    var form = new FormData();
    var convertible = this.documentService.isConvertibleFile(item.file.name);

    FileUploader.notifyBeforeUploadItem(item);

    angular.forEach(item.formData, obj => {
      angular.forEach(obj, (value, key: string) => {
        form.append(key, value);
      });
    });

    form.append(item.alias, item.domFileItem, item.file.name);

    xhr.upload.onprogress = (event) => {
      var previousChunkBytes, progress;

      if (event.lengthComputable) {
        previousChunkBytes = item.chunkSize * (item.currentChunk - 1);
        progress = (event.loaded + previousChunkBytes) / item.file.size;
        progress = Math.round(progress * (convertible ? 33 : 100));
      } else {
        progress = 0;
      }

      FileUploader.notifyProgressItem(item, progress);
      if (detectChanges) {
        detectChanges();
      }
    };

    xhr.onload = () => {
      var gist = this.isSuccessCode(xhr.status) ? 'Success' : 'Error';
      var method = 'notify' + gist + 'Item';

      if (xhr.status === 308) {
        var range: any = xhr.getResponseHeader('Range');

        if (range) {
          range = parseInt(range.split('-')[1], 10) + 1;

          if (!isNaN(range)) {
            xhr.sendChunk(range);
          } else {
            console.log('Resumable upload - Failed to parse Range header', item);

            xhr.onerror(null);
          }
        } else {
          console.log('Resumable upload - Range header not present, restarting', item);

          item.progress = 0;

          xhr.sendChunk(0);
        }
      } else if (xhr.status === 503) {
        xhr.requestNextStartByte();
      } else {
        if (this.documentService.isConvertibleFile(item.file.name)) {
          var progressTimeout;
          var remainProgress = 33;
          var fakeProgress = (delay) => {
            progressTimeout = setTimeout(() => {
              remainProgress = Math.round(remainProgress + (100 - remainProgress) / 3);
              FileUploader.notifyProgressItem(item, remainProgress);
              fakeProgress(5000);
            }, delay);
          };
          fakeProgress(0);

          this.documentService.requestAssets(item.file.name).then(() => {
            clearTimeout(progressTimeout);
            FileUploader.notifyProgressItem(item, 100);
            FileUploader[method](item, xhr.status);
            FileUploader.notifyCompleteItem(item);

          }).catch(() => {
            FileUploader.notifyErrorItem(item, 408);
            FileUploader.notifyCompleteItem(item);
          });
        } else {
          FileUploader[method](item, xhr.status);
          FileUploader.notifyCompleteItem(item);
        }
      }
    };

    xhr.onerror = (ev) => {
      FileUploader.notifyErrorItem(item, xhr.status);
      FileUploader.notifyCompleteItem(item);
    };

    xhr.onabort = () => {
      FileUploader.notifyCancelItem(item);
      FileUploader.notifyCompleteItem(item);
    };

    xhr.requestNextStartByte = () => {
      xhr.open(item.method, item.url, true);
      xhr.withCredentials = item.withCredentials;
      xhr.setRequestHeader('Content-Range', 'bytes */' + item.file.size);
      xhr.send();
    };

    xhr.sendChunk = (startByte) => {
      var endByte = startByte + item.chunkSize - 1;
      var length = item.file.size;
      var range = 'bytes ';

      if (length === 0) {
        range += '*';
      } else {
        range += startByte + '-' + Math.min(endByte, length - 1);
      }
      range += '/' + item.file.size;

      item.currentChunk = item.currentChunk ? item.currentChunk + 1 : 1;

      xhr.open(item.method, item.url, true);
      xhr.withCredentials = item.withCredentials;

      angular.forEach(item.headers, (value, name: string) => {
        xhr.setRequestHeader(name, value);
      });

      xhr.setRequestHeader('Content-Range', range);
      xhr.send(item.domFileItem.slice(startByte, startByte + item.chunkSize));
    };

    xhr.sendChunk(0);
  }
}
