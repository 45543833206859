import { Injectable } from '@angular/core';
import { ArtboardService } from './artboard.service';
import { PlaceholderService } from './placeholder.service';
import { EditorService } from './editor.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { cloneDeep } from 'lodash';
import { PresentationParserService } from './presentation-parser.service';

@Injectable({
 providedIn: 'root'
})
export class PlaceholdersService {

  constructor (
    private editorService: EditorService,
    private artboardService: ArtboardService,
    private trackerService: TrackerService,
    private presentationParserService: PresentationParserService,
    private placeholderService: PlaceholderService
  ) { }

  getPlaceholders () {
    return this.editorService.presentation.placeholders ?
      this.editorService.presentation.placeholders :
      this.editorService.presentation.placeholders = [];
  }

  private _newPlaceholder () {
    return {
      type: 'playlist',
      width: 400,
      widthUnits: 'px',
      height: 200,
      heightUnits: 'px',
      top: 0,
      topUnits: 'px',
      left: 0,
      leftUnits: 'px',
      visibility: true,
      transition: 'none',
      timeDefined: false,
      distributeToAll: true
    };
  }

  private _getCenterPosition (widgetSize, artboardSize,
    totalPixelSize, scroll) {
    var center = (artboardSize / 2) + scroll;
    var position = center - (widgetSize / 2);

    if (position < 0) {
      position = 0;
    } else if (position + widgetSize > totalPixelSize) {
      position = totalPixelSize - widgetSize;
    }

    return parseInt(position.toString(), 10);
  }

  private _offsetPlaceholder (placeholder) {
    var placeholders = this.getPlaceholders();

    for (var i = 0; i < placeholders.length; i++) {
      if (placeholders[i].top === placeholder.top && placeholders[i].left ===
        placeholder.left) {
        var cornersFound = 0;

        var rightPadding = this.editorService.presentation.width -
          (placeholder.left + placeholder.width);
        var bottomPadding = this.editorService.presentation.height -
          (placeholder.top + placeholder.height);
        if (rightPadding > 0) {
          placeholder.left += Math.min(20, rightPadding);
        } else {
          cornersFound++;
        }

        if (bottomPadding > 0) {
          placeholder.top += Math.min(20, bottomPadding);
        } else {
          cornersFound++;
        }

        if (cornersFound < 2) {
          this._offsetPlaceholder(placeholder);
        }

        break;
      }
    }
  }

  private _centerPlaceholder (placeholder) {
    if (!this.artboardService.getWorkspaceElement) {
      return;
    }

    var leftPadding = 12;
    var topPadding = 60 + 12;
    var workspaceElement = this.artboardService.getWorkspaceElement();
    var width = this.editorService.presentation.width;
    var height = this.editorService.presentation.height;
    if (placeholder.width > width) {
      placeholder.width = width;
      placeholder.left = 0;
    } else {
      var totalWidth = Math.min(width, workspaceElement.clientWidth -
        leftPadding);
      var scrollLeft = workspaceElement.scrollLeft;

      placeholder.left = this._getCenterPosition(placeholder.width,
        totalWidth, width, scrollLeft);
    }

    if (placeholder.height > height) {
      placeholder.height = height;
      placeholder.top = 0;
    } else {
      var totalHeight = Math.min(height, workspaceElement.clientHeight -
        topPadding);
      var scrollTop = workspaceElement.scrollTop;

      placeholder.top = this._getCenterPosition(placeholder.height,
        totalHeight, height, scrollTop);
    }

    this._offsetPlaceholder(placeholder);
  }

  addNewPlaceholder (placeholder?) {
    this.trackerService.presentationEvent('Add Placeholder', this.editorService.presentation.id,
      this.editorService.presentation.name);
    placeholder = placeholder || this._newPlaceholder();

    this._centerPlaceholder(placeholder);

    this.getPlaceholders().push(placeholder);

    placeholder.zIndex = this.getPlaceholders().length - 1;

    // Update Presentation - adds Placeholder to HTML
    // & assigns Id to placeholder
    this.presentationParserService.updatePresentation(this.editorService.presentation);
    this.placeholderService.setPlaceholder(placeholder);
  }

  private _getItemIndex (placeholder) {
    return this.getPlaceholders() ?
      this.getPlaceholders().indexOf(placeholder) : -1;
  }

  isNew (placeholder) {
    return this._getItemIndex(placeholder) === -1;
  }

  updatePlaceholder (placeholder) {
    if (this.isNew(placeholder)) {
      this.getPlaceholders().push(placeholder);
    }
  }

  removePlaceholder (placeholder) {
    var index = this._getItemIndex(placeholder);
    if (index !== -1) {
      placeholder.deleted = true;

      // Update Presentation - removes placeholder form HTML
      this.presentationParserService.updatePresentation(this.editorService.presentation);
    }
  }

  duplicatePlaceholder (placeholder) {
    var index = this._getItemIndex(placeholder);
    if (index !== -1) {
      var newPlaceholder = cloneDeep(placeholder);
      newPlaceholder.id = undefined;

      this.addNewPlaceholder(newPlaceholder);
    }
  }

}
