<div class="u_margin-sm-top">
  <h4>Please Verify Your Email</h4>
  <p>One step away, check your email & click the link to activate your account.</p>

  <div class="panel-body bg-danger u_margin-sm-top" *ngIf="errorMessage">
    <p class="u_remove-bottom">
      <i aria-hidden="true" class="fa fa-warning icon-left"></i>
      <span>{{errorMessage}}</span>
    </p>
  </div>

  <div class="my-4 flex-row">
    <div class="border-bottom w-100"></div>
  </div>

  <div>
    <b>{{username}}</b>
  </div>

  <div class="flex-row justify-center w-100 mt-5 mb-5">
    <img class="img-responsive" src="../images/email-resend.svg">
  </div>

  <button class="btn btn-primary w-100 mb-3" (click)="requestConfirmationEmail()" [disabled]="emailSent || emailSending">
    {{ emailSent ? 'Email Sent!' : 'Resend Email' }}
  </button>
  <button class="btn btn-default w-100" (click)="changeAccount()">
    Change Account
  </button>
</div>
