<ng-template #assignedToPopover><div [innerHtml]="assignedToUsers"></div></ng-template>
<weekly-templates></weekly-templates>
<main class="container madero-style">
  <div class="app-header u_margin-md-top mb-5">
    <!-- App Title -->
    <h1 class="app-header-title" id="title">Presentations</h1>
  </div>
  <div id="errorBox" [shown]="presentations.apiError" class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
    <p><strong>{{presentations.errorMessage}}</strong></p>
    {{presentations.apiError}}
  </div>
  @if (!(!presentations.loadingItems && presentations.items.list.length === 0 && search.query === undefined)) {
    <div>
      <div class="flex-row button-toolbar-md-folded">
        <search-filter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="presentations.doSearch" iconSet="madero"></search-filter>
        <div class="ml-md-3 button-toolbar flex-row" *requireRole="'cp ce ap'">
          <button id="presentationAddButton" (click)="editorService.addPresentationModal()" class="btn btn-primary btn-toolbar-wide">
            Add Presentation
          </button>
        </div>
      </div>
      <batch-operations [listObject]="presentations"></batch-operations>
      <div class="scrollable-list horizontal-scroll border-container u_margin-md-top u_margin-md-bottom"
        scrollingList (scrollEndEvent)="presentations.load()"
        rvSpinner="presentation-list-loader" [rvShowSpinner]="presentations.loadingItems || editorService.loadingPresentation || templateEditorFactory.loadingPresentation">
        <table id="presentationListTable" class="table">
          <thead class="table-header">
            <tr class="table-header__row u_clickable">
              <th class="table-header__cell col-sm-4">
                <div class="flex-row">
                  <mat-checkbox
                    (click)="presentations.selectAll()"
                    [checked]="search.selectAll"
                    [ariaLabel]="search.selectAll ? 'Deselect all' : 'Select all'"
                    class="mr-2"
                  />
                  <div class="u_clickable u_ellipsis-lg" id="tableHeaderName" (click)="presentations.sortBy('name')">
                    Name
                    @if (search.sortBy === 'name') {
                      <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
                    }
                  </div>
                </div>
              </th>
              <th id="tableHeaderSchedules" class="table-header__cell col-sm-2"  (click)="presentations.sortBy('scheduleIds')">
                Schedules
                @if (search.sortBy === 'scheduleIds') {
                  <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
                }
              </th>
              <th id="tableHeaderStatus" class="table-header__cell col-sm-2"  (click)="presentations.sortBy('revisionStatusName')">
                Status
                @if (search.sortBy === 'revisionStatusName') {
                  <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
                }
              </th>
              <th tabindex="0" id="tableHeaderChangeDate" class="table-header__cell col-sm-2" (click)="presentations.sortBy('changeDate')" (keyup.enter)="presentations.sortBy('changeDate')">
                Last Modified
                @if (search.sortBy === 'changeDate') {
                  <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
                }
              </th>
              @if (companyStateService.isK12Customer() || companyStateService.isHigherEdCustomer()) {
                <th id="tableHeaderAssignedTo" class="table-header__cell col-sm-2" (click)="presentations.sortBy('assignedUsersValue')">
                  Assigned To
                  @if (search.sortBy === 'assignedUsersValue') {
                    <i [attr.aria-label]="search.reverse ? 'Descending order' : 'Ascending order'" class="fa" [ngClass]="{false: 'fa-caret-up', true: 'fa-caret-down'}[search.reverse]"></i>
                  }
                </th>
              }
            </tr>
          </thead>
          <tbody class="table-body">
            @for (presentation of presentations.items.list; track presentation) {
              <tr class="table-body__row">
                <td class="table-body__cell">
                  <div class="flex-row">
                    <mat-checkbox
                      (click)="presentations.select(presentation)"
                      [checked]="presentation.selected"
                      [ariaLabel]="presentation.selected ? 'Deselect presentation' : 'Select presentation'"
                      class="mr-2"
                    />
                    @if (!isHtmlPresentation(presentation)) {
                      <a class="madero-link u_ellipsis-lg" uiSref="apps.editor.workspace.artboard" [uiParams]="{presentationId: presentation.id}"><strong>{{presentation.name}}</strong></a>
                    }
                    @else {
                      <a class="madero-link u_ellipsis-lg" uiSref="apps.editor.templates.edit" [uiParams]="{presentationId: presentation.id}"><strong>{{presentation.name}}</strong></a>
                    }
                  </div>
                </td>
                <td class="table-body__cell">{{!presentation.scheduleCount ? 'No Schedules' : (presentation.scheduleCount + (presentation.scheduleCount === 1 ? ' Schedule' : ' Schedules'))}}</td>
                <td class="table-body__cell"><span [ngClass]="{'text-danger': presentation.revisionStatusName==='Revised'}">{{presentation.revisionStatusName}}</span></td>
                <td class="table-body__cell">{{presentation.changeDate | date:'d-MMM-yyyy h:mm a'}} by {{presentation.changedBy | username}}</td>
                @if (companyStateService.isK12Customer() || companyStateService.isHigherEdCustomer()) {
                  <td class="table-body__cell">
                    <span class="u_ellipsis-md">
                      {{ presentation.assignedUsers && presentation.assignedUsers.length > 0 ? (presentation.assignedUsers[0] | username) : '' }}
                    </span>
                    @if (presentation.assignedUsers && presentation.assignedUsers.length > 1) {
                      <span class="badge badge-primary u_clickable ml-2"
                        [popover]="assignedToPopover"
                        (onShown)="popoverText(presentation.assignedUsers)"
                        containerClass="madero-style"
                        outsideClick="true"
                        placement="bottom right">
                        +{{ presentation.assignedUsers.length - 1 }}
                      </span>
                    }
                  </td>
                }
              </tr>
            }
            <!-- If no search results -->
            <tr class="table-body__row" [shown]="presentations.items.list.length === 0 && search.query !== undefined">
              <td colspan="3" class="text-center">Your search didn't return any results.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  }
  @if (!presentations.loadingItems && presentations.items.list.length === 0 && search.query === undefined) {
    <div class="border-container text-center u_margin-md-bottom">
      <div class="panel-body">
        <div class="form-group my-4">
          <label>Add a presentation to start communicating better with your audience!</label>
        </div>
        <button id="presentationAddButton" *requireRole="'cp ce ap'" class="btn btn-primary btn-toolbar-wide mb-2" (click)="editorService.addPresentationModal()">
          Add Presentation
        </button>
      </div>
    </div>
  }
</main>
