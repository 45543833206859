<div [shown]="companyStateService.isSubcompanySelected()"
  class="common-header-alert sub-company-alert hidden-xs">
  You're in Sub-Company {{companyStateService.getSelectedCompanyName()}}&nbsp;
  <a href="#" (click)="companyStateService.resetCompany()" class="link-button">Switch to My Company</a>
</div>
<div [shown]="companyStateService.isSubcompanySelected()"
  class="common-header-alert sub-company-alert visible-xs">
  <a href="#" (click)="companyStateService.resetCompany()" class="link-button">My Company</a>
  <span class="sub-company-name">Sub-Company {{companyStateService.getSelectedCompanyName()}}</span>
  <span>&nbsp;</span>
</div>
