<div class="attribute-editor-component" rvSpinner="web-page-editor-loader" [rvShowSpinner]="spinner">
  <div class="attribute-editor-row">
    <div class="form-group has-feedback" [ngClass]="{ 'has-error': validationResult && validationResult !== 'VALID', 'has-success': validationResult === 'VALID' && src !== '' }">
      <label class="control-label" for="te-web-page-src">Web Page URL:</label>
      <input type="text" id="te-web-page-src" name="te-web-page-src" class="form-control u_ellipsis" [(ngModel)]="src" placeholder="Enter URL" (debounceEvent)="srcChanged()">
      <streamline-icon class="overlay-icon form-control-feedback" aria-hidden="true" name="{{validationResult && validationResult !== 'VALID' ? 'exclamation' : 'checkmark'}}" width="14" height="12"></streamline-icon>
      <p class="help-block">
        @switch (validationResult) {
          @case ('INVALID_URL') {
            <span>Provide a valid and secure URL (https://).</span>
          }
          @case ('LOAD_FAILURE') {
            <span>The Web Page at the URL provided could not be loaded.</span>
          }
          @case ('FRAME_RESTRICTED') {
            <span>The owner of the Web Page at the URL provided does not allow the page to be embedded within an iFrame. If possible, please contact the Web Page owner to discuss the below restrictions:</span>
          }
        }
      </p>
      <ul [shown]="validationResult === 'FRAME_RESTRICTED'">
        <li><a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/HTTP/X-Frame-Options">X-Frame-Options</a></li>
        <li><a target="_blank" href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/frame-ancestors">Content-Security-Policy: frame-ancestors</a></li>
      </ul>
    </div>
  </div>
  <div class="attribute-editor-row">
    <div class="form-group">
      <label class="control-label" for="te-web-page-refresh-interval">Refresh Interval:</label>
      <select id="te-web-page-refresh-interval" [(ngModel)]="refresh" class="form-control" (change)="onAttributeChanged()">
        <option value="0" [hidden]="authentication">Never Refresh</option>
        <option value="1" [hidden]="authentication">1 min</option>
        <option value="5">5 min</option>
        <option value="30">30 min</option>
        <option value="60">60 min</option>
      </select>
    </div>
  </div>
  <div class="attribute-editor-row" [hidden]="true">
    <span class="flex-row">
      <madero-checkbox id="te-web-page-unload" name="te-web-page-unload" ngDefaultControl [(ngModel)]="unload" (ngModelChange)="onAttributeChanged()"></madero-checkbox>
      <label for="te-web-page-unload">Unload Web Page when not visible in Presentation (recommended)</label>
    </span>
  </div>
  <div class="attribute-editor-row border-bottom"[shown]="!authentication">
    <div class="form-group">
      <label class="control-label" for="te-web-page-zoom">Zoom:</label>
      <select id="te-web-page-zoom" [(ngModel)]="zoom" class="form-control" (change)="onAttributeChanged()">
        <option value="50">50%</option>
        <option value="75">75%</option>
        <option value="90">90%</option>
        <option value="100">100%</option>
        <option value="125">125%</option>
        <option value="150">150%</option>
        <option value="200">200%</option>
      </select>
    </div>
  </div>

  <div class="attribute-editor-row">
    <div class="form-group my-3">
      <span class="flex-row left-right-aligner">
        <label for="authentication-toggle-button" class="mb-0" [ngClass]="{ 'text-muted' : !featuresService.isFeatureAvailable('authenticated_web_pages')}">Allow Authentication</label>
        <upgrade-notice feature="authenticated_web_pages" class="ml-2 mt-0 u_lh-1" [hidden]="featuresService.isFeatureAvailable('authenticated_web_pages')"></upgrade-notice>
        <mat-slide-toggle class="mb-2" id="authentication-toggle" [checked]="authentication" (change)="toggleAuthentication()" [hidden]="!featuresService.isFeatureAvailable('authenticated_web_pages')"></mat-slide-toggle>
      </span>
    </div>
  </div>
  @if (authentication) {
    <form #webPageAuthenticationForm="ngForm" id="forms.webPageAuthenticationForm" role="form" name="forms.webPageAuthenticationForm" novalidate>
      @if (credentialReadOnly) {
        <div class="attribute-editor-row">
          <div class="form-group has-feedback my-3">
            <label class="control-label" for="te-username">User Name / Email:</label>
            {{usernameReadOnly}}
          </div>
        </div>
      } @else {
        <div class="attribute-editor-row">
          <div class="form-group has-feedback my-3" [ngClass]="{ 'has-error': teUsername.invalid && !teUsername.pristine }">
            <label class="control-label" for="te-username">User Name / Email: *</label>
            <input #teUsername="ngModel" type="text" id="te-username" name="teUsername" class="form-control u_ellipsis" [(ngModel)]="username" placeholder="Username" required>
          </div>
        </div>
        <div class="attribute-editor-row">
          <div class="form-group has-feedback my-3" [ngClass]="{ 'has-error': tePassword.invalid && !tePassword.pristine }">
            <label class="control-label" for="te-password">Password: *</label>
            <input #tePassword="ngModel" type="password" id="te-password" name="tePassword" class="form-control u_ellipsis" [(ngModel)]="password" placeholder="Password" required>
          </div>
        </div>
      }

      <div class="attribute-editor-row aligner">
        <div class="mr-auto"></div>
        @if (usernameReadOnly) {
          <div class="form-group has-feedback my-3 mr-3">
            <button type="button" id="te-reset-authentication" name="te-reset-authentication" class="btn btn-default" (click)="removeCredential()" [disabled]="spinner">Reset</button>
          </div>
        }
        @if (credentialReadOnly) {
          <div class="form-group has-feedback my-3">
            <button type="button" id="te-edit-authentication" name="te-edit-authentication" class="btn btn-primary" (click)="editCredential()">Update</button>
          </div>
        } @else {
          <div class="form-group has-feedback my-3">
            <button type="button" id="te-save-authentication" name="te-save-authentication" class="btn btn-primary" (click)="saveAuthentication()" [disabled]="spinner || webPageAuthenticationForm.invalid">Save</button>
          </div>
        }
      </div>
    </form>
  }

  <div class="attribute-editor-row border-top" [class.border-bottom]="!authentication && interactive && zoom <= 100">
    <div class="form-group my-3">
      <span class="flex-row left-right-aligner">
        <label for="interactive-toggle-button" class="mb-0" [ngClass]="{ 'text-muted' : authentication }">Allow Interaction</label>
        <mat-slide-toggle class="mb-2" id="interactive-toggle" [checked]="interactive" (change)="toggleInteractive()" [disabled]="authentication"></mat-slide-toggle>
      </span>
    </div>
  </div>
  <div class="attribute-editor-row" [shown]="!authentication && interactive && zoom <= 100">
    <div class="form-group my-3">
      <span class="flex-row left-right-aligner">
        <label for="scrollbars-toggle-button" class="mb-0">Scrollbars Enabled</label>
        <mat-slide-toggle class="mb-2" id="scrollbars-toggle" [checked]="scrollbars" (change)="toggleScrollbars()"></mat-slide-toggle>
      </span>
    </div>
  </div>
</div>
