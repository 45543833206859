@if (files.length === 0 && filesDetails?.code!==202 && filesDetails?.code!==404 && fileListVisible) {
  <div class="text-center text-muted u_margin-md-top u_margin-md-bottom">You haven't uploaded any files yet!</div>
}
@if (files.length > 0 && filesDetails?.code!==202 && filesDetails?.code!==404 && fileListVisible) {
  <div id="storageFilesGrid" class="grid-selector-list grid-selector-list_storage">
    @for (file of storageUtilsService.filterFiles(files, search, storageService.storageFull); track file) {
      <div
        (click)="triggerFileClick(file)"
        (keyup.enter)="triggerFileClick(file)"
        tabindex="0"
        class="grid-selector-list-item"
        container="body"
        containerClass="madero-style thumbnail-tooltip"
        [tooltipAnimation]="false"
        [adaptivePosition]="false"
        [delay]="100"
        placement="bottom"
        (onShown)="onTooltipShown()"
        tooltip="{{file.name | storageFileName:folderPath}}{{ file.isThrottled? ' - This file is being requested too many times and is blocked.' : '' }}">
        <thumbnail-image [file]="file" [folderPath]="folderPath"></thumbnail-image>
      </div>
    }
    <div class="grid-selector-list-item list-item-placeholder"></div>
    <div class="grid-selector-list-item list-item-placeholder"></div>
    <div class="grid-selector-list-item list-item-placeholder"></div>
    <div class="grid-selector-list-item list-item-placeholder"></div>
    <div class="grid-selector-list-item list-item-placeholder"></div>
    <div class="grid-selector-list-item list-item-placeholder"></div>
    <div class="grid-selector-list-item list-item-placeholder"></div>
    <div class="grid-selector-list-item list-item-placeholder"></div>
  </div>
}
