import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

import * as angular from 'angular';

@Injectable({
  providedIn: 'root'
})
export class ContactUtilsService {

  constructor() { }

  contactsAreIdentical(c1, c2) {
    return (
      c1.firstName === c2.firstName &&
      c1.lastName === c2.lastName &&
      c1.email === c2.email &&
      c1.telephone === c2.telephone);
  }

  copyContactObj(src, dest?) {
    if (!dest) {
      dest = {};
    }

    dest.username = src.username;
    dest.firstName = src.firstName;
    dest.lastName = src.lastName;
    dest.email = src.email;
    dest.telephone = src.telephone;

    return dest;
  }

}

angular.module('risevision.apps.services')
  .factory('contactUtils', downgradeInjectable(ContactUtilsService));
