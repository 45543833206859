import { Injectable } from '@angular/core';
import { TemplateApiService } from 'src/app/api/services/template-api.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

export type GroupedTemplates = {
  date: string,
  name: string,
  templates: any[]
};

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  static readonly K12_EDUCATION = 'K-12 Education';

  private _templates: any[] = [];
  private _templatesFromIds: any[] = [];
  private _sortedTemplates: GroupedTemplates[] = [];
  private _templateDates: string[] = [];
  private _upcomingEvents: any[] = [];
  capAlerts: any[] = [];

  get templates(): any[] {
    return this._templates;
  }
  get sortedTemplates(): GroupedTemplates[] {
    return this._sortedTemplates;
  }
  get templateDates(): string[] {
    return this._templateDates;
  }
  get upcomingEvents(): any[] {
    return this._upcomingEvents;
  }

  private set templates(value: any[]) {
    this._templates = value;
  }
  private set sortedTemplates(value: GroupedTemplates[]) {
    this._sortedTemplates = value;
  }
  private set templateDates(value: string[]) {
    this._templateDates = value;
  }
  private set upcomingEvents(value: any[]) {
    this._upcomingEvents = value;
  }

  constructor(
    private templateApiService: TemplateApiService,
    private companyStateService: CompanyStateService
  ) {}

  assignTemplates(templates: any[]): void {
    this.templates = templates;
  }

  extractSearchDate (date: string): string {
    return date.substring(date.indexOf('-') + 1, date.indexOf('T'));
  }

  formatSearchDate (date: Date): string {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0');
  }

  formatDateName (date: Date): string {
    return new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' }).format(date);
  }

  isIndustryTemplate(item: any): boolean {
    const isK12 = this.companyStateService.isK12Customer();
    return item.templateIndustries != null && (
      isK12 && item.templateIndustries.includes(TemplatesService.K12_EDUCATION) ||
      !isK12 && (
        !item.templateIndustries.includes(TemplatesService.K12_EDUCATION) ||
        item.templateIndustries.length > 1
      )
    );
  }

  loadDates(templates): boolean {
    this.sortedTemplates = [];
    this.templateDates = [];
    const withDatesAndIndustry = templates.filter((item) => {
      return item.templateCalendarStartDate != null && this.isIndustryTemplate(item);
    });
    withDatesAndIndustry.sort((a, b) => {
      const d1 = this.extractSearchDate(a.templateCalendarStartDate);
      const d2 = this.extractSearchDate(b.templateCalendarStartDate);
      if (d1 === d2) {
        return 0;
      }
      return d1 > d2 ? 1 : -1;
    });

    withDatesAndIndustry.forEach((item) => {
      let name = item.templateCalendarEventName;
      const splitDate = item.templateCalendarStartDate.substring(0, item.templateCalendarStartDate.indexOf('T')).split('-');
      const startDate = new Date(parseInt(splitDate[0]), parseInt(splitDate[1]) - 1, parseInt(splitDate[2]));
      const nameDate = this.formatDateName(startDate);
      name = name ? name + ' (' + nameDate + ')' : nameDate;

      const d = this.extractSearchDate(item.templateCalendarStartDate);
      if (!this.templateDates.includes(d)) {
        this.templateDates.push(d);
      }

      const group = this.sortedTemplates.find(exists => exists.name === name);
      if (group) {
        group.templates.push(item);
      } else {
        this.sortedTemplates.push({
          date: d,
          name: name,
          templates: [item]
        });
      }
    });
    return withDatesAndIndustry.length > 0;
  }

  nextSortedIndex (date: Date) {
    const fromDate = this.formatSearchDate(date);
    let startAt = this.templateDates.find((item) => {
      return item >= fromDate;
    });
    startAt = startAt ? startAt : this.templateDates[0];
    return this.sortedTemplates.findIndex((item) => {
      return item.date >= startAt;
    });
  }

  loadUpcomingEvents (number: number): void {
    const index = this.nextSortedIndex(new Date());
    this.upcomingEvents = [];

    const total = this.sortedTemplates.length;
    const end = total + index;
    for (let i = index; i < end && this.upcomingEvents.length < number; i++) {
      const group =  this.sortedTemplates[i % total];
      this.upcomingEvents.push(...group.templates.slice(0, Math.min(number - this.upcomingEvents.length, group.templates.length)));
    }
  }

  getTemplatesByIds (templateIds) {
    let search = {
      filter: templateIds.map(id => `id:=${id}`).join(' || ')
    };

    return this.templateApiService.listTemplates(search);
  }

  getCachedTemplatesByIds (templateIds) {
    let uniqueIds = [...new Set(templateIds)];
    let allTemplates = this.templates.concat(this._templatesFromIds);

    let cachedTemplates = uniqueIds
      .map(id => allTemplates.find(template => template.productCode === id))
      .filter(template => template);

    let uncachedIds = uniqueIds
      .filter(id => !cachedTemplates.find(template => template.productCode === id));

    if (uncachedIds.length > 0) {
      return this.getTemplatesByIds(uncachedIds).then(templates => {
        this._templatesFromIds = this._templatesFromIds.concat(templates.items);

        return {
          items: cachedTemplates.concat(templates.items)
        };
      });
    } else {
      return Promise.resolve({ items: cachedTemplates });
    }
  }
}
