import { Component } from '@angular/core';

import { PlaceholderPlaylistService } from '../../services/placeholder-playlist.service';
import { ModalService } from 'src/app/components/modals/modal.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';
import { SettingsService } from '../../services/settings.service';
import { PlaylistItemService } from '../../services/playlist-item.service';
import { WidgetUtilsService } from '../../services/widget-utils.service';

@Component({
  selector: 'placeholder-playlist',
  templateUrl: './placeholder-playlist.component.html',
  styleUrls: ['./placeholder-playlist.component.scss']
})
export class PlaceholderPlaylistComponent {

  constructor(private modalService: ModalService,
    private broadcaster: BroadcasterService,
    public placeholderPlaylistService: PlaceholderPlaylistService,
    public playlistItemService: PlaylistItemService,
    private settingsService: SettingsService,
    public widgetUtils: WidgetUtilsService) { }

  getDurationTooltip(item) {
    var msg = 'Duration: ';
    if (item.playUntilDone) {
      msg += 'Play Until Done';
    } else {
      msg += item.duration + ' seconds';
    }
    return msg;
  }

  remove(item) {
    this.modalService.confirmDanger('Remove Item',
      'Are you sure you want to remove this Widget from the Playlist?',
      'Remove'
    )
    .then(() => {
      this.placeholderPlaylistService.removeItem(item);
    });
  }

  sortItem(evt) {
    var oldIndex = evt.data.oldIndex;
    var newIndex = evt.data.newIndex;

    this.placeholderPlaylistService.moveItem(oldIndex, newIndex);

    this.broadcaster.emit('placeholderUpdated');
  }

  showSettingsModal(item) {
    this.settingsService.showSettingsModal(item);
  }

  isEditable(item) {
    if (item.type === 'widget' &&
      (item.objectReference || item.settingsUrl)) {
      return true;
    } else if (item.type === 'presentation') {
      return true;
    } else {
      return false;
    }
  }

}
