import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { MicrosoftService } from './microsoft.service';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftOneDriveService extends MicrosoftService {
  public static readonly ENDPOINT_SUBSCRIBE = 'subscribe';
  public static readonly ENDPOINT_ITEM = 'item';
  public static readonly ENDPOINT_USER_TYPE = 'userType';

  subscribe(provider, account, id, cid) {
    const options = this._getRequestOptions();
    const body: any = {provider, account, id, cid};

    const request = new HttpRequest( 'POST',
      environment.MICROSOFT_ONEDRIVE_SERVICE_URL + MicrosoftOneDriveService.ENDPOINT_SUBSCRIBE,
      body,
      options
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body) {
          return 'success';
        } else {
          throw new Error('Unexpected response');
        }
      }).catch((err) => {
        console.error('Error calling "/subscribe" endpoint.', err);

        throw err;
      });
  }

  getFileInfo(provider, account, id) {
    const options = this._getRequestOptions();
    options.params = new HttpParams({fromObject:{
      provider,
      account,
      id
    }});

    const request = new HttpRequest( 'GET',
      environment.MICROSOFT_ONEDRIVE_SERVICE_URL + MicrosoftOneDriveService.ENDPOINT_ITEM,
      options
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body) {
          return response.body;
        } else {
          throw new Error('Unexpected response');
        }
      }).catch((err) => {
        console.error('Error calling "/item" endpoint.', err);

        throw err;
      });
  }

  getUserType(account) {
    const options = this._getRequestOptions();
    options.params = new HttpParams({fromObject:{
      account,
    }});

    const request = new HttpRequest( 'GET',
      environment.MICROSOFT_ONEDRIVE_SERVICE_URL + MicrosoftOneDriveService.ENDPOINT_USER_TYPE,
      options
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body) {
          return response.body;
        } else {
          throw new Error('Unexpected response');
        }
      }).catch((err) => {
        console.error('Error calling "/userType" endpoint.', err);

        throw err;
      });
  }

}
