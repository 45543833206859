import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { NgForm } from '@angular/forms';

import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { PresentationsService } from '../../services/presentations.service';
import { ModalComponent } from 'src/app/components/modals/modal.component';
import { TemplateEditorService } from 'src/app/template-editor/services/template-editor.service';

@Component({
  selector: 'presentation-item',
  templateUrl: './presentation-item.component.html',
  styleUrls: ['./presentation-item.component.scss']
})
export class PresentationItemComponent extends ModalComponent implements OnInit, AfterViewInit {

  @ViewChild('presentationItemForm')
  public presentationItemForm: NgForm;

  _item: any;

  @Input() item: any;
  @Output() itemChange: EventEmitter<any> = new EventEmitter<any>();

  isNew = false;
  showPresentationId = false;
  apiWarning = false;

  private _initialPresentationName;
  private _presentationName: any = '';
  set presentationName(value: any) {
    this._presentationName = value;

    if (value) {
      if (!this._item.name ||
        this._item.name === 'Embedded Presentation' ||
        this._initialPresentationName === this._item.name) {
  
        this._item.name = value;
      }
      this._initialPresentationName = value;
    }
  }
  get presentationName(): any {
    return this._presentationName;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef,
    public modalRef: BsModalRef,
    private presentationsService: PresentationsService) {
    super(modalRef);
  }

  ngOnInit(): void {
    this._item = _.cloneDeep(this.item);
  }

  ngAfterViewInit(): void {
    if (this._item.objectData) {
      this.validatePresentationId();
    } else {
      this.selectPresentation();
    }
  }

  selectPresentation() {
    this.presentationsService.selectPresentation().then((presentationDetails) => {
      this._item.objectData = presentationDetails[0];
      this.presentationName = presentationDetails[1];
    });
  }

  clearSelection() {
    this._item.objectData = '';
    this.presentationName = '';
    this.apiWarning = false;
  }

  validatePresentationId() {
    this.apiWarning = false;
  
    if (this._item.objectData && !this.presentationItemForm.form.controls['presentationId']?.errors?.['pattern']) {
      this.presentationItemForm.form.controls['presentationId']?.setErrors(null);

      this.presentationsService.getPresentationCached(this._item.objectData)
        .then((presentation: any) => {
          if (presentation && presentation.presentationType === TemplateEditorService.HTML_PRESENTATION_TYPE) {
            this.presentationItemForm.form.controls['presentationId'].setErrors({'template': true});
          }
  
          if (presentation && presentation.name) {
            this.presentationName = presentation.name;
          }
        })
        .catch(() => {
          this.showPresentationId = true;
          this.presentationName = '';
          this.apiWarning = true;
        })
        .finally(() => {
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  save() {
    _.assign(this.item, this._item);

    super.resolve();
  }

  dismiss() {
    super.reject();
  }

}
