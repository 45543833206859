import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { InvoiceService } from '../../services/invoice.service';

@Component({
  selector: 'invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent {

  showPaymentForm;

  updateInvoice = this.invoiceService.updateInvoice.bind(this.invoiceService);

  @ViewChild('paymentMethodsForm')
  public paymentMethodsForm: NgForm;

  constructor(public invoiceService: InvoiceService) {
    this.invoiceService.generateToken();
  }

  completeCardPayment() {
    if (!this.paymentMethodsForm.valid) {
      return;
    }

    this.invoiceService.payInvoice();
  }

}
