import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { MicrosoftService } from './microsoft.service';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftOutlookService extends MicrosoftService {
  public static readonly ENDPOINT_GROUPS = 'groups';
  public static readonly ENDPOINT_CALENDARS = 'calendars';

  getGroups(account) {
    let options = this._getRequestOptions();

    options.params = new HttpParams({fromObject:{
      account: account
    }});

    const request = new HttpRequest( 'GET',
      environment.MICROSOFT_OUTLOOK_SERVICE_URL + MicrosoftOutlookService.ENDPOINT_GROUPS, options
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body && response.body.groups) {
          return response.body.groups;
        } else {
          throw new Error('Invalid outlook groups data');
        }
      }).catch((err) => {
        console.error('Failed to get outlook groups.', err);

        throw err;
      });
  }

  getCalendars(account, groupId) {
    let options = this._getRequestOptions();

    options.params = new HttpParams({fromObject:{
      account: account,
      groupId: groupId || ''
    }});

    const request = new HttpRequest( 'GET',
      environment.MICROSOFT_OUTLOOK_SERVICE_URL + MicrosoftOutlookService.ENDPOINT_CALENDARS, options
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body && response.body.calendars) {
          return response.body.calendars;
        } else {
          throw new Error('Invalid outlook calendars data');
        }
      }).catch((err) => {
        console.error('Failed to get outlook calendars.', err);

        throw err;
      });
  }

}
