import { Component } from '@angular/core';

import { PresentationSelectorComponent } from '../presentation-selector/presentation-selector.component';

@Component({
  selector: 'presentation-multi-selector',
  templateUrl: './presentation-multi-selector.component.html',
  styleUrls: ['./presentation-multi-selector.component.scss']
})
export class PresentationMultiSelectorComponent extends PresentationSelectorComponent {

  add() {
    super.resolve(this.presentations.getSelected());
  }

}
