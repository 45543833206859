import { Injectable } from '@angular/core';
import { ComponentsService, PlaylistComponentItem, PlaylistComponentInfo } from '../../services/components.service';
import { BasePlaylistService, BasePlaylistTemplateItem } from './base-playlist.service';
import { cloneDeep } from 'lodash';
import { MediaSelectorService } from './media-selector.service';

@Injectable({
  providedIn: 'root'
})

export class UserComponentsService extends BasePlaylistService {

  playlistComponents: PlaylistComponentItem[] = ComponentsService.USER_COMPONENTS_ARRAY;

  private _getBaseAttributes(type: string) {
    const attributes = {
      'id': 'uc-' + type + '-' + Date.now(),
      'tagName': type,
      'userComponent': true
    };

    if (this.getComponentByType(type).playUntilDone) {
      attributes['play-until-done'] = true;
    }

    return attributes;
  }

  private _addUserComponentsItem(item, editItem: boolean = true, fileType:string = '', options?: any) {
    this.playlistItems.unshift(item);

    // save the added component to attribute data
    this.attributeDataService.addUserComponent(cloneDeep(item));

    const componentType = item.tagName.toLowerCase() === 'rise-folder' && fileType ? `rise-folder/${fileType}` : item.tagName;

    this.analyticsFactory.track('User Component Added', {
      componentType
    });

    if (editItem) {
      this.editComponent(item, false, options);
    }
  }

  protected getComponentObject(item: BasePlaylistTemplateItem): PlaylistComponentInfo {
    return {
      type: item.tagName,
      id: item.id
    };
  }

  addItem(type: string, options?: any): void {
    if (type === 'rise-folder') {
      if (options && options.fileType && options.fileType === UserComponentsService.FOLDER_TYPE.GOOGLE_PHOTOS_ALBUM) {
        return this.showGooglePhotos();
      }

      return this.showMediaSelector(options);
    }

    if (type === 'rise-stock-library') {
      return this.showMediaSelector({
        stockLibrary: true
      });
    }

    if (type === 'rise-canva') {
      return this.showCanva();
    }

    const component = this.getComponentByType(type);
    const baseAttributes = this._getBaseAttributes(type);
    const defaultAttributes = cloneDeep(component.defaultAttributes) || {};

    const item = {...baseAttributes,...defaultAttributes};

    this._addUserComponentsItem(item, true, '', options);
  }

  addUserComponentsMediaItems(itemsToAdd: any[], editItem?: boolean, fileType?: string) {
    // we're only supporting single file, so the item to add is always first index
    const itemToAdd = itemsToAdd[0];

    const baseAttributes = this._getBaseAttributes('rise-folder');
    const itemAttributes = itemToAdd.attributes ? cloneDeep(itemToAdd.attributes) : {};

    const item = {...baseAttributes,...itemAttributes};

    this._addUserComponentsItem(item, editItem, fileType);
  }

  showMediaSelector(options?: any) {
    super.showMediaSelector(options);

    this.mediaSelectorService.onUserComponentAdd = this.addUserComponentsMediaItems.bind(this);
  }

  showCanva(): void {
    super.showCanva();

    this.mediaSelectorService.onUserComponentAdd = this.addUserComponentsMediaItems.bind(this);
  }

  showGooglePhotos() {
    this.addUserComponentsMediaItems([{
      attributes: this.getGooglePhotosItemAttributes()
    }], true);
  }

  removeUserComponent(id) {

    const key = this.playlistItems.findIndex((item) => item.id === id);

    if (key === -1) {
      return;
    }

    const item = this.playlistItems[key];

    if (!item.userComponent) {
      return;
    }

    // Remove Authenticated Web Page credential, if any
    const component = this.attributeDataService.getComponent(id);
    if (this.webPageService.isWebPageComponent(item.tagName) && component?.credential) {
      this.webPageService.removeCredential(component?.credential);
    }

    this.playlistItems.splice(key, 1);
    this.attributeDataService.removeUserComponent(id);
    this._trackItemRemoval(item);
  }

  _trackItemRemoval(item) {

    const type = item.tagName;
    let fileType;

    if (type.toLowerCase() === 'rise-folder') {
      if (item.type === MediaSelectorService.FOLDER_TYPE.STORAGE_FILE) {
        fileType = this.storageManagerService.getFileType(item.source);
      } else if (item.type === MediaSelectorService.FOLDER_TYPE.STORAGE_FOLDER) {
        fileType = 'folder';
      } else {
        fileType = item.type;
      }
    }

    const componentType = fileType ? `rise-folder/${fileType}` : type;

    this.analyticsFactory.track('User Component Removed', {
      componentType
    });
  }
}
