import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public readonly emitter = new EventEmitter<{event: string, targetSpinner: string}>();

  constructor() { }

  start(spinnerKeys) {
    spinnerKeys = Array.isArray(spinnerKeys) ? spinnerKeys : [spinnerKeys];
    for (var i = 0; i < spinnerKeys.length; i++) {
      this.emitter.emit({event: 'rv-spinner:start', targetSpinner: spinnerKeys[i]});
    }
  }

  stop(spinnerKeys) {
    spinnerKeys = Array.isArray(spinnerKeys) ? spinnerKeys : [spinnerKeys];
    for (var i = 0; i < spinnerKeys.length; i++) {
      this.emitter.emit({event: 'rv-spinner:stop', targetSpinner: spinnerKeys[i]});
    }
  }
}
