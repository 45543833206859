import { Component, ElementRef } from '@angular/core';

import { ComponentsService } from '../../services/components.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'template-component-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent {

  public componentId: string;
  public scale: string;
  public canEditCompany: boolean;
  public hasValidAddress: boolean;

  constructor(private elementRef: ElementRef,
    private userStateService: UserStateService,
    private companyStateService: CompanyStateService,
    private componentsFactory: ComponentsService,
    private attributeDataFactory: AttributeDataService) {
    this.canEditCompany =  this.userStateService.hasRole('ua');

    var company = this.companyStateService.getCopyOfSelectedCompany(true);
    this.hasValidAddress = !!(company.postalCode || (company.city && company.country));

    this.componentsFactory.registerDirective({
      type: 'rise-data-weather',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsFactory.selected.id;
        this._load();
      }
    });

  }

  _load() {
    var attributeDataValue = this.attributeDataFactory.getAttributeData(this.componentId, 'scale');
    if (attributeDataValue) {
      this.scale = attributeDataValue;
    } else {
      this.scale = this.attributeDataFactory.getBlueprintData(this.componentId, 'scale');
    }
  }

  save() {
    this.attributeDataFactory.setAttributeData(this.componentId, 'scale', this.scale);
  }

}
