import { Component, ViewChild, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/components/modals/modal.component';
import { StorageService } from '../../services/storage.service';
import { StorageUtilsService } from '../../services/storage-utils.service';
import { FilesListComponent } from '../files-list/files-list.component';

@Component({
  selector: 'storage-modal',
  templateUrl: './storage-modal.component.html',
  styleUrls: ['./storage-modal.component.scss']
})
export class StorageModalComponent extends ModalComponent implements OnInit {

  selectorType: string;
  selectorFilter: string = '';
  enableByURL: boolean = false;

  selectedItemsCount: number = 0;

  @ViewChild(FilesListComponent) filesList: FilesListComponent;

  get title() {
    return this.storageUtilsService.storageTitle(this.selectorType || 'single-file', this.selectorFilter);
  }

  constructor (
    public modalRef: BsModalRef,
    private storageService: StorageService,
    private storageUtilsService: StorageUtilsService
  ) {
    super(modalRef);
  }

  ngOnInit(): void {
    this.storageService.setSelectorType(this.selectorType, this.selectorFilter);
    this.storageService.storageFull = false;
  }

  selectByUrl () {
    // send blank response to indicate By Url selection
    super.resolve();
    this.storageService.reset();
  }

  close (files: any[]) {
    super.resolve(files);
    this.storageService.reset();
  }

  dismiss () {
    super.reject();
    this.storageService.reset();
  }
}
