import { Component, ElementRef } from '@angular/core';
import { TemplateEditorService } from '../../services/template-editor.service';
import { ComponentsService } from '../../services/components.service';
import { TrackerService } from 'src/app/components/logging/tracker.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'template-share-settings',
  templateUrl: './template-share.component.html',
  styleUrls: ['./template-share.component.scss']
})
export class TemplateShareComponent {

  shareTemplate: boolean;

  constructor(private elementRef: ElementRef,
    private componentsService: ComponentsService,
    public templateEditorService: TemplateEditorService,
    private trackerService: TrackerService,
    private featuresService: FeaturesService,
    public companyStateService: CompanyStateService) {

    this.componentsService.registerDirective({
      type: 'rise-template-share-settings',
      element: this.elementRef.nativeElement,
      show: () => {
        this._init();
      }
    });
  }

  private _init() {
    this.shareTemplate = this.templateEditorService.presentation.isTemplate;
  }

  get sharedTemplatesAvailable(): boolean | null {
    return this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_SHARED_TEMPLATES);
  }

  showUpgradePlanModal(): void {
    this.featuresService.showUpgradePlanModal(FeaturesService.FEATURE_SHARED_TEMPLATES);
  }

  save() {
    this.trackerService.presentationEvent('Share Presentation Property Updated',
      this.templateEditorService.presentation.id,
      this.templateEditorService.presentation.name,
      {
        presentationType: this.templateEditorService.presentation.presentationType,
        isTemplate: this.shareTemplate
      }
    );

    this.templateEditorService.presentation.isTemplate = this.shareTemplate;
  }

}
