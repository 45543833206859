<div>
  <div class="panel-body">

    <h4>Unsubscribed</h4>

    <div class="u_margin-md-bottom">
      <strong>{{email}}</strong> has been unsubscribed from monitoring the display <strong>'{{name}}'</strong>.
      (Display ID: {{id}})
    </div>

    <a id="signin-link" href="/" class="btn btn-primary btn-hg btn-block">
      Proceed To Your Account
    </a>

  </div>
</div><!--panel-->
