import { Directive, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[ngModel][debounceEvent]',
})
export class DebounceDirective implements OnInit, OnDestroy {
  @Output()
  public debounceEvent = new EventEmitter<any>();

  @Input()
  public debounce: number = 1000;

  private isFirstChange: boolean = true;
  private subscription: Subscription;

  constructor(public model: NgControl) {
  }

  ngOnInit() {
    this.subscription =
      this.model.valueChanges.pipe(
          debounceTime(this.debounce),
          distinctUntilChanged())
        .subscribe(modelValue => {
          if (this.isFirstChange) {
            this.isFirstChange = false;
          } else {
            this.debounceEvent.emit(modelValue);
          }
        });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}