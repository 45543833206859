<div class="branding-component-container attribute-list-container">
  @if (brandingAvailable === null) {
    <div class="u_margin-md-top">
      <a tabindex="0" href="#" class="madero-link" uiSref="apps.purchase.home">Subscription Required</a>
    </div>
  }
  @else if (!brandingAvailable) {
    <div class="u_margin-md-top">
      <a tabindex="0" ref="#" class="madero-link" (click)="showUpgradePlanModal()" (keyup.enter)="showUpgradePlanModal()">Plan Upgrade Required</a>
    </div>
  }
  <p class="u_margin-md-top" [ngClass]="{ 'u_link-disabled': !brandingAvailable }">Any changes you make to your branding will apply to all Templates.</p>
  <div class="attribute-row attribute-row-first">
    <div class="col-xs-12 pl-0 attribute-desc">
      <streamline-icon tabindex="-1" aria-hidden="true" class="streamline-component-icon" name="circleStar" width="24" height="18"></streamline-icon>
      <a tabindex="0" id="edit-logo" [ngClass]="{ 'madero-link': brandingAvailable, 'u_link-disabled': !brandingAvailable}" (click)="editLogo()" (keyup.enter)="editLogo()">Logo</a>
    </div>
  </div>
  <div class="attribute-row">
    <div class="col-xs-12 pl-0 attribute-desc">
      <streamline-icon aria-hidden="true" class="streamline-component-icon" name="palette" width="24" height="18"></streamline-icon>
      <a tabindex="0" id="edit-colors" [ngClass]="{ 'madero-link': brandingAvailable, 'u_link-disabled': !brandingAvailable}" (click)="editColors()" (keyup.enter)="editColors()">Colors</a>
    </div>
  </div>
</div>
