<h4 class="mb-1">Total Licenses</h4>
<table class="table license-totals-table">
  <thead class="table-header">
    <tr>
      <th class="table-header__cell u_align-middle align-center">License<wbr> Type</th>
      <th class="table-header__cell u_align-middle align-center">Purchased</th>
      <th class="table-header__cell u_align-middle align-center">Allocated</th>
      <th class="table-header__cell u_align-middle align-center">Remaining</th>
    </tr>
  </thead>
  <tbody class="table-body">
    <tr>
      <td class="table-body__cell u_nowrap">Per-Display
        @if (companyAllocationService.getRequiredLicenses('enterprise')) {
          <i aria-label="Insufficient licenses available" class="fa fa-exclamation-circle text-warning"></i>
        }
      </td>
      <td id="enterprise-purchased-licenses" class="table-body__cell align-center">{{ companyAllocationService.getPurchasedLicenses('enterprise') }}</td>
      <td id="enterprise-allocated-licenses" class="table-body__cell align-center">{{ companyAllocationService.getAllocatedLicenses('enterprise') }}</td>
      <td id="enterprise-remaining-licenses" class="table-body__cell align-center">{{ companyAllocationService.getRemainingLicenses('enterprise') }}</td>
    </tr>
    @if (companyStateService.isK12Customer()) {
      <tr>
        <td class="table-body__cell">Unlimited
          @if (companyAllocationService.getRequiredLicenses('unlimited')) {
            <!-- TODO: add aria-label -->
            <i class="fa fa-exclamation-circle text-warning"></i>
          }
        </td>
        <td id="unlimited-purchased-licenses" class="table-body__cell align-center">{{ companyAllocationService.getPurchasedLicenses('unlimited') }}</td>
        <td id="unlimited-allocated-licenses" class="table-body__cell align-center">{{ companyAllocationService.getAllocatedLicenses('unlimited') }}</td>
        <td id="unlimited-remaining-licenses" class="table-body__cell align-center">{{ companyAllocationService.getRemainingLicenses('unlimited') }}</td>
      </tr>
    }
  </tbody>
</table>
<button id="purchase-licenses" [shown]="!companyAllocationService.loading && !companyAllocationService.isManagedByParent" type="button" class="btn btn-default btn-block my-3" (click)="companyAllocationService.purchaseLicenses(false)">Purchase Licenses</button>
