<label>Monitoring Email Recipients:</label>

<p class="mt-1 text-normal">Separate multiple recipients by a comma.</p>
<div class="mt-3 mb-4" [ngClass]="{ 'has-error': monitoringEmails.invalid }">
  <emails-field #monitoringEmails="ngModel" name="monitoringEmails" ngDefaultControl [(ngModel)]="model.monitoringEmails"></emails-field>
  <p class="help-block text-danger" [shown]="monitoringEmails.invalid">
    A valid email address is required.
  </p>
</div>

<timeline-basic-textbox [(timelineString)]="model.monitoringSchedule"/>
