import { Component } from '@angular/core';
import { ModalComponent } from 'src/app/components/modals/modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'schedule-picker-modal',
  templateUrl: './schedule-picker-modal.component.html',
  styleUrls: ['./schedule-picker-modal.component.scss']
})
export class SchedulePickerModalComponent extends ModalComponent {

  selectedSchedule: any = null;

  constructor(public modalRef: BsModalRef) {
    super(modalRef);
  }

  assign () {
    super.resolve(this.selectedSchedule);
  }

  dismiss () {
    super.reject();
  }

}