import { Component, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ScreenshotService } from '../../services/screenshot.service';
import { DisplayService } from '../../services/display.service';
import { PlayerProService } from '../../services/player-pro.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';

@Component({
  selector: 'screenshot',
  templateUrl: './screenshot.component.html',
  styleUrls: ['./screenshot.component.scss']
})
export class ScreenshotComponent {

  @Output() licenseRequired: EventEmitter<void> = new EventEmitter<void>();

  get screenshotUrl(): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(this.screenshotService.screenshot?.imageUrl || '');
  }

  get lastModified(): Date|undefined {
    return this.screenshotService.screenshot?.lastModified;
  }

  constructor(
    public displayService: DisplayService,
    public screenshotService: ScreenshotService,
    public userStateService: UserStateService,
    public playerProService: PlayerProService,
    private domSanitizer: DomSanitizer
  ) { }

  screenshotState () {
    if (!this.playerProService.isScreenshotCompatiblePlayer(this.displayService.display)) {
      return 'not-supported';
    } else if (this.displayService.showLicenseRequired()) {
      return 'no-license';
    } else if (!this.displayService.display || this.screenshotService.screenshotLoading) {
      return 'loading';
    } else if (this.screenshotService.screenshot && this.screenshotService.screenshot.lastModified) {
      return 'screenshot-loaded';
    } else if (this.screenshotService.screenshot && this.screenshotService.screenshot.error) {
      return 'screenshot-error';
    }

    return '';
  }

  reloadScreenshotEnabled () {
    if (!this.displayService.display) {
      return false;
    }

    if (this.displayService.showLicenseRequired()) {
      return false;
    } else if (this.screenshotService.screenshotLoading || !this.screenshotService.screenshot) {
      return false;
    } else if (this.displayService.display.onlineStatus === 'online') {
      return true;
    }

    return false;
  }

  confirmLicensing () {
    this.licenseRequired.emit();
  }

}
