import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '../../modals/modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TimelineBasicService } from '../timeline-basic.service';

@Component({
  selector: 'timeline-basic-modal',
  templateUrl: './timeline-basic-modal.component.html',
  styleUrls: ['./timeline-basic-modal.component.scss']
})
export class TimelineBasicModalComponent extends ModalComponent implements OnInit {

  timeline: any = {};
  recurrence: any = {};

  processing = false;

  constructor(
    public modalRef: BsModalRef,
    private timelineBasicService: TimelineBasicService
  ) {
    super(modalRef);
  }

  ngOnInit(): void {
    this.timelineBasicService.timeline = this.timeline;
    this.recurrence = this.timelineBasicService.recurrence;
  }

  apply () {
    this.processing = true;
    this.timelineBasicService.save();
    super.resolve(this.timeline);
  }

  dismiss () {
    super.reject();
  }
}
