import { Component } from '@angular/core';

@Component({
  selector: 'storage-home',
  templateUrl: './storage-home.component.html',
  styleUrl: './storage-home.component.scss'
})
export class StorageHomeComponent {

  constructor() { }
}
