import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { GoogleService } from './google.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleSheetsService extends GoogleService {
  public static readonly ENDPOINT_SHEETS_DATA = 'sheets/data';
  public static readonly ENDPOINT_SHEETS_FRESH_DATA = 'sheets/freshData';

  private executeGetData(request: HttpRequest<any>) {
    return lastValueFrom(this.httpClient.request(request))
      .then((response: any) => {
        if (response && response.body && response.body.values) {
          return response.body.values;
        } else {
          throw new Error('Invalid sheets data');
        }
      }).catch((err) => {
        console.error('Failed to get google sheets.', err);

        throw err;
      });

  }

  getData(account: string, id: string) {
    const request = new HttpRequest( 'GET',
      environment.GOOGLE_SERVICE_URL + GoogleSheetsService.ENDPOINT_SHEETS_DATA, {
        responseType: 'json',
        params: new HttpParams({fromObject:{
          account,
          id,
          cb: Date.now()
        }})
      }
    );

    return this.executeGetData(request);
  }

  getFreshData(account: string, id: string) {
    const options = this._getRequestOptions();

    options.params = new HttpParams({fromObject:{
      account: account,
      id: id,
      cb: Date.now()
    }});

    const request = new HttpRequest( 'GET',
      environment.GOOGLE_SERVICE_URL + GoogleSheetsService.ENDPOINT_SHEETS_FRESH_DATA, options
    );

    return this.executeGetData(request);
  }
}
