<div class="attribute-editor-component">
  <div class="attribute-editor-row">
    <div class="form-group has-feedback" [ngClass]="{ 'has-error': validationError, 'has-success': !validationError }">
      <label class="control-label">HTML:</label>
      <div class="row">
        <div class="col-xs-12">
          @if (selected) {
            <ngx-codemirror id="codemirror-html-input" [(ngModel)]="html" (debounceEvent)="save()" [options]="codemirrorOptions"></ngx-codemirror>
          }
        </div>
      </div>
      <p  class="text-sm" [hidden]="validationError">
        If your HTML Embed references insecure content, it can’t be shown. Make sure all URL(s) in your content begin with “HTTPS”. If you need assistance, please email <a target="_blank" href="mailto:support@risevision.com">support&#64;risevision.com</a>.
      </p>
      <p class="help-block" [shown]="validationError">
        Your HTML Embed references insecure content that can’t be shown. Make sure all URL(s) in your content begin with "HTTPS". If you need assistance, please email <a target="_blank" href="mailto:support@risevision.com">support&#64;risevision.com</a>.
      </p>
    </div>
  </div>
</div>
