import { Component, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { CreditCardService } from '../../services/credit-card.service';
import { PaymentSourcesService } from '../../services/payment-sources.service';
import { StripeElementsService } from '../../services/stripe-elements.service';

@Component({
  selector: 'credit-card-form',
  templateUrl: './credit-card-form.component.html',
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
  styleUrls: ['./credit-card-form.component.scss']
})
export class CreditCardFormComponent implements OnInit {

  public parentForm: NgForm;

  constructor(private controlContainer: ControlContainer,
    public creditCardService: CreditCardService,
    public paymentSourcesService: PaymentSourcesService,
    public stripeElementsService: StripeElementsService) {
  }

  ngOnInit(): void {
    this.parentForm = <NgForm>this.controlContainer;

    this.stripeElementsService.init();
  }

  stripeElementError(elementId) {
    var element = document.getElementById(elementId);

    if (!element) {
      return false;
    } else if (this.parentForm.submitted || element.className.indexOf('dirty') !== -1) {
      return element.className.indexOf('StripeElement--invalid') !== -1 ||
        element.className.indexOf('StripeElement--empty') !== -1;
    }
  }

}
