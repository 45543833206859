import { Component } from '@angular/core';

import { ComponentsService } from '../../services/components.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { AttributeDataService } from '../../services/attribute-data.service';

@Component({
  selector: 'template-component',
  templateUrl: './template-component.component.html',
  styleUrls: ['./template-component.component.scss']
})
export class TemplateComponentComponent {

  constructor(
    public componentsService: ComponentsService,
    public storageManagerService: StorageManagerService,
    private attributeDataService: AttributeDataService
  ) {}

  getComponentLabel(component: any): string {
    return component ?
      this.attributeDataService.getAvailableAttributeData(component.id, 'editorLabel') ||
      this.componentsService.getComponentTitle(component) :
      '';
  }
}
