import { Injectable } from '@angular/core';
import { CoreAPILoader } from 'src/app/ajs-upgraded-providers';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})
export class GadgetApiService {

  static readonly GADGET_SEARCH_FIELDS = [ 'name', 'id' ];

  constructor(private coreAPILoader: CoreAPILoader,
    private companyStateService: CompanyStateService) { }

  private _createSearchQuery (fields, search) {
    var query = '';

    for (var i in fields) {
      query += 'OR ' + fields[i] + ':~\"' + search + '\" ';
    }

    query = query.substring(3);

    return query.trim();
  }

  private _createQuery (field, ids) {
    var query = '';

    for (var i in ids) {
      query += 'OR ' + field + ':\'' + ids[i] + '\' ';
    }

    query = query.substring(3);

    return query.trim();
  }

  list (search, cursor?) {
    var query = search.query ?
      this._createSearchQuery(GadgetApiService.GADGET_SEARCH_FIELDS, search.query) :
      '';

    query += search.ids ? this._createQuery('id', search.ids) : '';
    query += search.productCodes ?
      this._createQuery('productCode', search.productCodes) : '';

    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'search': query,
      'cursor': cursor,
      'count': search.count,
      'sort': search.sortBy + (search.reverse ? ' desc' : ' asc')
    };
    console.debug('list gadgets called with', obj);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.gadget.list(obj);
      })
      .then((resp) => {
        console.debug('list gadgets resp', resp);

        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to get list of gadgets.', e);
        throw e;
      });
  }

  get (gadgetId?) {
    var obj = {
      'id': gadgetId
    };

    console.debug('get gadget called with', gadgetId);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.gadget.get(obj);
      })
      .then((resp) => {
        console.debug('get gadget resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to get gadget.', e);
        throw e;
      });
  }
}