import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[dateEndAfterStart]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DateEndAfterStartDirective, multi: true }]
})
export class DateEndAfterStartDirective {

  @Input() startDate: any;
  @Input() endDate: any;
  @Input() skipValidation: boolean;

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {

    if (this.skipValidation || !this.startDate || !this.startDate.value || !this.endDate || !this.endDate.value) {
      return null;
    }

    const start = new Date(this.startDate.value.getFullYear(), this.startDate.value.getMonth(), this.startDate.value.getDate());
    const end = new Date(this.endDate.value.getFullYear(), this.endDate.value.getMonth(), this.endDate.value.getDate());

    if (start.getTime() > end.getTime()) {
      return {
        dateEndAfterStart: {
          message: 'End Date must be later than Start Date'
        }
      };
    }

    return null;
  }

}