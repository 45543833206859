import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ScheduleService } from '../../services/schedule.service';

@Component({
  selector: 'schedule-add',
  templateUrl: './schedule-add.component.html',
  styleUrls: ['./schedule-add.component.scss']
})
export class ScheduleAddComponent {

  @ViewChild('scheduleForm')
  public scheduleForm: NgForm;

  constructor(public scheduleService: ScheduleService) {

  }

  save() {
    if (!this.scheduleForm.valid || this.scheduleService.hasInsecureUrls()) {
      console.error('form not valid: ', this.scheduleForm.errors);
      return;
    }

    this.scheduleService.addSchedule();
  }
}
