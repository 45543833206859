import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/components/modals/modal.component';

@Component({
  selector: 'canva-type-picker',
  templateUrl: './canva-type-picker.component.html',
  styleUrls: ['./canva-type-picker.component.scss']
})
export class CanvaTypePickerComponent extends ModalComponent {

  designType = 'Presentation';

  constructor(modalRef: BsModalRef) {
    super(modalRef);
  }

  ok() {
    super.resolve(this.designType);
  }

}
