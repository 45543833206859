import { Component } from '@angular/core';

import { PurchaseService } from '../../services/purchase.service';
import { PurchaseStepsService } from '../../services/purchase-steps.service';
import { TaxExemptionService } from '../../services/tax-exemption.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'purchase-summary',
  templateUrl: './purchase-summary.component.html',
  styleUrls: ['./purchase-summary.component.scss']
})
export class PurchaseSummaryComponent {

  addCoupon;
  purchase = this.purchaseService.purchase;
  selectedCompany = this.companyStateService.getCopyOfSelectedCompany();
  isSubcompanySelected = this.companyStateService.isSubcompanySelected();

  constructor(private companyStateService: CompanyStateService,
    public purchaseService: PurchaseService,
    public purchaseStepsService: PurchaseStepsService,
    public taxExemptionService: TaxExemptionService) { }

  applyCouponCode() {
    if (this.purchase.couponCode) {
      this.purchaseService.getEstimate()
        .then(() => {
          if (!this.purchaseService.purchase.estimate.estimateError) {
            this.addCoupon = false;
          }
        });
    }
  }

  clearCouponCode() {
    this.purchase.couponCode = null;
    this.addCoupon = false;

    if (this.purchaseService.purchase.estimate.estimateError) {
      this.purchaseService.getEstimate();
    }
  }

  applyTaxExemption() {
    if (!this.taxExemptionService.taxExemption.sent) {
      this.taxExemptionService.taxExemption.show = !this.taxExemptionService.taxExemption.show;
    }
  }

}