import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { PurchaseService } from '../../services/purchase.service';
import { PurchaseStepsService } from '../../services/purchase-steps.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Component({
  selector: 'plan-tier-picker',
  templateUrl: './plan-tier-picker.component.html',
  styleUrls: ['./plan-tier-picker.component.scss']
})
export class PlanTierPickerComponent {

  canAccessUnlimitedPlan = this.companyStateService.isK12Customer();
  isUnlimitedPlan = this.canAccessUnlimitedPlan;
  isVolumePlan: boolean;
  periodMonthly = this.purchaseService.purchase?.volumePlan?.isMonthly;
  totalPrice = 0;
  selectedProductCode = '';

  displayCount = this.purchaseService.purchase?.volumePlan?.licenses;
  unlimitedCount = 1;

  @ViewChild('planPickerForm')
  public planPickerForm: NgForm;

  constructor(private companyStateService: CompanyStateService,
    private purchaseService: PurchaseService,
    private purchaseStepsService: PurchaseStepsService
  ) { }

  updatePlan() {
    if (this.isUnlimitedPlan) {
      this.purchaseService.pickUnlimitedPlan(this.unlimitedCount);
    } else {
      this.purchaseService.clearUnlimitedPlan();
    }
    if (this.isVolumePlan) {
      this.purchaseService.pickVolumePlan(this.displayCount, this.periodMonthly, this.totalPrice, this.selectedProductCode);
    } else {
      this.purchaseService.clearVolumePlan();
    }
    this.purchaseStepsService.setNextStep();
  }

  updateProductCode(value) {
    this.selectedProductCode = value;
  }

  updateTotalPrice(value) {
    this.totalPrice = value;
  }

}
