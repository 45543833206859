import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'weekday-checkboxes',
  templateUrl: './weekday-checkboxes.component.html',
  styleUrls: ['./weekday-checkboxes.component.scss']
})
export class WeekdayCheckboxesComponent {

  @Input() days: any = {};
  @Output() daysChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  update(day: string, value: boolean) {
    this.days[day] = value;
    this.daysChange.emit({ ...this.days });
  }

}
