import { Injectable } from '@angular/core';
import { EncodingService } from './encoding.service';
import { ProcessErrorCode } from 'src/app/ajs-upgraded-providers';
import { StorageApiService } from 'src/app/api/services/storage-api.service';

@Injectable({
  providedIn: 'root'
})
export class UploadUriService {

  constructor(
    private processErrorCode: ProcessErrorCode,
    private storageApiService: StorageApiService,
    private encodingService: EncodingService
  ) { }

  getUri(file, forceStorage?) {
    if (!file.name) {
      return Promise.reject({
        message: 'Invalid Params'
      });
    }

    return this.encodingService.isApplicable(file.type, file.name)
      .then((useEncoding) => {
        var applicableService = useEncoding ? this.encodingService : this.storageApiService;

        if (forceStorage) {
          applicableService = this.storageApiService;
        }
        return applicableService.getResumableUploadURI(file.name, file.type);
      })
      .then(resp => {
        return resp;
      })
      .catch(e => {
        var type = file.type === 'folder' ? 'Folder' : 'File';
        var message = e && e.result && e.result.error && e.result.error.message === 'Unencodable overwrite' ?
          e.result.error.message : this.processErrorCode(type, 'upload', e);

        console.debug('Failed upload uri request');
        return Promise.reject({
          message: message,
          status: e.status
        });
      });
  }
}
