import { Injectable } from '@angular/core';
import { pick } from 'lodash';

import { HtmlParserService } from './html-parser.service';

@Injectable({
  providedIn: 'root'
})
export class PresentationParserService {

  static readonly PRESENTATION_JSON_FIELDS = [
    'id', 'hidePointer', 'donePlaceholder', 'embeddedIds'
  ];
  static readonly SUPPORTED_PLACEHOLDER_ITEMS = [
    'widget', 'presentation'
  ];
  static readonly PLACEHOLDER_JSON_FIELDS = [
    'id', 'type', 'timeDefined', 'startDate',
    'endDate', 'startTime', 'endTime',
    'recurrenceType', 'recurrenceFrequency',
    'recurrenceAbsolute', 'recurrenceDayOfWeek',
    'recurrenceDaysOfWeek',
    'recurrenceDayOfMonth', 'recurrenceWeekOfMonth',
    'recurrenceMonthOfYear', 'visibility',
    'transition', 'items', 'distributeToAll'
  ];
  static readonly PLAYLIST_ITEM_JSON_FIELDS = [
    'name', 'duration', 'type', 'objectReference',
    'index', 'playUntilDone', 'objectData',
    'additionalParams', 'timeDefined',
    'startDate', 'endDate',
    'startTime', 'endTime',
    'recurrenceType', 'recurrenceFrequency',
    'recurrenceAbsolute', 'recurrenceDayOfWeek',
    'recurrenceDaysOfWeek',
    'recurrenceDayOfMonth', 'recurrenceWeekOfMonth',
    'recurrenceMonthOfYear', 'settingsUrl'
  ];

  public hasLegacyItems;

  htmlTag = '<html';
  htmlEndTag = '</html>';
  headStartTag = '<head';
  headEndTag = '</head>';
  linkTag = '<link';
  scriptStartTag = '<script';
  scriptEndTag = '</script>';
  bodyTag = '<body';
  bodyEndTag = '</body>';

  divTag = '<div';
  divEndTag = '</div>';

  dataVariableParam = 'presentationdata';
  dataVariableString = '' +
    '<script language="javascript">\n' +
    '\t<!--\n' +
    '\tvar presentationData = %data%;\n' +
    '\t//-->\n' +
    '\t</script>';

  relParam = ' rel=';
  relHelpLinkParam = 'help';
  hrefParam = ' href=';

  idParam = ' id=';
  placeholderParam = ' placeholder=';
  //	var urlParam = ' src=';
  styleParam = ' style=';
  widthParam = 'width';
  heightParam = 'height';
  topParam = 'top';
  leftParam = 'left';
  zIndexParam = 'z-index';
  backgroundParam = 'background';
  backgroundSizeParam = 'background-size';
  backgroundScaleValue = 'contain';
  //	var overflowParam = 'overflow';

  idPrefix = 'ph';

  // AD - overflow:hidden removed
  //	var nativeStyleString = 'position:absolute;overflow:hidden;';
  nativeStyleString = 'position:absolute;';

  HIDE_SCRIPT = '<script>try {' +
    'setVisible("%s1", false);' +
    '} catch(err) { parent.writeToLog("setVisible call - %s1 - " + err.message); }' +
    '</script>';

  constructor(
    private htmlParserService: HtmlParserService
  ) { }

  isDeprecatedItem(type?) {
    return PresentationParserService.SUPPORTED_PLACEHOLDER_ITEMS.indexOf(type) === -1;
  }

  parseHelpLink (htmlString) {
    var start, end;

    start = htmlString.toLowerCase().indexOf(this.linkTag);
    while (start !== -1) {
      // search for the end of the tag
      end = htmlString.indexOf('>', start);

      if (this.relHelpLinkParam === this.htmlParserService.stripGarbage(this.htmlParserService.getPropertyValue(
          htmlString.substring(start, end + 1), this.relParam)).toLowerCase()) {
        return this.htmlParserService.stripGarbage(this.htmlParserService.getPropertyValue(
          htmlString.substring(start, end + 1), this.hrefParam));
      }

      start = htmlString.toLowerCase().indexOf(this.linkTag, end);
    }

    return '';
  }

  parseBodyStyle (presentation, htmlString) {
    var tokens = htmlString.split(';');
    for (var x = 0; x < tokens.length; x++) {
      if (tokens[x].indexOf(':') !== -1) {
        var param = tokens[x].split(':')[0];
        var value = tokens[x].substring(tokens[x].indexOf(':') + 1).trim();

        if (this.htmlParserService.equalsIgnoreCase(param, this.widthParam)) {
          presentation.width = this.htmlParserService.getIntValue(value);
          presentation.widthUnits = this.htmlParserService.getUnits(value);
        } else if (this.htmlParserService.equalsIgnoreCase(param, this.heightParam)) {
          presentation.height = this.htmlParserService.getIntValue(value);
          presentation.heightUnits = this.htmlParserService.getUnits(value);
        } else if (this.htmlParserService.equalsIgnoreCase(param, this.backgroundParam)) {
          presentation.backgroundStyle = value;
        } else if (this.htmlParserService.equalsIgnoreCase(param, this.backgroundSizeParam)) {
          presentation.backgroundScaleToFit = value ===
          this.backgroundScaleValue;
        }
      }
    }
  }

  _cleanPlaceholderData (placeholders) {
    var items, j;
    for (var i = 0; i < placeholders.length; i++) {
      this.htmlParserService.parseBooleanProperty(placeholders[i],
        'recurrenceAbsolute');
      this.htmlParserService.parseBooleanProperty(placeholders[i], 'timeDefined');
      this.htmlParserService.parseBooleanProperty(placeholders[i], 'visibility');

      this.htmlParserService.parseIntProperty(placeholders[i],
        'recurrenceDayOfMonth');
      this.htmlParserService.parseIntProperty(placeholders[i],
        'recurrenceDayOfWeek');
      this.htmlParserService.parseIntProperty(placeholders[i],
        'recurrenceFrequency');
      this.htmlParserService.parseIntProperty(placeholders[i],
        'recurrenceMonthOfYear');
      this.htmlParserService.parseIntProperty(placeholders[i],
        'recurrenceWeekOfMonth');

      if (placeholders[i].items) {
        items = placeholders[i].items;
        for (j = 0; j < items.length; j++) {
          if (this.isDeprecatedItem(items[j].type)) {
            this.hasLegacyItems = true;
          }
          this.htmlParserService.parseBooleanProperty(items[j], 'playUntilDone');
          this.htmlParserService.parseBooleanProperty(items[j],
            'recurrenceAbsolute');
          this.htmlParserService.parseBooleanProperty(items[j], 'timeDefined');

          this.htmlParserService.parseIntProperty(items[j], 'duration', 10);
          this.htmlParserService.parseIntProperty(items[j], 'index');

          this.htmlParserService.parseIntProperty(items[j], 'recurrenceDayOfMonth');
          this.htmlParserService.parseIntProperty(items[j], 'recurrenceDayOfWeek');
          this.htmlParserService.parseIntProperty(items[j], 'recurrenceFrequency');
          this.htmlParserService.parseIntProperty(items[j], 'recurrenceMonthOfYear');
          this.htmlParserService.parseIntProperty(items[j], 'recurrenceWeekOfMonth');
        }
      }
    }
  }

  _processLayout (htmlString) {
    var start, end;

    start = htmlString.toLowerCase().indexOf(this.dataVariableParam);

    if (start !== -1) {
      // find if the next character is a quote or a bracket
      if (htmlString.indexOf('\'', start) === -1 ||
        htmlString.indexOf('{', start) < htmlString.indexOf('\'', start)
      ) {
        start = htmlString.indexOf('{', start);

        end = htmlString.lastIndexOf('};');

        // increment end only if end string was found
        if (end !== -1) {
          // do not include the ;
          end = end + 1;
        }
      } else {
        var quote = this.htmlParserService.getNextQuote(htmlString.substring(start));

        start = htmlString.indexOf(quote, start) + 1;
        end = htmlString.indexOf(quote, start);
        while (end !== -1 && htmlString.charAt(end - 1) === '\\') {
          end = htmlString.indexOf(quote, end + 1);
        }
      }

      if (end !== -1) {
        var json = htmlString.substring(start, end);
        var dataObject = null;

        json = json.replace('\\\'', '\'');

        try {
          dataObject = JSON.parse(json);
        } catch (err) {
          console.error('parse presentation error', err);
          return {
            valid: false
          };
        }

        return {
          valid: true,
          dataObject: dataObject && dataObject.presentationData
        };
      }
    }

    return {
      valid: true
    };
  }

  validatePresentation (presentation) {
    return this._processLayout(presentation.layout).valid;
  }

  parsePresentationData (presentation) {
    var start, end;
    var layout = this._processLayout(presentation.layout);

    if (layout.valid && layout.dataObject) {
      var dataObject = layout.dataObject;

      presentation.hidePointer =
        this.htmlParserService.getBooleanValue(dataObject.hidePointer);
      presentation.donePlaceholder = dataObject.donePlaceholder;

      this._cleanPlaceholderData(dataObject.placeholders);

      presentation.placeholders = dataObject.placeholders;
    }

    return layout.valid;
  }

  parseStyle (placeholder, htmlString) {
    var tokens = htmlString.split(';');
    for (var x = 0; x < tokens.length; x++) {
      if (tokens[x].indexOf(':') !== -1) {
        var param = tokens[x].split(':')[0].trim();
        var value = tokens[x].substring(tokens[x].indexOf(':') + 1).trim();

        if (this.htmlParserService.equalsIgnoreCase(param, this.widthParam)) {
          placeholder.width = this.htmlParserService.getFloatValue(value);
          placeholder.widthUnits = this.htmlParserService.getUnits(value);
        } else if (this.htmlParserService.equalsIgnoreCase(param, this.heightParam)) {
          placeholder.height = this.htmlParserService.getFloatValue(value);
          placeholder.heightUnits = this.htmlParserService.getUnits(value);
        } else if (this.htmlParserService.equalsIgnoreCase(param, this.topParam)) {
          placeholder.top = this.htmlParserService.getFloatValue(value);
          placeholder.topUnits = this.htmlParserService.getUnits(value);
        } else if (this.htmlParserService.equalsIgnoreCase(param, this.leftParam)) {
          placeholder.left = this.htmlParserService.getFloatValue(value);
          placeholder.leftUnits = this.htmlParserService.getUnits(value);
        } else if (this.htmlParserService.equalsIgnoreCase(param, this.zIndexParam)) {
          placeholder.zIndex = this.htmlParserService.getIntValue(value);
        } else if (this.htmlParserService.equalsIgnoreCase(param, this.backgroundParam)) {
          placeholder.backgroundStyle = value;
        } else if (this.htmlParserService.equalsIgnoreCase(param, this.backgroundSizeParam)) {
          placeholder.backgroundScaleToFit = value ===
            this.backgroundScaleValue;
        }
      }
    }
  }

  parseDiv (htmlString) {
    var placeholder = null;

    var id = this.htmlParserService.getPropertyValue(htmlString, this.idParam);
    if (id && this.htmlParserService.getPropertyValue(htmlString, this.placeholderParam)) {
      placeholder = {};

      placeholder.id = id;

      this.parseStyle(placeholder, this.htmlParserService.stripOuterGarbage(
        this.htmlParserService.getPropertyValue(htmlString, this.styleParam)));
    }
    return placeholder;
  }

  _removeDeletedPlaceholders (placeholders) {
    // remove deleted placeholders from the list
    for (var i = 0; i < placeholders.length; i++) {
      if (placeholders[i].deleted) {
        placeholders.splice(i, 1);
        i--;
      }
    }
  }

  parsePlaceholders (presentation, htmlString) {
    var start, end;
    var found, i, j;
    presentation.placeholders = presentation.placeholders || [];
    var placeholders = presentation.placeholders;
    var newPlaceholders = [];

    start = htmlString.indexOf(this.divTag);
    while (start !== -1) {
      // search for the end of the div
      end = htmlString.indexOf('>', start);

      var placeholder = this.parseDiv(htmlString.substring(start,
        end + 1));

      if (placeholder) {
        newPlaceholders.push(placeholder);
      }

      start = htmlString.indexOf(this.divTag, end);
    }

    var listChanged = false;
    for (i = 0; i < placeholders.length; i++) {
      found = false;
      for (j = 0; j < newPlaceholders.length; j++) {
        if (newPlaceholders[j].id === placeholders[i].id) {
          found = true;
          break;
        }
      }
      if (!found) {
        listChanged = true;
        placeholders[i].deleted = true;
      }
    }

    this._removeDeletedPlaceholders(placeholders);

    for (j = 0; j < newPlaceholders.length; j++) {
      found = false;
      for (i = 0; i < placeholders.length; i++) {
        if (newPlaceholders[j].id === placeholders[i].id) {
          found = true;
          placeholders[i].width = newPlaceholders[j].width;
          placeholders[i].widthUnits = newPlaceholders[j].widthUnits;
          placeholders[i].height = newPlaceholders[j].height;
          placeholders[i].heightUnits = newPlaceholders[j].heightUnits;
          placeholders[i].left = newPlaceholders[j].left;
          placeholders[i].leftUnits = newPlaceholders[j].leftUnits;
          placeholders[i].top = newPlaceholders[j].top;
          placeholders[i].topUnits = newPlaceholders[j].topUnits;
          placeholders[i].zIndex = newPlaceholders[j].zIndex;
          placeholders[i].backgroundStyle = newPlaceholders[j].backgroundStyle;
          placeholders[i].backgroundScaleToFit = newPlaceholders[j].backgroundScaleToFit;
        }
      }

      if (!found) {
        listChanged = true;
        placeholders.push(newPlaceholders[j]);
      }
    }

    return listChanged;
  }

  parsePresentation (presentation) {
    var start, end;
    var htmlString = presentation.layout;

    this.hasLegacyItems = false;

    if (!htmlString) {
      return true;
    }

    start = htmlString.toLowerCase().indexOf(this.htmlTag);
    end = htmlString.toLowerCase().indexOf(this.htmlEndTag, start);
    if (start === -1 || end === -1) {
      return true;
    }

    // process head for help link
    start = htmlString.toLowerCase().indexOf(this.headStartTag, start);
    end = htmlString.toLowerCase().indexOf(this.headEndTag, start);

    if (start === -1 || end < start) {
      return true;
    }

    presentation.helpURL = this.parseHelpLink(htmlString.substring(
      start, end + 1));

    // process body next
    start = htmlString.toLowerCase().indexOf(this.bodyTag, start);
    end = htmlString.indexOf('>', start);

    if (start === -1 || end < start) {
      return true;
    }

    this.parseBodyStyle(presentation, this.htmlParserService.stripOuterGarbage(
      this.htmlParserService.getPropertyValue(htmlString.substring(start, end +
        1), this.styleParam)));

    end = htmlString.toLowerCase().indexOf(this.bodyEndTag, start);
    if (start === -1 || end === -1) {
      return true;
    }

    // process data
    if (!this.parsePresentationData(presentation)) {
      return false;
    }

    this.parsePlaceholders(presentation, htmlString.substring(
      start, end + this.bodyEndTag.length));

    console.debug('parse presentation result', presentation);

    return true;
  }

  // ======================================================================
  // Update Presentation functionality beings:


  setPlaceholderId (placeholder, placeholders) {
    for (var i = 0; i < placeholders.length; i++) {
      var found = false;
      for (var j = 0; j < placeholders.length; j++) {
        if (placeholders[j] !== placeholder && !placeholders[j].deleted &&
          placeholders[j].id === (this.idPrefix + i)) {
          found = true;
          break;
        }
      }
      if (!found) {
        placeholder.id = this.idPrefix + i;
        break;
      }
    }

  }

  findPlaceholder (htmlString, id) {
    var start = -1;

    start = htmlString.indexOf('"' + id + '"');
    if (start === -1) {
      start = htmlString.indexOf('\'' + id + '\'');
    }

    if (start !== -1) {
      start++;
    }

    return start;
  }

  updateDiv (placeholder, htmlString) {
    var start = 0,
      end = 0;
    var idString, styleString;
    var found = false;

    idString = this.htmlParserService.getPropertyValue(htmlString, this.idParam);
    if (idString) {
      start = htmlString.indexOf(idString);
      end = start + idString.length;

      // now is the time to re-name the placeholder (if needed); and reset newId variable
      if (placeholder.newId) {
        placeholder.id = placeholder.newId;
        placeholder.newId = undefined;
      }
      htmlString = htmlString.substring(0, start) + placeholder.id +
        htmlString.substring(end, htmlString.length);
    } else {
      htmlString += this.idParam + '"' + placeholder.id + '"';
    }

    if (!this.htmlParserService.getPropertyValue(htmlString, this.placeholderParam)) {
      htmlString += this.placeholderParam + '"true"';
    }

    styleString = this.htmlParserService.getPropertyValue(htmlString, this.styleParam);
    if (styleString) {
      start = htmlString.indexOf(styleString);
      end = start + styleString.length;
      found = true;
    }

    if (placeholder.width) {
      styleString = this.htmlParserService.updateStyle(this.widthParam, this.htmlParserService.getUnitString(
        placeholder.width, placeholder.widthUnits), styleString);
    } else {
      styleString = this.htmlParserService.updateStyle(this.widthParam, '0px',
        styleString);
    }
    if (placeholder.height) {
      styleString = this.htmlParserService.updateStyle(this.heightParam, this.htmlParserService.getUnitString(
        placeholder.height, placeholder.heightUnits), styleString);
    } else {
      styleString = this.htmlParserService.updateStyle(this.heightParam, '0px',
        styleString);
    }
    if (placeholder.left) {
      styleString = this.htmlParserService.updateStyle(this.leftParam, this.htmlParserService.getUnitString(
        placeholder.left, placeholder.leftUnits), styleString);
    } else {
      styleString = this.htmlParserService.updateStyle(this.leftParam, '0px',
        styleString);
    }
    if (placeholder.top) {
      styleString = this.htmlParserService.updateStyle(this.topParam, this.htmlParserService.getUnitString(
        placeholder.top, placeholder.topUnits), styleString);
    } else {
      styleString = this.htmlParserService.updateStyle(this.topParam, '0px', styleString);
    }
    if (placeholder.zIndex) {
      styleString = this.htmlParserService.updateStyle(this.zIndexParam, placeholder.zIndex +
        '', styleString);
    } else {
      styleString = this.htmlParserService.updateStyle(this.zIndexParam, '0',
        styleString);
    }
    if (placeholder.backgroundStyle) {
      styleString = this.htmlParserService.updateStyle(this.backgroundParam, placeholder
        .backgroundStyle, styleString);
    } else {
      styleString = this.htmlParserService.removeStyle(this.backgroundParam,
        styleString);
    }
    if (placeholder.backgroundStyle && placeholder.backgroundScaleToFit) {
      styleString = this.htmlParserService.updateStyle(this.backgroundSizeParam,
        this.backgroundScaleValue, styleString);
    } else {
      styleString = this.htmlParserService.removeStyle(this.backgroundSizeParam,
        styleString);
    }

    if (found) {
      htmlString = this.htmlParserService.updateInnerString(htmlString, start, end,
        styleString);
    } else {
      htmlString += this.styleParam + '"' + styleString + this.nativeStyleString +
        '"';
    }

    return htmlString;
  }

  updatePlaceholders (placeholders, htmlString) {
    var start, end;
    var placeholder;

    for (var i = 0; i < placeholders.length; i++) {
      placeholder = placeholders[i];
      if (!placeholder.id) {
        if (!placeholder.deleted) {
          this.setPlaceholderId(placeholder, placeholders);
        }
        start = -1;
      } else {
        start = this.findPlaceholder(htmlString, placeholder.id);
      }

      if (start !== -1) {
        start = htmlString.lastIndexOf('<', start);

        if (!placeholder.deleted) {
          start += this.divTag.length;
          end = htmlString.indexOf('>', start);

          var newDiv = this.updateDiv(placeholder, htmlString.substring(
            start, end));

          htmlString = this.htmlParserService.updateInnerString(htmlString, start,
            end, newDiv);
        } else {
          end = htmlString.indexOf('>', start);
          var nextTag = htmlString.indexOf(this.divTag, end);
          end = htmlString.indexOf(this.divEndTag, end);

          if (end !== -1 && (nextTag === -1 || end < nextTag)) {
            end += this.divEndTag.length;
            htmlString = htmlString.substring(0, start).trim() +
              htmlString.substring(end, htmlString.length).trim();
          }

          placeholder.id = '';
        }
      } else if (!placeholder.deleted) {
        var newTag = '' + this.divTag + '' +
        this.updateDiv(placeholder, '') + '>' + this.divEndTag + '\n\t';
        end = htmlString.indexOf(this.bodyEndTag);
        htmlString = htmlString.substring(0, end) + newTag +
          htmlString.substring(end);
      }
    }

    this._removeDeletedPlaceholders(placeholders);

    return htmlString;
  }

  updatePresentationObject (htmlString,
    modifiedDataVariableString) {
    var start, end = -1;

    start = htmlString.toLowerCase().indexOf(this.dataVariableParam);
    if (start === -1) {
      end = htmlString.indexOf(this.htmlEndTag);
      start = end;

      // Add spacing for the data variable
      modifiedDataVariableString = '' +
        '\n<!-- Warning - Editing the Presentation Data Object incorrectly may result in the Presentation not functioning correctly -->' +
        '\n\t' + modifiedDataVariableString + '' +
        '\n<!-- No scripts after this point -->' +
        '\n';
    } else {
      if (htmlString.indexOf('\'', start) === -1 || htmlString.indexOf(
          '{', start) < htmlString.indexOf('\'', start)) {
        start = htmlString.indexOf('{', start);

        end = htmlString.lastIndexOf('};');

        // increment end only if end string was found
        if (end !== -1) {
          end = end + 2;
        }
      } else {
        var quote = this.htmlParserService.getNextQuote(htmlString.substring(
          start));

        start = htmlString.indexOf(quote, start);
        end = htmlString.indexOf(quote, start + 1);
        while (end !== -1 && htmlString.charAt(end - 1) === '\\') {
          end = htmlString.indexOf(quote, end + 1);
        }
        end++;
      }

      if (end !== -1) {
        start = htmlString.lastIndexOf(this.scriptStartTag, start);
        end = htmlString.lastIndexOf(this.scriptEndTag) + this.scriptEndTag.length;
      }
    }

    if (start !== -1 && end !== -1) {
      htmlString = this.htmlParserService.updateInnerString(htmlString, start, end,
        modifiedDataVariableString);
    }

    return htmlString;
  }

  updatePresentationData (presentation, htmlString) {
    var data = pick.apply(this, [presentation].concat(
      PresentationParserService.PRESENTATION_JSON_FIELDS));

    data.placeholders = [];
    for (var i = 0; i < presentation.placeholders.length; i++) {
      data.placeholders.push(pick.apply(this, [presentation.placeholders[
        i]].concat(PresentationParserService.PLACEHOLDER_JSON_FIELDS)));

      data.placeholders[i].items = [];
      for (var j = 0; presentation.placeholders[i].items &&
        j < presentation.placeholders[i].items.length; j++) {

        data.placeholders[i].items.push(pick.apply(this, [presentation.placeholders[
          i].items[j]].concat(PresentationParserService.PLAYLIST_ITEM_JSON_FIELDS)));
      }
    }

    data = {
      presentationData: data
    };

    var presentationDataString = JSON.stringify(data, null, '\t').replace(
      /&quot;/g, '\\\\\\"');

    var modifiedDataVariableString = this.dataVariableString.replace(
      '%data%', presentationDataString);

    return this.updatePresentationObject(htmlString,
      modifiedDataVariableString);
  }

  updatePresentation (presentation) {
    var start, end;

    // process presentation header first
    this.updatePresentationHeader(presentation);

    var htmlString = presentation.layout;

    // process body
    start = htmlString.toLowerCase().indexOf(this.bodyTag, start);
    end = htmlString.toLowerCase().indexOf(this.bodyEndTag, start);
    if (start === -1 || end === -1) {
      return;
    }

    end += this.bodyEndTag.length;

    var newPlaceholders = this.updatePlaceholders(presentation.placeholders,
      htmlString.substring(start, end));

    htmlString = this.htmlParserService.updateInnerString(htmlString, start, end,
      newPlaceholders);

    presentation.layout = this.updatePresentationData(
      presentation, htmlString);

    console.debug('update presentation result', presentation);
  }

  // =======================================================================
  // Update presentation header functionality begins:

  updateBodyTag (presentation, htmlString) {
    var start = 0,
      end = 0;
    var styleString;
    var found = false;

    styleString = this.htmlParserService.getPropertyValue(htmlString, this.styleParam);
    if (styleString !== '') {
      start = htmlString.indexOf(styleString);
      end = start + styleString.length;
      found = true;
    }

    styleString = this.htmlParserService.updateStyle(this.widthParam, presentation.width +
      presentation.widthUnits, styleString);
    styleString = this.htmlParserService.updateStyle(this.heightParam, presentation.height +
      presentation.heightUnits, styleString);

    if (presentation.backgroundStyle) {
      styleString = this.htmlParserService.updateStyle(this.backgroundParam,
        presentation.backgroundStyle, styleString);
    } else {
      styleString = this.htmlParserService.removeStyle(this.backgroundParam,
        styleString);
    }
    if (presentation.backgroundStyle && presentation.backgroundScaleToFit) {
      styleString = this.htmlParserService.updateStyle(this.backgroundSizeParam,
        this.backgroundScaleValue, styleString);
    } else {
      styleString = this.htmlParserService.removeStyle(this.backgroundSizeParam,
        styleString);
    }

    if (found) {
      htmlString = this.htmlParserService.updateInnerString(htmlString, start, end,
        styleString);
    } else {
      htmlString += this.styleParam + '"' + styleString + '"';
    }

    return htmlString;
  }

  updatePresentationHeader (presentation) {
    var start, end;
    var htmlString = presentation.layout;

    if (!htmlString) {
      return;
    }

    start = htmlString.toLowerCase().indexOf(this.htmlTag);
    end = htmlString.toLowerCase().indexOf(this.htmlEndTag, start);
    if (start === -1 || end === -1) {
      return;
    }

    // process body first
    start = htmlString.toLowerCase().indexOf(this.bodyTag, start);
    end = htmlString.indexOf('>', start);

    if (start === -1 || end < start) {
      return;
    }

    var newBody = this.updateBodyTag(presentation, htmlString.substring(
      start, end));
    htmlString = this.htmlParserService.updateInnerString(htmlString, start, end,
      newBody);

    presentation.layout = htmlString;

    console.debug('update presentation header result', presentation);
  }

}
