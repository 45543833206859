import { Injectable } from '@angular/core';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { TemplateEditorService } from 'src/app/template-editor/services/template-editor.service';

@Injectable({
  providedIn: 'root'
})
export class PresentationUtilsService {

  static readonly HTML_TEMPLATE_TYPE = 'HTMLTemplates';

  isMobileBrowser () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }

  isHtmlTemplate (product) {
    return product.productTag &&
      product.productTag.indexOf(PresentationUtilsService.HTML_TEMPLATE_TYPE) >= 0;
  }

  isHtmlPresentation (presentation) {
    return presentation.presentationType === TemplateEditorService.HTML_PRESENTATION_TYPE;
  }
}

angular.module('risevision.editor.services')
  .factory('presentationUtils', downgradeInjectable(PresentationUtilsService));