import { Component, Input } from '@angular/core';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
  selector: 'search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent {
  @Input() filterConfig: any = {};
  @Input() search: any = {};
  @Input() doSearch!: any;
  @Input() iconSet!: string;
  @Input() localSearch: any = false;
  @Input() focus: boolean = false;

  searchTimeout: any = null;

  constructor() {}

  searchAction(ms: number) {
    if (this.localSearch) {
      this.doSearch();
    } else {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(this.doSearch, ms);
    }
  }

  reset() {
    if (this.search.query) {
      this.search.query = '';
      this.doSearch();
    }
  }

}

angular.module('risevision.common.components')
  .directive(
    'searchFilter',
    downgradeComponent({
      component: SearchFilterComponent
    }) as angular.IDirectiveFactory
  );
