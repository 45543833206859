<div id="distributionModal">
  <div class="modal-header">
    <button type="button" class="close" (click)="dismiss()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 class="modal-title">Edit Distribution</h4>
  </div>
  <div class="modal-body align-left">
    <distribution-list
      [(distribution)]="distribution"
      [(subcompanyDistribution)]="subcompanyDistribution"
      [(distributeToSubcompanies)]="distributeToSubcompanies"
      [enableSubcompanies]="enableSubcompanies"
      (closeEvent)="dismiss()">
    </distribution-list>
  </div>

  <div class="modal-footer">
    <a class="btn btn-default btn-toolbar pull-left" (click)="dismiss()" uiSref="apps.displays.add">
      Add Display
    </a>
    <button id="distributionModalCancelButton" type="button" class="btn btn-default btn-toolbar" (click)="dismiss()">Cancel</button>
    <button id="applyButton" type="button" class="btn-primary btn btn-toolbar" (click)="apply()">Apply</button>
  </div>
</div>