<div>
  <div id="uploadPanel" class="panel animated fadeInDown progress-panel u_margin-md-bottom" [shown]="uploader.queue.length > 0">
    <div class="panel-header"> <!-- //TODO: IS this a needed class? -->
      <span class="collapse-control" (click)="isCollapsed = !isCollapsed">
        <i [attr.aria-label]="isCollapsed ? 'Expand queue list' : 'Collapse queue list'" class="fa" [ngClass]="{'fa-chevron-up': isCollapsed, 'fa-chevron-down': !isCollapsed}"></i>
      </span>
      <p>
        <strong [shown]="someEncoding()">Uploading and optimizing files - Depending on the size of your files, this might take a moment</strong>
        <strong [shown]="!someEncoding()">Uploading files</strong>
        <!--
        TODO: To FIX: activeUploadCount does not take into account all files being uploaded but just the queue size of 10
        <strong>Upload Status:</strong>
        <span [shown]="activeUploadCount() === 1">Uploading 1 file</span>
        <span [shown]="activeUploadCount() !== 1">Uploading {{activeUploadCount()}} files</span>
        -->
      </p>
      <div class="actions-block">
        <button class="btn btn-default btn-sm" type="button" (click)="uploader.cancelAllUploads()" title="Cancel All">
          Cancel All
        </button>
        <button id="retryAll" class="btn btn-default btn-sm ml-3" type="button" (click)="uploader.retryFailedUploads()" [shown]="getErrorCount() > 0 && getNotErrorCount() === 0" title="Retry failed uploads">
          Retry Failed Uploads
        </button>
      </div>
    </div><!--panel-header-->
    <div [shown]="!isCollapsed">
      <div class="panel-body">
        @for (item of uploader.queue; track item) {
          <div class="u_margin-sm-top">
            <p>
              <button class="btn btn-link pull-right" (click)="uploader.removeItem(item)">
                remove
              </button>
              <button class="btn btn-link pull-right u_margin-right" (click)="uploader.retryFailedUpload(item)" [shown]="item.isError && !item.isUnsupportedFile">
                retry
              </button>
              <span class="pull-right text-muted u_margin-left u_margin-right pt-3"> {{ item.file.size|storageFileSize }}</span>
              <span class="pull-right text-danger pt-3" [shown]="item.isUnsupportedFile">
                File type not supported. File did not upload.
              </span>
              <span class="u_ellipsis-lg pt-3" [ngClass]="{'text-danger': item.isError }">
                <i aria-label="Error" class="fa fa-exclamation u_icon-red icon-left" [shown]="item.isError"></i>
                {{item.file.name}}
              </span>
            </p>
            <div class="progress inline-progress">
              <div class="progress-bar progress-bar-striped progress-bar-success active" role="progressbar"
              [ngStyle]="{ 'width': item.progress + '%' }"></div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
  <div class="modal fade" tabindex="-1">
    <form id="uploadform">
      <input type="file" id="upload-files" (change)="storageFileSelect($event)" multiple>
      <input type="file" id="upload-folder" (change)="storageFileSelect($event)" webkitdirectory>
    </form>
  </div>
</div><!-- UploadController -->
