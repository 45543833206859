import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';

import { InsecureUrl } from 'src/app/ajs-upgraded-providers';

import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';
import { YoutubeValidationResult, YouTubeValidationService } from '../services/youtube-validation-service.service';
import { UserService } from 'src/app/common-header/services/user.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';

@Component({
  selector: 'template-component-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.scss'],
})
export class YouTubeComponent implements OnInit {
  spinner = false;
  componentId: string;
  source: string;
  volume: number;
  validationStatus: YoutubeValidationResult['status'];
  termsAccepted = false;

  constructor(
    private elementRef: ElementRef,
    private componentsFactory: ComponentsService,
    private attributeDataService: AttributeDataService,
    private youTubeValidationService: YouTubeValidationService,
    private changeDetectorRef: ChangeDetectorRef,
    private insecureUrl: InsecureUrl,
    private userService: UserService,
    private userStateService: UserStateService
  ) {
    componentsFactory.registerDirective({
      type: 'rise-youtube',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsFactory.selected.id;

        this.load();
      },
    });
  }

  load() {
    this.source = this.attributeDataService.getAvailableAttributeData(this.componentId, 'source');
    this.volume = this.attributeDataService.getAvailableAttributeData(this.componentId, 'volume') || 0;
  }

  ngOnInit(): void {
    this.termsAccepted = !!this.userStateService.getCopyOfProfile().settings?.youtubeTermsAccepted;
  }

  acceptTerms() {
    this.spinner = true;
    this.userService.updateUser(this.userStateService.getUsername(), {
      settings: {
        youtubeTermsAccepted: true
      }
    }).then(() => {
      this.termsAccepted = true;
    }).finally(() => {
      this.spinner = false;
    });
  }

  isValidYouTubeUrl(url: string) {
    /**
     * JavaScript function to match (and return) the video Id
     * of any valid Youtube Url, given as input string.
     * @author: Stephan Schmitz <eyecatchup@gmail.com>
     * @url: https://stackoverflow.com/a/10315969/624466
     */
    const urlWithVideoId = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|live\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const urlWithPlaylistId = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:playlist\?list=|playlist\?.+&list=))(?:\S+)?$/;
    const urlWithUserHandle = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(@[^?#&]+))(?:\S+)?$/;

    return urlWithVideoId.test(url) || urlWithPlaylistId.test(url) || urlWithUserHandle.test(url);
  }

  validateSource() {
    //clear the error
    this.validationStatus = undefined;

    const _source = !this.source ? '' : this.source.trim();
    if (_source === '') {
      //empty string is allowed
      return true;
    }

    if (this.insecureUrl(_source)) {
      this.source = _source.replace('http://', 'https://');
    }

    if (this.isValidYouTubeUrl(_source)) {
      return true;
    }

    this.validationStatus = 'INVALID';
    return false;
  }

  async saveSource() {
    if (this.validateSource()) {
      this.validationStatus = 'VALID';

      this.attributeDataService.setAttributeData(this.componentId, 'source', this.source);

      this.spinner = true;
      this.changeDetectorRef.detectChanges();

      const result = await this.youTubeValidationService.validate(this.source);
      if (result.data?.kind === 'youtube#channel') {
        this.attributeDataService.setAttributeData(this.componentId, 'channelId', result.data.id);
      } else {
        this.attributeDataService.setAttributeData(this.componentId, 'channelId', null);
      }

      this.validationStatus = result.status;
      this.spinner = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  saveVolume() {
    this.attributeDataService.setAttributeData(this.componentId, 'volume', this.volume);
  }
}
