import { Component, Input } from '@angular/core';

import * as angular from 'angular';
import { downgradeComponent } from '@angular/upgrade/static';

import { AddressService } from 'src/app/shared/services/address.service';

@Component({
  selector: 'suggest-general-delivery',
  templateUrl: './suggest-general-delivery.component.html',
  styleUrls: ['./suggest-general-delivery.component.scss']
})
export class SuggestGeneralDeliveryComponent {
  @Input() addressObject: any;

  constructor(
    public addressFactory: AddressService
  ) { }

}

angular.module('risevision.common.components')
  .directive(
    'suggestGeneralDelivery', 
    downgradeComponent({
      component: SuggestGeneralDeliveryComponent
    }) as angular.IDirectiveFactory
  );
