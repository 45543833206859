import { Component, ViewChild, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from 'src/app/components/modals/modal.component';
import { StorageService } from '../../services/storage.service';
import { FilesListComponent } from '../files-list/files-list.component';

@Component({
  selector: 'folder-selector-modal',
  templateUrl: './folder-selector-modal.component.html',
  styleUrls: ['./folder-selector-modal.component.scss']
})
export class FolderSelectorModalComponent extends ModalComponent implements OnInit {

  folderPath: string = '';
  isTrashFolder: boolean = false;
  excludedFiles: string[] = [];
  selectedItemsCount: number = 0;

  @ViewChild(FilesListComponent) filesList: FilesListComponent;

  constructor(
    public modalRef: BsModalRef,
    private storageService: StorageService
  ) {
    super(modalRef);
  }

  ngOnInit(): void {
    this.storageService.setSelectorType('single-folder', 'folders');
    this.storageService.storageFull = false;
  }

  close (files: any[]) {
    super.resolve(files);
    this.storageService.reset();
  }

  dismiss () {
    super.reject();
    this.storageService.reset();
  }

}
