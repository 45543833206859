import { Injectable } from '@angular/core';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { EditorService } from './editor.service';
import { StateService } from '@uirouter/angular';

@Injectable({
  providedIn: 'root'
})
export class ArtboardService {
  public static readonly PRESENTATION_BORDER_SIZE = 10;
  public static readonly PRESENTATION_SIDEBAR_SIZE = 340;

  constructor(private stateService: StateService,
    private editorService: EditorService) { }

  // Need to account for sidebar size when switching from
  // HTML mode to artboard
  sidebarTransition = false;
  designMode = false;
  zoomLevel = 0.5;

  getWorkspaceElement;

  init() {
    if (this.stateService.current.name === 'apps.editor.workspace.htmleditor') {
      this.designMode = false;
    } else {
      this.designMode = true;
    }
  }

  showArtboard() {
    return this.editorService.validatePresentation()
      .then(() => {
        this.sidebarTransition = true;

        this.designMode = true;
        this.stateService.go('apps.editor.workspace.artboard');
      });
  }

  showHtmlEditor() {
    this.designMode = false;
    this.stateService.go('apps.editor.workspace.htmleditor');
  }

  hideSidebar() {
    return !this.designMode;
  }

  canZoomIn() {
    return this.zoomLevel <= 1.99;
  }

  canZoomOut() {
    return this.zoomLevel >= 0.21;
  }

  zoomIn() {
    if (this.canZoomIn()) {
      this.zoomLevel = Math.floor(this.zoomLevel * 10 + 1) / 10;
    }
  }

  zoomOut() {
    if (this.canZoomOut()) {
      this.zoomLevel = Math.ceil(this.zoomLevel * 10 - 1) / 10;
    }
  }

  zoomFit() {
    if (!this.getWorkspaceElement) {
      return;
    }

    var workspaceElement = this.getWorkspaceElement();
    var workspaceWidth = workspaceElement.clientWidth -
      (this.sidebarTransition ? ArtboardService.PRESENTATION_SIDEBAR_SIZE : 0);
    var workspaceHeight = workspaceElement.clientHeight;
    var artboardWidth = this.editorService.presentation.width + 2 * ArtboardService.PRESENTATION_BORDER_SIZE;
    var artboardHeight = this.editorService.presentation.height + 2 * ArtboardService.PRESENTATION_BORDER_SIZE;

    var fitWidth = workspaceWidth / artboardWidth;
    var fitHeight = workspaceHeight / artboardHeight;
    var fitRatio = Math.min(fitWidth, fitHeight);
    fitRatio = Math.min(2, fitRatio);
    fitRatio = Math.max(0.2, fitRatio);

    this.sidebarTransition = false;
    this.zoomLevel = fitRatio;
  }

  zoomPercent(argument) {
    this.zoomLevel = argument / 100;
  }

}

angular.module('risevision.editor.services')
  .factory('artboardService', downgradeInjectable(ArtboardService));
