import { Component, OnInit } from '@angular/core';

import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalComponent } from 'src/app/components/modals/modal.component';
import { WidgetUtilsService } from '../../services/widget-utils.service';

@Component({
  selector: 'store-content',
  templateUrl: './store-content.component.html',
  styleUrls: ['./store-content.component.scss']
})
export class StoreContentComponent extends ModalComponent implements OnInit {
  constructor(modalRef: BsModalRef,
    private widgetUtils: WidgetUtilsService) {
    super(modalRef);
  }

  _addWidgetByUrl = null;

  // Provided by users of the component
  addWidgetByUrl = null;

  products;

  ngOnInit(): void {
    this.products = this.widgetUtils.getProfessionalWidgets();
    if (this.addWidgetByUrl) {
      this._addWidgetByUrl = function () {
        this.close();
        this.addWidgetByUrl();
      };
    }
  }

  slugify(value) {
    return value.toLowerCase().replaceAll(' ', '-');
  }

  select(product) {
    super.resolve(product);
  }

  close() {
    super.resolve();
  }
}
