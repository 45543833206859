import { Component } from '@angular/core';
import { ModalComponent } from '../../modals/modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'distribution-modal',
  templateUrl: './distribution-modal.component.html',
  styleUrls: ['./distribution-modal.component.scss']
})
export class DistributionModalComponent extends ModalComponent {

  distribution: string[];
  subcompanyDistribution: string[];
  distributeToSubcompanies: boolean;
  enableSubcompanies: boolean = false;

  constructor(public modalRef: BsModalRef) {
    super(modalRef);
  }

  apply () {
    super.resolve({
      distribution: this.distribution,
      subcompanyDistribution: this.subcompanyDistribution,
      distributeToSubcompanies: this.distributeToSubcompanies
    });
  }

  dismiss () {
    super.reject();
  }

}