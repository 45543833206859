import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HubspotService {

  loaded = false;

  _hsq: Array<any>;

  constructor() {
    this._hsq = window._hsq = window._hsq || [];
  }

  loadAs(email) {
    if (!this.loaded) {
      this._hsq.push(['identify', {
        email: email
      }]);
      this._hsq.push(['trackPageView']);

      var e = document.createElement('script');
      e.type = 'text/javascript';
      e.defer = !0;
      e.async = !0;
      e.id = 'hs-script-loader';
      e.src = '//js.hs-scripts.com/' + environment.HUBSPOT_ACCOUNT + '.js';
      var n = document.getElementsByTagName('script')[0];
      n.parentNode.insertBefore(e, n);

      this.loaded = true;
    }
  }
}
