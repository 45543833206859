import { Injectable } from '@angular/core';

import * as tus from 'tus-js-client';

import { EncodingService } from './encoding.service';

@Injectable({
  providedIn: 'root'
})
export class TusService {
  upload(domFileItem, options): any {
    return new tus.Upload(domFileItem, options);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TusUploaderService {

  constructor(
    private encodingService: EncodingService,
    private tusService: TusService
  ) { }

  tusUpload(item, FileUploader) {
    var tusUpload = this.tusService.upload(item.domFileItem, {
      endpoint: [item.url, item.taskToken].join('/'),
      retryDelays: [0, 2000, 6000, 9000],
      removeFingerprintOnSuccess: true,
      metadata: {
        filename: item.file.name,
        filetype: item.file.type
      },
      onError: (e: any) => {
        FileUploader.notifyErrorItem(item, e.originalResponse ? e.originalResponse.getStatus() : 0);
        FileUploader.notifyCompleteItem(item);
        this.encodingService.disableEncoding();
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        var pct: any = (bytesUploaded / bytesTotal * 100).toFixed(2);

        // Arbitrarily expect encoding to take as long as uploading
        FileUploader.notifyProgressItem(item, pct / 2);
      },
      onSuccess: () => {
        item.tusURL = tusUpload.url;
        this.encodingService.startEncoding(item)
          .then((resp) => {
            item.encodingStatusURL = resp.statusURL;

            return this.encodingService.monitorStatus(item, (pct) => {
              // Arbitrarily expect upload was first 50% of progress,
              // and encoding is remaining 50%
              FileUploader.notifyProgressItem(item, 50 + pct / 2);
            });
          })
          .then(this.encodingService.acceptEncodedFile.bind(this.encodingService, item.encodingFileName))
          .then(() => {
            FileUploader.notifySuccessItem(item);
            FileUploader.notifyCompleteItem(item);
          })
          .catch((e) => {
            FileUploader.notifyErrorItem(item);
            FileUploader.notifyCompleteItem(item);
            this.encodingService.disableEncoding();
          });
      }
    });

    FileUploader.notifyBeforeUploadItem(item);

    item.tusAbort = () => {
      tusUpload.abort();
      FileUploader.notifyCancelItem(item);
      FileUploader.notifyCompleteItem(item);
    };

    tusUpload.start();
  }
}
