import { Injectable } from '@angular/core';

import * as $ from 'jquery';

import { GadgetsApi } from 'src/app/ajs-upgraded-providers';

import { WidgetUtilsService } from './widget-utils.service';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})
export class WidgetRendererService {

  IFRAME_PREFIX = 'if_';
  _placeholders = {};

  constructor(
    private gadgetsApi: GadgetsApi,
    private companyStateService: CompanyStateService,
    private widgetUtilsService: WidgetUtilsService
  ) {

    gadgetsApi.rpc.register('rsevent_ready', (id) => {
      gadgetsApi.rpc.call(this.IFRAME_PREFIX + id, 'rscmd_play_' + id);
      //force redraw to fix #866
      $('#' + this.IFRAME_PREFIX + id).parent().css('top', '+=1');
      window.setTimeout(() => {
        $('#' + this.IFRAME_PREFIX + id).parent().css('top', '-=1');
      }, 0);
    });

    gadgetsApi.rpc.register('rsparam_get', (id, param) => {
      var value;
      if (typeof (param) === 'string') {
        value = this.getParam(param, id);
      } else if (param.length) {
        value = [];
        for (var i = 0; i < param.length; i++) {
          value[i] = this.getParam(param[i], id);
        }
      }
      gadgetsApi.rpc.call(this.IFRAME_PREFIX + id, 'rsparam_set_' + id, null,
        param,
        value);
    });
  }

  _isRenderingAllowed(placeholder) {
    if (placeholder.items && placeholder.items[0]) {
      var playlistItem = placeholder.items[0];
      var objectReference = playlistItem.objectReference;

      if (this.widgetUtilsService.isRenderingAllowed(objectReference)) {
        if (this.widgetUtilsService.isWebpageWidget(objectReference)) {
          var params = JSON.parse(playlistItem.additionalParams);
          return params && ((params.url && params.url.slice(0, 8) ===
            'https://') || (params.selector && params.selector.url &&
            params.selector.url.slice(0, 8) === 'https://'));
        }
        return true;
      }
    }
    return false;
  }

  _setPlaceholderIcon(placeholder) {
    placeholder.className = '';

    if (placeholder.items && placeholder.items[0]) {
      placeholder.className = this.widgetUtilsService.getIconClass(placeholder.items[0]);
    }
  }

  register(placeholder, element) {
    if (this._isRenderingAllowed(placeholder)) {
      placeholder.className = '';
      this._placeholders[placeholder.id] = placeholder;
      this._createIframe(placeholder, element);
    } else {
      this._setPlaceholderIcon(placeholder);
    }
  }

  unregister(placeholder, element) {
    this._setPlaceholderIcon(placeholder);
    delete this._placeholders[placeholder.id];
    var frameName = this.IFRAME_PREFIX + placeholder.id;
    this.gadgetsApi.rpc.removeReceiver(frameName);
    element.find('#' + frameName).remove();
  }

  forceReload(placeholder, element) {
    if (this._placeholders[placeholder.id]) {
      this.unregister(placeholder, element);
      this.register(placeholder, element);
    }
  }

  notifyChanges(placeholder, element) {
    if (this._placeholders[placeholder.id]) {
      if (!this._isRenderingAllowed(placeholder)) {
        this.unregister(placeholder, element);
      } else {
        this.gadgetsApi.rpc.call(this.IFRAME_PREFIX + placeholder.id,
          'rsparam_set_' +
          placeholder.id, null, 'additionalParams', placeholder.items[
            0].additionalParams);
      }
    } else {
      this.register(placeholder, element);
    }
  }

  _createIframe(placeholder, element) {
    var renderId = placeholder.id;
    var widgetUrl = this.widgetUtilsService.rewriteS3Urls(placeholder.items[0].objectData);
    widgetUrl = widgetUrl +
      (widgetUrl.indexOf('?') > -1 ? '&' : '?') +
      'up_id=' + renderId +
      '&up_companyId=' + this.companyStateService.getSelectedCompanyId() +
      '&up_rsW=' + placeholder.width +
      '&up_rsH=' + placeholder.height +
      '&parent=' + encodeURIComponent(window.location.origin);

    widgetUrl = widgetUrl
      .replace('http://', '//')
      .replace('https://', '//');

    var frameName = this.IFRAME_PREFIX + renderId;
    var myFrame: any = document.createElement('iFrame');
    myFrame.setAttribute('id', frameName);
    myFrame.setAttribute('name', frameName);
    myFrame.style.width = '100%';
    myFrame.style.height = '100%';
    myFrame.setAttribute('allowTransparency', 'true');
    myFrame.setAttribute('frameBorder', '0');
    myFrame.setAttribute('scrolling', 'no');
    element.append(myFrame);
    var myFrameObj = (myFrame.contentWindow) ? myFrame.contentWindow :
      (myFrame.contentDocument.document) ? myFrame.contentDocument.document :
      myFrame.contentDocument;
    myFrame.src = widgetUrl;

    myFrameObj.onload = (() => {
      this.gadgetsApi.rpc.setupReceiver(frameName);
    })();
  }

  getParam(paramName, id) {
    if (paramName === 'additionalParams') {
      return this._placeholders[id].items[0].additionalParams;
    } else {
      return '';
    }
  }

}
