import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { CompanyIcpService } from '../../services/company-icp.service';
import { ModalComponent } from 'src/app/components/modals/modal.component';

@Component({
  selector: 'company-icp-modal',
  templateUrl: './company-icp-modal.component.html',
  styleUrls: ['./company-icp-modal.component.scss']
})
export class CompanyIcpModalComponent extends ModalComponent {

  constructor(public modalRef: BsModalRef) {
    super(modalRef);
  }

  company: any = {};
  COMPANY_INDUSTRY_FIELDS = CompanyIcpService.COMPANY_INDUSTRY_FIELDS;

  save() {
    super.resolve({
      company: this.company
    });
  }

}
