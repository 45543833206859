import { Injectable } from '@angular/core';
import { CoreAPILoader } from 'src/app/ajs-upgraded-providers';
import { CompanyStateService } from 'src/app/auth/services/company-state.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateApiService {

  static readonly TEMPLATE_SEARCH_FIELDS = [ 'name', 'id' ];

  constructor(private companyStateService: CompanyStateService,
    private coreAPILoader: CoreAPILoader) { }

  private _createSearchQuery (fields, search) {
    var query = '';
    for (var i in fields) {
      query += 'OR ' + fields[i] + ':~\"' + search + '\" ';
    }
    query = query.substring(3);
    return query.trim();
  }

  listPresentationTemplates (search, cursor?) {
    var query = search.query ?
      this._createSearchQuery(TemplateApiService.TEMPLATE_SEARCH_FIELDS, search.query) :
      '';
    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'search': query,
      'cursor': cursor,
      'count': search.count,
      'sort': search.sortBy + (search.reverse ? ' desc' : ' asc')
    };
    console.debug('list presentation templates called with', obj);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.presentation.listTemplates(obj);
      })
      .then((resp) => {
        console.debug('list presentation templates resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to get list of presentation templates.', e);
        throw e;
      });
  }

  listTemplates (search, cursor?) {
    var obj = {
      'companyId': this.companyStateService.getSelectedCompanyId(),
      'search': search.query || '*',
      'filter': search.filter || '',
      'cursor': cursor,
      'count': search.count,
      'sort': search.sort || 'defaultOrderWeight ASC'
    };

    console.debug('list templates called with', obj);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.template.list(obj);
      })
      .then((resp) => {
        console.debug('list templates resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to get list of templates.', e);
        throw e;
      });
  }

  getTemplate (productId) {
    var obj = {
      'productId': productId
    };

    console.debug('get template called with', obj);
    return this.coreAPILoader().then((coreApi) => {
        return coreApi.template.getByProductId(obj);
      })
      .then((resp) => {
        console.debug('get template resp', resp);
        return resp.result;
      })
      .catch((e) => {
        console.error('Failed to get get template.', e);
        throw e;
      });
  }
}