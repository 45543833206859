import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as angular from 'angular';
import { setAngularJSGlobal } from '@angular/upgrade/static';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { UIRouter, UrlService } from '@uirouter/core';

setAngularJSGlobal(angular);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(platformRef => {
    const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;
    platformRef.injector.get<NgZone>(NgZone).run(() => {
      urlService.listen();
      urlService.sync();
    });
  })
  .catch(err => console.log(err));
