@if (showActivationNotice) {
  <div>
    <div class="madero-style alert alert-info mb-2">
      <div [shown]="isFirstDisplay">
        <strong>You&rsquo;ve activated your first display!</strong>
      </div>
      <div [shown]="!isFirstDisplay">
        <strong>Your display is activated.</strong>
      </div>
      <div [shown]="showAddScheduleNotice">
        Select a schedule to start showing content.
      </div>
      <div [shown]="showAddPresentationNotice">
        Start showing content by creating your first presentation.
      </div>
    </div>
    <button class="btn btn-primary w-100 mt-3 mb-2" [shown]="showAddPresentationNotice" (click)="editorService.addPresentationModal()">Create Presentation</button>
  </div>
}
