import { Component, ElementRef } from '@angular/core';

import { ComponentsService } from '../../services/components.service';
import { AttributeDataService } from '../../services/attribute-data.service';

@Component({
  selector: 'template-component-html',
  templateUrl: './html.component.html',
  styleUrls: ['./html.component.scss']
})
export class HtmlComponent {

  public componentId: string;
  public html: string;
  public codemirrorOptions = {};
  public selected = false;
  public validationError = false;

  constructor(private elementRef: ElementRef,
    private componentsFactory: ComponentsService,
    private attributeDataFactory: AttributeDataService) {

    this.componentsFactory.registerDirective({
      type: 'rise-html',
      element: this.elementRef.nativeElement,
      show: () => {
        this.componentId = this.componentsFactory.selected.id;
        this._load();
      },
      onBackHandler: () => {
        this.selected = false;
        this.codemirrorOptions = {};

        return false;
      }
    });
  }

  _load() {
    var html = this.attributeDataFactory.getAvailableAttributeData(this.componentId, 'html');

    this.html = html;
    this.selected = true;
    this.validationError = false;

    setTimeout(() => {
      this.codemirrorOptions = {
        lineNumbers: true,
        theme: 'default',
        lineWrapping: false,
        mode: 'htmlmixed'
      };
    }, 400);

  }

  save() {
    if (this.html && this.html.indexOf('http://') !== -1) {
      this.validationError = true;
    } else {
      this.validationError = false;
      this.attributeDataFactory.setAttributeData(this.componentId, 'html', this.html);      
    }
  }

}
