@if (allowUpload) {
  <upload [folderPath]="folderPath" (addFile)="addFile($event)" (queueLengthChange)="uploadQueueLength = $event"></upload>
}
<div [shown]="filesDetails.apiError" class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
  <p><strong>Failed to load files</strong></p>
  {{filesDetails.apiError}}. If the problem persists, please
  <a class="madero-link" href="#" (click)="helpWidgetFactory.showContactUs()">Contact Us</a>.
</div>
<div class="flex-row button-toolbar-md-folded">
  <search-filter class="w-100" [filterConfig]="filterConfig" [search]="search" [doSearch]="search.doSearch" [localSearch]="true" [shown]="isFileListVisible()" iconSet="madero"></search-filter>
  @if (allowUpload) {
    <div class="ml-auto button-toolbar flex-row">
      <div class="search-buttons" [ngClass]="{'disabled': isTrashFolder}">
        <button tabindex="0" id="newFolderButton" class="btn btn-default" (click)="addFolder()" [disabled]="isTrashFolder">
          Create Folder
        </button>
        <label tabindex="0" id="uploadButtonUnsubscribed" class="btn btn-primary" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
          Upload Files
        </label>
        <label tabindex="0" id="uploadButton" class="btn btn-primary" for="upload-files" [shown]="currentPlanService.isPlanActive()">
          Upload Files
        </label>
        <label tabindex="0" id="uploadFolderButtonUnsubscribed" class="btn btn-primary" (click)="currentPlanService.showUnlockThisFeatureModal()" [hidden]="currentPlanService.isPlanActive()">
          Upload Folder
        </label>
        <label tabindex="0" id="uploadFolderButton" class="btn btn-primary" for="upload-folder" [shown]="currentPlanService.isPlanActive()">
          Upload Folder
        </label>
      </div>
    </div>
  }
</div>
<div class="flex-row u_margin-md-top" [shown]="isFileListVisible()">
  <div class="flex-row-entry flex-grow">
    <storage-breadcrumb [folderPath]="folderPath" (folderClicked)="changeFolder({name: $event})"></storage-breadcrumb>
  </div>
  @if (storageService.isMultipleSelector() && !allowBatchOperations && filesDetails.checkedItemsCount > 0) {
    <div class="flex-row-entry pr-4">
      {{ filesDetails.checkedItemsCount }} Selected
    </div>
  }
  <div class="flex-row-entry" [shown]="filesDetails.bucketExists && allowUpload">
    <list-grid-toggle [(isListView)]="isListView"></list-grid-toggle>
  </div>
</div>
<div [shown]="files.apiError && filesDetails.checkedItemsCount > 0" class="madero-style alert alert-danger text-center u_margin-md-top mb-0" role="alert">
  <p><strong>{{files.errorMessage}}</strong><br>{{files.apiError}}</p>
</div>
<div id="files-toolbar" class="flex-row" [hidden]="fileListStatus.code === 404">
  @if (allowBatchOperations) {
    <batch-operations class="w-100" [listObject]="files"></batch-operations>
  }
</div>
<div>
  <div class="scrollable-list horizontal-scroll border-container u_margin-md-top u_margin-md-bottom grid-selector" rvSpinner="storage-selector-loader" [rvShowSpinner]="files.loadingItems">
    @if (isListView) {
      <storage-list-view
        [files]="files.items.list"
        [folderPath]="folderPath"
        [filesDetails]="filesDetails"
        [fileListVisible]="isFileListVisible()"
        [search]="search"
        [selectAll]="files.search.selectAll"
        (selectAllCheckboxes)="files.selectAll()"
        (fileClick)="fileClick($event)">
      </storage-list-view>
    }
    @else {
      <storage-grid-view
        [files]="files.items.list"
        [folderPath]="folderPath"
        [filesDetails]="filesDetails"
        [fileListVisible]="isFileListVisible()"
        [search]="search"
        (fileClick)="fileClick($event)">
      </storage-grid-view>
    }
    <empty-state [shown]="!files.loadingItems && isEmptyState()" [isMultipleFilesFoldersSelector]="storageService.isMultipleFilesFoldersSelector()"></empty-state>
  </div>
</div>
