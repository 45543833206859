<div>
  <div class="auto-save text-center">
    <div>
      <last-modified [changeDate]="templateEditorFactory.presentation.changeDate" [changedBy]="templateEditorFactory.presentation.changedBy"></last-modified>
      •
      @if (templateEditorFactory.hasContentEditorRole()) {
        <span>
          <span [shown]="!templateEditorFactory.savingPresentation && templateEditorFactory.isUnsaved()">
            Unsaved changes
          </span>
          <span [shown]="templateEditorFactory.savingPresentation">
            Saving changes...
          </span>
          <span [shown]="!templateEditorFactory.savingPresentation && !templateEditorFactory.isUnsaved()">
            All changes saved <streamline-icon aria-hidden="true" name="checkmark" width="12" height="12"></streamline-icon>
          </span>
        </span>
      }
      @else {
        <span class="text-danger">
          You don’t have permission to edit
        </span>
      }
    </div>
  </div>
  <button id="publishButton" *requireRole="'cp'" class="btn btn-primary btn-block"
    [disabled]="templateEditorFactory.isPublishDisabled()"
    (click)="templateEditorFactory.publish()">
    Publish
  </button>
</div>
