import { Injectable } from '@angular/core';
import { TimelineUtilsService } from './timeline-utils.service';

@Injectable({
  providedIn: 'root'
})
export class TimelineBasicService {

  private _timeline: any;
  get timeline() {
    return this._timeline;
  }
  set timeline(timeline: any) {
    this._timeline = timeline;
    this._recurrence = {
      weekly: {}
    };

    // _init
    if (timeline.allDay) {
      this._timeline.startTime = this.timelineUtilsService.getDateTime(8, 0, timeline.useLocaldate);
      this._timeline.endTime = this.timelineUtilsService.getDateTime(17, 30, timeline.useLocaldate);
    }

    // _initRecurrence
    if (timeline.recurrenceDaysOfWeek) {
      this._recurrence.weekly = this.timelineUtilsService.parseWeeklyRecurrence(timeline.recurrenceDaysOfWeek);
    }
  }

  private _recurrence: any;
  get recurrence() {
    return this._recurrence;
  }

  constructor(private timelineUtilsService: TimelineUtilsService) { }

  save () {
    if (!this._timeline) {
      return;
    }

    if (this._timeline.allDay) {
      this._timeline.startTime = null;
      this._timeline.endTime = null;
    }

    // _saveRecurrence
    let recurrenceDaysOfWeek = [];

    if (!this._timeline.everyDay) {
      recurrenceDaysOfWeek = this.timelineUtilsService.saveWeeklyRecurrence(this._recurrence.weekly);
    }

    this._timeline.recurrenceDaysOfWeek = recurrenceDaysOfWeek;
    this._timeline.everyDay = recurrenceDaysOfWeek.length === 0 || recurrenceDaysOfWeek.length === 7;
  }
}
