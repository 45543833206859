import { Directive, Input, Injector, ElementRef } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

/* eslint-disable @angular-eslint/directive-class-suffix */
@Directive({selector: 'background-image'})
export class BackgroundImageComponent extends UpgradeComponent {

  @Input() background!: any;
  @Input() companyId!: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('backgroundImageSetting', elementRef, injector);
  }

}
