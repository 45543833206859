<div id="timelineModal">
  <div class="modal-header">
    <button type="button" class="close" (click)="dismiss()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 class="modal-title">Edit Timeline</h4>
  </div>
  <div class="modal-body align-left">
    <form role="form" #timelineForm="ngForm" id="forms.timelineForm" name="forms.timelineForm">
      <div class="timeline">
        <section
          ngModelGroup="timelineDates"
          #timelineDates="ngModelGroup"
          dateEndAfterStart
          [startDate]="startDate"
          [endDate]="endDate"
          [skipValidation]="processing">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group">
                <label class="control-label">Start Date</label>
                <div class="input-group">
                  <input type="text"
                    id="startDate"
                    name="startDate"
                    class="form-control"
                    [(ngModel)]="timeline.startDate"
                    (ngModelChange)="detectChanges()"
                    (onShown)="datepickerShown()"
                    bsDatepicker
                    #startDate="ngModel"
                    #startDatePicker="bsDatepicker"
                    [bsConfig]="options"
                    [required]="!timeline.always" />
                  <span class="input-group-btn">
                    <button type="button" class="btn btn-primary" (click)="startDatePicker.show()"><i class="fa fa-calendar"></i></button>
                  </span>
                </div>
              </div>
            </div><!--col-sm-4-->
            <div class="col-sm-4">
              <div class="form-group">
                <label class="control-label">End Date</label>
                <div class="input-group">
                  <input type="text"
                    id="endDate"
                    name="endDate"
                    class="form-control"
                    [(ngModel)]="timeline.endDate"
                    (ngModelChange)="detectChanges()"
                    (onShown)="datepickerShown()"
                    bsDatepicker
                    #endDate="ngModel"
                    #endDatePicker="bsDatepicker"
                    [bsConfig]="options"
                    [minDate]="timeline.startDate" />
                  <span class="input-group-btn">
                    <button type="button" class="btn btn-primary" (click)="endDatePicker.show()"><i class="fa fa-calendar"></i></button>
                  </span>
                </div>
              </div>
            </div><!--col-sm-4-->
          </div><!--row-->
          @if (startDate.errors?.required) {
            <p class="text-danger">Start Date is a required field</p>
          }
          @if (timelineDates.errors?.dateEndAfterStart) {
            <p class="text-danger">End Date must be later than Start Date</p>
          }
        </section>
        <section>
          <pick-time-range [(allDay)]="timeline.allDay" [(startTime)]="timeline.startTime" [(endTime)]="timeline.endTime"></pick-time-range>
        </section>
        <section>
          <label class="control-label u_margin-sm-bottom" for="recurrence">
            <strong>Recurrence</strong>
          </label>
          <div class="form-group">
            <div class="flex-row">
              <div class="madero-radio flex-center mr-4" id="dailyRadio">
                <input type="radio"
                  [(ngModel)]="timeline.recurrenceType"
                  (ngModelChange)="detectChanges()"
                  value="Daily"
                  id="Daily"
                  name="recurrenceType">
                <label for="Daily" class="u_margin-right control-label">Daily</label>
              </div>
              <div class="madero-radio flex-center mr-4" id="weeklyRadio">
                <input type="radio"
                  [(ngModel)]="timeline.recurrenceType"
                  (ngModelChange)="detectChanges()"
                  value="Weekly"
                  id="Weekly"
                  name="recurrenceType">
                <label for="Weekly" class="u_margin-right control-label">Weekly</label>
              </div>
              <div class="madero-radio flex-center mr-4" id="monthlyRadio">
                <input type="radio"
                  [(ngModel)]="timeline.recurrenceType"
                  (ngModelChange)="detectChanges()"
                  value="Monthly"
                  id="Monthly"
                  name="recurrenceType">
                <label for="Monthly" class="u_margin-right control-label">Monthly</label>
              </div>
              <div class="madero-radio flex-center" id="yearlyRadio">
                <input type="radio"
                  [(ngModel)]="timeline.recurrenceType"
                  (ngModelChange)="detectChanges()"
                  value="Yearly"
                  id="Yearly"
                  name="recurrenceType">
                <label for="Yearly" class="u_margin-right control-label">Yearly</label>
              </div>
            </div>
          </div>
          <div class="recurrence-option">
            <div [shown]="timeline.recurrenceType === 'Daily'">
              <div class="form-group">
                <label class="control-label">Every</label>
                <input type="number"
                  class="form-control input-short"
                  id="dailyRecurrenceFrequency"
                  name="dailyRecurrenceFrequency"
                  [(ngModel)]="recurrence.daily.recurrenceFrequency"
                  (ngModelChange)="detectChanges()"
                  #dailyRecurrenceFrequency="ngModel"
                  min="1"
                  max="999"
                  [required]="timeline.recurrenceType === 'Daily'">
                <label class="control-label">Day(s)</label>
              </div><!--form group-->
              @if (dailyRecurrenceFrequency.errors) {
                <p class="text-danger pb-3">
                  Daily Recurrence Frequency must be a number between 1 and 999
                </p>
              }
            </div>
            <div [shown]="timeline.recurrenceType === 'Weekly'">
              <div class="form-group">
                <label class="control-label">Every</label>
                <input type="number"
                  class="form-control input-short"
                  id="weeklyRecurrenceFrequency"
                  name="weeklyRecurrenceFrequency"
                  [(ngModel)]="recurrence.weekly.recurrenceFrequency"
                  (ngModelChange)="detectChanges()"
                  #weeklyRecurrenceFrequency="ngModel"
                  min="1"
                  max="999"
                  [required]="timeline.recurrenceType === 'Weekly'">
                <label class="control-label">Week(s)</label>
              </div>
              @if (weeklyRecurrenceFrequency.errors) {
                <p class="text-danger pb-3">
                  Weekly Recurrence Frequency must be a number between 1 and 999
                </p>
              }
              <weekday-checkboxes [(days)]="recurrence.weekly"></weekday-checkboxes>
            </div>
            <div [shown]="timeline.recurrenceType === 'Monthly'">
              <div class="form-group flex-row">
                <div class="madero-radio mr-2 u_nowrap">
                  <input type="radio"
                    id="monthlyRecurrenceAbsoluteTrue"
                    name="monthlyRecurrenceAbsoluteOption"
                    [(ngModel)]="recurrence.monthly.recurrenceAbsolute"
                    (ngModelChange)="detectChanges()"
                    [value]="true">
                  <label for="monthlyRecurrenceAbsoluteTrue" class="control-label">Day</label>
                </div>
                <fieldset [disabled]="!recurrence.monthly.recurrenceAbsolute" class="flex-center">
                  <input type="number"
                    class="form-control input-short"
                    name="monthlyAbsoluteRecurrenceDayOfMonth"
                    [(ngModel)]="recurrence.monthly.absolute.recurrenceDayOfMonth"
                    (ngModelChange)="detectChanges()"
                    #monthlyAbsoluteRecurrenceDayOfMonth="ngModel"
                    min="1"
                    max="31"
                    [required]="timeline.recurrenceType === 'Monthly' && recurrence.monthly.recurrenceAbsolute">
                  <label class="control-label">of Every</label>
                  <input type="number"
                    class="form-control input-short"
                    name="monthlyAbsoluteRecurrenceFrequency"
                    [(ngModel)]="recurrence.monthly.absolute.recurrenceFrequency"
                    (ngModelChange)="detectChanges()"
                    #monthlyAbsoluteRecurrenceFrequency="ngModel"
                    min="1"
                    max="999"
                    [required]="timeline.recurrenceType === 'Monthly' && recurrence.monthly.recurrenceAbsolute">
                  <label class="control-label">Month(s)</label>
                </fieldset>
              </div>
              @if (monthlyAbsoluteRecurrenceDayOfMonth.errors) {
                <p class="text-danger pb-3">
                  Monthly Recurrence Day Of Month value must be between 1 and 31
                </p>
              }
              @if (monthlyAbsoluteRecurrenceFrequency.errors) {
                <p class="text-danger pb-3">
                  Monthly Recurrence Frequency must be a number between 1 and 999
                </p>
              }
              <div class="form-group flex-row">
                <div class="madero-radio mr-2">
                  <input type="radio"
                    id="monthlyRecurrenceAbsoluteFalse"
                    name="monthlyRecurrenceAbsoluteOption"
                    [(ngModel)]="recurrence.monthly.recurrenceAbsolute"
                    (ngModelChange)="detectChanges()"
                    [value]="false">
                  <label for="monthlyRecurrenceAbsoluteFalse" class="control-label">The</label>
                </div>
                <fieldset [disabled]="recurrence.monthly.recurrenceAbsolute" class="flex-center">
                  <week-dropdown [(week)]="recurrence.monthly.relative.recurrenceWeekOfMonth"></week-dropdown>
                  <weekday-dropdown [(weekday)]="recurrence.monthly.relative.recurrenceDayOfWeek"></weekday-dropdown>
                  <label class="control-label">of Every</label>
                  <input type="number"
                    class="form-control input-short"
                    name="monthlyRelativeRecurrenceFrequency"
                    [(ngModel)]="recurrence.monthly.relative.recurrenceFrequency"
                    (ngModelChange)="detectChanges()"
                    #monthlyRelativeRecurrenceFrequency="ngModel"
                    min="1"
                    max="999"
                    [required]="timeline.recurrenceType === 'Monthly' && !recurrence.monthly.recurrenceAbsolute">
                  <label class="control-label">Month(s)</label>
                </fieldset>
              </div><!--form-group-->
              @if (monthlyRelativeRecurrenceFrequency.errors) {
                <p class="text-danger pb-3">
                  Monthly Relative Recurrence Frequency must be a number between 1 and 999
                </p>
              }
            </div>
            <div [shown]="timeline.recurrenceType === 'Yearly'">
              <div class="form-group flex-row">
                <div class="madero-radio mr-2 u_nowrap">
                  <input type="radio"
                    id="yearlyRecurrenceAbsoluteTrue"
                    name="yearlyRecurrenceAbsoluteOption"
                    [(ngModel)]="recurrence.yearly.recurrenceAbsolute"
                    (ngModelChange)="detectChanges()"
                    [value]="true">
                  <label for="yearlyRecurrenceAbsoluteTrue" class="control-label">Every</label>
                </div>
                <fieldset [disabled]="!recurrence.yearly.recurrenceAbsolute" class="u_nowrap">
                  <month-dropdown [(month)]="recurrence.yearly.absolute.recurrenceMonthOfYear"></month-dropdown>
                  <input type="number"
                    class="form-control input-short"
                    name="yearlyAbsoluteRecurrenceDayOfMonth"
                    [(ngModel)]="recurrence.yearly.absolute.recurrenceDayOfMonth"
                    (ngModelChange)="detectChanges()"
                    #yearlyAbsoluteRecurrenceDayOfMonth="ngModel"
                    min="1"
                    max="31"
                    [required]="timeline.recurrenceType === 'Yearly' && recurrence.yearly.recurrenceAbsolute">
                </fieldset>
              </div>
              @if (yearlyAbsoluteRecurrenceDayOfMonth.errors) {
                <p class="text-danger pb-3">
                  Yearly Recurrence Day Of Month value must be between 1 and 31
                </p>
              }
              <div class="form-group flex-row">
                <div class="madero-radio mr-2">
                  <input type="radio"
                    id="yearlyRecurrenceAbsoluteFalse"
                    name="yearlyRecurrenceAbsoluteOption"
                    [(ngModel)]="recurrence.yearly.recurrenceAbsolute"
                    (ngModelChange)="detectChanges()"
                    [value]="false">
                  <label for="yearlyRecurrenceAbsoluteFalse" class="control-label">The</label>
                </div>
                <fieldset [disabled]="recurrence.yearly.recurrenceAbsolute">
                  <week-dropdown [(week)]="recurrence.yearly.relative.recurrenceWeekOfMonth"></week-dropdown>
                  <weekday-dropdown [(weekday)]="recurrence.yearly.relative.recurrenceDayOfWeek"></weekday-dropdown>
                  <label class="control-label">of</label>
                  <month-dropdown [(month)]="recurrence.yearly.relative.recurrenceMonthOfYear"></month-dropdown>
                </fieldset>
              </div><!--form-group-->
            </div>
          </div>
        </section>
      </div><!--timeline-->
    </form>
  </div><!--modal-body-->
  <div class="modal-footer">
    <button type="button" id="timelineModalCancel" class="btn btn-default btn-toolbar" (click)="dismiss()">Cancel</button>
    <button type="button"
      id="timelineModalApply"
      class="btn btn-primary btn-toolbar"
      (click)="apply()"
      [disabled]="!timelineForm.valid">
      Apply
    </button>
  </div>
</div>