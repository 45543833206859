import { Directive, ElementRef, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[rvSortable]',
})
export class RvSortableDirective implements OnInit, OnDestroy {

  @Input() appendTo: string;

  @Output()
  public rvSortable = new EventEmitter<any>();

  private sortable;

  constructor(private el: ElementRef) {

  }

  ngOnInit() {
    if (window.Draggable) {
      this.sortable = new window.Draggable.Sortable(this.el.nativeElement, {
        handle: '.rv-sortable-handle',
        draggable: '.rv-sortable-item',
        mirror: {
          appendTo: this.appendTo,
          constrainDimensions: true,
          xAxis: false
        }
      });

      this.sortable.on('sortable:stop', (evt) => {
        setTimeout(() => {
          this.rvSortable.emit(evt);
        });
      });
    }
  }

  ngOnDestroy() {
    this.sortable && this.sortable.destroy();
  }

}
