import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'src/app/components/content-oauth/services/oauth.service';
import { UserStateService } from 'src/app/auth/services/user-state.service';

@Injectable({
  providedIn: 'root'
})
export class MicrosoftService {
  constructor(
    protected httpClient: HttpClient,
    private userStateService: UserStateService,
    private authService: OAuthService
  ) { }

  _getRequestOptions(): any {
    var authorization = (this.userStateService.getAccessToken().token_type === 'Bearer') ?
      this.userStateService.getAccessToken().token_type + ' ' + this.userStateService.getAccessToken().access_token :
      this.userStateService.getAccessToken().access_token;

    var headers = new HttpHeaders({
      'authorization': authorization
    });

    var requestOptions = {
      headers,
      withCredentials: true,
      responseType: 'json'
    };

    return requestOptions;
  }

  revoke(provider) {
    return this.authService.revoke(provider)
    .then((revoked: boolean) => revoked)
    .catch( (err) => {
      console.error('Failed to revoke access for user.', err);

      throw err;
    });
  }
}
