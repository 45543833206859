import { Component, ElementRef, ChangeDetectorRef, EventEmitter } from '@angular/core';

import * as _ from 'lodash';

import { ComponentsService } from '../../services/components.service';
import { AttributeDataService } from '../../services/attribute-data.service';
import { TemplateEditorService } from '../../services/template-editor.service';

import { InstrumentSearchService } from '../services/instrument-search.service';

@Component({
  selector: 'template-component-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss']
})
export class FinancialComponent {

  private wording = {
    "add-category": {
      "bonds": "Add Bonds",
      "commodities": "Add Commodities",
      "currencies": "Add Currencies",
      "market-statistics": "Add Market Statistics",
      "stocks": "Add Stocks",
      "world-indexes": "Add World Indexes"
    },
    "empty-list": {
      "bonds": "Add bonds to keep your display interesting!",
      "commodities": "Add commodities to keep your display interesting!",
      "currencies": "Add currencies to keep your display interesting!",
      "market-statistics": "Add market statistics to keep your display interesting!",
      "stocks": "Add stocks to keep your display interesting!",
      "world-indexes": "Add world indexes to keep your display interesting!"
    },
    "most-popular-category": {
      "bonds": "Most Popular Bonds",
      "commodities": "Most Popular Commodities",
      "currencies": "Most Popular Currencies",
      "market-statistics": "Most Popular Market Statistics",
      "stocks": "Most Popular Stocks",
      "world-indexes": "Most Popular World Indexes"
    },
    "search-category": {
      "bonds": "Search Bonds",
      "commodities": "Search Commodities",
      "currencies": "Search Currencies",
      "market-statistics": "Search Market Statistics",
      "stocks": "Search Stocks",
      "world-indexes": "Search World Indexes"
    }
  };

  public componentId: string;

  public searching = false;
  public searchKeyword: string;

  public showInstrumentList = false;
  private showInstrumentListChange = new EventEmitter<boolean>();

  public showSymbolSelector = false;
  public enteringInstrumentSelector = false;
  public exitingInstrumentSelector = false;
  public enteringSymbolSelector = false;
  public exitingSymbolSelector = false;

  public category = '';
  public instruments = [];

  public instrumentSearch = [];
  public popularResults;
  public canAddInstrument;

  _reset() {
    this.showInstrumentList = false;
    this.showInstrumentListChange.emit(this.showInstrumentList);
    this.showSymbolSelector = false;
    this.enteringInstrumentSelector = false;
    this.exitingInstrumentSelector = false;
    this.enteringSymbolSelector = false;
    this.exitingSymbolSelector = false;

    this.category = '';
    this.instruments = [];
  }

  constructor(private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private componentsFactory: ComponentsService,
    private attributeDataFactory: AttributeDataService,
    public templateEditorFactory: TemplateEditorService,
    private instrumentSearchService: InstrumentSearchService) {

    this.componentsFactory.registerDirective({
      type: 'rise-data-financial',
      element: this.elementRef.nativeElement,
      show: () => {
        this._reset();
        this.componentId = this.componentsFactory.selected.id;
        this.category = this._getCategory();

        this._loadInstrumentList();
        this.enteringInstrumentSelector = true;

        setTimeout(() => {
          this.enteringInstrumentSelector = false;
          this.showInstrumentList = true;
          this.showInstrumentListChange.emit(this.showInstrumentList);

          this.changeDetectorRef.detectChanges();
        }, 1000);
      },
      isHeaderBottomRuleVisible: () => {
        return this.enteringInstrumentSelector || this.showInstrumentList ||
          this.exitingInstrumentSelector || this.exitingSymbolSelector;
      },
      onBackHandler: () => {
        if (this.showSymbolSelector) {
          this._changeInstrumentView(false);
          return true;
        }
      }

    });

    this.showInstrumentListChange.subscribe({
      next: (value) => {
        if (value) {
          this.searching = false;
      
          if (this.instrumentSearch) {
            this.instrumentSearch.forEach((item) => {
              item.isSelected = false;
            });
          }
      
          this.resetSearch();
        }
      }
    });

  }

  _loadInstrumentList() {
    var instruments =
      this.attributeDataFactory.getAttributeData(this.componentId, 'instruments');

    if (instruments) {
      this.instruments = instruments;
    } else {
      this._buildInstrumentListFromBlueprint();
    }
  }

  _buildInstrumentListFromBlueprint() {
    this.templateEditorFactory.loadingPresentation = true;
    var symbolString = this.attributeDataFactory.getBlueprintData(this.componentId, 'symbols');

    if (!symbolString) {
      console.error('The component blueprint data is not providing default symbols value: ' + this.componentId);

      return;
    }

    var instruments = [];
    var symbols = symbolString.split('|');

    this._buildListRecursive(instruments, symbols);
  }

  _buildListRecursive(instruments, symbols) {
    if (symbols.length === 0) {
      this._setInstruments(instruments);
      this.templateEditorFactory.loadingPresentation = false;

      return;
    }

    var symbol = symbols.shift();

    this.instrumentSearchService.keywordSearch(this.category, symbol)
      .then((items) => {
        var instrument = _.find(items, {
          symbol: symbol
        });

        if (instrument) {
          instruments.push(instrument);
        } else {
          console.warn('no instrument found for symbol: ' + symbol);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this._buildListRecursive(instruments, symbols);
      });
  }

  _symbolsFor(instruments) {
    return _.map(instruments, (instrument) => {
      return instrument.symbol;
    }).join('|');
  }

  _setInstruments(instruments) {
    var value = _.cloneDeep(instruments);

    this.instruments = value;
    this.attributeDataFactory.setAttributeData(this.componentId, 'instruments', value);
    this.attributeDataFactory.setAttributeData(this.componentId, 'symbols', this._symbolsFor(value));
  }

  _getCategory() {
    var category = this.attributeDataFactory.getBlueprintData(this.componentId, 'category');

    if (!category) {
      console.error('No category was defined for financial component');
    }

    return category;
  }

  showSymbolSearch() {
    this._changeInstrumentView(true);
  }

  selectInstruments() {
    this._changeInstrumentView(false);
  }

  searchKeyPressed() {
    this.searchInstruments();
  }

  selectInstrument(key) {
    if (this.searching) {
      return;
    }
    this.instrumentSearch[key].isSelected = !this.instrumentSearch[key].isSelected;
    this.canAddInstrument = _.some(this.instrumentSearch, (item) => {
      return item.isSelected === true;
    });
  }

  addInstrument() {
    var instrumentsSelected = _.chain(this.instrumentSearch)
      .filter((instrument) => {
        return instrument.isSelected;
      })
      .sortBy('symbol')
      .map((instrument) => {
        delete instrument.isSelected;
        return instrument;
      })
      .value()
      .reverse();

    var instrumentsToAdd = _.reject(instrumentsSelected, (instrument) => {
      return _.find(this.instruments, (item) => {
        return item.symbol === instrument.symbol;
      }) !== undefined;
    });

    if (instrumentsToAdd.length && instrumentsToAdd.length > 0) {
      var instruments = _.cloneDeep(this.instruments);

      instrumentsToAdd.forEach((item) => {
        instruments.unshift(item);
      });

      this._setInstruments(instruments);
    }

    this.selectInstruments();
  }

  searchInstruments() {
    var promise = this.searchKeyword ?
      this.instrumentSearchService.keywordSearch(this.category, this.searchKeyword) :
      this.instrumentSearchService.popularSearch(this.category);

    this.searching = true;
    this.canAddInstrument = false;

    promise.then((res) => {
        this.instrumentSearch = _.cloneDeep(res);
        this.popularResults = !this.searchKeyword;
        this.searching = false;
      })
      .catch((err) => {
        console.error(err);
        this.searching = false;
      });
  }

  resetSearch() {
    this.searchKeyword = '';
    this.searchInstruments();
  }

  getOpenSymbolSelectorButtonLabel() {
    return this._getFinancialLabel('add-category');
  }

  getEmptyListSuggestionText() {
    return this._getFinancialLabel('empty-list');
  }

  getPopularTitle() {
    return this._getFinancialLabel('most-popular-category');
  }

  getSearchPlaceholder() {
    return this._getFinancialLabel('search-category');
  }

  _getFinancialLabel(key) {
    var category = this.category.toLowerCase().replace(' ', '-');

    return this.wording[key][category];
  }

  removeInstrument(symbol) {
    var filtered = _.reject(this.instruments, {
      symbol: symbol
    });

    this._setInstruments(filtered);
  }

  _changeInstrumentView(enteringSelector, delay?) {
    this.showInstrumentList = false;
    this.showInstrumentListChange.emit(this.showInstrumentList);
    this.showSymbolSelector = false;

    if (enteringSelector) {
      this.enteringSymbolSelector = true;
      this.exitingSymbolSelector = false;
    } else {
      this.enteringSymbolSelector = false;
      this.exitingSymbolSelector = true;
    }

    setTimeout(() => {
      this.enteringSymbolSelector = false;
      this.exitingSymbolSelector = false;
      this.showSymbolSelector = enteringSelector;
      this.showInstrumentList = !enteringSelector;
      this.showInstrumentListChange.emit(this.showInstrumentList);

      this.changeDetectorRef.detectChanges();
    }, !isNaN(delay) ? delay : 500);
  }

}
