import { Directive, ElementRef, Input, OnInit, SecurityContext } from '@angular/core';
import { PresentationsService } from '../services/presentations.service';

@Directive({
  selector: '[presentationName]'
})
export class PresentationNameDirective implements OnInit {

  @Input() presentationName: string;

  constructor (
    private element: ElementRef,
    private presentationsService: PresentationsService
  ) { }

  ngOnInit(): void {
    if (this.presentationName) {
      this.presentationsService.getPresentationCached(this.presentationName).then((presentation) => {
        this.element.nativeElement.innerText = presentation.name;
      });
    }
  }
}
