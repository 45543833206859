<select id="resolutionSelect" class="form-control" [(ngModel)]="resolutionOption" (change)="updateResolution()">
  @for (key of objectKeys(resolutionOptions); track key) {
    <option value="{{key}}" label="{{resolutionOptions[key]}}">{{resolutionOptions[key]}}</option>
  }
</select>
@if (resolutionOption === 'custom') {
  <div id="customResolution" class="animated fadeIn u_margin-sm-top">
    <div class="row sm-gutter">
      <div class="col-sm-6">
        <label id="widthLabel" class="control-label">Width:</label>
        <div class="input-group">
          <input #widthInput="ngModel" name="width" id="widthInput" class="form-control" type="number" [(ngModel)]="width" (ngModelChange)="widthChange.emit(width)">
          <span id="widthPxLabel" class="input-group-addon">px</span>
        </div>
      </div>
      <div class="col-sm-6">
        <label id="heightLabel" class="control-label">Height:</label>
        <div class="input-group">
          <input #heightInput="ngModel" name="height" id="heightInput" class="form-control" type="number" [(ngModel)]="height" (ngModelChange)="heightChange.emit(height)">
          <span id="heightPxLabel" class="input-group-addon">px</span>
        </div>
      </div>
    </div>
  </div>
}
