import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import * as _ from 'lodash';
import { AnalyticsFactory } from 'src/app/ajs-upgraded-providers';
import { AttributeDataService } from '../../services/attribute-data.service';
import { ComponentsService } from '../../services/components.service';
import { BlueprintService } from '../../services/blueprint.service';
import { PlaylistComponentService } from '../services/playlist-component.service';
import { MediaSelectorService } from '../services/media-selector.service';
import { FileExistenceCheckService } from '../services/file-existence-check.service';
import { StorageManagerService } from 'src/app/storage/services/storage-manager.service';
import { LogoImageService } from '../services/logo-image.service';
import { Subscription } from 'rxjs';
import { PlaylistService, PlaylistTemplateItem } from '../services/playlist.service';
import { FeaturesService } from 'src/app/components/plans/features.service';
import { UpgradeNoticeComponent } from 'src/app/components/plans/upgrade-notice/upgrade-notice.component';
import { WebPageService } from '../services/web-page.service';

@Component({
  selector: 'template-component-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent {

  selectedItem: any = {};
  subscription: Subscription;

  @ViewChildren('ItemName') itemNameInputs: ElementRef[];
  @ViewChildren('UpgradeNotice') notices: QueryList<UpgradeNoticeComponent>;

  constructor(private elementRef: ElementRef,
    public playlistService: PlaylistService,
    private featuresService: FeaturesService,
    private componentsService: ComponentsService,
    private attributeDataService: AttributeDataService,
    private blueprintService: BlueprintService,
    public playlistComponentService: PlaylistComponentService,
    private analyticsFactory: AnalyticsFactory,
    private fileExistenceCheckService: FileExistenceCheckService,
    public storageManagerService: StorageManagerService,
    private webPageService: WebPageService
  ) {
    this.playlistService.reset();

    componentsService.registerDirective({
      type: 'rise-playlist',
      element: this.elementRef.nativeElement,
      show: () => {
        this.playlistService.componentId = this.componentsService.selected.id;
        this.playlistService.playlistItems = [];

        playlistComponentService.onAddHandler = this.addItems.bind(this);

        this._load();

        setTimeout(() => {
          UpgradeNoticeComponent.hidePopovers(this.notices);
        });

        this.analyticsFactory.track('Playlist Viewed', {
          componentId: this.playlistService.componentId
        });
      },
      onBackHandler: () => {
        playlistComponentService.onAddHandler = null;
      }
    });

    componentsService.registerDirective({
      type: 'rise-playlist-item',
      element: this.elementRef.nativeElement,
      show: () => {
        if (this.selectedItem.name) {
          this.componentsService.setPanelTitle(this.selectedItem.name);
        } else {
          const item = this.playlistService.playlistItems[this.selectedItem.key];

          this.componentsService.setPanelTitle(this.playlistService.getComponentName(item));
        }
      },
      onBackHandler: () => {
        this.componentsService.resetPanelHeader();
      }
    });

    this.subscription = componentsService.componentValueUpdated$.subscribe((source) => {
      this._setFileMissing(source, false);
    });

  }

  private _setFileMissing (source, value) {
    const file = this.playlistService.playlistItems.find((item) => item.attributes?.source === source);
    if (file) {
      file.fileMissing = value;
    }
  }

  _updatePlaylistComponents() {
    const allowedComponents = this.attributeDataService.getBlueprintData(this.playlistService.componentId, 'allowed-components');

    if (!allowedComponents || allowedComponents === '*') {
      this.playlistService.playlistComponents = ComponentsService.PLAYLIST_COMPONENTS;
    } else {
      var componentsArray = allowedComponents.split(',');

      this.playlistService.playlistComponents = _.filter(ComponentsService.PLAYLIST_COMPONENTS, (component: any) => {
        return componentsArray.indexOf(component.type) !== -1;
      });
    }
  }

  _loadPresentationNames() {
    if (!this.playlistService.playlistItems || !this.playlistService.playlistItems.length) {
      return;
    }

    const presentationItems = this.playlistService.playlistItems.filter(this.isEmbeddedTemplate);

    if (!presentationItems?.length) {
      return;
    }

    this.playlistComponentService.loadPresentationNames(presentationItems);
  }

  _load() {
    this._updatePlaylistComponents();

    const itemsJson = this.attributeDataService.getAvailableAttributeData(this.playlistService.componentId, 'items');
    this.playlistService.playlistItems = this.playlistService.jsonToPlaylistItems(itemsJson);

    const fileNames = this.playlistService.playlistItems
      .filter((item) => item.attributes?.type === MediaSelectorService.FOLDER_TYPE.STORAGE_FILE)
      .map((file) => String(file.attributes.source));

    this.fileExistenceCheckService.requestMetadataFor(fileNames, LogoImageService.DEFAULT_IMAGE_THUMBNAIL).then((metadata) => {
      metadata.forEach((meta) => {
        if (!meta.exists) {
          this._setFileMissing(meta.file, true);
        }
      });
    });

    this.playlistService.playlistItems
      .filter((item) => item.attributes?.type === MediaSelectorService.FOLDER_TYPE.STORAGE_FOLDER)
      .forEach((item) => {
        let source = String(item.attributes.source);
        source = source.substring(source.indexOf('/') + 1);
        // ensure the file type 'image,video,folder' is used in loadFiles function
        this.storageManagerService.loadFiles(source, StorageManagerService.FILE_TYPE.IMAGE_VIDEO_FOLDER).then((files) => {
          item.fileMissing = files.length === 0;
        });
      });

    this._loadPresentationNames();
  }

  get webPageServiceGetter() {
    return this.webPageService;
  }

  showComponentsDropdown() {
    return this.blueprintService.isRiseInit() && !!this.playlistService.playlistComponents && this.playlistService.playlistComponents.length > 0;
  }

  showProperties() {
    this.componentsService.editComponent({
      type: 'rise-playlist-item'
    });
  }

  isEmbeddedTemplate(item) {
    return item.tagName === 'rise-embedded-template' || !item.tagName;
  }

  addItems(itemsToAdd, editItem?) {
    this.playlistService.playlistItems = this.playlistService.playlistItems.concat(itemsToAdd);
    this.playlistService.save();

    console.debug('add items', itemsToAdd);

    if (editItem && itemsToAdd[0]) {
      this.playlistService.editComponent(itemsToAdd[0]);
    }
  }

  private _findItemNameInput(key: number): ElementRef {
    return this.itemNameInputs.find((el) => el.nativeElement.id === `input-item-name-${key}`);
  }

  getDisplayName(item: any): string {
    return this.isEmbeddedTemplate(item) ? item.label || item.name : this.playlistService.getComponentName(item);
  }

  renameItem(key: number): void {
    this.playlistService.playlistItems[key].editing = true;
    const input = this._findItemNameInput(key);
    setTimeout(() => {
      input.nativeElement.focus();
    });
  }

  itemNameKeyup(key: number, event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      const input = this._findItemNameInput(key).nativeElement;
      input.value = this.getDisplayName(this.playlistService.playlistItems[key]);
      input.blur();
    } else if (event.key === 'Enter') {
      this._findItemNameInput(key).nativeElement.blur();
    }
  }

  saveItemName(key: number, event: Event): void {
    const item = this.playlistService.playlistItems[key];
    const value = (<HTMLInputElement>event.target).value.trim();
    if (value !== this.getDisplayName(item)) {
      item.label = value;
      this.playlistService.save();
    }
    item.editing = false;
  }

  copyItem(key) {
    const item = _.cloneDeep(this.playlistService.playlistItems[key]);

    // Duplicate Authenticated Web Page credential, if any
    if (this.webPageService.isWebPageComponent(item.tagName) && item.attributes?.credential) {
      item.attributes.credential = this.webPageService.copyCredential(item.attributes.credential);
    }

    this.playlistService.playlistItems.splice(key + 1, 0, item);

    this.playlistService.save();
  }

  removeItem(key) {
    const item = this.playlistService.playlistItems[key];

    this.playlistService.playlistItems.splice(key, 1);

    // Remove Authenticated Web Page credential, if any
    if (item && this.webPageService.isWebPageComponent(item.tagName) && item.attributes?.credential) {
      this.webPageService.removeCredential(item.attributes.credential);
    }

    this.playlistService.save();
  }

  sortItem(evt) {
    this.moveItem(evt.data.oldIndex, evt.data.newIndex);

    this.playlistService.save();
  }

  moveItem(oldIndex, newIndex) {
    this.playlistService.playlistItems.splice(newIndex, 0, this.playlistService.playlistItems.splice(oldIndex, 1)[0]);
  }

  durationToText(item) {
    return item['play-until-done'] ? 'PUD' : (item.duration ? item.duration : '10') + 's';
  }

  _updatePlayUntilDone(isSupported) {
    this.selectedItem['play-until-done-supported'] = isSupported;

    this.selectedItem['play-until-done'] = this.selectedItem['play-until-done-supported'] &&
      this.selectedItem['play-until-done'];
  }

  editProperties(key) {
    this.selectedItem = _.cloneDeep(this.playlistService.playlistItems[key]);
    this.selectedItem.key = key;

    //set default values
    this.selectedItem.duration = Number.isInteger(this.selectedItem.duration) ? this.selectedItem
      .duration : 10;
    this.selectedItem['transition-type'] = this.selectedItem['transition-type'] ? this.selectedItem[
      'transition-type'] : 'normal';
    this.selectedItem.timeline = this.selectedItem.timeline ? this.selectedItem.timeline : {};

    if (!this.isEmbeddedTemplate(this.selectedItem)) {
      var component = this.playlistService.getComponentByType(this.selectedItem.tagName);

      this._updatePlayUntilDone(!!component.playUntilDone);

      this.showProperties();
    } else {
      this.blueprintService.isPlayUntilDone(this.selectedItem.productCode)
        .then((res) => {
          this._updatePlayUntilDone(res);
        })
        .catch(() => {
          this._updatePlayUntilDone(false);
        })
        .finally(() => {
          this.showProperties();
        });
    }
  }

  saveTimeline() {
    this.saveProperties();

    this.analyticsFactory.track('Playlist Item Property Updated', {
      componentId: this.playlistService.componentId,
      propertyName: 'timeline'
    });
  }

  saveProperties() {
    const item = this.playlistService.playlistItems[this.selectedItem.key];

    item.duration = Number.isInteger(this.selectedItem.duration) ? this.selectedItem.duration : 10;
    item['play-until-done'] = this.selectedItem['play-until-done'];
    item['transition-type'] = this.selectedItem['transition-type'];
    item.timeline = this.selectedItem.timeline;

    this.playlistService.save();
  }

  savePlayUntilDone() {
    this.selectedItem['play-until-done'] = !this.selectedItem['play-until-done'];

    this.saveProperties();
  }

  editPlaylistItem(key) {
    const item = this.playlistService.playlistItems[key];

    if (this.requiresUpgrade(item)) {
      this.featuresService.showUpgradePlanModal(item.tagName === 'rise-powerbi' ? FeaturesService.FEATURE_POWER_BI : '');
    } else {
      this.playlistService.editComponent(item);
    }
  }

  showAddPlaylistItem() {
    this.componentsService.editComponent({
      type: 'rise-playlist-item-selector'
    });
  }

  editWithCanva(key: number) {
    const item = this.playlistService.playlistItems[key];

    if (item.attributes?.source) {
      this.playlistService.showCanva(String(item.attributes.source));
    }
  }

  requiresUpgrade(item: PlaylistTemplateItem): boolean {
    return item.tagName === 'rise-powerbi' && !this.featuresService.isFeatureAvailable(FeaturesService.FEATURE_POWER_BI);
  }

}
