import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'display-preview',
  templateUrl: './display-preview.component.html',
  styleUrl: './display-preview.component.scss'
})
export class DisplayPreviewComponent {

  zoomIn = false;

  _sanitizedPreviewUrl;
  _previewUrl;
  get santitizedPreviewUrl() {
    let previewUrl = '';
    if (this.data.selectedSchedule.id) {
      previewUrl = environment.SHARED_SCHEDULE_URL.replace('SCHEDULE_ID', this.data.selectedSchedule.id) + '&env=apps_display';
    }

    if (previewUrl !== this._previewUrl || !this._sanitizedPreviewUrl) {
      this._previewUrl = previewUrl;
      this._sanitizedPreviewUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(previewUrl);
    }

    return this._sanitizedPreviewUrl;
  }

  constructor(
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: { selectedSchedule: any }
  ) {}

  toggleZoom() {
    this.zoomIn = !this.zoomIn;
  }
}
