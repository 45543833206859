<form>
  <div class="modal-header">
    <button type="button" id="closeButton" class="close" (click)="cancel()" aria-label="Click to close this modal">
      <streamline-icon ariaLabel="Click to close this modal" name="close" width="12" height="12"></streamline-icon>
    </button>
    <h4 id="title" class="modal-title">Link To "{{ copyFile.name }}"</h4>
  </div>
  <div id="copyUrlModal" class="modal-body">
    <div class="input-group">
      <input #copyUrlInput
        id="copyUrlInput"
        type="text"
        readonly
        class="form-control readonly-clean"
        value="{{ copyUrl }}"
        (focus)="selectInput()"
        [focusMe]="true"
      >
      <span class="input-group-addon u_clickable" title="Copy URL" (mousedown)="copyToClipboard($event)">
        <streamline-icon ariaLabel="Copy URL" name="copy" width="15" height="15"></streamline-icon>
      </span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary btn-toolbar" (click)="cancel()">
      Ok
    </button>
  </div>

</form>
