import { BsModalRef } from 'ngx-bootstrap/modal';

import { ModalStackService } from './modal-stack.service';

export abstract class ModalComponent {

  promise: Promise<any>;
  private _reject: any;
  private _resolve: any;
  private _isResolved = false;

  constructor(public modalRef: BsModalRef) {
    this.promise = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });

    this.modalRef.onHide.subscribe(() => {
      if (!this._isResolved) {
        this._reject();
      }

      ModalStackService.remove(this);
    });

    ModalStackService.push(this);
  }

  resolve(value?) {
    this._isResolved = true;
    this.modalRef.hide();
    this._resolve(value);
  }

  reject(value?) {
    this._isResolved = true;
    this.modalRef.hide();
    this._reject(value);
  }

  hide() {
    this.modalRef.hide();
  }

}
