import { Component } from '@angular/core';

import { ModalService } from 'src/app/components/modals/modal.service';
import { EditorService } from '../../services/editor.service';
import { ArtboardService } from '../../services/artboard.service';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  constructor(private modalService: ModalService,
    public editorService: EditorService,
    public artboardService: ArtboardService) { }

  confirmDelete() {
    this.modalService.confirmDanger('Deleting Presentation',
      'Are you sure you want to delete this Presentation?',
      'Delete Forever'
    ).then(() => {
      this.editorService.deletePresentation();
    });
  }

}
