<div class="row">
  <div class="col-md-4">
    <label class="mb-0 u_nowrap">PO Number:</label>
  </div>
  <div class="col-md-8">
    <span [shown]="!item?.po_number && !editPoNumber">
      <a aria-label="Add PO Number" class="madero-link u_clickable" tabindex="1" (click)="editPoNumber = true">Add</a>
    </span>
    <span [shown]="item?.po_number && !editPoNumber" class="flex-row">
      <span class="mr-2 u_ellipsis">
      {{item.po_number}}
      </span>
      <a aria-label="Edit PO Number" class="madero-link u_clickable" tabindex="1" (click)="editPoNumber = true">Edit</a>
    </span>
    <span [shown]="editPoNumber">
      <a aria-label="Cancel PO Number" class="madero-link u_clickable" (click)="hideEditForm()" tabindex="1">Cancel</a>
    </span>
  </div>
</div>
<div class="row" [shown]="editPoNumber">
  <div class="col-md-12">
    <div class="flex-row">
      <input id="po-number" aria-required="false" type="text" class="form-control mr-3" name="poNumber" [(ngModel)]="item.poNumber" maxlength="100">
      <button id="update-po-number" type="button" aria-label="Update PO Number" class="btn btn-default" (click)="updatePoNumber()">Update</button>
    </div>
  </div>
</div>
