import { ElementRef, Component } from '@angular/core';
import { ComponentsService } from '../../services/components.service';
import { BrandingService } from '../services/branding.service';

@Component({
  selector: 'template-branding-colors',
  templateUrl: './branding-colors.component.html',
  styleUrls: ['./branding-colors.component.scss']
})
export class BrandingColorsComponent {

  constructor(private elementRef: ElementRef,
      private componentsFactory: ComponentsService, 
      public brandingFactory: BrandingService) {
    componentsFactory.registerDirective({
      type: 'rise-branding-colors',
      element: this.elementRef.nativeElement
    });
  }

  saveBranding() {
    this.brandingFactory.setUnsavedChanges();
  }
}
