import { Injectable } from '@angular/core';
import { md5 } from 'js-md5';

import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import { CompanyStateService } from './company-state.service';
import { RvTokenStoreService } from './rv-token-store.service';
import { UserApiService } from 'src/app/api/services/user-api.service';
import { LocalStorageHelperService } from 'src/app/shared/services/local-storage-helper.service';
import { ObjectHelperService } from 'src/app/shared/services/object-helper.service';
import { BroadcasterService } from 'src/app/shared/services/broadcaster.service';

@Injectable({
  providedIn: 'root'
})
export class UserStateService {

  PROFILE_PICTURE_URL = 'https://www.gravatar.com/avatar/{emailMD5}?d=mp';

  _state: any = {
    profile: {}, //Rise vision profile
    user: {}, //Google user
    roleMap: {},
    userToken: this.rvTokenStoreService.read(),
    isRiseAuthUser: false,
    loginMethod: null
  };

  constructor(
    private rvTokenStoreService: RvTokenStoreService,
    private localStorageHelperService: LocalStorageHelperService,
    private objectHelperService: ObjectHelperService,
    private broadcasterService: BroadcasterService,
    private companyStateService: CompanyStateService,
    private userApiService: UserApiService
  ) { }

  refreshProfile() {
    //populate profile if the current user is a rise vision user
    return this.userApiService.getProfile(this._state.user.username, true)
      .then((profile) => {
        this.updateUserProfile(profile);

        //populate company info
        return this.companyStateService.init();
      });
  }

  isLoggedIn() {
    if (!this._state.user.username) {
      return false;
    } else {
      return true;
    }
  }

  isRiseVisionUser() {
    return this._state.profile.username !== null &&
      this._state.profile.username !== undefined;
  }

  hasRole(role) {
    return angular.isDefined(this._state.roleMap[role]);
  }

  getAccessToken() {
    return (window as any).gapi && (window as any).gapi.auth ?
      (window as any).gapi.auth.getToken() : null;
  }

  _restoreState() {
    var sFromStorage = this.localStorageHelperService.get('risevision.common.userState');
    if (sFromStorage) {
      angular.extend(this._state, sFromStorage);
      this.localStorageHelperService.remove('risevision.common.userState'); //clear
      console.debug('userState restored with', sFromStorage);
    }
  }

  _resetState() {
    this.objectHelperService.clearObj(this._state.user);
    this.objectHelperService.clearObj(this._state.profile);
    this._state.roleMap = {};

    this.companyStateService.resetCompanyState();
    console.debug('User state has been reset.');
  }

  _getEmailMD5() {
    var emailHash = this.getUsername() && md5(
      this.getUsername());
    var gravatarId = emailHash || '0';
    return this.PROFILE_PICTURE_URL.replace('{emailMD5}', gravatarId);
  }

  // user getters
  getUsername() {
    return (this._state.user && this._state.user.username) || null;
  }
  getUserHash() {
    var hash = this.getUsername() && this.companyStateService.getUserCompanyId() && md5(this.getUsername() + ':' + this.companyStateService.getUserCompanyId());

    return hash || null;
  }
  getUserFullName() {
    var firstName = (this._state.profile && this._state.profile.firstName) || '';
    var lastName = (this._state.profile && this._state.profile.lastName) || '';

    return (firstName + ' ' + lastName).trim();
  }
  getUserEmail() {
    return this._state.profile.email;
  }
  getCopyOfProfile(noFollow?) {
    if (noFollow) {
      return angular.extend({}, this._state.profile);
    } else {
      return this.objectHelperService.follow(this._state.profile);
    }
  }
  getUserPicture() {
    return this._state.user.picture || this._getEmailMD5();
  }
  hasPermission(roles) {
    if (!roles) {
      return true;
    }
    var checkRoles = roles.split(' ');
    for (var role of checkRoles) {
      var negated = false;
      if (role.charAt(0) === '!') {
        negated = true;
        role = role.substring(1);
      }
      if (this.hasRole(role)) {
        if (!negated) {
          return true;
        }
      } else if (negated) {
        return true;
      }
    }
    return false;
  }
  isRiseAdmin() {
    return this.hasRole('sa') && this.companyStateService.isRootCompany();
  }
  isRiseStoreAdmin() {
    return this.hasRole('ba') && this.companyStateService.isRootCompany();
  }
  isUserAdmin() {
    return this.hasRole('ua');
  }
  isPurchaser() {
    return this.hasRole('pu');
  }
  isRiseAuthUser() {
    return this._state.isRiseAuthUser;
  }
  isSignUp() {
    return !!this._state.isSignUp;
  }
  isAssignedPublisher() {
    return this.hasRole('ap');
  }
  isModerator() {
    return this.hasRole('sm');
  }
  // user functions
  checkUsername(username?) {
    return (username || false) &&
      (this.getUsername() || false) &&
      username.toUpperCase() === this.getUsername().toUpperCase();
  }
  updateUserProfile(user) {
    if (this.checkUsername(user.username)) {
      this.objectHelperService.clearAndCopy(angular.extend({
        username: this._state.user.username
      }, user), this._state.profile);

      //set role map
      this._state.roleMap = {};
      if (this._state.profile.roles) {
        this._state.profile.roles.forEach((val) => {
          this._state.roleMap[val] = true;
        });
      }

      this.broadcasterService.emit('risevision.user.updated');
    }
  }
  getLoginMethod() {
    return this._state.loginMethod;
  }
  setLoginMethod(method) {
    this._state.loginMethod = method;
  }

  _persistState() {
    // persist user state
    this.localStorageHelperService.set('risevision.common.userState', this._state);
  }

  _setIsRiseAuthUser(isRiseAuthUser) {
    this._state.isRiseAuthUser = isRiseAuthUser;
  }

}

angular.module('risevision.apps.services')
  .factory('userState', downgradeInjectable(UserStateService));