import { Component, OnInit } from '@angular/core';

import { UIRouterGlobals } from '@uirouter/angular';

@Component({
  selector: 'unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
  email;
  id;
  name;

  constructor(private uiRouterGlobals: UIRouterGlobals) {}

  ngOnInit(): void {
    this.email = this.uiRouterGlobals.params.email;
    this.id = this.uiRouterGlobals.params.id;
    this.name = this.uiRouterGlobals.params.name;
  }
}
