import { Injectable } from '@angular/core';
import { ModalService } from 'src/app/components/modals/modal.service';

@Injectable({
  providedIn: 'root'
})
export class UploadOverwriteWarningService {

  confirmOverwriteModal;

  constructor(
    private modalService: ModalService
  ) { }

  checkOverwrite (resp, skip = false) {
    if (resp.isOverwrite === true && !skip) {
      //multiple uploads can trigger the modal, they should all use the same instance
      if (!this.confirmOverwriteModal) {
        this.confirmOverwriteModal = this.modalService.confirmDanger(
          'Are you sure you want to overwrite your files?',
          'There is a file or folder in this folder with the same name as the one(s) you are trying to upload.<br/>Uploading these new files or folder will cause the existing ones to be overwritten and could change the content on your Displays.',
          'Yes, overwrite files',
          'No, keep source files'
        );
      }
      return this.confirmOverwriteModal;
    } else {
      return Promise.resolve();
    }
  }

  resetConfirmation () {
    this.confirmOverwriteModal = undefined;
  }
}
