import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { COUNTRIES } from 'src/app/ajs-upgraded-providers';

@Pipe({
  name: 'countryName',
  pure: false
})
export class CountryNamePipe implements PipeTransform {
  private _country: any;

  constructor(private countries: COUNTRIES) { }

  transform(countryCode: string): string {
    if (this._country && this._country.code === countryCode) {
      return this._country.name;
    }

    this._country = _.find(this.countries, {
      code: countryCode
    });

    return this._country && this._country.name || countryCode;
  }

}
