import { Injectable } from '@angular/core';
import { PrimusService } from './primus.service';

@Injectable({
  providedIn: 'root'
})
export class ScreenshotRequesterService {

  constructor(private primusService: PrimusService) { }

  request(coreRequester: Function): Promise<any> {
    return new Promise((resolve, reject) => {
      this.primusService.create().then((primus) => {
        const timer = setTimeout(() => {
          primus.end();
          reject('timeout');
        }, 10000);

        primus.on('data', (data) => {
          if (data.msg === 'client-connected') {
            coreRequester(data.clientId)
              .then(null, (err) => {
                primus.end();
                reject(err);
              });
          } else if (data.msg === 'screenshot-saved') {
            clearTimeout(timer);
            primus.end();
            resolve(data);
          } else if (data.msg === 'screenshot-failed') {
            clearTimeout(timer);
            primus.end();
            reject('screenshot-failed');
          }
        });

        primus.on('error', (err) => {
          primus.end();
          clearTimeout(timer);
          reject(err);
        });
      });
    });
  }
}
