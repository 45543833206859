<div class="component-container">
  <div class="rise-playlist-container attribute-editor-component">
    <div dropdown container=".workspace-sidebar">
      <button id="addContentButton" dropdownToggle class="btn btn-default btn-block mb-2">
        Add
      </button>
      <ul class="dropdown-menu" *dropdownMenu role="menu">
        <li><a id="addImageButton" class="u_clickable" (click)="playlistItemService.selectFiles('images')">Image</a></li>
        <li><a id="addVideoButton" class="u_clickable" (click)="playlistItemService.selectFiles('videos')">Video</a></li>
        <li><a id="addTextButton" class="u_clickable" (click)="playlistItemService.addTextWidget()">Text</a></li>
        <li><a id="addContentButton" class="u_clickable" (click)="playlistItemService.addContent()">Widgets</a></li>
      </ul>
    </div>
    <div class="workspace-sidebar-body">
      <div (rvSortable)="sortItem($event)" appendTo=".workspace-sidebar" class="sortable-list" [shown]="placeholderPlaylistService.getItems().length">
        @for (item of placeholderPlaylistService.getItems(); track item) {
          <div class="templates-row rv-sortable-item">
            <div class="rv-sortable-handle">
              <streamline-icon aria-label="Click and hold to drag and sort" class="streamline-component-icon" name="sortableHandle" width="14" height="14"></streamline-icon>
            </div>
            <div>
              <streamline-icon aria-hidden="true" class="streamline-component-icon" name="{{ widgetUtils.getSvgIcon(item) }}" width="24" height="18"></streamline-icon>
            </div>
            <div class="px-2 mr-auto u_text-ellipsis">
              <span class="templates-name" [shown]="!isEditable(item)">{{ item.name }}</span>
              <a class="madero-link" href="#" (click)="showSettingsModal(item)" [shown]="isEditable(item)">
                <span class="templates-name">{{ item.name }}</span>
              </a>
            </div>
            <a class="madero-link u_text-ellipsis edit-properties" href="#" (click)="playlistItemService.edit(item)">
              @if (item.playUntilDone) {
                <span class="playlist-item-duration">PUD</span>
              }
              @else {
                <span class="playlist-item-duration">{{item.duration}}s</span>
              }
            </a>
            <a href="#" (click)="placeholderPlaylistService.duplicateItem(item)">
              <streamline-icon aria-label="Click to duplicate this item" class="ml-3 mr-0 streamline-component-icon" name="copy" width="16" height="18"></streamline-icon>
            </a>
            <a href="#" (click)="remove(item)">
              <streamline-icon aria-label="Click to remove this item" class="ml-3 streamline-component-icon" name="trash" width="16" height="18"></streamline-icon>
            </a>
          </div>
        }
      </div>
      <!-- Empty State -->
      <div [hidden]="placeholderPlaylistService.getItems().length">
        <p class="text-muted text-center u_remove-bottom">Click to add Image, Video, Text or Widgets to this Playlist</p>
      </div>
    </div>
  </div>
</div>
