import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { GoogleService } from './google.service';

@Injectable({
  providedIn: 'root'
})
export class PowerBIService extends GoogleService {
  public static readonly ENDPOINT_GROUPS = 'groups';
  public static readonly ENDPOINT_DASHBOARDS = 'dashboards';

  getGroups(account) {
    let options = this._getRequestOptions();

    options.params = new HttpParams({fromObject:{
      account: account
    }});

    const request = new HttpRequest( 'GET',
      environment.POWERBI_SERVICE_URL + PowerBIService.ENDPOINT_GROUPS, options
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body && response.body.groups) {
          return response.body.groups;
        } else {
          throw new Error('Invalid powerbi groups data');
        }
      }).catch((err) => {
        console.error('Failed to get powerbi groups.', err);

        throw err;
      });
  }

  getDashboards(account, groupId) {
    let options = this._getRequestOptions();

    options.params = new HttpParams({fromObject:{
      account: account,
      groupId: groupId === 'my-workspace' ? '' : groupId
    }});

    const request = new HttpRequest( 'GET',
      environment.POWERBI_SERVICE_URL + PowerBIService.ENDPOINT_DASHBOARDS, options
    );

    return this.httpClient.request(request)
      .toPromise()
      .then((response: any) => {
        if (response && response.body && response.body.dashboards) {
          return response.body.dashboards;
        } else {
          throw new Error('Invalid powerbi dashboards data');
        }
      }).catch((err) => {
        console.error('Failed to get powerbi dashboards.', err);

        throw err;
      });
  }

}
